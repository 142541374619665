import React, { Component } from 'react';
import { Layout, Col, Row, Empty, Card } from 'antd';
import Settings from 'services/config/Settings';
import { withTranslation } from 'react-i18next';
import fetchJSON from 'services/utils/fetchJSON';
import Menu from '../../SingleBranch/components/Menu/Menu';

const { Content } = Layout;

class ManagerMenu extends Component {
	state = {
		isFetching: false,
    menuId: null
	}
	componentDidMount() {  
    this.mounted = true;
    const { t } = this.props;
    document.title = `${t('shareable.menu', { defaultValue: 'Menu' })} | ${Settings.title}`;
    this.getMenuId();
  }
  getMenuId = () => {
    this.setState({
      isFetching: true
    });
    fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/menus/getManagerMenuId`, {
      method: 'get'
    }).then(response => {
      if (this.mounted) {
        this.setState({
          menuId: response.data,
          isFetching: false
        })
      }
    }).catch(error => {
      //console.log(error);
      if (this.mounted) { 
        this.setState({
          isFetching: false,
          menuId: null
        });        
      }
    })
  }
  render() {
    const {
      isFetching,
      menuId
    } = this.state;
    
    return (
      <Content className="bg-transparent table-content">
        <Card
          className="general-card" 
          loading={isFetching}
          >
          <Row gutter={8}>
            <Col span={24}>
            { menuId ? 
              <Menu 
                role={'manager'}
                menuId={menuId}
                branchUuid={this.props.branchUuid}
                restaurantId={this.props.related_restaurant_id}
                restaurantUuid={this.props.related_restaurant_uuid}
                history={this.props.history}
                /> :
                <Empty />}
            </Col>
          </Row>
        </Card>
      </Content>
    )
  }
}

export default withTranslation()(ManagerMenu);