import React from 'react';
import _ from 'lodash';
import { withTranslation, Trans } from 'react-i18next';
import { Row, Button, Steps, Input, Col, Form, Checkbox, Spin, Collapse, Modal } from 'antd';
import { withWindowSizeListener } from 'react-window-size-listener';
import ProductsDraggableTable from '../ProductsDraggableTable/ProductsDraggableTable';
import * as _sharedModules from '../../../../../../../modules/_modules';
import CategoriesDraggableTable from '../CategoriesDraggableTable/CategoriesDraggableTable';

import './styles.less';

const { Step } = Steps;
const { Panel } = Collapse;

class AddMenu extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      isFetching: false,
      categories: [],
      currentStep: 1,
      categoriesList: [],
      anyBranchURL: ''
    };
    Object.keys(_sharedModules).forEach((_moduleName) => {
      this[_moduleName] = _sharedModules[_moduleName].bind(this);
    });
  }
  componentDidMount() {
    this.mounted = true;
    const { addStep, menuId } = this.props;
    this.getCategories();
    this.getBranches();
    this.getMenu(menuId);
    this.setState({
      currentStep: addStep || 0
    })
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  addMenu = () => {
    return this.formRef.current.validateFields(['branches', 'display_name'])
    .then((values) => {
      this.createMenu(values, true);
    })
  }
  renderCollapsePanels = () => {
    const { menuObj } = this.state;
    const render = [];
    const categoriesOrder = _.get(menuObj, 'categories_order');
    if (categoriesOrder && Array.isArray(categoriesOrder) && categoriesOrder.length) {
      categoriesOrder.forEach((category, i) => {
        const categoryName = _.get(category, 'category.display_name');
        const categoryId = category.category_id;
        if (categoryName && categoryId) {
          render.push(
            <Panel key={i} header={categoryName}>
              <ProductsDraggableTable
                menuId={menuObj.id}
                productsInMenu={menuObj.products}
                categoryId={categoryId}
                productsCategory={this.props.products[categoryId]}
                updateMenu={() => this.getMenu(menuObj.id)}
                />
            </Panel>);
        }
      })
    }
    return render
  }
  renderBranchOptions = () => {
    const branchesStorage = [];
    const branchesArr = [];
    const { branches } = this.state;
    const render = [];
    if (branches && branches.length) {
      branches.forEach((branch) => {
        if (branchesStorage.indexOf(branch.id) === -1) {
          branchesStorage.push(branch.id);
          branchesArr.push({ id: branch.id, title: branch.title });
        }
      })
    }
    if (branchesArr && Array.isArray(branchesArr)) {
      branchesArr.forEach((branch, i) => {
        render.push(
          <Row key={i}>
            <Checkbox style={{lineHeight: '32px'}} key={i} value={branch.id}>{branch.title}</Checkbox>
          </Row>
        )
      });
    }
    return render
  }
  renderCategoriesOptions = () => {
    const { categories, categoriesList } = this.state;
    const isMobile = this.props.windowSize.windowWidth < 576;
    const render = [];
    let group = [];
    if (categories && categories.length) {
      categories.forEach((category, i) => {
        const categoryName = _.get(category, 'category.display_name');
        const categoryId = category.category_id;
        if (categoryId && categoryName) {
          const classNameButton = categoriesList.indexOf(categoryId) > -1 ? 'category-btn__selected' : 'category-btn__not-selected';
          group.push(
            <Col key={i} xs={12} sm={6}>
              <Button onClick={() => this.addCategory(categoryId)} className={classNameButton}>{categoryName}</Button>
            </Col>
          )
          if ((isMobile && group.length === 2) || (!isMobile && group.length === 4)) {
            render.push(<Row key={i} gutter={[16, 16]}>{group}</Row>);
            group = [];
          }
          if ((i + 1) === categories.length && group.length) {
            render.push(<Row key={i} gutter={[16, 16]}>{group}</Row>);
          }
        }
      })
    }
    return render
  }
  addCategory = (categoryId) => {
    const { categoriesList } = this.state;
    const list = [];
    if (categoriesList.indexOf(categoryId) === -1) {
      list.push(...categoriesList, categoryId);
    } else {
      categoriesList.forEach((id) => {
        if (categoryId !== id && list.indexOf(id) === -1) {
          list.push(id);
        }
      })
    }
    if (!list.length && categoryId) {
        Modal.info({
          title: 'Menu must have at least one category'
        });
        return;
    }
    this.setState({ categoriesList: list });
  }
  goNextStep = () => {
    const { currentStep, categoriesList } = this.state;
    if (currentStep === 1) {
      if (!categoriesList || !categoriesList.length) {
        Modal.info({
          title: 'Choose at least one category'
        });
        return;
      }
    }
    //this.props.history.push(`${this.props.history.location.pathname}?menu=1&add=1&step=${currentStep+2}`);
    this.setState({
      currentStep: currentStep + 1
    })
  }
  setAnyBranchURL = (branchId) => {
    const { branches } = this.state;
    let anyBranchURL = null;
    if (branches && branches.length && branchId) {
      branches.forEach((branch) => {
        if (branch.id === branchId) {
          anyBranchURL = branch.url;
        }
      })
    }

    this.setState({
      anyBranchURL
    });
  }
  branchSelected = (result) => {
    if (result && Array.isArray(result) && result.length) {
      this.setAnyBranchURL(result[0])
    } else {
      this.setAnyBranchURL(false)
    }
  }
  render() {
  	const isMobile = this.props.windowSize.windowWidth < 576;
    const { t, branchId } = this.props;
    const {
      currentStep,
      branches,
      categoriesList,
      isFetching,
      showAddCategory,
      categories,
      menuObj,
      categoriesLoaded,
      menuLoaded,
      anyBranchURL
    } = this.state;

  	return (
  		<div>
        <Steps
          current={currentStep}
          onChange={this.onStepChange}
          style={{ marginBottom: '30px'}}
        >
          <Step title={t('shareable.general', { defaultValue: 'General'})}/>
          <Step title={t('shareable.categories', { defaultValue: 'Categories'})} />
          <Step title={t('shareable.products', { defaultValue: 'Products'})} />
        </Steps>
        <Form
          className="general-form--big mobile-margin-fix-form"
          style={{ minHeight: '300px'}}
          ref={this.formRef}
          onFinish={this.handleSubmit}
          scrollToFirstError={true}
          initialValues={{
            branches: branchId ? [branchId] : []
          }}>
          <Spin spinning={isFetching || ((!branches || branches.length === 0) && currentStep === 0)}>
          { currentStep === 0 ?
            <div>
              <Row>
                <label className="formDefault__label">
                  <Trans i18nKey="shareable.display_name">Display name</Trans>
                </label>
              </Row>
              <Row id='formitem_display_name'>
                <Form.Item className="addLocation__xs--fw" name="display_name" rules={[{ required: true, message: t('shareable.required.name', { defaultValue: 'Please enter a name' }) }]} >
                  <Input className="addLocation__input--short" placeholder={t('shareable.display_name', { defaultValue: 'Display name' })} />
                </Form.Item>
              </Row>
              <Row style={isMobile ? {
                  marginTop: '16px'
                } : {}}>
                <label className="formDefault__label" style={{
                    display: branchId ? 'none' : ''
                  }}>
                  <Trans i18nKey="shareable.venues">Venues</Trans>
                </label>
              </Row>
              <Row>
                <Form.Item
                  name="branches"
                  rules={[{ required: true, message: t('shareable.required.branch', { defaultValue: 'Please select at least one branch' }) }]}
                  style={{
                    display: branchId ? 'none' : ''
                  }}
                  >
                  <Checkbox.Group onChange={this.branchSelected}>
                    {this.renderBranchOptions()}
                  </Checkbox.Group>
                </Form.Item>
              </Row>
              <Form.Item>
                <Button type="primary" onClick={this.addMenu}>Next</Button>
              </Form.Item>
            </div>
            : null }
          { currentStep === 1 && menuLoaded ?
            <div>
              <Row>
                <p>Choose categories you want to see present in menu.</p>
              </Row>
              <Row style={{marginTop: '16px'}}>
                <Col sm={18} xs={24}>
                  {this.renderCategoriesOptions()}
                </Col>
              </Row>
              { categoriesLoaded && menuLoaded ?
              <Row>
                <Col xs={24} sm={12}>
                  <CategoriesDraggableTable
                    history={this.props.history}
                    addCategoryVisible={showAddCategory}
                    categories={categories}
                    menuId={menuObj.id}
                    categoriesOrder={menuObj.categories_order}
                    categoriesInMenu={categoriesList}
                    updateMenu={() => this.getMenu(menuObj.id)}
                    closeAddCategory={() => this.setState({ showAddCategory: false })}
                    updateCategoriesList={this.getCategories}
                    restaurantId={this.props.restaurantId}
                    />
                </Col>
              </Row> : null }
              <Row style={{marginTop: '20px'}}>
                <Button type="primary" onClick={this.goNextStep}>Next</Button>
              </Row>
            </div>
            : null }
          { currentStep === 2 && menuLoaded ?
            <div>
              <Row>
                <p>Add products to each category</p>
              </Row>
              <Collapse className="collapse-gray" destroyInactivePanel={true}>
                {this.renderCollapsePanels()}
              </Collapse>
              <Row style={{marginTop: '20px'}}>
                <Button type="primary" onClick={() => {
                  if (anyBranchURL) {
                    const url = 'https://app.dezy.com.au/i/' + anyBranchURL;
                    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
                    if (newWindow) newWindow.opener = null;
                  }

                  window.location.href = `${this.props.history.location.pathname}?menues=1`;}
                }>Finish{anyBranchURL ? ' and Preview' : '' }</Button>
              </Row>
            </div>
            : null }
            </Spin>
          </Form>
		  </div>
  	)
  }
}
export default  withTranslation()(withWindowSizeListener(AddMenu));
