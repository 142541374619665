import React from 'react';
import Settings from 'services/config/Settings';
import fetchJSON from 'services/utils/fetchJSON';
import { Link } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
import qs from 'qs'; 
import _ from 'lodash';
import { Layout, Card, Row, Form, Input, Col, Empty, Button, Select, Alert, message } from 'antd';
import BranchesTable from '../../../Branches/components/BranchesTable';
import AddressField from '../../../components/AddressField/AddressField';
import * as _sharedModules from '../../../modules/_modules';

import '../../styles.less';
import { LeftOutlined } from '@ant-design/icons';

const FormItem = Form.Item;
const Option = Select.Option; 

class SingleRestaurant extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      isFetching: false,
      branch: {},
      initialValues: {},
      addressGroup: {},
      activeTab: 'info'
    };
    Object.keys(_sharedModules).forEach((_moduleName) => {
      this[_moduleName] = _sharedModules[_moduleName].bind(this);
    });
  }
  componentDidMount = () => {
    this.mounted = true;
    const restaurantUuid = this.props.match.params.restaurantUuid;
    let activeTab = 'info';
    let paramFromUrl = {};
    if (window.location.search) {
      paramFromUrl = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      const tabList = ['info', 'branches'];
      if (Object.keys(paramFromUrl).length) {
        Object.keys(paramFromUrl).forEach((param) => {
          if (paramFromUrl[param] && tabList.indexOf(param) > -1) {
            activeTab = param;
          }
        });
      }
    }
    this.setState({ 
      restaurantUuid,
      activeTab 
    })
    this.getRestaurant(restaurantUuid);
  }
  getRestaurant = (restaurantUuid) => {
    this.setState({
      isFetching: true
    });
    fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/restaurants/${restaurantUuid}`, {
      method: 'get'
    }).then(response => {
      if (this.mounted) {
        if (response.data) {
          const restaurant = response.data;
          document.title = `${restaurant.name} | ${Settings.title}`;
          const addressGroup = {
            address: restaurant.address,
            addressComponents: restaurant.address_components,
            coordinates: restaurant.coordinates && restaurant.coordinates.lat ? restaurant.coordinates : {},
            timezone: restaurant.timezone,
            place_id: restaurant.place_id,
            currency_code: restaurant.currency_code,
            opening_hours: restaurant.opening_hours,
            country_code: restaurant.country,
            correct: true
          };
          const initialValues = {
            name: restaurant.name,
            status: restaurant.status !== null ? restaurant.status : 1,
            address: restaurant.address,
            contact_name: restaurant.contacts.name,
            contact_phone: restaurant.contacts.phone,
            contact_email: restaurant.contacts.email
          };
          this.setState({ 
            isFetching: false, 
            restaurant,
            initialValues,
            addressGroup
          })
        } else {
          this.setState({ isFetching: false });
        } 
      }    
    }).catch(error => {
      console.log(error);
      if (this.mounted) { 
        this.setState({
          isFetching: false
        });
      }
    });
  }
  handleSubmit = values => {
    const { t } = this.props;
    //console.log('values', values);
    if (! this.state.restaurant || !this.state.restaurant.id) return false;
    
    values.addressGroup = this.state.addressGroup;
    values.formatted_address = this.state.formatted_address;
    
    //console.log('values.addressGroup', values.addressGroup);
    const lat = _.get(values.addressGroup, 'coordinates.lat');

    if (!lat) {
      message.error(t('shareable.enterSelectAddressPlease', { defaultValue: 'Please, enter correct address and select right option from a dropdown list' }));
      return false;
    }

    this.setState({
      putREQUEST: true
    });
    fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/restaurants/${this.state.restaurant.id}`, {
      method: 'put',
      body: values
    }).then(response => {
      console.log(response);
      if (response.success) {
        this.setState({
          putREQUEST: false,
          putSUCCESS: true,
          putFAILURE: false,
          //errorMessage: 'Saved successfully'
        }, () => this.getRestaurant(this.state.restaurantUuid));
          message.success(t('singleLocation.feedback.saveSuccess', { defaultValue: 'Saved successfully' }));
      } else {
        this.setState({
          putREQUEST: false,
          putFAILURE: true,
          putSUCCESS: false,
          //errorMessage: response.message
        });
        message.error(response.message);
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          putSUCCESS: false,
          putREQUEST: false,
          putFAILURE: true,
        });
        message.error(t('shareable.ERR', { defaultValue: 'ERR' }));
     });

  }
  onTabChange = (key, type) => {
    const { restaurantUuid } = this.state;
    this.props.history.push(`/restaurants/${restaurantUuid}?${key}=1`);
    this.setState({
      [type]: key
    });
  }

  render() {
    const { t } = this.props;
    const { isFetching, restaurant, initialValues, activeTab, addressGroup, addressError } = this.state;
    const tabList = [{
      key: 'info',
      tab: t('shareable.info', { defaultValue: 'Info' }),
    }, {
      key: 'branches',
      tab: t('shareable.venues', { defaultValue: 'Venues' }),
    }];
    const cardClassName = activeTab === 'branches' ? "general-card card-body-no-padding" : "general-card";
    return (
      <Layout className="bg-transparent">
      { initialValues && Object.keys(initialValues).length ? 
        <Card
          className={cardClassName}
          loading={isFetching}
          tabList={tabList}
          activeTabKey={activeTab}
          onTabChange={(key) => { this.onTabChange(key, 'activeTab'); }}
          title={
            <Row>
              <Link to="/restaurants" className="general-card__back-btn"><LeftOutlined /></Link>
              <span className="general-card__title-text">{restaurant.name}</span>
            </Row>
          }
          >
          { activeTab === 'info' ?
          <Form className="general-form" layout="vertical" ref={this.formRef} initialValues={initialValues} onFinish={this.handleSubmit} requiredMark={false}>
            <Row gutter={16}>
              <Col sm={8} xs={12}>
                <FormItem name="name" label="Title">
                  <Input/>
                </FormItem>
              </Col>
              <Col sm={4} xs={12}>
                <FormItem name="status" label="Status">
                  <Select>
                    <Option value={1}>Active</Option>
                    <Option value={0}>Inactive</Option>
                  </Select>
                </FormItem>
              </Col>
            </Row>
            <FormItem>
              <Row gutter={8}>
                <Col sm={15} xs={24}>
                  <Row>
                    <h3 style={{color: '#454545'}}><Trans i18nKey="shareable.address">Address</Trans></h3>
                  </Row>
                  <Row>
                   <AddressField
                      t={this.props.t}
                      value={addressGroup}
                      triggerChanges={this.triggerAddressChanges}
                    />
                  </Row>
                  { addressError ? 
                    <div style={{marginTop: '20px'}}><Alert message={addressError} type="error" /></div>
                  : null }
                </Col>
              </Row>
            </FormItem>
            <Row>
              <Col sm={15} xs={24}>
                <Row>
                  <h3 style={{color: '#454545'}}><Trans i18nKey="shareable.contact">Contact</Trans></h3>
                </Row>
                <Row gutter={16}>
                  <Col sm={8} xs={24}>
                    <FormItem rules={[{ required: true, message: t('shareable.required.name', { defaultValue: 'Name is required!' }) }]} name="contact_name" label={t('shareable.name', { defaultValue: 'Name' })}>
                      <Input placeholder={ t('shareable.name', { defaultValue: 'Name' }) } />
                    </FormItem>
                  </Col>
                  <Col sm={8} xs={24}>
                    <FormItem rules={[{ required: true, message: t('shareable.required.phone', { defaultValue: 'Phone is required!' }) }]} name="contact_phone" label={t('shareable.phone', { defaultValue: 'Phone' })}>
                      <Input placeholder={t('shareable.phone', { defaultValue: 'Phone' })}/>
                    </FormItem>
                  </Col>
                  <Col sm={8} xs={24}>
                    <FormItem rules={[{
                        type: 'email',
                        message: t('login.email.wrongFormat', { defaultValue: 'Wrong format of email' }),
                        validateTrigger: 'onSubmit'
                      }, { required: true, message: t('shareable.required.email', { defaultValue: 'Email is required!' }) }]} name="contact_email" label={t('shareable.email', { defaultValue: 'Email' })}>
                      <Input placeholder={ t('shareable.email', { defaultValue: 'Email' }) } />
                    </FormItem>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Button className="general-card__submit-btn" htmlType="submit" type="primary">Save</Button>
            </Row>
          </Form> : null}
          { activeTab === 'branches' ? 
            <BranchesTable 
              history={this.props.history} 
              inner={true} 
              restaurantId={restaurant.id} 
              restaurantUuid={restaurant.uuid} 
              role={this.props.role}
            /> 
            : null }

          { ['info', 'branches'].indexOf(activeTab) === -1 ?
            <Empty/> : null }
        </Card> : null }
      </Layout>
    )
  }
};

export default  withTranslation()(SingleRestaurant);