import _ from 'lodash';
import moment from 'moment';

const timeStringToDate = function timeStringToDate(timeString) {
  const result = {};
  if (timeString.length === 9) {
    const times = timeString.split('-');
    const start = moment(times[0], 'HHmm');
    const end = moment(times[1], 'HHmm');
    result.startObj = start;
    result.endObj = end;
    result.start = start.format('hh:mm A');
    result.end = end.format('hh:mm A');
  }
  return result;
}

const adjustGoogleHours = function adjustGoogleHours(opening_hours) {
  this.setState({
    opening_hours
  });
  if (!opening_hours) {
    return;
  }
  if (typeof opening_hours === 'object') {
    if (Object.keys(opening_hours).indexOf('periods') > -1) {
      const { periods } = opening_hours;
      console.log('adjustGoogleHours', periods);
      if (Array.isArray(periods) && periods.length) {
        const state = {
          week: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
          availableWeekDays: [],
          slotTemplate: {
            working_days: [],
            working_hours: {
              start: '9:00 AM',
              end: '6:00 PM'
            },
            break_hours: {
              start: '12:00 PM',
              end: '1:00 PM'
            },
            break_hours_enabled: 0,
            always_open: false
          }
        };
        const businessHours = [];
        if (periods.length === 1) {
          const day = _.get(periods, '0.open.day');
          const open = _.get(periods, '0.open.time');
          const close = _.get(periods, '0.close.time');
          if (day === 0 && open === '0000' && !close) {
            businessHours.push({
              working_days: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
              working_hours: {
                start: '12:00 AM',
                end: '11:59 PM'
              },
              break_hours: {
                start: '12:00 PM',
                end: '1:00 PM'
              },
              break_hours_enabled: 0,
              always_open: true
            });
          }
        } else {
          const listOfDays = {};
          periods.forEach(period => {
            const day = _.get(period, 'open.day');
            const open = _.get(period, 'open.time');
            const close = _.get(period, 'close.time');
            if (open && close) {
              if (Object.keys(listOfDays).indexOf(day.toString()) === -1) {
                listOfDays[day.toString()] = [];
              }
              listOfDays[day.toString()].push(`${open}-${close}`);
            }
          });
          let listOfTimes = {};

          Object.keys(listOfDays).forEach((key) => {
            if (listOfDays[key].length === 2) {
              const i = `${listOfDays[key][0]}=${listOfDays[key][1]}`
              if (Object.keys(listOfTimes).indexOf(i) === -1) {
                listOfTimes[i] = [];
              }
              listOfTimes[i].push(parseInt(key, 10));
              delete listOfDays[key];
            }
          });
          Object.keys(listOfTimes).forEach(key => {
            if (key.length === 19 && listOfTimes[key].length) {
              const period = key.split('=');
              const time1 = this.timeStringToDate(period[0]);
              const time2 = this.timeStringToDate(period[1]);
              const newSlot = JSON.parse(JSON.stringify(state.slotTemplate));

              newSlot.working_hours.start = time1.start;
              newSlot.working_hours.end = time2.end;
              newSlot.break_hours.start = time1.end;
              newSlot.break_hours.end = time2.start;
              newSlot.break_hours_enabled = true;

              listOfTimes[key].forEach((day) => {
                newSlot.working_days.push(state.week[day]);
              });
              businessHours.push(newSlot);
            }
          });            
          listOfTimes = {};
          Object.keys(listOfDays).forEach((key) => {
            if (listOfDays[key].length === 1) {
              if (Object.keys(listOfTimes).indexOf(listOfDays[key][0]) === -1) {
                listOfTimes[listOfDays[key][0]] = [];
              }
              listOfTimes[listOfDays[key][0]].push(parseInt(key, 10));
              delete listOfDays[key];
            }
          });
          Object.keys(listOfTimes).forEach(key => {
            if (key.length === 9 && listOfTimes[key].length) {
              const time = this.timeStringToDate(key);
              const newSlot = JSON.parse(JSON.stringify(state.slotTemplate));
              newSlot.working_hours.start = time.start;
              newSlot.working_hours.end = time.end;
              listOfTimes[key].forEach((day) => {
                newSlot.working_days.push(state.week[day]);
              });
              businessHours.push(newSlot);
            }
          });
        }
        if (businessHours.length) {
          console.log('businessHours', businessHours);
          this.setState({
            businessHours
          });
        }
      }
    }
  }
}
const setBusinessHours = function setBusinessHours(params, callback = () => {}) {
  this.setState(params, callback);
}

export {
	timeStringToDate,
	adjustGoogleHours,
	setBusinessHours
};