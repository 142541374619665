import React from 'react';
import { Layout, Table, Button, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
import EditEmployee from './components/EditEmployee';
import AddEmployee from './components/AddEmployee';

import * as _sharedModules from '../../../modules/employees';
import * as _sharedModulesReports from '../../../modules/reports';

import './styles.less';

const { Content } = Layout;

class EmployeesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      branchesList: [],
      visibleCreateNew: false,
      visibleEdit: false,
      selectedUser: {},
      totalNumberTable: 0
    };
    Object.keys(_sharedModules).forEach((_moduleName) => {
      this[_moduleName] = _sharedModules[_moduleName].bind(this);
    });
    this['getBranchesList'] = _sharedModulesReports['getBranchesList'].bind(this);
    this.userRole = this.props.role;
    this.totalNumberTable = 0;
    this.totalNumberInterval = null;
    // for detecting if scroll is enabled
    this.scrollTableInterval = null;
    this.tableScrollChecked = false;
  }
  componentDidMount() {
    this.mounted = true;
    this.setState({
      pagination: {
        total: 0,
        pageSizeOptions: [10, 30, 50, 100],
        showSizeChanger: false,
        showTotal: (total) => {
          return <p id="totalNumber" style={{display: 'none'}}>{this.renderTotal(total)}</p>
        }
      }
    })
    this.scrollTableInterval = setInterval(this.checkIfScrollOn, 100);
    this.getEmployees();
    this.getBranchesList(false, this.props.restaurantId);
  }
  componentWillUnmount() {
    this.mounted = false;
    clearInterval(this.totalNumberInterval);
    clearInterval(this.scrollTableInterval);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.restaurantId !== this.props.restaurantId) {
      this.getEmployees();
    }
  }
  checkIfScrollOn = () => {
    const { isFetching } = this.state;
    const tableContent = document.getElementsByClassName("ant-table-content");
    if (tableContent && tableContent.length && !isFetching) {
      if (this.checkTableScroll(tableContent[0])) {
        this.setState({
          scrollTable: true
        })
      }  else {
        this.setState({
          scrollTable: false
        })
      }
      clearInterval(this.scrollTableInterval);
    }
  }
  checkTableScroll = (e) => {
    const tableClass = 'ant-table-wrapper generalTable';
    const tableWrapper = document.getElementsByClassName(tableClass);
    this.tableScrollChecked = true;
    if (tableWrapper && tableWrapper[0] && e) {
      return (tableWrapper[0].offsetWidth < e.scrollWidth)
    }
    return false
  }
  trackTotalNumberValue = () => {
    clearInterval(this.totalNumberInterval);
    const { totalNumberTable } = this.state;
    if (totalNumberTable !== this.totalNumberTable) {
      this.setState({
        totalNumberTable: this.totalNumberTable
      });
    }
    this.totalNumberInterval = null;
  }
  renderTotal = (total) => {
   this.totalNumberTable = total;
   const { totalNumberTable } = this.state;
   if (totalNumberTable !== this.totalNumberTable && !this.totalNumberInterval) {
    this.totalNumberInterval = setInterval(this.trackTotalNumberValue, 100);
   }
   return total;
  }
  columns = (platform = false) => {
    let { filteredInfo, branchFilters } = this.state;
    filteredInfo = filteredInfo || {};
    const rolesFilter = this.getRolesFilters() || [];

    const { role } = this.props;
    const columns = [];
    const infoColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    }];
    const restColumns = [
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      filters:  rolesFilter,
      filteredValue: filteredInfo.role || null,
      onFilter: (value, record) => {
        return record.role === value
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <span style={{color: record.status === 2 ? 'red' : 'green'}}>
          { !record.is_deleted && <React.Fragment>{record.status === 2 ? 'Suspended' : 'Active'}</React.Fragment> }
          { record.is_deleted ? <React.Fragment>Deleted</React.Fragment> : '' }
        </span>
      )
    }, {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <React.Fragment>
          {!record.is_deleted ? <React.Fragment>
            <Button style={{display: record.role === 'Owner' ? 'none' : ''}} onClick={() => this.editUser(record)} size="small">
              Edit employee
            </Button>
            <Button
              onClick={() => {
                const status = record.status === 2 ? 1 : 2;
                this.changeUserStatus(record.uuid, status)
              }}
              size="small" style={{ marginLeft: record.role === 'Owner' ? '0px' : '8px' }}>
              {record.status === 2 ? 'Activate employee' : 'Suspend employee'}
            </Button>
            <Button
              onClick={() => {
                const status = 2;
                this.changeUserStatus(record.uuid, status, true)
              }}
              size="small" style={{ marginLeft: '8px' }}>
              Delete
            </Button>
          </React.Fragment> : ''}
          </React.Fragment>
        )
      }
    }];
    const branchTitleColumn = {
      title: 'Branch',
      dataIndex: 'branchId',
      key: 'branchId',
      filters:  branchFilters,
      filteredValue: filteredInfo.branchId || null,
      onFilter: (value, record) => {
        return record.branchId === value || record.branchId === 'all'
      },
      render: (text, record) => {
        if (record.branchId && typeof record.branchId === 'number') {
          return <Link to={`/branches/${record.branchUuid}`}>{record.branchTitle}</Link>
        }
        return
      }
    };
    if (role === 'owner' || role === 'admin') {
      columns.push(...infoColumns, branchTitleColumn, ...restColumns);
    } else {
      columns.push(...infoColumns, ...restColumns);
    }
    return columns;
  }
  editUser = (emloyee) => {
    this.setState({
      selectedUser: emloyee
    }, () => this.updateState('visibleEdit', true))
  }
  updateState = (key, value) => {
    this.setState({
      [key]: value
    });
  }
  getRolesFilters = () => {
    const roles = this.getRolesObject();
    const rolesFilter = [];
    if (roles && typeof roles === 'object' && Object.keys(roles).length) {
      Object.keys(roles).forEach((key) => {
        rolesFilter.push({ text: roles[key], value: roles[key] });
      })
    }
    return rolesFilter
  }
  getRolesObject = () => {
    const { role } = this.props;
    const roles = ['admin', 'owner'].indexOf(role) > -1 ? {
      waiter: 'FOH',
      kitchen: 'Kitchen Staff',
      manager: 'Manager',
      owner: 'Owner'
    } : {
      waiter: 'FOH',
      kitchen: 'Kitchen Staff',
      manager: 'Manager'
    };
    return roles;
  }
  getData = () => {
    const data = [];
    const { fullEmployeesObj } = this.state;
    const { role } = this.props;

    if (!fullEmployeesObj || typeof fullEmployeesObj !== 'object') return data;

    const roles = this.getRolesObject();

    if ((role === 'admin' || role === 'owner') && Array.isArray(fullEmployeesObj.restaurantRelated) && fullEmployeesObj.restaurantRelated.length) {
      const restaurantRelatedUsers = fullEmployeesObj.restaurantRelated;
      restaurantRelatedUsers.forEach((user, i) => {
        const obj = {
          key: i,
          uuid: user.uuid,
          id: user.id,
          name: user.name,
          phone: user.phone,
          role: roles[user.role] || user.role,
          status: user.status,
          phoneObj: user.phone_obj,
          is_deleted: user.is_deleted,
          branchId: 'all',
          branchUuid: null
        };
        data.push(obj);
      });
    }
    if (fullEmployeesObj.branchesRelated && Array.isArray(fullEmployeesObj.branchesRelated)) {
      let startIndex = Array.isArray(fullEmployeesObj.restaurantRelated) ? fullEmployeesObj.restaurantRelated.length : 0;
      fullEmployeesObj.branchesRelated.forEach((branch) => {
        if (typeof branch === 'object' && branch.workers && Array.isArray(branch.workers)) {
          branch.workers.forEach((user, i) => {
            const obj = {
              key: i + startIndex,
              uuid: user.uuid,
              id: user.id,
              name: user.name,
              phone: user.phone,
              role: roles[user.role] || user.role,
              status: user.status,
              phoneObj: user.phone_obj,
              is_deleted: user.is_deleted,
              branchId: branch.branchId,
              branchUuid: branch.branchUuid,
              branchTitle: branch.branchTitle
            };
            data.push(obj);
            startIndex += branch.workers.length;
          })
        }
      });
    }
    return data;
  }
  handleTableChange = (pagination, filters, sorter) => {
    console.log('handleTableChange', pagination, filters, sorter);
    setTimeout(() => {
      if (!document.getElementById("totalNumber")) {
        this.setState({
          totalNumberTable: 0
        })
      }
    }, 100)
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    })
  }
  render() {
    const {
      isFetching,
      pagination,
      visibleCreateNew,
      selectedUser,
      visibleEdit,
      totalNumberTable,
      branchesList,
      branchesListLoaded
    } = this.state;
    return (
      <Content className="bg-transparent">
        <Col span={24}>
          <Table
            size={"middle"}
            className="generalTable innerTable"
            columns={ this.columns() }
            loading={ isFetching }
            dataSource={ this.getData() }
            pagination={ pagination }
            onChange={ this.handleTableChange }
            footer={() => {}}
            scroll={this.state.scrollTable ? {x: 'fit-content'} : false}
            title={() =>
              <div>
                <Row className="tableHeader__container">
                  <div className="tableHeader__start-container">
                    <p className="tableHeader__quantity-text" style={{marginLeft: '0px'}}>
                      Quantity: {totalNumberTable}
                    </p>
                  </div>
                  <div className="tableHeader__end-container">
                    <Button type="primary" className="tableHeader__add-button" onClick={() => { this.updateState('visibleCreateNew', true) }}>
                      <p><Trans i18nKey="shareable.addEmployee">Add employee</Trans></p>
                    </Button>
                  </div>
                </Row>
              </div>
            }
          />
        </Col>
        { visibleEdit && branchesListLoaded ? <EditEmployee
          user={selectedUser}
          visible={visibleEdit}
          branchesList={branchesList}
          renderList = { this.getEmployees }
          role={this.props.role}
          restaurantId={this.props.restaurantId}
          handleClose={ () => { this.updateState('visibleEdit', false) } } />
          : null
        }
        { visibleCreateNew && branchesListLoaded ? <AddEmployee
          visible={visibleCreateNew}
          branchesList={branchesList}
          renderList = { this.getEmployees }
          role={this.props.role}
          restaurantId={this.props.restaurantId}
          managerBranchUuid={this.props.managerBranchUuid}
          handleClose={ () => { this.updateState('visibleCreateNew', false) } } />
          : null
        }
      </Content>
    )
  }
};

export default withTranslation()(EmployeesTable);
