import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode'

export const getUserRole = () => {
  try {
    const token = Cookies.get('dashboard_userToken')
    return jwt_decode(token)?.role
  } catch (e) {
    return ''
  }

}

