import React from 'react';
import fetchJSON from 'services/utils/fetchJSON';
import { withTranslation } from 'react-i18next';
import { Modal, Form, Input, message, Spin } from 'antd';
import * as _sharedModules from './modifierModules';
import { v4 as uuidv4 } from 'uuid';

const FormItem = Form.Item;

class EditModifierSets extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      putREQUEST: false,
      isFetching: false,
      modifierList: [],
      productsTotal: [],
      productConnected: []
    };
    Object.keys(_sharedModules).forEach((_moduleName) => {
      this[_moduleName] = _sharedModules[_moduleName].bind(this);
    });
  }
	componentDidMount = () => {
    this.mounted = true;
    this.getSingleModifier();
    this.getProducts();
  }
  componentWillUnmount = () => {
    this.mounted = false;
  }
  getSingleModifier = () => {
    const bodyObj = {};
    const { modifierSetId, restaurantId } = this.props;

    if (restaurantId) { 
      bodyObj.restaurantId = restaurantId;
    }
    this.setState({
      isFetching: true
    });

    fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/products/modifiers/single/${modifierSetId}`, {
      method: 'post',
      body: bodyObj
    }).then(response => {
      console.log(response, 'response');
      if (this.mounted) {
        if (response.success) {
          const { data } = response;

          const {
            display_name,
            single_select,
            required,
            modifiers,
            products
          } = data;

          const modifierList = [];
          const productConnected = [];
          if (modifiers) {
            const sorted = modifiers.sort((a,b) => a.sort_order - b.sort_order);
            sorted.forEach((modifier) => {
              modifierList.push({
                key: uuidv4(),
                sort_order: modifier.sort_order,
                display_name: modifier.display_name,
                price_add: modifier.price_add,
                modifier_id: modifier.id
              });
            });
            this.saveChanges(modifierList);
          }

          if (products) {
            products.forEach((product) => {
              if (productConnected.indexOf(product.id) === -1) 
                productConnected.push(product.product_id);
            });
          }
          console.log('productConnected', productConnected);
          
          this.formRef.current.setFieldsValue({
            display_name,
            single_select,
            required
          });

          this.setState({ 
            isFetching: false,
            data: [],
            modifierList,
            productConnected
          });

        } else {
          this.setState({ 
            isFetching: false,
            data: [] 
          });
        } 
      }    
    }).catch(error => {
      console.log(error);
      if (this.mounted) { 
        this.setState({
          isFetching: false,
          data: [] 
        });
      }
    });
  }
  handleSubmit = () => {
    const { t, modifierSetId } = this.props;

    const { modifierList, productConnected } = this.state;
    if (!modifierList.length) {
      message.error("You can't save without list of modifiers");
      return;
    }
    if (modifierSetId) {
      return this.formRef.current.validateFields()
      .then((values) => {
        console.log('values', values);
        values.productConnected = productConnected;

        this.setState({
          putREQUEST: true
        });
        fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/products/modifiers/update/${modifierSetId}`, {
          method: 'post',
          body: values
        }).then(response => {
          console.log(response);
          if (response.success) {
            this.setState({
              // putREQUEST: false,
              putSUCCESS: true,
              putFAILURE: false
            }, () => {
              setTimeout(() => {
                message.success(t('singleLocation.feedback.saveSuccess', { defaultValue: 'Saved successfully' }));
                this.props.handleClose();
                this.props.updateModifiersList();
              }, 500);
            });          
          } else {
            this.setState({
              putREQUEST: false,
              putFAILURE: true,
              putSUCCESS: false,
              //errorMessage: response.message
            });
            message.error(response.message);
          }
         }).catch(error => {
            console.log(error);
            this.setState({
              putSUCCESS: false,
              putREQUEST: false,
              putFAILURE: true,
            });
            message.error(t('shareable.ERR', { defaultValue: 'ERR' }));
         });
      }).catch((err) => {
        console.log('validate err', err)
        message.error("Please, check all fields and try again");
      })
    }
  }

  render() {
    const { modifierSetId } = this.props;
    const { 
      isFetching,
      modifierList,
      putREQUEST
    } = this.state;

    return (
      <Modal 
        className="general-modal-btns-center general-card"
        title="Edit modifier set"
        centered={true}
        visible={this.props.visible}
        okButtonProps={{ htmlType: "submit" }}
        okText="Update"
        onOk={() => this.handleSubmit()}
        onCancel={this.props.handleClose}
        destroyOnClose={true}
        confirmLoading={putREQUEST}
        maskClosable={true}
        width={650}
      >
        <Spin spinning={isFetching}>
          <Form 
            requiredMark={false} 
            className="general-form" 
            layout="vertical" 
            ref={this.formRef}
            initialValues={{
              modifierSetId: modifierSetId,
              display_name: '',
              single_select: false,
              required: false,
              modifiers: modifierList
            }} 
          >
            <div style={{ display: 'none' }}>
              <FormItem name="modifierSetId" label="modifierSetId">
                  <Input />
                </FormItem>
            </div>
            { this.ModifierForm() }
          </Form> 
        </Spin>
      </Modal>
    )
  }
};

export default withTranslation()(EditModifierSets);