import React from 'react';
import _ from 'lodash';
import { withTranslation, Trans } from 'react-i18next';
import { Row, Button, Steps, Input, Col, Form, Radio, Spin, Modal, Select } from 'antd';
import { withWindowSizeListener } from 'react-window-size-listener';
import * as _sharedModules from 'screens/Dashboard/modules/_modules';
import { DatabaseOutlined, CreditCardOutlined } from '@ant-design/icons';


// import './styles.less';

const { Step } = Steps;

class AddPrinter extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      isFetching: false,
      categories: [],
      currentStep: 0,
      categoriesList: [],
      workshopObj: {},
      workshopUuid: null,
      restaurantId: null,
      noPrinterType: true,
      printersModelsList: {},
      printersSelectedTypeList: []
    };
    Object.keys(_sharedModules).forEach((_moduleName) => {
      this[_moduleName] = _sharedModules[_moduleName].bind(this);
    });
  }
  componentDidMount() {
    this.mounted = true;
    const { addStep } = this.props;

    this.getCategories();
    this.getListPrintersModels();

    this.setState({
      currentStep: addStep || 0
    })
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  addWorkshop = () => {
    const { branchUuid } = this.props;

    return this.formRef.current.validateFields(['display_name', 'is_receipt_station'])
    .then((values) => {
      values.branchUuid = branchUuid;
      this.createWorkshop(values);
    });
  }
  
  renderCategoriesOptions = () => {
    const { categories, categoriesList } = this.state;
    const isMobile = this.props.windowSize.windowWidth < 576;
    const render = [];
    let group = [];
    if (categories && categories.length) {
      categories.forEach((category, i) => {
        const categoryName = _.get(category, 'category.display_name');
        const categoryId = category.category_id;
        if (categoryId && categoryName) {
          const classNameButton = categoriesList.indexOf(categoryId) > -1 ? 'category-btn__selected' : 'category-btn__not-selected';
          group.push(
            <Col key={i} xs={12} sm={6}>
              <Button onClick={() => this.addCategory(categoryId)} className={classNameButton}>{categoryName}</Button>
            </Col>
          )
          if ((isMobile && group.length === 2) || (!isMobile && group.length === 4)) {
            render.push(<Row key={i} gutter={[16, 16]}>{group}</Row>);
            group = [];
          }
          if ((i + 1) === categories.length && group.length) {
            render.push(<Row key={i} gutter={[16, 16]}>{group}</Row>);
          }
        }
      })
    }
    return render
  }
  addCategory = (categoryId) => {
    const { categoriesList } = this.state;
    const list = [];
    if (categoriesList.indexOf(categoryId) === -1) {
      list.push(...categoriesList, categoryId);
    } else {
      categoriesList.forEach((id) => {
        if (categoryId !== id && list.indexOf(id) === -1) {
          list.push(id);
        } 
      })
    }
    if (!list.length && categoryId) {
        Modal.info({
          title: 'Menu must have at least one category'
        });
        return;
    }
    this.setState({ categoriesList: list });
  }
  goNextStep = () => {
    const { currentStep, categoriesList } = this.state;
    if (currentStep === 1) {
      if (!categoriesList || !categoriesList.length) {
        Modal.info({
          title: 'Choose at least one category'
        });
        return;
      }
      console.log('categoriesList', categoriesList);
      const values = {};
      const { workshopUuid } = this.state;
      values.workshopUuid = workshopUuid;
      values.categoriesList = categoriesList;
      this.updateWorkshop(values);
    }

    this.setState({
      currentStep: currentStep + 1
    });
  }
  createPrinter = () => {
    return this.formRef.current.validateFields(['serial_number', 'device_id', 'ip_address', 'printerModel'])
    .then((values) => {
      console.log('values', values);
      const { workshopUuid } = this.state;
      values.workshopUuid = workshopUuid;

      this.createNewPrinter(values);
    });
    //window.location.href = `${this.props.history.location.pathname}?printing=1`;
  }
  render() {
  	// const isMobile = this.props.windowSize.windowWidth < 576;
    const { t } = this.props;
    const { currentStep, isFetching, categoriesLoaded, noPrinterType } = this.state;
  	return (
  		<div>
        <Steps
          current={currentStep}
          onChange={this.onStepChange}
          style={{ marginBottom: '30px'}}
        >
          <Step title={t('shareable.general', { defaultValue: 'General'})}/>
          <Step title={t('shareable.categories', { defaultValue: 'Categories'})} />
          <Step title={t('shareable.products', { defaultValue: 'Printer'})} />
        </Steps>  
        <Form className="general-form--big mobile-margin-fix-form" style={{ minHeight: '300px'}} ref={this.formRef} onFinish={this.handleSubmit} scrollToFirstError={true}> 
          <Spin spinning={isFetching}>
          { currentStep === 0 ? 
            <div>
              <Row>
                <label className="formDefault__label">
                  <Trans i18nKey="shareable.display_name">Display name</Trans>
                </label>
              </Row>
              <Row id='formitem_display_name'>
                <Form.Item className="addLocation__xs--fw" name="display_name" rules={[{ required: true, message: t('shareable.required.name', { defaultValue: 'Please enter a name' }) }]} >
                  <Input className="addLocation__input--short" placeholder={t('shareable.display_name', { defaultValue: 'Display name' })} />
                </Form.Item>
              </Row>

              <Row id='formitem_is_receipt_station'>
                 <Form.Item className="addLocation__xs--fw" name="is_receipt_station" rules={[{ required: true, message: 'Please select an option' }]} >

                   <Radio.Group className="locationRadioButton" style={{marginLeft: 'auto', marginRight: 'auto'}}>
                    <Radio value={false} className="locationRadioButton__item locationRadioButton__item--menuType locationRadioButton__item--workshop">
                      <span className="locationRadioButton__title">Workshop</span>
                      <span className="locationRadioButton__descr">To print kitchen/bar tickets</span>
                      <span className="locationRadioButton__icon locationRadioButton__icon--online"><DatabaseOutlined  className="locationRadioButton--iconOnline"/></span>
                    </Radio> 
                    <Radio value={true} className="locationRadioButton__item locationRadioButton__item--menuType locationRadioButton__item--receipt">
                      <span className="locationRadioButton__title">Receipt</span>
                      <span className="locationRadioButton__descr">To print the receipts</span>
                      <span className="locationRadioButton__icon locationRadioButton__icon--scan"><CreditCardOutlined /></span>
                    </Radio>
                  </Radio.Group>

                </Form.Item>
                
              </Row>


              <Form.Item>
                <Button type="primary" onClick={this.addWorkshop}>Next</Button>
              </Form.Item>
            </div>
            : null }
          { currentStep === 1 && !categoriesLoaded ? 
            <div>
              <Row>
                <p>Please, add categories to your menu first.</p>
              </Row>
            </div>
            : null }
          { currentStep === 1 && categoriesLoaded ? 
            <div>
              <Row>
                <p>Choose categories you want to be connected to the workshop.</p>
              </Row>
              <Row style={{marginTop: '16px'}}>
                <Col sm={18} xs={24}>
                  {this.renderCategoriesOptions()}
                </Col>
              </Row>
              <Row style={{marginTop: '20px'}}>
                <Button type="primary" onClick={this.goNextStep}>Next</Button>
              </Row>
            </div>
            : null }
          { currentStep === 2 && categoriesLoaded ?
            <div>
              <div>

                <Row gutter={8}>
                  <Form.Item className="addLocation__pd15 addLocation__xs--fw">
                    <Row id='formitem_businessType'>
                      <label className="formDefault__label">
                        <Trans i18nKey="shareable.printer_type.label">Type of printer</Trans>
                      </label>
                    </Row>
                    <Form.Item
                      name="printerType"
                      rules={[{required: true, message: t('shareable.printer_type.required', { defaultValue: 'Please choose your printer type'}) }]}
                      style={{marginBottom: '0px'}}
                      >
                      <Select 
                        className="addLocation__selectType"
                        placeholder={t('shareable.printer_type.placeholder', { defaultValue: 'Select your printer type'})}
                        onChange={this.sortPrintersModelsList}
                      >
                        {this.renderPrintersTypeList()}
                      </Select>
                    </Form.Item>
                  </Form.Item>

                  <Form.Item className="addLocation__pd15 addLocation__xs--fw">
                    <Row id='formitem_businessCategory'>
                      <label className="formDefault__label">
                        <Trans i18nKey="shareable.printer_model.label">Printer Model</Trans>
                      </label>
                    </Row>
                    <Form.Item
                      name="printerModel"
                      rules={[{required: true, message: t('shareable.printer_model.required', { defaultValue: 'Please choose your printer model'}) }]}
                      style={{marginBottom: '0px'}}
                      >
                      <Select 
                        className="addLocation__selectType"
                        placeholder={t('shareable.printer_model.placeholder', { defaultValue: 'Select your printer model'})}
                        disabled={noPrinterType}
                      >
                        {this.renderPrintersModels()}
                      </Select>
                    </Form.Item>
                  </Form.Item>
                </Row>

                <Row>
                  <label className="formDefault__label">
                    Serial Number
                  </label>
                </Row>
                <Row id='formitem_display_name'>
                  <Form.Item className="addLocation__xs--fw" name="serial_number" rules={[{ required: true, message: t('shareable.required.serial_number', { defaultValue: 'Please enter a serial number' }) }]} >
                    <Input className="addLocation__input--short" placeholder={t('shareable.serial_number', { defaultValue: 'Serial Number' })} />
                  </Form.Item>
                </Row>

                <Row>
                  <label className="formDefault__label">
                    Device ID or MAC address
                  </label>
                </Row>
                <Row id='formitem_display_name'>
                  <Form.Item className="addLocation__xs--fw" name="device_id" rules={[{ required: true, message: t('shareable.required.device_id', { defaultValue: 'Please enter a device ID' }) }]} >
                    <Input className="addLocation__input--short" placeholder={t('shareable.device_id', { defaultValue: 'Device ID' })} />
                  </Form.Item>
                </Row>
                <Row>
                  <label className="formDefault__label">
                    Printer's IP Address
                  </label>
                </Row>

                <Row id='formitem_display_name'>
                  <Form.Item className="addLocation__xs--fw" name="ip_address" rules={[{ required: true, message: t('shareable.required.ip_address', { defaultValue: 'Please enter a ip address' }) }]} >
                    <Input className="addLocation__input--short" placeholder={t('shareable.ip_address', { defaultValue: 'IP Address' })} />
                  </Form.Item>
                </Row>                
              </div>
              <Row style={{marginTop: '20px'}}>
                <Button type="primary" onClick={this.createPrinter}>Finish</Button>
              </Row>
            </div>
            : null }
            </Spin>
          </Form>
		  </div>
  	)
  }
}
export default  withTranslation()(withWindowSizeListener(AddPrinter));
