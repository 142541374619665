import React from 'react';
import Settings from 'services/config/Settings';
import _ from 'lodash';
import { Table, Layout, Input, Row, Tag, Tooltip, Button, Select, Modal, message, notification } from 'antd';
import { Link } from 'react-router-dom';
import fetchJSON from 'services/utils/fetchJSON';
import { withTranslation, Trans } from 'react-i18next';
import { withWindowSizeListener } from 'react-window-size-listener';
import { DeleteOutlined } from '@ant-design/icons';
import AddProduct from '../AddProduct/AddProduct';

import './styles.less';

const { Content } = Layout;
const { Option } = Select;
const Search = Input.Search; 

class ProductsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredInfo: {},
      sortedInfo: {},
      searchText: '',
      filtered: false,
      result: [],
      data: [],
      totalNumberTable: 0,
      scrollTable: false,
      showAddProduct: false
    };
    this.totalNumberTable = 0;
    this.totalNumberInterval = null;
    // for detecting if scroll is enabled
    this.scrollTableInterval = null;
    this.tableScrollChecked = false;
  }
  componentDidMount() {  
    const { t } = this.props;
    this.mounted = true;
    document.title = `${t('shareable.products', { defaultValue: 'Products' })} | ${Settings.title}`;
    this.setState({
      pagination: {
        total: 0,
        pageSizeOptions: [10, 30, 50, 100],
        showSizeChanger: true,
        showTotal: (total) => {
          return <p id="totalNumber" style={{display: 'none'}}>{this.renderTotal(total)}</p>
        }
      }
    })
    if (this.props.shouldAddProduct) {
      this.setState({
        showAddProduct: true
      });
      this.props.turnOffShouldAddProduct();
    }
    this.scrollTableInterval = setInterval(this.checkIfScrollOn, 100);
    this.getProducts();
  }

  componentWillUnmout() {
    this.mounted = false;
    clearInterval(this.totalNumberInterval);
    clearInterval(this.scrollTableInterval);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.restaurantId !== this.props.restaurantId) {
      this.getProducts();
      clearInterval(this.scrollTableInterval);
      this.tableScrollChecked = false;
      this.scrollTableInterval = setInterval(this.checkIfScrollOn, 100);
    }
  }
  getProducts = () => {
    const categoryFilters = [];
    const categoryStorage = [];
    const bodyObj = {};
    clearInterval(this.scrollTableInterval);
    this.tableScrollChecked = false;
    this.scrollTableInterval = setInterval(this.checkIfScrollOn, 100);
    if (this.props.restaurantId) { 
      bodyObj.restaurantId = this.props.restaurantId;
    }
    this.setState({
      isFetching: true
    });
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/products/list', {
      method: 'put',
      body: bodyObj
    }).then(response => {
      //console.log(response, 'response');
      if (this.mounted) {
        if (response.data && response.data.length) {
          response.data.forEach((product) => {
            if (product.category && product.category.length) {
              product.category.forEach((category) => {
                const categoryTitle = _.get(category, 'category.display_name');
                const categoryId = _.get(category, 'category.id');
                if (categoryTitle && categoryId && categoryStorage.indexOf(categoryId) === -1) {
                  categoryFilters.push({ text: categoryTitle, value: categoryTitle });
                  categoryStorage.push(categoryId);
                }
              })
            }
          })
          this.setState({ 
            isFetching: false,
            result: response.data,
            categoryFilters,
            totalNumberTable: response.data && Array.isArray(response.data) ? response.data.length : 0
          });
        } else {
          this.setState({ 
            isFetching: false,
            result: [],
            totalNumberTable: 0 
          });
        } 
      }    
    }).catch(error => {
      console.log(error);
      if (this.mounted) { 
        this.setState({
          isFetching: false,
          result: [] 
        });
      }
    });
  }
  checkIfScrollOn = () => {
    const { isFetching } = this.state;
    const tableContent = document.getElementsByClassName("ant-table-tbody");
    if (tableContent && tableContent.length && !isFetching) {
      if (this.checkTableScroll(tableContent[0])) {
        this.setState({
          scrollTable: true
        })
      } else {
        this.setState({
          scrollTable: false
        })
      }
      clearInterval(this.scrollTableInterval);
    }
  }
  checkTableScroll = (e) => {
    const tableWrapper = document.getElementsByClassName("ant-table-wrapper generalTable innerTable");
    this.tableScrollChecked = true;
    if (tableWrapper && tableWrapper[0] && e) {
      return (tableWrapper[0].offsetWidth < e.scrollWidth)
    }
    return false
  }
  isEllipsisActive = (e) => {
    if (e) {
      return (e.offsetWidth < e.scrollWidth)
    }
    else {
      return false
    }
  }
  trackTotalNumberValue = () => {
    clearInterval(this.totalNumberInterval);
    const { totalNumberTable } = this.state;
    if (totalNumberTable !== this.totalNumberTable) {
      this.setState({
        totalNumberTable: this.totalNumberTable
      });
    }
    this.totalNumberInterval = null;
  }
  renderTotal = (total) => {
   this.totalNumberTable = total;
   const { totalNumberTable } = this.state;
   if (totalNumberTable !== this.totalNumberTable && !this.totalNumberInterval) {
    this.totalNumberInterval = setInterval(this.trackTotalNumberValue, 100); 
   }
   return total;
  }
  columns = () => {
    const { t, restaurantId } = this.props;
    let {  
      sortedInfo,
      filteredInfo,
      categoryFilters
    } = this.state;

    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};

    let photoItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            { t('shareable.photo', { defaultValue: 'Photo' })}
          </div>
          )
      },
      dataIndex: 'photo',
      key: 'photo',
      width: 80,
      sorter: (a, b) => a.photo > b.photo ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'photo' && sortedInfo.order,
      render: (text, record) => {
        if (!record.photo) return;
        if (record.photo) return <div style={{height: '40px', width: '40px', backgroundImage: `url(${record.photo})`,
          backgroundSize: record.photoOrientation === 'portrait' ? 'contain' : 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}/>
      }
    };
    let titleItem = {
      title: () => {
        return (
          <div style={{whiteSpace: 'nowrap' }}>
            { t('shareable.productName', { defaultValue: 'Product name' })}
          </div>
          )
      },
      dataIndex: 'title',
      key: 'title',
      width: 140,
      render: (text, record) => (
        <div>
          <Tooltip title={this.isEllipsisActive(document.getElementById(`productTitleText_${record.key}`)) ? record.title : ''}>
            <Link to={`/products/${record.uuid}?rid=${restaurantId}`}> 
              <p id={`productTitleText_${record.key}`} style={{ margin:0, width: '140px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                {record.title}
              </p>
            </Link>
          </Tooltip>
        </div>
      )
    };
    const descriptionItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            { t('shareable.description', { defaultValue: 'Description'})}
          </div>
          )
      },
      dataIndex: 'description',
      key: 'description',
      width: 170,
      render: (text, record) => (
        <div>
          <Tooltip title={this.isEllipsisActive(document.getElementById(`descriptionText__${record.key}`)) ? record.description : ''}>
            <p id={`descriptionText__${record.key}`} style={{margin:0, width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
              {record.description}
            </p>
          </Tooltip> 
        </div>
      )
    };
    const categoryItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            { t('shareable.category', { defaultValue: 'Category' }) }
          </div>
        )
      },
      dataIndex: 'categoriesList',
      key: 'categoriesList',
      align: 'center',
      width: 130,
      filters:  categoryFilters,
      filteredValue: filteredInfo.categoriesList || null,
      onFilter: (value, record) => {
        return record.categoriesList.indexOf(value) > -1
      },
      render: (text, record) => {
        if (!record.categoriesRender) return null;
        return (<span>{record.categoriesRender}</span>);
      }
    }
    const priceItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            { t('shareable.price', { defaultValue: 'Price' }) }
          </div>
        )
      },
      dataIndex: 'price',
      key: 'price',
      align: 'center',
      width: 80,
      sorter: (a, b) => parseFloat(a.price, 10) > parseFloat(b.price, 10) ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'price' && sortedInfo.order,
      render: (text, record) => {
        if (record.price) return (<span>{'$' + record.price}</span>);
      }
    }
    const taxItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            { t('shareable.sellTax', { defaultValue: 'Sell tax' }) }
          </div>
        )
      },
      dataIndex: 'tax',
      key: 'tax',
      align: 'center',
      width: 80,
      filters:  [
      { text: 'Tax free', value: 'Tax free'},
      { text: '10% GST', value: '10% GST'}],
      filteredValue: filteredInfo.tax || null,
      onFilter: (value, record) => {
        return record.tax === value
      }
    }
    const showOnlineItem = {
      title: t('shareable.showOnline', { defaultValue: 'Show online'}),
      dataIndex: 'show_online',
      key: 'show_online',
      align: 'center',
      width: 80,
      filters:  [
      { text: 'Yes', value: 'Yes'},
      { text: 'No', value: 'No'}],
      filteredValue: filteredInfo.show_online || null,
      onFilter: (value, record) => {
        return ((record.show_online && value === 'Yes') || (!record.show_online && value === 'No'))
      },
      render: (text, record) => {
        return (<span style={{color: record.show_online ? 'green' : 'red'}}>{record.show_online ? 'Yes' : 'No'}</span>);
      }
    }
    const deleteItem = {
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 60,
      render: (text, record) => {
        return (<Button danger type="link" onClick={() => this.deleteProductRequest(record.id)} style={{fontSize: '18px'}}><DeleteOutlined/></Button>);
      }
    }
    const columns = [];
    if (this.state.scrollTable) {
      titleItem.fixed = "left";
      deleteItem.fixed = 'right';
      columns.push(titleItem, photoItem);
   } else {
      columns.push(photoItem, titleItem);
    }
    
    const restColumns = [descriptionItem, categoryItem, priceItem, taxItem, showOnlineItem, deleteItem];
    columns.push(...restColumns);
    // if (!this.props.restaurantId && this.props.role === 'admin') columns.push(restaurantItem);
    // columns.push(addressItem, employeeItem);

    return columns;
  }
  deleteProduct = (productId) => {
    Modal.confirm({
      className: "modal-btns-center",
      title: 'Are you sure you want to delete this product?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        this.setState({
          isFetching: true
        });
        fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/products/delete/${productId}`, {
          method: 'put'
        }).then(response => {
          if (this.mounted) {
            if (response.success) {
              this.setState({ 
                isFetching: false
              }, this.getProducts)
            } else {
              this.setState({
                isFetching: false
              })
            }
          }    
        }).catch(error => {
          console.log(error);
          if (this.mounted) { 
            this.setState({
              isFetching: false
            });
          }
        })
      }
    })
  }
  getData = () => {
    const data = [];
    if (!this.state.result) return data;

    let products = this.state.result;
    if (this.state.filtered) {
      products =  this.state.data;
    }
    if (!Array.isArray(products) || !products.length) {
      return data;
    }
    products.forEach((product, i) => {
      const categoriesRender = [];
      const categoriesList = [];
      const tagColors = ['geekblue', 'cyan', 'magenta', 'purple'];
      if (product.category && product.category.length) {
        product.category.forEach((relation, k) => {
          if (relation.category) {
            if (categoriesList.indexOf(relation.category.display_name) === -1) {
              if (categoriesList.length < 4) {
                categoriesRender.push(<Tag key={k} color={tagColors[categoriesList.length]} style={{margin: '3px'}}>
                  {categoriesList.length === 3 ? '...' : relation.category.display_name}
                </Tag>)
              }
              categoriesList.push(relation.category.display_name)
            }
          }
        })
      }
      const photo = _.get(product, 'file.sizes.origin');
      const orientation = _.get(product, 'file.orientation');
      data.push({
        key: i,
        id: product.id,
        title: product.display_name,
        description: product.description,
        price: product.product_price || 0,
        tax: product.vat === 10 ? '10% GST' : 'Tax free',
        show_online: product.show_online,
        uuid: product.uuid,
        categoriesRender,
        categoriesList,
        photo: photo || '',
        photoOrientation: orientation || ''
      })  
    });
    return data;
  }
  handleTableChange = (pagination, filters, sorter) => {
    //console.log('handleTableChange', pagination, filters, sorter);
    setTimeout(() => {
      if (!document.getElementById("totalNumber")) {
        this.setState({
          totalNumberTable: 0
        })
      }
    }, 100)
    clearInterval(this.scrollTableInterval);
    this.tableScrollChecked = false;
    this.scrollTableInterval = setInterval(this.checkIfScrollOn, 100);
    // used for tooltips in cells (because it searchs for html element)
    setTimeout(() => {
      const { pagination } = this.state;
      this.setState({
        pagination: {
          ...pagination,
          update: !pagination.update
        }
      })
    }, 100)
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    })
  }
  onSearch = (e) => {
    this.setState({ searchText: e.target.value });
    const searchText = e.target.value;
    const reg = new RegExp(e.target.value, 'gi');
    this.setState({
      filtered: !!searchText,
      data: _.flatMap(this.state.result, record => {
        const nameMatch = record.display_name && record.display_name.length ? record.display_name.match(reg) : false;
        if (!nameMatch) {
          return null;
        }
        return {          
          ...record,
          };
      }).filter(record => !!record),
    });
    if (e.target.value === ''){
      this.setState({
        data: this.state.result,
        filtered: null
      });
    }
  }
  modalVisible = (key, value) => {
    this.setState({ [key]: value });
  }
  onRowSelect = (selectedRowKeys, selectedRowValues) => {
    //console.log('selectedRowKeys changed: ', selectedRowKeys, selectedRowValues);
    this.setState({
      selectedRowKeys,
      selectedRowValues
    });
  }
  actions = (value) => {
    const { selectedRowValues } = this.state;
    if (!selectedRowValues || !selectedRowValues.length) {
      Modal.warning({
        title: 'Please, select at least one product.', 
        okText: 'Got it',
        maskClosable: true })
      return;
    }
    if (value === 'delete') {
      const question = selectedRowValues.length > 1 ? 'Are you sure you want to delete these products?' : 'Are you sure you want to delete this product?';
      Modal.confirm({
        className: "modal-btns-center",
        title: question,
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => {
          const promises = [];
          const responses = [];
          this.setState({
            isFetching: true
          });
          selectedRowValues.forEach((product) => {
            promises.push(new Promise((resolveLocal, reject) => {
              this.deleteProduct(product.id, (response = false) => {
                if (response) {
                  responses.push(response);
                }
                resolveLocal();
              })
            }));
          });
          Promise.all(promises).then((values) => {
            //console.log('responses', responses);
            if (responses.indexOf(400) === -1) {
              message.success('Success!');
              this.setState({
                selectedRowKeys: [],
                selectedRowValues: []
              }, this.getProducts);
            } else {
              message.error('Something went wrong, please try again');  
            }
          })
        }
      });                     
    }
  }
  deleteProductRequest = (productId) => {
    Modal.confirm({
      className: "modal-btns-center",
      title: 'Are you sure you want to delete this product?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        this.deleteProduct(productId)
      }
    });
  }
  deleteProduct = (productId, callback = false) => {
    if (!callback) {
      this.setState({ isFetching: true });
    }
    fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/products/delete/${productId}`, {
      method: 'put'
    }).then(response => {
      if (this.mounted) {
        if (response.success) {
          if (!callback) {
            message.success('Success!');
            this.setState({
              isFetching: false
            }, this.getProducts);
          } else {
            callback(200)
          }
        } else {
          if (!callback) {
            message.error('Something went wrong, please try again');
            this.setState({
              isFetching: false
            })
          } else {
            callback(400);
          }
        }
      }    
    }).catch(error => {
      console.log(error);
      if (this.mounted) {
        if (!callback) {
          message.error('Something went wrong, please try again');
          this.setState({
            isFetching: false
          });          
        } else {
          callback(400);
        }
      }
    })
  }
  openNotificationWithIcon = () => {
    notification.info({
      placement: 'topLeft',
      message: 'Important!',
      description:
        'For changes to appear in menu you should edit it directly from menu tab.',
    });
  };
  render() {
    const { t } = this.props;
    const { 
      searchText, 
      isFetching, 
      pagination,
      totalNumberTable,
      selectedRowKeys,
      showAddProduct
    } = this.state;
    const tableClass = !this.props.outer ? "generalTable innerTable" : 'generalTable';
    const contentClass = !this.props.outer ? "bg-transparent table-content innerContent" : "bg-transparent table-content";
    const showActionsButton = selectedRowKeys && selectedRowKeys.length ? true : false;
    const actionsPositionAnotherRow = this.props.windowSize.windowWidth < 576;
    const generalActions = (
      <div className="actions-select">
        <Select placeholder="Actions" onChange={ (value) => this.actions(value) } value={'Actions'}>
          <Option value="delete" style={{color: '#CF1322'}}>Delete</Option>
        </Select>
      </div>
    )
    const visiblleTable = !isFetching && !this.tableScrollChecked;
    return (
      <Content className={contentClass} style={{marginTop: '4px'}}>
        <Table
          style={{visibility: !visiblleTable ? '' : 'hidden'}}
          rowSelection = {
            {
              selectedRowKeys: selectedRowKeys,
              onChange: this.onRowSelect
            }
          }
          columns={ this.columns() }
          loading={ isFetching }
          dataSource={ isFetching ? null : this.getData() }
          pagination={{
            ...pagination, 
            showSizeChanger: totalNumberTable > 10 ? true : false}  
          }
          onChange={ this.handleTableChange }
          showSorterTooltip={false} 
          size={"small"}
          scroll={{x: 'fit-content'}}
          className={tableClass}
          footer={() => {}}
          title={() => 
            <div>
            <Row className="tableHeader__container">
              <div className="tableHeader__start-container">
                <p className="tableHeader__header-text">
                  <Trans i18nKey="shareable.products">Products</Trans>
                </p>
                { showActionsButton && !actionsPositionAnotherRow ? generalActions : null }
                <p className="tableHeader__quantity-text">
                  Quantity: {totalNumberTable}
                </p>
                
              </div>
              <div className="tableHeader__end-container">
                <Button type="primary" className="tableHeader__add-button" onClick={() => this.modalVisible('showAddProduct', true)}>
                  <p><Trans i18nKey="shareable.addProduct">Add product</Trans></p>
                </Button> 
                 <Search allowClear className="tableHeader__search" size="middle" disabled={isFetching} ref={ele => this.searchText = ele} onChange={this.onSearch} placeholder={t('shareable.search', { defaultValue: 'Search' })} value={searchText} 
                 onPressEnter={this.onSearch} />
              </div>
              { showActionsButton && actionsPositionAnotherRow ? <Row>{generalActions}</Row> : null }
            </Row>       
            </div>
          }
        />
        {showAddProduct ? <AddProduct
          history={this.props.history}
          handleClose={() => this.modalVisible('showAddProduct', false)}
          showInfoNotification={this.openNotificationWithIcon}
          visible={showAddProduct} 
          updateProductsList={this.getProducts}
          restaurantId={this.props.restaurantId}
        /> : null }
      </Content>
    )
  }
};

export default withTranslation()(withWindowSizeListener(ProductsTable));