import React from 'react';
import { Input, Button, Form, Row, Spin, Alert } from 'antd';
import { Link } from 'react-router-dom';
import fetchJSON from 'services/utils/fetchJSON';
import { withTranslation, Trans } from 'react-i18next';

import '../styles.less';
const FormItem = Form.Item;

class UpdatePassword extends React.Component {
	formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isAuthenticated: false,
      redirectUrl: '/',
      resetPass: false,
      resetEmail: false
    };
  }
  componentDidMount() {
  	this.mounted = true;
  }
  componentWillUnmount = () => {
    this.mounted = false;
  }
  handleSubmit = async (values) => {
    const { t } = this.props;
    if (!values.password || !values.confirm || values.password !== values.confirm) return false;
    return new Promise((resolve, reject) => {
      this.setState({
        isFetching: true
      })
      fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/auth/updateUserPass', {
        method: 'PUT',
        body: {
          password: values.password,
          confirm: values.confirm,
          workerId: this.props.userId
        }
      }).then(response => {
        if (response.success) {
          this.setState({
            isFetching: false
          })
        this.props.history.push(`/login?resetpass=success`)
        resolve();
        }
        else { 
          this.setState({
            isFetching: false,
            serverMessage: response.message,
            serverMessageType: 'error'
          })
         } 
      }).catch(error => {
        this.setState({
          isFetching: false,
          serverMessage: t('resetpass.error.tryAgain', { defaultValue: 'Something went wrong, please try again' }),
          serverMessageType: 'error'
        })
          reject();
       })
    }).catch(() => console.log('Oops errors!'));
  }
  validatePassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || getFieldValue('password') === value) {
        const checkPass = value && value.length && /[a-z]/.test(value) && /[0-9]/.test(value) && /[A-Z]/.test(value)
        if (value) {
          if (!checkPass) {
            return Promise.reject(<p><Trans i18nKey="shareable.passwordRequirements">Password have to have minimum eight characters, at least one uppercase letter, one lowercase letter and one number</Trans></p>);
          }
          else {
            return Promise.resolve();
          }
        }
        else {return Promise.reject()}      
      }
    },
    validateTrigger: 'onSubmit'
  })

  validatePasswordMatch = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (value) {
        if (getFieldValue('password') !== value) {
          return Promise.reject(<p><Trans i18nKey="shareable.passwordsDontMatch">Passwords do not match</Trans></p>);
        }
        else { return Promise.resolve() }
      }
      else { return Promise.resolve() }
    }
  })
  render() {
    const { t } = this.props;
    const {
      isFetching,
      serverMessage,
      serverMessageType
    } = this.state;

    return (
      <div>
      <Spin spinning = {isFetching }>
        <div style={{marginRight: '40px'}}>
          <img className="layoutLogin__logo-img" src={this.props.logo} alt="Logo" />
        </div>
        <div>
          <h2>
            <Trans i18nKey="resetpass.header.setNew">Please set your new password</Trans> 
          </h2>
        </div>
          <Form ref={this.formRef} onFinish={this.handleSubmit}>
            { serverMessage ? 
              <Row>
                <FormItem>
                    <Alert style={{marginBottom: '8px'}} message={serverMessage} type={ serverMessageType } />
                 </FormItem>
              </Row> : null }
            <div>
              <Row className="pass-req-alert" align="center" style={{marginBottom: '16px'}}>
                <Alert
                  description= {t('resetpass.description.passRequirements', { defaultValue: "Please make sure your password has at least 8 characters, one digit and minimum one uppercase letter." })}
                  type="info"
                  showIcon
                  
                />
              </Row>
              <Row className="layoutLogin__input-container">
                <FormItem
                  name="password"
                  rules={[{
                    required: true,
                    message: t('shareable.enterYourPassPlease', { defaultValue: 'Please enter your password' })
                  }, this.validatePassword ]}
                >
                  <Input.Password className="layoutLogin__input" placeholder={t('shareable.password', { defaultValue: 'Password' })} />
                </FormItem>
              </Row>
              <Row className="layoutLogin__input-container">
                <FormItem
                  name="confirm"
                  dependencies={['password']}
                  rules={[{
                    required: true,
                    message: <p><Trans i18nKey="shareable.confirmPasswordPlease">Please confirm your password</Trans><n/></p>,
                    validateTrigger: 'onSubmit'
                  }, this.validatePasswordMatch ]}
                >
                  <Input.Password className="layoutLogin__input" placeholder={t('shareable.confirmPassword', { defaultValue: 'Confirm password' })}/>
                </FormItem>
              </Row>
              </div>
            <FormItem>
              <Row align="center">
                <Button className="layoutOnboarding__submit-button" style={{width: '140px'}} type="primary" shape="round" htmlType="submit">
                  { t('resetpass.button.save', { defaultValue: 'Save password' }) }
                </Button>
              </Row>
            </FormItem> 
        </Form>
        <Row>
          <Link className="layoutLogin__link" to='/login'>
            { t('resetpass.link.backToLogin', { defaultValue: 'Go back to login' }) }
          </Link>
        </Row>
      </Spin>
    </div>
    );
  }
}

export default withTranslation()(UpdatePassword);