import React from 'react';
import Settings from 'services/config/Settings';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import {
	Button,
	Input,
	Row,
	Col,
	Spin,
	Form,
  Card,
  message,
  Layout,
  Steps,
  Alert 
} from 'antd'; 
import { withWindowSizeListener } from 'react-window-size-listener';
import { withTranslation, Trans } from 'react-i18next';
import fetchJSON from 'services/utils/fetchJSON';
import AddressField from '../../../components/AddressField/AddressField';
import * as _sharedModules from '../../../modules/_modules';
import CountryPhoneInput from 'components/AntdCountryPhoneInput';

import {
  LeftOutlined,
  InfoCircleTwoTone
 } from '@ant-design/icons';

import './styles.less';
const { Content } = Layout;
const FormItem = Form.Item;
const { Step } = Steps;

class AddRestaurant extends React.Component {
  constructor() {
    super();
    this.formRef = React.createRef();
    this.state = {
      putREQUEST: false,
      addressInput: '',
      allFieldsToForm: 0,
      everVisitedStep: 0,
      currentStep: 0,
      formatted_address: '',
      isFetching: false,
      addressError: '',
      addressGroup: {}
    };
    Object.keys(_sharedModules).forEach((_moduleName) => {
      this[_moduleName] = _sharedModules[_moduleName].bind(this);
    });
  }
  componentDidMount() {
    this.mounted = true;
    const { t } = this.props;
    document.title = `${t('addBranch.documentTitle', { defaultValue: 'Add restaurant' })} | ${Settings.title}`;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  onStepChange = currentStep => {
    this.setState({ currentStep });
    this.proccessStepChanging(currentStep);
  }
  changeFieldStateCheckbox = (e, key) => {
    if (key === 'createNewRest') {
      if (e.target.checked) {
        this.formRef.current.setFieldsValue({
          restaurantId: null
        });
      }
      else if (!e.target.checked) {
        const { restaurantsList } = this.state;
        if (restaurantsList && Array.isArray(restaurantsList) && restaurantsList.length === 1) {
          this.formRef.current.setFieldsValue({
            restaurantId: restaurantsList[0].id
          })
        }
      }
    }
    this.setState({
      [key]: e.target.checked
    });
  }
  modalVisible = (key, value) => {
    this.setState({ [key]: value });
  }
  changeFieldStateValue = (e, key) => {
    this.setState({
      [key]: e.target.value
    });
  }
  setFieldsValue = (name, value) => {
    this.formRef.current.setFieldsValue({
      [name]: value
    });
  }
  proccessStepChanging = (nextStep) => {
    //console.log('test values', this.formRef.current.getFieldsValue())
    const {
      everVisitedStep
    } = this.state;
    let everVisited = everVisitedStep;
    let allFieldsToForm = 0;
    if (nextStep > everVisited) {
      everVisited = nextStep;
    }
    if (nextStep === 1) {
      allFieldsToForm = 1;
    }
    this.setState({
      allFieldsToForm,
      everVisitedStep: everVisited
    });
  }
  goNextStep = () => {
    const {
      currentStep,
    } = this.state;
    let nextStep = currentStep + 1;
    this.setState({
      currentStep: nextStep
    });
    this.proccessStepChanging(nextStep);
  }
  nextStep = () => {
    const { t } = this.props;
    const { validateFields } = this.formRef.current;
    validateFields()
    .then(values => {
      const { currentStep, addressGroup } = this.state;
      if (currentStep === 0) {  
        console.log('values.addressGroup', addressGroup);
        const lat = _.get(addressGroup, 'coordinates.lat');
        if (!lat && currentStep === 1) {
          this.setState({
            addressError: t('shareable.enterSelectAddressPlease', { defaultValue: 'Please, enter correct address and select right option from a dropdown list' })
          });
          return false;
        }
      }
      this.goNextStep();
    })
    .catch((error) => {
      this.scrollToError(error);
    });
    
  }
  scrollToError = (error) => {
    const fieldName = _.get(error, 'errorFields[0].name[0]');
    if (fieldName) {
      const element = document.getElementById(`formitem_${fieldName}`);
      if (element) {
        const reactElement = element.getBoundingClientRect();
        const windowPosition = window.scrollY;
        const windowHeight = this.props.windowSize.windowHeight;
        if (reactElement.top > 0 && reactElement.top < windowHeight) {
          return;
        } else {
          window.scrollTo({
              top: windowPosition + reactElement.top - windowHeight*0.4,
              behavior: "smooth"
          });
        }
      }
    }
  }
  changeFieldStateSwitch = (value, key) => {
    this.setState({
      [key]: value
    });
  }
  handleInviteSubmit = (values) => {
    const { t } = this.props;
    values.addressGroup = this.state.addressGroup;
    values.formatted_address = this.state.formatted_address;
    values.phone = values.phoneObj ? values.phoneObj.code + values.phoneObj.phone : null;
    
    const lat = _.get(values.addressGroup, 'coordinates.lat');
    if (!lat) {
      message.error(t('shareable.enterSelectAddressPlease', { defaultValue: 'Please, enter correct address and select right option from a dropdown list' }));
      return false;
    }
    console.log(values, 'values')
    this.setState({
      putREQUEST: true
    });
    fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/restaurants/createAndInvite`, {
      method: 'POST',
      body: values
    }).then(response => {
      console.log(response);
      if (response.success) {
        this.setState({
          putREQUEST: false,
          putSUCCESS: true,
          putFAILURE: false
        }, this.props.history.push(`/restaurants/${response.data.uuid}`));
      } else {
        this.setState({
          putREQUEST: false,
          putFAILURE: true,
          putSUCCESS: false,
        });
        message.error(response.message);
      }
      setTimeout(() => {
        this.setState({
          errorMessage: ''
        });
      }, 3000)
     }).catch(error => {
        console.log(error);
        this.setState({
          putSUCCESS: false,
          putREQUEST: false,
          putFAILURE: true,
        });
     }); 
  }
  validatePhone = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || !value.phone) {
        return Promise.reject('Phone is required!')
      } else {
        if (value.phone.length < 7) {
          return Promise.reject(<p><Trans i18nKey="shareable.reqPhone.minLength">Phone should be 8 symbols and longer</Trans></p>);
        }
        const isnum = /^\d+$/.test(value.phone);
        if (!isnum) {
          return Promise.reject(<p><Trans i18nKey="shareable.reqPhone.onlyNumber">Please, enter correct phone</Trans></p>);
        }
      }
      return Promise.resolve();
    },
    validateTrigger: 'onSubmit'
  })
  render() {
    const { t } = this.props;
    const {
    	putREQUEST,
      isFetching,
      currentStep,
      allFieldsToForm,
      everVisitedStep,
      addressGroup,
      addressError
    } = this.state; 
    
    return (
      <Content className="bg-transparent table-content">
        <Card 
        className="general-card" 
        style={{padding: '20px'}}
        loading={isFetching}
        title={
          <Row>
            <Link to="/restaurants" className="general-card__back-btn"><LeftOutlined /></Link>
            <span className="general-card__title-text">{t('shareable.addRest', { defaultValue: 'Add Restaurant' })}</span>
          </Row>
          }>
          <Col sm={6} xs={24}>
            <Steps
              current={currentStep}
              onChange={this.onStepChange}
              style={{ marginBottom: '30px'}}
            >
              <Step title={t('shareable.info', { defaultValue: 'Info'})} />
              <Step title={t('shareable.addressAndContact', { defaultValue: 'Invitation'})} disabled={everVisitedStep < 1} />
            </Steps>
          </Col>
          <Form className="general-form mobile-margin-fix-form" style={{ minHeight: '300px'}} ref={this.formRef} onFinish={this.handleInviteSubmit} scrollToFirstError={true}> 
            <Spin spinning={putREQUEST}>
            { currentStep === 0 || allFieldsToForm ?
              <div style={{ display: allFieldsToForm && currentStep !== 0 ? 'none' : '' }}>
                <Row>
                  <h2 style={{color: '#454545'}}>Restaurant info</h2>
                </Row>
                <Row>
                  <Col span={24} style={{padding: 0}}>
                    <div>
                      <Row>
                        <h3 style={{color: '#454545'}}>
                          <Trans i18nKey="shareable.location_name">What is restaurant name?</Trans>
                        </h3>
                      </Row>
                      <Row id='formitem_title' className="error-fix">
                        <Form.Item className="addLocation__xs--fw" name="name" rules={[{ required: true, message: t('shareable.enterTitlePlease', { defaultValue: 'Please enter a name' }) }]} >
                          <Input
                            className="addLocation__input--short"
                            placeholder={t('shareable.location_name', { defaultValue: 'Restaurant name' })}
                            />
                        </Form.Item>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <FormItem name="address" className="restaurant-add-address-fix">
                  <Row gutter={8}>
                    <Col xs={24} sm={12}>
                      <Row>
                        <h3 style={{color: '#454545'}}>
                        <Trans i18nKey="shareable.address">Address</Trans></h3>
                      </Row>
                      <Row>
                        <p style={{marginBottom: '6px', fontWeight: 400}}>
                          <Trans i18nKey="addressField.description">If your organization is present in Google Maps please search it by its name. Otherwise search by address.
                          </Trans>
                        </p>
                      </Row>
                      <Row id='formitem_addressInput'>
                         <AddressField
                            t={this.props.t}
                            value={addressGroup}
                            triggerChanges={this.triggerAddressChanges}
                            
                          />
                      </Row>
                      { addressError ? 
                        <div style={{marginTop: '20px'}}><Alert message={addressError} type="error" /></div>
                      : null }
                    </Col>
                  </Row>
                </FormItem>
                <Row>
                  <Col sm={15} xs={24}>
                    <Row>
                      <h3 style={{color: '#454545'}}><Trans i18nKey="shareable.contact">Contact</Trans></h3>
                    </Row>
                    <Row className="error-fix">
                      <Col sm={8} xs={24}>
                        <FormItem className="addLocation__pd15 addLocation__xs--fw">
                          <Row id='formitem_contactName'>
                            <label className="formDefault__label">
                              <Trans i18nKey="shareable.name">Name</Trans>
                            </label>
                          </Row>
                          <FormItem
                            name="contact_name"
                            rules={[{ required: true, message: t('shareable.required.name', { defaultValue: 'Name is required!' }) }]}
                            style={{marginBottom: '0px'}}
                            >
                            <Input placeholder={ t('shareable.name', { defaultValue: 'Name' }) } />
                          </FormItem>
                        </FormItem>
                      </Col>
                      <Col sm={8} xs={24}>
                        <FormItem className="addLocation__pd15 addLocation__xs--fw">
                          <Row id='formitem_contactName'>
                            <label className="formDefault__label">
                              <Trans i18nKey="shareable.phone">Phone</Trans>
                            </label>
                          </Row>
                          <FormItem
                            name="contact_phone"
                            rules={[{ required: true, message: t('shareable.required.phone', { defaultValue: 'Phone is required!' }) }]}
                            style={{marginBottom: '0px'}}
                            >
                            <Input placeholder={t('shareable.phone', { defaultValue: 'Phone' })}/>
                          </FormItem>
                        </FormItem>
                      </Col>
                      <Col sm={8} xs={24}>
                        <FormItem className="addLocation__pd15 addLocation__xs--fw">
                          <Row id='formitem_contactName'>
                            <label className="formDefault__label">
                              <Trans i18nKey="shareable.email">Email</Trans>
                            </label>
                          </Row>
                          <FormItem
                            name="contact_email"
                            rules={[{
                              type: 'email',
                              message: t('login.email.wrongFormat', { defaultValue: 'Wrong format of email' }),
                              validateTrigger: 'onSubmit'
                            }, { required: true, message: t('shareable.required.email', { defaultValue: 'Email is required!' }) }]}
                            style={{marginBottom: '0px'}}
                            >
                            <Input placeholder={ t('shareable.email', { defaultValue: 'Email' }) } />
                          </FormItem>
                        </FormItem>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <FormItem>
                  <Row>
                    <Button className="general-card__submit-btn" type="primary" htmlType="button" onClick={this.nextStep}>
                      <Trans i18nKey="shareable.next">Next</Trans>
                    </Button>
                  </Row>
                </FormItem>
              </div> : null }
              { currentStep === 1 || allFieldsToForm ? 
                <div>
              <Row style={{marginTop: '6px'}}>
                <Col span={24} style={{padding: 0}}>
                  <div>
                    <Row>
                      <h2 style={{color: '#454545'}}>
                        <Trans i18nKey="shareable.owner_email">Who owns this place?</Trans>
                      </h2>
                    </Row>
                    <Row style={{marginTop: '6px', marginBottom: '13px'}}> 
                      <h3 style={{color: '#1C8DCC'}}>
                        <InfoCircleTwoTone twoToneColor="#1C8DCC" style={{marginRight: '6px'}}/>
                        <Trans i18nKey="addressField.description">
                        Email is required for us to send invitation. Phone number is needed for a full access to all features for new account owner.
                        </Trans>
                      </h3>
                    </Row>
                    <Row>
                      <Form.Item className="addLocation__xs--fw" name="owner_name" label="Name"
                        rules={[{ required: true, message: t('shareable.enterOwnerName', { defaultValue: 'Please enter owner name' }) }]} >
                        <Input
                          className="addLocation__input--short"
                          placeholder={t('shareable.owner_name', { defaultValue: 'Owner name' })}
                          />
                      </Form.Item>
                    </Row>
                    <Row id='formitem_title' className="error-fix">
                      <Form.Item className="addLocation__xs--fw" name="email" label="Email"
                      rules={[{
                        type: 'email',
                        message: t('login.email.wrongFormat', { defaultValue: 'Wrong format of email' }),
                        validateTrigger: 'onSubmit'
                      }, { required: true, message: t('shareable.enterTitlePlease', { defaultValue: 'Please enter owner email' }) }]} >
                        <Input
                          className="addLocation__input--short"
                          placeholder={t('shareable.email', { defaultValue: 'Owner email' })}
                          />
                      </Form.Item>
                    </Row>
                    <Row>
                      <Col sm={6} xs={24}>
                        <FormItem className="addLocation__xs--fw" rules={[this.validatePhone]} name="phoneObj" label="Phone">
                          <CountryPhoneInput style={{width: '100%'}} className="phoneCodeInput" placeholder="Phone" />
                        </FormItem>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
                <FormItem>
                  <Row>
                    <Button className="general-card__submit-btn" type="primary" htmlType="submit">
                      <Trans i18nKey="shareable.next">Create</Trans>
                    </Button>
                  </Row>
                </FormItem> </div> : null }
            </Spin>
          </Form>
        </Card>
      </Content>
    )
  }
}

export default withTranslation()(withWindowSizeListener(AddRestaurant));