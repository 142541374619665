import React from 'react';
import Settings from 'services/config/Settings';
import fetchJSON from 'services/utils/fetchJSON';
import { Link } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
import qs from 'qs'; 
import _ from 'lodash';
import { Layout, Card, Row, Form, Input, Col, Button, Select, message, Switch, Checkbox, Modal } from 'antd';
import ImageProduct from './components/ImageProduct';
import AddModifierSets from '../ModifierSets/components/AddModifierSets';

import './styles.less';
import { LeftOutlined } from '@ant-design/icons';

const FormItem = Form.Item;
const Option = Select.Option; 
const { TextArea } = Input;

class SingleProduct extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      isFetching: false,
      categoriesLoaded: false,
      product: {},
      initialValues: {},
      modifierSetsList: [],
      modifierSetsConnected: [],
      showAddModifierSets: false
    };
    this.modifierSetsModal = null;
  }
	componentDidMount = () => {
    this.mounted = true;
    const productUuid = this.props.match.params.productUuid;
    this.setState({ 
      productUuid
    })
    let restaurantId = null;
    let paramFromUrl = {};
    if (window.location.search) {
      paramFromUrl = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      if (paramFromUrl.rid && parseInt(paramFromUrl.rid, 10)) {
        restaurantId = parseInt(paramFromUrl.rid, 10);
      }
    }
    if (this.props.role === 'admin' && restaurantId) {
      this.restaurantId = restaurantId;
    }
    console.log(this.props, restaurantId, 'test')
    this.getProduct(productUuid);
    this.getCategories();
    this.getModifiers();
  }
  getProduct = (productUuid) => {
    this.setState({
      isFetching: true
    });
    const bodyObj = {};
    console.log(this.restaurantId, this.props.role, 'test @')
    if (this.restaurantId && this.props.role === 'admin') {
      bodyObj.restaurantId = this.restaurantId;
    }
    fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/products/${productUuid}`, {
      method: 'post',
      body: bodyObj
    }).then(response => {
      if (this.mounted) {
        if (response.data) {
          const product = response.data;
          document.title = `${product.display_name} | ${Settings.title}`;
          const categoriesIds = [];
          if (product.category && Array.isArray(product.category)) {
            product.category.forEach((category) => {
              if (category.category_id) {
                const idStr = category.category_id.toString(10);
                if (idStr && categoriesIds.indexOf(idStr) === -1) {
                  categoriesIds.push(idStr);
                }
              }
            })
          }

          const initialValues = {
            display_name: product.display_name,
            description: product.description,
            product_price: product.product_price,
            vat: product.vat ? product.vat : 0,
            show_online: product.show_online,
            categories: categoriesIds,
            serving_time: product.serving_time,
            accounting_number: product.accounting_number,
            is_alcohol: product.is_alcohol
          };
          const modifierSetsConnected = [];
          if (product.modifier_sets && product.modifier_sets.length) {
            product.modifier_sets.forEach((modifier_set) => {
              if (modifierSetsConnected.indexOf(modifier_set.modifier_set_id))
                modifierSetsConnected.push(modifier_set.modifier_set_id)
            })
          }

          this.setState({ 
            isFetching: false, 
            product,
            initialValues,
            modifierSetsConnected
          })
        } else {
          this.setState({ isFetching: false });
        } 
      }    
    }).catch(error => {
      console.log(error);
      if (this.mounted) { 
        this.setState({
          isFetching: false
        });
      }
    });
  }
  getModifiers = (modifier_id = false) => {
    const bodyObj = {};
    if (this.restaurantId) { 
      bodyObj.restaurantId = this.restaurantId;
    }

    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/products/modifiers/list', {
      method: 'post',
      body: bodyObj
    }).then(response => {
      if (this.mounted) {
        if (response.success) {
          this.setState({ 
            modifierSetsList: response.data,
          }, () => {
            if (modifier_id) {
              console.log('modifier_id', modifier_id);
              this.modifierSetToProduct(modifier_id);
              if (this.modifierSetsModal) {
                this.modifierSetsModal.destroy()
              }
            }
          });
        } else {
          this.setState({ 
            modifierSetsList: [] 
          });
        } 
      }    
    }).catch(error => {
      console.log(error);
      if (this.mounted) { 
        this.setState({
          modifierSetsList: [] 
        });
      }
    });
  }
  getCategories = () => {
    const bodyObj = {};
    const categoriesList = [];
    if (this.restaurantId && this.props.role === 'admin') { 
      bodyObj.restaurantId = this.restaurantId;
    }

    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/products/categories/list', {
      method: 'post',
      body: bodyObj
    }).then(response => {
      console.log(response, 'response');
      if (this.mounted) {
        if (response.success && response.data && Array.isArray(response.data)) {
          response.data.forEach((category) => {
            const categoryName = _.get(category, 'category.display_name');
            const categoryId = _.get(category, 'category_id');
            if (categoryId && categoryName) {
              categoriesList.push(<Option key={categoryId}>{categoryName}</Option>);
            }
          })
          this.setState({ 
            categoriesLoaded: true,
            categories: response.data,
            categoriesList
          });
        } else {
          this.setState({ 
            categoriesLoaded: false,
            categories: [],
            categoriesList 
          });
        } 
      }    
    }).catch(error => {
      console.log(error);
      if (this.mounted) { 
        this.setState({
          isFetching: false,
          data: [] 
        });
      }
    });
  }
  handleSubmit = values => {
    const { t } = this.props;
    //console.log('values', values);
    values.show_online = values.show_online ? 1 : 0;
    const { modifierSetsConnected } = this.state;

    values.modifierSetsConnected = modifierSetsConnected;

    this.setState({
      putREQUEST: true
    });

    fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/products/update/${this.state.productUuid}`, {
      method: 'post',
      body: values
    }).then(response => {
      console.log(response);
      if (response.success) {
        this.setState({
          putREQUEST: false,
          putSUCCESS: true,
          putFAILURE: false,
          //errorMessage: 'Saved successfully'
        }, () => this.getProduct(this.state.productUuid));
          message.success(t('singleLocation.feedback.saveSuccess', { defaultValue: 'Saved successfully' }));
      } else {
        this.setState({
          putREQUEST: false,
          putFAILURE: true,
          putSUCCESS: false,
          //errorMessage: response.message
        });
        message.error(response.message);
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          putSUCCESS: false,
          putREQUEST: false,
          putFAILURE: true,
        });
        message.error(t('shareable.ERR', { defaultValue: 'ERR' }));
     });
  }
  validateInteger = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value) return Promise.resolve();
      const reg = /^\d+$/;
      if (reg.test(value) && (value[0] !== '0' || value.length === 1)) {
        return Promise.resolve();
      } else {
        return Promise.reject(<span><Trans i18nKey="addBranch.amountMustBeRealNumber">Amount must be a real number</Trans></span>);
      }
    },
    validateTrigger: 'onSubmit'
  })
  validateFloat = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value) return Promise.resolve();
      /* eslint-disable-next-line */
      const reg = /^\-?[0-9]+(e[0-9]+)?(\.[0-9]+)?$/;
      if (reg.test(value) && (value[0] !== '0' || value.length === 1)) {
        return Promise.resolve();
      } else {
        return Promise.reject(<span><Trans i18nKey="addBranch.amountMustBeNumber">Amount must be number</Trans></span>);
      }
    },
    validateTrigger: 'onSubmit'
  })
  setFieldsValue = (name, value) => {
    this.formRef.current.setFieldsValue({
      [name]: value
    });
  }
  setImageCoverUrl = (imageCoverUrl) => {
    this.setState({
      imageCoverUrl
    });
  }
  checkModifierSetChecked = (modifier_set_id) => {
    const { modifierSetsConnected } = this.state;
    return modifierSetsConnected.indexOf(modifier_set_id) > -1;
  }
  modifierSetToProduct = (modifier_set_id) => {
    const { modifierSetsConnected } = this.state;
    const copy = [...modifierSetsConnected];
    const index = copy.indexOf(modifier_set_id);

    if (index > -1) {
      delete copy[index];
    } else {
      copy.push(modifier_set_id);
    }

    const final = copy.filter(el => !!el);
    this.setState({
      modifierSetsConnected: final
    });
  }
  renderConnectedModifierSets = () => {
    const { modifierSetsList, modifierSetsConnected } = this.state;

    return modifierSetsList.map((modifierSet, i) => {
      if (modifierSetsConnected.indexOf(modifierSet.id) > -1) {
        return <span className="ant-select-selection-item" key={i}>
          <span className="ant-select-selection-item-content">{modifierSet.display_name}</span>
        </span>;
      }
      return null;
    })
  }
  openModifiersModal = () => {
    const { modifierSetsList } = this.state;

    this.modifierSetsModal = Modal.confirm({
      width: 400,
      title: 'Modifier Sets',
      icon: false,
      centered: true,
      content: (
        <div className="UserManualsAddFirstLocation__body">
          <ul style={{listStyle: 'none', padding: 0}}>
            {modifierSetsList.map((modifierSet, i) => <li key={i} style={{padding: '5px 0'}}>
              <Checkbox
                onChange={e => this.modifierSetToProduct(modifierSet.id)}
                defaultChecked={this.checkModifierSetChecked(modifierSet.id)}
                > { modifierSet.display_name } </Checkbox>
            </li>)}
          </ul>
          <div>
            <Button onClick={e => this.modalVisible('showAddModifierSets', true)}>Add new Modifier Set</Button>
          </div>
        </div>
      ),
      cancelText: 'Cancel',
      cancelButtonProps: {style: {display: 'none'}},
      okText: 'Close',
      onOk: () => {
        console.log('okay openProductsModal');
      },
      maskClosable: true
    });
  }
  modalVisible = (key, value) => {
    this.setState({ [key]: value });
  }
  render() {
    const { t } = this.props;
    const { 
      isFetching,
      categoriesLoaded,
      categoriesList,
      product, 
      initialValues,
      modifierSetsConnected,
      showAddModifierSets
    } = this.state;
    return (
    	<Layout className="bg-transparent">
      { initialValues && Object.keys(initialValues).length ? 
        <Card
          className="general-card" 
          loading={isFetching || !categoriesLoaded}
          title={
            <Row>
              <Link to="/products" className="general-card__back-btn"><LeftOutlined /></Link>
              <span className="general-card__title-text">{product.display_name}</span>
            </Row>
          }
          >
          <Form requiredMark={false} className="general-form" layout="vertical" ref={this.formRef} initialValues={initialValues} onFinish={this.handleSubmit}>
            <Row gutter={140}>
              <Col xs={24} sm={12}>
                <Row gutter={16}>
                  <Col xs={14} sm={12}>
                    <FormItem rules={[{ required: true, message: t('shareable.required.prodName', { defaultValue: 'Product name is required!' }) }]} name="display_name" label="Product name">
                      <Input placeholder="Name"/>
                    </FormItem>
                  </Col>
                  <Col sm={6} xs={8}>
                    <FormItem style={{marginLeft: '8px'}} valuePropName="checked" rules={[{ required: true }]} name="show_online" label="Show online">
                      <Switch/>
                    </FormItem>
                  </Col>
                  <Col sm={6} xs={8}>
                    <FormItem style={{marginLeft: '8px'}} valuePropName="checked" rules={[{ required: true }]} name="is_alcohol" label="It's alcohol">
                      <Switch/>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <FormItem name="description" label="Description" style={{marginBottom: '0px'}}>
                      <TextArea placeholder="E.g.: Delicious meal..." showCount maxLength={255}/>
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <FormItem 
                      rules={[{required: false, message: '' }, this.validateInteger ]}
                      name="serving_time" label="Serving time">
                      <Input placeholder="Serving time in minutes"/>
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem 
                      rules={[{required: false, message: '' }]}
                      name="accounting_number" label="Product No">
                      <Input placeholder="Product No"/>
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <FormItem rules={[{required: true, message: t('shareable.product_price.required', { defaultValue: 'Please, enter price'}) }, this.validateFloat ]}
                      label="Price" name="product_price" className="prefix-input-fix">
                        <Input placeholder="2.50" addonBefore="$"/>
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      label="Sell tax"
                      name="vat"          
                      rules={[{required: true, message: t('shareable.tax_select.required', { defaultValue: 'Please choose tax option'}) }]}
                      >
                      <Select 
                        placeholder={t('shareable.tax_select.placeholder', { defaultValue: 'Select tax'})}
                      >
                        <Option value={0}>Tax free</Option>
                        <Option value={10}>10% GST</Option>
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <FormItem rules={[{ required: true,  message: t('shareable.categories.required', { defaultValue: 'Please, select at least one category'}) }]} name="categories" label="Categories">
                      <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Please select categories"
                        showArrow={true}
                      >
                        {categoriesList}
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{marginBottom: '24px'}}>
                    <Row>
                      <div className="ant-col ant-form-item-label">
                        <label className="ant-form-item-required" title="Modifiers">Modifiers</label>
                      </div>
                    </Row>
                    <div className="ant-select ant-select-multiple" style={{ width: '100%' }} onClick={ this.openModifiersModal }>
                      <div className="ant-select-selector heightNotLimited">
                        {
                        !modifierSetsConnected.length ? 
                          <span className="ant-select-selection-placeholder">No modifiers connected yet</span>
                        : 
                          this.renderConnectedModifierSets()
                        }
                      </div>

                      <span className="ant-select-arrow" unselectable="on" aria-hidden="true" style={{userSelect: 'none'}}>
                        <span role="img" aria-label="down" className="anticon anticon-down ant-select-suffix">
                          <svg viewBox="64 64 896 896" focusable="false" data-icon="down" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                            <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z" />
                          </svg>
                        </span>
                      </span>
                    </div>
                  </Col>
                </Row>
                
              </Col>
              <Col xs={24} sm={12}>
                <ImageProduct
                  file={product.file}
                  productId={product.id}
                  />
                <Form.Item name="cover_image_id" style={{ display: 'none' }}>
                  <Input placeholder="cover_image_id" />
                </Form.Item> 
              </Col>
            </Row>
            <Row>
              <Button className="general-card__submit-btn" htmlType="submit" type="primary">Apply changes</Button>
            </Row>
          </Form> 
        </Card> : null }

        { showAddModifierSets && <AddModifierSets
          history={this.props.history}
          handleClose={() => this.modalVisible('showAddModifierSets', false)}
          visible={showAddModifierSets} 
          updateModifiersList={this.getModifiers}
          restaurantId={this.restaurantId}
          hideProductConnection={true}
        /> }
      </Layout>
    )
  }
};

export default  withTranslation()(SingleProduct);