import React from 'react';
import fetchJSON from 'services/utils/fetchJSON';
import { withTranslation, Trans } from 'react-i18next';
import {
  Modal,
  Form,
  message,
  Radio,
  Button,
  Input,
  InputNumber,
  Spin,
  Checkbox,
  Select,
  notification,
  Col,
  Row,
} from 'antd';
import { withWindowSizeListener } from 'react-window-size-listener';
import moment from 'moment-timezone';
import { DeleteOutlined } from '@ant-design/icons';
import _ from 'lodash';
import SplitOrder from './SplitOrder';
import { surchargesToString, surchargesToValue } from 'helpers/surchargesToString';
import roundTo5Cents from 'roundto5cents'
import formModifierPriceString from '../../../../../../../helpers/formModifierPriceString'
import { correctModifierSurcharges } from '../../../../../../../helpers/correctModifierSurcharges'
const FormItem = Form.Item;
const confirm = Modal.confirm;

require("moment-duration-format");


class AddOrder extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      putREQUEST: false,
      payment_type: '',
      orderUuid: null,
      selectedCategory: null,
      cartListArr: [],
      fetchingProducts: false,
      menuId: null,
      paymentMethod: false,
      receiptScreen: false,
      showList: true,
      table_id: null,
      receipt_option: null,
      customers_email: '',
      payNowScenario: false,
      repeatReceipt: false,
      change_amount: 0,
      cartQuantityTotal: 0,
      splitActive: 0,
      surcharges: 1,
      processing_fee: 0,
      discount_amount: 0,
      discount_comment: '',
      discount_value: 0,
      order_total: 0,
      order_discount_value: 0,
      order_discount_amount: 0,
      surcharges_amount: 0,
      total_before_discount: 0,
      userRole: '',
      s_value: 1
    };
  }
  componentDidMount = () => {
    this.mounted = true;
    const { selectedOrder, payNowScenario, userRole } = this.props;
    const { uuid, is_delivered, table_id, receipt, surcharges, processing_fee, discount_amount, discount_value } = selectedOrder;

    const casE = _.get(selectedOrder, 'customer.email');

    this.setState({
      customers_email: casE || '',
      orderUuid: uuid,
      showList: !is_delivered,
      checkoutOpen: is_delivered,
      table_id,
      payNowScenario,
      receipt_option: receipt,
      surcharges,
      processing_fee,
      discount_amount,
      discount_value,
      userRole,
      s_value: surcharges
    }, () => {
      if (payNowScenario) {
        this.payNowScenario();
      }
      this.restoreItemsToCart()
    });


  }

  payNowScenario = () => {
    this.setState({
      payNowScenario: true,
      checkoutOpen: false,
      showList: false,
      paymentMethod: true
    });
  }
  restoreItemsToCart = () => {
    const { cartListArr } = this.state;
    const newCartListArr = [...cartListArr];

    const { selectedOrder, branch } = this.props;
    const { menu } = branch;
    const { order_items } = selectedOrder;
    let cartQuantityTotal = 0;

    order_items.forEach((item) => {

      const addToCart = {
        item: false,
        quantity: item.quantity,
        comments: item.comments,
        order_item_id: item.id,
        is_ready: item.is_ready,
        modifiers: item.modifiers
      };
      cartQuantityTotal += item.quantity;

      if (menu.constructor === Object && Object.entries(menu).length !== 0) {
        if (menu.categories && Array.isArray(menu.categories) && menu.categories.length)  {
          menu.categories.forEach((category) => {
            const { items } = category;
            if (items && Array.isArray(items) && items.length)  {
              category.items.forEach((itemObj) => {
                if (itemObj.id === item.product_id && !addToCart.item) {
                  addToCart.item = {...itemObj};
                }
              });
            }
          });
          if (addToCart.item) {
            newCartListArr.push(addToCart);
          }
        }
      }
    });

    this.setState({
      cartListArr: newCartListArr,
      cartQuantityTotal
    }, () => {
      this.calculateOrderView()
    });
  }
  componentWillUnmount = () => {
    this.mounted = false;
  }
  handleSubmit = () => {
    const { t, selectedOrder, deliverNowScenario } = this.props;
    const { payNowScenario, order_discount_amount, discount_comment, order_discount_value, surcharges_amount } = this.state;
    return this.formRef.current.validateFields()
    .then((values) => {
      if (!values.receipt) {
        this.setState({
          receiptScreen: true,
          paymentMethod: false
        });
        return;
      }
      values.payNowScenario = payNowScenario;
      if (payNowScenario) {
        values.deliverNowScenario = deliverNowScenario;
      }
      values.discount_amount = order_discount_amount
      values.discount_comment = discount_comment
      values.discount_value = order_discount_value
      values.surcharges_amount = surcharges_amount


      this.setIsFetching(true);
      this.setState({
        putREQUEST: true
      });
      fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches-orders/orderPaid/`, {
        method: 'POST',
        body: values
      }).then(response => {
        this.setIsFetching(false);
        if (response.code === 200) {
          message.success('Success');
          selectedOrder.is_paid = true;

          this.props.renderList();
          this.setState({
            // putREQUEST: false,
            putSUCCESS: false,
            putFAILURE: false,
            paymentMethod: false,
            checkoutOpen: true
          }, this.props.handleClose);

        } else {
          this.setState({
            putREQUEST: false,
            putFAILURE: true,
            putSUCCESS: false,
            //errorMessage: response.message
          });
          message.error(response.message);
        }
       }).catch(error => {
          this.setIsFetching(false);
          this.setState({
            putSUCCESS: false,
            putREQUEST: false,
            putFAILURE: true,
          });
          message.error(t('shareable.ERR', { defaultValue: 'ERR' }));
       });
    }).catch((err) => console.log('validate err', err))
  }
  validatePhone = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || !value.phone) {
        return Promise.reject('Phone is required!')
      } else {
        if (value.phone.length < 7) {
          return Promise.reject(<p><Trans i18nKey="shareable.reqPhone.minLength">Phone should be 8 symbols and longer</Trans></p>);
        }
        const isnum = /^\d+$/.test(value.phone);
        if (!isnum) {
          return Promise.reject(<p><Trans i18nKey="shareable.reqPhone.onlyNumber">Please, enter correct phone</Trans></p>);
        }
      }
      return Promise.resolve();
    },
    validateTrigger: 'onSubmit'
  })
  paymentTypeChanged = (e) => {
    this.setState({
      payment_type: e.target.value
    });
  }
  setIsFetching = (param) => {
    this.setState({
      fetchingProducts: param
    });
  }

  cartEmpty = () => {
    const { cartListArr } = this.state;
    let empty = 1;

    if (cartListArr && typeof cartListArr === 'object' && cartListArr.length) {
      empty = 0;
    }
    return empty;
  }

  calculateOrderView = (params = false) => {
    const { cartListArr, surcharges, processing_fee, discount_value } = this.state
    console.log('state', this.state)

    let order_total = 0
    let s_order_total = 0

    if (cartListArr && typeof cartListArr === 'object' && cartListArr.length) {
      cartListArr.forEach((line) => {
        const { modifiers } = line

        let totalModifiers = 0;
        if (modifiers && modifiers.length) {
          modifiers.forEach((modifier) => {
            totalModifiers += correctModifierSurcharges(modifier.price, surcharges)
          });
        }

        if (!params) {
          order_total += correctModifierSurcharges(line.quantity * parseFloat(line.item.price), surcharges)  + totalModifiers * line.quantity
        }

        if (params === 'noTaxes') {
          let gross = correctModifierSurcharges(line.quantity * parseFloat(line.item.price), surcharges)  + totalModifiers * line.quantity
          let net = gross
          if (line.item.vat) {
            net = gross - (gross * line.item.vat / 100)
          }
          order_total += net
        }
        if (params === 'onlyTaxes') {
          let gross = correctModifierSurcharges(line.quantity * parseFloat(line.item.price), surcharges)  + totalModifiers * line.quantity
          let taxes = 0;
          if (line.item.vat) {
            taxes = (gross * line.item.vat / 100)
          }
          order_total += taxes
        }
      });

      cartListArr.forEach((line) => {
        const { modifiers } = line

        let s_totalModifiers = 0;
        if (modifiers && modifiers.length) {
          modifiers.forEach((modifier) => {
            s_totalModifiers += modifier.price
          });
        }

        if (!params) {
          s_order_total += line.quantity * (parseFloat(line.item.price)  + s_totalModifiers);
        }

        if (params === 'noTaxes') {
          let s_gross = line.quantity * (parseFloat(line.item.price)  + s_totalModifiers)
          let s_net = s_gross
          if (line.item.vat) {
            s_net = s_gross - (s_gross * line.item.vat / 100)
          }
          s_order_total += s_net
        }
        if (params === 'onlyTaxes') {
          let s_gross = line.quantity * (parseFloat(line.item.price)  + s_totalModifiers)
          let s_taxes = 0;
          if (line.item.vat) {
            s_taxes = (s_gross * line.item.vat / 100)
          }
          s_order_total += s_taxes
        }
      });
    }

    const surcharges_amount = Math.abs(order_total -  s_order_total)

    order_total += processing_fee
    const total_before_discount = order_total
    const discount_amount = order_total * discount_value / 100
    order_total -= discount_amount
    this.setState({
      order_total,
      discount_value,
      discount_amount,
      surcharges_amount,
      total_before_discount
    })
  }

  countTotal = (params = false) => {
    const { selectedOrder } = this.props;
    const { cartListArr, surcharges, processing_fee, discount_value } = this.state;
    let total = 0;
    if (cartListArr && typeof cartListArr === 'object' && cartListArr.length) {
      cartListArr.forEach((line) => {
        const { modifiers } = line;

        let totalModifiers = 0;
        if (modifiers && modifiers.length) {
          modifiers.forEach((modifier) => {
            totalModifiers += correctModifierSurcharges(modifier.price, surcharges)
          });
        }

        if (!params) {
          total += correctModifierSurcharges(line.quantity * parseFloat(line.item.price), surcharges)  + totalModifiers * line.quantity
        }

        if (params === 'noTaxes') {
          const gross = correctModifierSurcharges(line.quantity * parseFloat(line.item.price), surcharges)  + totalModifiers * line.quantity
          let net = gross;
          if (line.item.vat) {
            net = gross - (gross * line.item.vat / 100);
          }
          total += net;
        }

        if (params === 'onlyTaxes') {
          const gross = correctModifierSurcharges(line.quantity * parseFloat(line.item.price), surcharges)  + totalModifiers * line.quantity
          let taxes = 0;
          if (line.item.vat) {
            taxes = (gross * line.item.vat / 100);
          }
          total += taxes;
        }
      });
    }

    if (!params) {
      total += processing_fee
      const discount_amount = total * discount_value / 100
      total -= discount_amount
    }

    return `$${total.toFixed(2)}`;
  }
  switchCheckout = (param) => {
    this.setState({
      checkoutOpen: param
    })
  }
  kitchenStatus = () => {
    const { branchUuid } = this.props;
    const { orderUuid } = this.state;
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches-orders/getKitchenStatus/', {
      method: 'POST',
      body: {
        branchUuid,
        orderUuid,
      }
    }).then(response => {
      this.setIsFetching(false);
      if (response.code === 200) {
        if (response.data.is_kitched_completed) {
          this.isDelivered();
        } else {
          confirm({
            title: `This order hasn’t yet been marked as complete by the Kitchen. Are you sure you are delivering the correct order? Noting yes will mark this order complete in the Kitchen.`,
            onOk: () => {
              this.isDelivered();
            },
            onCancel() {},
          });
        }
      }
     }).catch(error => {
        this.setIsFetching(false);
     });

  }
  isDelivered = () => {
    this.setIsFetching(true);
    const { branchUuid } = this.props;
    const { orderUuid } = this.state;
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches-orders/submit/', {
      method: 'POST',
      body: {
        branchUuid,
        orderUuid,
        key: 'is_delivered',
        value: true
      }
    }).then(response => {
      this.setIsFetching(false);
      if (response.code === 200) {
        const { selectedOrder } = this.props;
        const is_delivered = true;

        selectedOrder.is_delivered = is_delivered;
        selectedOrder.orderStatus = 'Delivered';

        this.setState({
          showList: false,
          checkoutOpen: is_delivered,
        }, () => {
          const { is_paid } = selectedOrder;
          if (is_paid) {
            this.isClosed()
          }
        });
        this.props.renderList();
      }
     }).catch(error => {
        this.setIsFetching(false);
     });
  }
  isClosed = () => {
    const { receipt_option } = this.state;
    const { selectedOrder: { is_paid } } = this.props;

    if (!receipt_option) {
      this.setState({
        paymentMethod: !is_paid,
        receiptScreen: is_paid,
        checkoutOpen: false
      });
    } else {
      this.setState({
        showList: false,
        paymentMethod: false,
        receiptScreen: false,
        checkoutOpen: false
      }, this.handleSubmit);
    }
  }
  markAsReady = (e, item) => {
    this.setIsFetching(true);
    const { branchUuid } = this.props;
    const { orderUuid } = this.state;
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches-orders/itemReady/', {
      method: 'POST',
      body: {
        branchUuid,
        orderUuid,
        order_item_id: item.order_item_id,
        is_ready: e.target.checked
      }
    }).then(response => {
      item.is_ready = e.target.checked;
      this.setIsFetching(false);
      if (response.code === 200) {
        const { cartListArr } = this.state
        let is_delivered = true
        cartListArr.forEach((item) => {
          if (item.is_ready === false) {
            is_delivered = false
          }
        })
        if (is_delivered === true) {
          this.isDelivered()
        } else {
          this.props.renderList();
        }
      }
     }).catch(error => {
        this.setIsFetching(false);
     });
  }
  renderTable = (table_id) => {
    const { tables } = this.props.branch;

    if (table_id && tables && Array.isArray(tables) && tables.length) {
      return <Select value={table_id} onChange={this.changeTableId}>
        {tables.map((item, key) => <Select.Option key={key} value={item.id}>{item.display_name}</Select.Option>)}
      </Select>;
    }

    return null;
  }
  changeTableId = (value) => {
    this.setIsFetching(true);
    const { branchUuid } = this.props;
    const { orderUuid } = this.state;
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches-orders/submit/', {
      method: 'POST',
      body: {
        branchUuid,
        orderUuid,
        key: 'table_id',
        value: value
      }
    }).then(response => {
      this.setIsFetching(false);

      if (response.code === 200) {
        this.setState({
          table_id: value
        });
        this.props.renderList();
      }
     }).catch(error => {
        this.setIsFetching(false);
     });
  }
  getOrderStatus = (order) => {
    const { order_items } = order;
    let status = order.orderStatus;

    if (order_items && order_items.length && order.orderStatus === 'Submitted') {
      const ready = [];
      order_items.forEach((item) => {
        if (item.is_ready) {
          ready.push(item.id);
        }
      });
      if (ready.length > 0 && ready.length !== order_items.length) {
        status = `${ready.length} DELIVERED, ${order_items.length - ready.length} LEFT`;
      }
    }
    return status;
  }
  printOrderStatus = () => {
    const { selectedOrder } = this.props;
    const { table_id } = this.state;
    let formatDate = "h [h] m [min]";
    const start = moment.utc(selectedOrder.submitted_at);
    const ms = moment().diff(start);
    const dur = moment.duration(ms);
    const durationSession = dur.format(formatDate);

    let orderDue = moment.utc(selectedOrder.submitted_at);
    if (selectedOrder.order_type === 'takeout') {
      if (selectedOrder.take_out_delay && selectedOrder.order_type === 'takeout') {
        orderDue.add(selectedOrder.take_out_delay, 'minutes')
      }
    }
    if (selectedOrder.serving_time) {
      orderDue.add(selectedOrder.serving_time, 'minutes')
    }
    const ms2 = moment().diff(orderDue);
    const dur2 = moment.duration(ms2);
    orderDue = dur2.format(formatDate);

    return (<React.Fragment>
      { table_id && selectedOrder.order_type === 'dine_in' ? <div className="orderItem__table">{this.renderTable(table_id)}</div> : null }
      <div className="orderItem__line"><b>Order Placed:</b> {durationSession}</div>
      <div className="orderItem__line"><b>Order Placed:</b> {orderDue}</div>
      <div className="orderItem__line"><b>Status:</b>&nbsp; {this.getOrderStatus(selectedOrder)} </div>
      <div className="orderItem__line"><b>Payment:</b>&nbsp;
        <span style={{color: selectedOrder.is_paid ? '#64D148' : '#F94753' }}>{ selectedOrder.is_paid ? (selectedOrder.is_paid && selectedOrder.is_paid_online ? 'Paid via App' : 'Paid') : 'Not Paid'}</span>
      </div>
    </React.Fragment>);
  }
  deleteProduct = (item) => {
    confirm({
      title: `Are you sure you want to delete this item?`,
      onOk: () => {
        return new Promise((resolve, reject) => {
          const { cartListArr } = this.state;
          const newCartListArr = [...cartListArr];

          const indexOfItem = newCartListArr.findIndex(obj => obj.order_item_id === item.order_item_id);
          if (!indexOfItem) reject();

          const { order_item_id } = newCartListArr[indexOfItem];

          const { branchUuid } = this.props;
          const { orderUuid } = this.state;

          this.setIsFetching(true);
          fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches-orders/removeItem/', {
            method: 'POST',
            body: {
              branchUuid,
              orderUuid,
              order_item_id
            }
          }).then(response => {
            this.setIsFetching(false);
            if (response.code === 200) {
              const newCopy = [];
              newCartListArr.forEach((line, i) => {
                if (line.order_item_id !== order_item_id) {
                  newCopy.push(line);
                }
              });

              this.setState({
                cartListArr: newCopy
              });
              this.calculateOrderView()
              this.props.renderList();
              resolve();
            }
            reject();
           }).catch(error => {
              reject();
              this.setIsFetching(false);
           });
        }).catch((err) => console.log('Oops errors!', err));
      },
      onCancel() {},
    });
  }
  receiptOption = (e) => {
    this.setState({
      receipt_option: e.target.value
    });
    //this.handleSubmit();
  }
  openReceipt = () => {
    this.setState({
      repeatReceipt: true,
      paymentMethod: false,
      receiptScreen: true,
      checkoutOpen: false
    });
  }
  cashTendered = (e) => {
    const { selectedOrder } = this.props
    const { discount_amount } = this.state
    const total = roundTo5Cents(selectedOrder.total - discount_amount)
    const  entered = isNaN(parseFloat(e, 10)) ? 0: parseFloat(e, 10);
    const change_amount = entered - total;
    this.setState({
      change_amount: change_amount
    })

  }

  calculateCardTotal = (total, discount) => {
    const { selectedOrder } = this.props
    const { discount_amount } = this.state
    return (selectedOrder.total - discount_amount).toFixed(2)
  }

  discountCalc = (e) => {
    let value = isNaN(parseFloat(e, 10)) ? 0 : parseFloat(e, 10)
    if (value > 100) value = 100
    if (value < 0) value = 0

    this.setState({
      discount_value: value
    }, () => {
      this.calculateOrderView()
    })

    // const { selectedOrder } = this.props;
    // const total = selectedOrder.total;
    //
    // const discount_amount = total * value / 100
    // this.setState({
    //   discount_amount: parseFloat(discount_amount.toFixed(2), 10),
    //   discount_value: value
    // })
  }

  updateDiscountComment = (e) => {
    this.setState({
      discount_comment: e.target.value
    })
  }

  calculateSurchargesValue(surcharges, discount_value) {
    return surcharges - discount_value/100
  }

  printReceipt = () => {
    const { branchUuid } = this.props;
    const { orderUuid } = this.state;
    this.setIsFetching(true);
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches-orders/submit/', {
      method: 'POST',
      body: {
        branchUuid,
        orderUuid,
        key: 'print_counter_receipt',
        value: true
      }
    }).then(response => {
      this.setIsFetching(false);
      notification.success({
        message: 'Ok',
      });
     }).catch(error => {
        this.setIsFetching(false);
     });
  }
  splitOrder = () => {
    this.setState({
      splitActive: 1
    });
  }

  changeTotalIfCash = (total) => {}
  render() {
    const { branchUuid, selectedOrder, branch } = this.props;
    const {
      putREQUEST,
      payment_type,
      orderUuid,
      fetchingProducts,
      checkoutOpen,
      cartListArr,
      paymentMethod,
      showList,
      receiptScreen,
      receipt_option,
      customers_email,
      repeatReceipt,
      change_amount,
      cartQuantityTotal,
      splitActive,
      surcharges,
      processing_fee,
      discount_amount,
      discount_comment,
      discount_value,
      order_total,
      surcharges_amount,
      total_before_discount,
      userRole,
    } = this.state;

    const { total } = selectedOrder;
    const { windowWidth, windowHeight } = this.props.windowSize;
    const containerStyle = {
      width: windowWidth,
      height: this.props.windowSize.windowHeight,
      minHeight: this.props.windowSize.windowHeight
    };
    const formCss = {
      width: windowWidth < 768 && windowWidth,
      height: windowWidth < 768 && `calc(${windowHeight}px - 60px)`,
      minHeight: windowWidth < 768 && `calc(${windowHeight}px - 60px)`
    };
    const cartScreenCss = {
      ...formCss,
      opacity: showList || checkoutOpen ? 1 : 0,
      zIndex: showList || checkoutOpen ? 1009 : -100
    };

    let is_paid = false;
    if (selectedOrder) {
      is_paid = selectedOrder.is_paid;
    }
    const {is_closed, is_canceled } = selectedOrder;
    return (
      <Modal
        className="general-modal-btns-center general-card createModalOrder createModalOrder--noCart"
        title=""
        centered={true}
        visible={this.props.visible}
        footer={null}
        onCancel={this.props.handleClose}
        destroyOnClose={true}
        confirmLoading={putREQUEST}
        style={ windowWidth < 769 ? containerStyle : {} }
      >
        { orderUuid ?
          <React.Fragment>
          <Spin spinning={fetchingProducts}>

          { !splitActive ?
              <React.Fragment>
              { !checkoutOpen && !showList ? <Form
                  requiredMark={false}
                  className="general-form"
                  layout="vertical"
                  ref={this.formRef}
                  onFinish={this.handleSubmit}
                  initialValues={{
                    branchUuid,
                    orderUuid,
                    customers_email,
                    receipt: receipt_option
                  }}
                  style={formCss}
                >
                  <FormItem rules={[{
                    required: true,
                  }]} name="branchUuid" label="branchUuid" style={{display: 'none'}}>
                    <Input placeholder="branchUuid"/>
                  </FormItem>
                  <FormItem rules={[{
                    required: true,
                  }]} name="orderUuid" label="orderUuid" style={{display: 'none'}}>
                    <Input placeholder="orderUuid"/>
                  </FormItem>
                  <div style={{ display: !paymentMethod ? 'none' : ''}}>
                    <h2 className="orderTypeRadio__title">Choose type of payment</h2>
                    {this.props.currentSplitGuestIndex && <h3 className="orderTypeRadio__title">Guest #{this.props.currentSplitGuestIndex}</h3>}
                    <FormItem rules={[{
                      required: paymentMethod,
                      message: 'Payment type is required!'
                    }]} name="paymentType" label="">
                      <Radio.Group
                        className="orderTypeRadio"
                        size="large"
                        buttonStyle="solid"
                        onChange={this.paymentTypeChanged}
                        >
                        <Radio.Button value="card" disabled={payment_type === 'discount' && discount_comment === '' && discount_amount != 0 ? true: false}>Card</Radio.Button>
                        <Radio.Button value="cash" disabled={payment_type === 'discount' && discount_comment === '' && discount_amount != 0 ? true: false}>Cash</Radio.Button>
                        {userRole === 'waiter' ?
                          branch?.branch?.is_discount_for_foh_active === true ?
                          <Radio.Button className="PayDiscountBtn" value="discount">Discount</Radio.Button> : null
                          : <Radio.Button className="PayDiscountBtn" value="discount">Discount</Radio.Button>}
                      </Radio.Group>
                    </FormItem>
                    <FormItem rules={[{
                      required: payment_type === 'discount',
                      message: 'The note is required!'
                    }]} name="discount_comment" label="Discount comments" style={{display: payment_type !== 'discount' ? 'none' : ''}}>
                      <Input placeholder="Discount comments" onChange={this.updateDiscountComment} />
                    </FormItem>
                    <FormItem rules={[{
                    required: payment_type === 'discount',
                    message: 'The value is required!'
                  }]} name="discount" label="Discount %" style={{display: payment_type !== 'discount' ? 'none' : ''}}>
                    <InputNumber
                      min={0}
                      max={100}
                      defaultValue={discount_value}
                      style={{width:'100%'}}
                      step={1}
                      onChange={this.discountCalc} />
                  </FormItem>
                    <Row gutter={16}>
                      <Col sm={12} xs={12}>
                        <FormItem rules={[{
                          message: 'The note is required!'
                        }]} name="total" label="Total" style={{ display: payment_type !== 'discount' ? 'none' : '' }}>
                          <div style={{fontSize: '30px'}}>${!!order_total?order_total.toFixed(2):'0.00'}</div>
                        </FormItem>
                      </Col>
                      <Col sm={12} xs={12}>
                        <FormItem rules={[{
                          message: 'The note is required!'
                        }]} name="discount_amount" label={'Discount:'} style={{ display: payment_type !== 'discount' ? 'none' : '' }}>
                          <div style={{fontSize: '30px'}}>${discount_amount.toFixed(2)}</div>
                        </FormItem>
                      </Col>
                    </Row>
                    <FormItem rules={[{
                      required: payment_type === 'cash',
                      message: 'Cash tendered is required!'
                    }]} name="cash_tendered" label="Cash tendered" style={{ display: payment_type !== 'cash' ? 'none' : '' }}>
                      <InputNumber
                        placeholder="Cash tendered"
                        min={0}
                        style={{width:'100%'}}
                        step={0.01}
                        onChange={this.cashTendered}
                      />
                    </FormItem>
                    <Row gutter={16}>
                      <Col sm={12} xs={12}>
                        <FormItem rules={[{
                          message: 'The note is required!'
                        }]} name="total" label="Total" style={{ display: payment_type !== 'cash' ? 'none' : '' }}>
                          <div style={{fontSize: '30px'}}>${roundTo5Cents(order_total).toFixed(2)}</div>
                        </FormItem>
                      </Col>
                      <Col sm={12} xs={12}>
                        <FormItem rules={[{
                          message: 'The note is required!'
                        }]} name="change" label="Change" style={{ display: payment_type !== 'cash' ? 'none' : '' }}>
                          <div style={{fontSize: '30px'}}>${!!change_amount?change_amount.toFixed(2): 0.0.toFixed(2)}</div>
                        </FormItem>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col sm={12} xs={12}>
                        <FormItem rules={[{
                          message: 'The note is required!'
                        }]} name="card_total" label="Total" style={{ display: payment_type !== 'card' ? 'none' : '' }}>
                          <div style={{fontSize: '30px'}}>${!!order_total?order_total.toFixed(2):''}</div>
                        </FormItem>
                      </Col>
                    </Row>
                    <Button
                      className="createModalOrder__submit"
                      type="primary"
                      size="large"
                      htmlType="submit"
                      style={{ width: '100%' }}
                      disabled={ payment_type === 'discount' ? true : payment_type === 'cash' ? change_amount >= 0 ? false : true : false }
                    >{ payment_type === 'discount' ? `Choose type of payment before Submit` : `Submit` }</Button>
                  </div>
                  <div style={{ display: !receiptScreen ? 'none' : ''}}>
                    <h2 className="orderTypeRadio__title">Receipt</h2>
                    <FormItem rules={[{
                      required: receiptScreen,
                      message: 'Receipt type is required'
                    }]} name="receipt" label="">
                      <Radio.Group
                        className="orderTypeRadio"
                        size="large"
                        buttonStyle="solid"
                        onChange={this.receiptOption}
                        >
                        <Radio.Button value="print_receipt">Print receipt</Radio.Button>
                        <Radio.Button value="email_receipt">Email receipt</Radio.Button>
                        {!repeatReceipt ? <Radio.Button value="no_receipt">No Receipt</Radio.Button> : null }
                      </Radio.Group>
                    </FormItem>
                    <FormItem rules={[{
                      required: !is_paid && receipt_option === 'email_receipt',
                      message: 'The email is required!'
                    }]} name="customers_email" type="email" label="Customer's email" style={{display: receipt_option !== 'email_receipt' ? 'none' : ''}}>
                      <Input placeholder="Email"/>
                    </FormItem>
                    <Button className="createModalOrder__submit" type="primary" size="large" htmlType="submit" style={{ width: '100%' }}>Submit</Button>
                  </div>

                </Form> : null }
              </React.Fragment>
            : null }
            {
              splitActive ?
              <SplitOrder
                branchUuid={branchUuid}
                orderUuid={orderUuid}
                selectedOrder={selectedOrder}
                fetchingProducts={fetchingProducts}
                cartScreenCss={cartScreenCss}
                cartListArr={cartListArr}
                renderList={this.props.renderList}
                handleClose={this.props.handleClose}
                updateCurrentSplitOrders={this.props.updateCurrentSplitOrders}
                surcharges={surcharges}
              />
              : null
            }

            {
              !splitActive ?
              <React.Fragment>
                { !paymentMethod && (showList || checkoutOpen) ?
                  <React.Fragment>
                  <Spin spinning={fetchingProducts}>
                    <div className="cartScreen" style={cartScreenCss}>
                      <div className="cartScreen__header">
                        {this.printOrderStatus()}
                      </div>
                      <div className="cartScreenBody">
                      { this.cartEmpty() ?
                        <div className="cartScreenBody__empty">Cart is empty</div>
                      :
                      <div style={{ width: '100%'}}>
                        <div className="cartScreenList">
                          {cartListArr.map((line, index) => {
                            console.log('line:', line)
                            const { modifiers } = line;
                            return (<React.Fragment key={index}><div className="cartScreenItem">
                              <div className="cartScreenItem__middle">
                                <div className="cartScreenItem__title">{line.item.displayName}</div>
                                { line.comments && <div className="cartScreenItem__comments">{line.comments}</div> }
                              </div>

                              <div className="cartScreenItem__itemMiddle" style={{marginRight: '20px'}}>{line.quantity}</div>
                              { showList ?
                              <div className="cartScreenItem__itemMiddle">
                                <Checkbox onChange={(e) => this.markAsReady(e, line)} checked={line.is_ready} style={{zoom: '1.5', marginTop: '1px'}} />
                              </div>
                              : null }

                              {!is_paid && showList ? <div className="cartScreenItem__itemMiddle" style={{marginRight: '20px'}}>
                                <button
                                className="cartScreenItem__deleteBtn"
                                style={{ marginLeft: '15px' }}
                                onClick={() => this.deleteProduct(line)}
                                alt="delete product"
                                ><DeleteOutlined /></button>
                              </div> : null }

                              <div className="cartScreenItem__price">
                                ${(line.quantity * line.item.price * surcharges).toFixed(2)}
                              </div>
                            </div>
                            {Array.isArray(modifiers) && modifiers.length ?
                            modifiers.map((modifier) => <div className="cartScreenItem" key={modifier.id} style={{ paddingLeft: '35px' }}>
                              <div className="cartScreenItem__middle">
                                <div className="cartScreenItem__title">{!!modifier?.modifier_set ? modifier.modifier_set + ': ' : ''}{modifier.display_name}</div>
                              </div>
                              <div className="cartScreenItem__itemMiddle"></div>
                              <div className="cartScreenItem__price">
                                {modifier.price ? `${formModifierPriceString(correctModifierSurcharges(modifier.price, surcharges))}` : ''}
                              </div>
                            </div>)
                            : null}
                            </React.Fragment>)
                          }) }
                        </div>
                      </div>
                      }
                    </div>
                    <div className="cartScreenFooter">
                      <div className="cartScreenTotal">
                        <div className="cartScreenTotal__item">
                          GST
                          <span className="cartScreenTotal__price">{this.countTotal('onlyTaxes')}</span>
                        </div>
                        { surcharges != 1 ?
                          <div className="cartScreenTotal__item">
                            { surcharges > 1 ? `Restaurant surcharges` : `Restaurant discount` }
                            <span className="cartScreenTotal__price">{surchargesToString(surcharges)} | ${surcharges_amount.toFixed(2)}</span>
                          </div>
                          : null}
                        { processing_fee != 0 ?
                          <div className="cartScreenTotal__item">
                            Processing fee
                            <span className="cartScreenTotal__price">${!!processing_fee ? processing_fee.toFixed(2) : ''}</span>
                          </div>
                          : null }
                        { discount_value > 0 ?
                          <>
                            <div className="cartScreenTotal__item">
                              Subtotal
                              <span className="cartScreenTotal__price"> ${total_before_discount.toFixed(2)}</span>
                            </div>
                            <div className="cartScreenTotal__item">
                              Discount
                              <span className="cartScreenTotal__price"> {discount_value}% | ${discount_amount.toFixed(2)}</span>
                            </div>
                          </>
                          : null }
                        <div className="cartScreenTotal__item">
                          Total Amount Payable
                          <span className="cartScreenTotal__price cartScreenTotal__price--red">{this.countTotal()}{selectedOrder.is_paid_online === true && selectedOrder.payment_processing_by_client === 1 ? `*` : null}</span>
                        </div>
                        { selectedOrder.is_paid_online === true && selectedOrder.payment_processing_by_client === 1 ?
                          <div className="cartScreenTotal__item">
                            <span className="cartScreenTotal__price">*The payment processing fee was applied</span>
                          </div>
                          : null }
                      </div>
                      {!is_closed && !is_canceled ? <React.Fragment>
                        { showList && !checkoutOpen ?
                        <div className="cartScreenFooter__box cartScreenFooter__box--columns">
                          <button className="cartScreenFooter__checkout cartScreenFooter__checkout--reverse" onClick={this.kitchenStatus}>All Delivered</button>
                           { cartQuantityTotal > 1 && !is_paid ? <button
                              className="cartScreenFooter__checkout cartScreenFooter__checkout--reverse"
                              onClick={() => this.splitOrder()}
                              style={{marginBottom: '0', marginTop: '10px' }}
                              >
                              Split Order
                            </button> : null }
                          {!is_paid ? <button className="cartScreenFooter__checkout cartScreenFooter__checkout--reverse" onClick={this.payNowScenario}>Pay Now</button> : null }
                        </div> : null }

                        { !showList && checkoutOpen ?
                          <div className="cartScreenFooter__box" style={{flexDirection: 'column'}}>
                            <button
                              className="cartScreenFooter__checkout cartScreenFooter__checkout--reverse"
                              style={{marginBottom: '10px', width: '100%'}}
                              onClick={this.printReceipt}>Print Receipt</button>
                            { cartQuantityTotal > 1 ? <button
                              className="cartScreenFooter__checkout cartScreenFooter__checkout--reverse"
                              onClick={() => this.splitOrder()}
                              style={{marginBottom: '10px', marginTop: '0', width: '100%'}}
                              >
                              Split Order
                            </button> : null }
                            <button
                              className="cartScreenFooter__checkout"
                              onClick={() => this.isClosed()}
                              style={{marginBottom: '20px', width: '100%'}}
                              >
                              Pay Now
                            </button>
                          </div> : null }
                       </React.Fragment> : null }
                       {
                        is_closed ?
                        <React.Fragment>
                          <div className="cartScreenFooter__box" style={{flexDirection: 'column'}}>
                            <button
                              className="cartScreenFooter__checkout"
                              onClick={() => this.openReceipt()}
                              style={{marginBottom: '20px', width: '100%'}}
                              >
                              Receipt
                            </button>
                          </div>
                        </React.Fragment>
                       : null }
                    </div>
                    </div>
                  </Spin>
                </React.Fragment>
               : null }
              </React.Fragment>
              : null
            }

          </Spin>
          </React.Fragment>
          : null }
      </Modal>
    )
  }
}

export default withTranslation()(withWindowSizeListener(AddOrder));
