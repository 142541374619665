import React from 'react';
import { Router, Switch, Route} from 'react-router-dom';

import App from './App';

import LogOut from 'screens/LogOut';
import Login from 'screens/Login/Login';
import LoginEmployee from 'screens/Login/components/LoginEmployee';
import ResetPassword from 'screens/Login/ResetPassword/ResetPassword';
import Branches from 'screens/Dashboard/Branches/Branches';
import SingleBranch from 'screens/Dashboard/Branches/components/SingleBranch/SingleBranch';
import AddBranch from 'screens/Dashboard/Branches/components/AddBranch/AddBranch';
import AddRestaurant from 'screens/Dashboard/Restaurants/components/AddRestaurant/AddRestaurant';
import SingleRestaurant from 'screens/Dashboard/Restaurants/components/SingleRestaurant/SingleRestaurant';
import Products from 'screens/Dashboard/Products/Products';
import SingleProduct from 'screens/Dashboard/Products/components/SingleProduct/SingleProduct';
import ManagerMenu from 'screens/Dashboard/Branches/components/Manager/Menu/Menu';
import ManagerReports from 'screens/Dashboard/Branches/components/Manager/Reports/Reports';
import ManagerLocation from 'screens/Dashboard/Branches/components/Manager/Location/Location';
import UserSettings from 'screens/UserSettings/UserSettings';
import Reports from 'screens/Dashboard/Reports/Reports';
import SingleUser from 'screens/Dashboard/Users/SingleUser/SingleUser';
import Orders from 'screens/Dashboard/Orders/Orders';
import QRcode from 'components/QRcode/QRcode.jsx';
import Employees from 'screens/Dashboard/Employees/Employees';

// utils for check authorization status
import { checkLogin, checkAccessWrap, checkMainDefault } from 'services/utils/requireAuth';
// import AppWrapper from 'components/AppWrapper/AppWrapper';
import { createBrowserHistory as createHistory } from 'history'

export const history = createHistory();

const PrivateRoute = ({ component: Component, isAuthenticated, redirectTo, ...rest}) => (
	<Route
		{...rest}
		render = { props => (
			isAuthenticated(redirectTo, () => {
				return ( <Component {...props} /> );
			})
		)}
	/>
);

const router = () => (
	<Router history={history}>
		<App>
			<Switch>
				<PrivateRoute exact path="/login" component={Login} isAuthenticated={checkLogin} redirectTo='/' />
				<PrivateRoute exact path="/login-employee" component={LoginEmployee} isAuthenticated={checkLogin} redirectTo='/' />
				<PrivateRoute exact path="/resetpass" component={ResetPassword} isAuthenticated={checkLogin} redirectTo='/' />
				<Route exact path="/logout" component={LogOut} />
				<Route path='/restaurants/add' component={checkAccessWrap(AddRestaurant, 'restaurant')} />
				<Route path='/manager/menu' component={checkAccessWrap(ManagerMenu, '/manager/menu')} />
				<Route path='/manager/reports' component={checkAccessWrap(ManagerReports, '/manager/menu')} />
				<Route path='/manager/location' component={checkAccessWrap(ManagerLocation, '/manager/menu')} />
				<Route path='/restaurants/:restaurantUuid' component={checkAccessWrap(SingleRestaurant, 'restaurant')} />
				<Route exact path='/branches/add' component={checkAccessWrap(AddBranch, 'branches_add')} />
				<Route exact path='/branches' component={checkAccessWrap(Branches, 'branches')} />
				<Route path='/branches/:branchUuid' component={checkAccessWrap(SingleBranch, 'branch')} />
				<Route exact path ='/products' component={checkAccessWrap(Products, 'products')} />
				<Route path='/products/:productUuid' component={checkAccessWrap(SingleProduct, 'products')} />
				<Route path='/users/:userUuid' component={checkAccessWrap(SingleUser, 'users')}/>
				<Route path='/reports' component={checkAccessWrap(Reports, 'reports')} />
				<Route path='/orders' component={checkAccessWrap(Orders, 'orders')} />
				<Route path='/employees' component={checkAccessWrap(Employees, 'employees')} />
				<Route exact path='/settings' component={checkAccessWrap(UserSettings, 'settings')} />
				<Route exact path='/qr-code/' component={QRcode} />
				<Route path='/' component={checkMainDefault()} />
			</Switch>
		</App>
	</Router>
);

export default router;
