import React from 'react';
import fetchJSON from 'services/utils/fetchJSON';
import _ from 'lodash';
import { Table, Tag, Row, Col, Button, notification } from 'antd';
import { withTranslation } from 'react-i18next';
import { withWindowSizeListener } from 'react-window-size-listener';
import moment from 'moment';
import { Parser } from 'json2csv';
import SingleOrder from '../../../Branches/components/Orders/Manager/components/SingleOrder';
import SmsAuthenticationPrompt from 'components/SmsAuthenticationPrompt/SmsAuthenticationPrompt';

import './styles.less';

const paymentMethodsLabels = {
  card_pay_at_counter: 'Card pay at counter',
  cash: 'Cash',
  paid_online: 'Mobile payment',
  discount: 'Discount',
  credit_card: 'Credit Card',
  card: 'Credit Card',
  pay_at_counter: 'Pay at counter'
};

class TransactionsReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredInfo: {},
      sortedInfo: {},
      searchText: '',
      filtered: false,
      result: [],
      data: [],
      totalNumberTable: 0,
      countRestaurants: 0,
      scrollTable: false,
      visibleSingleOrder: false,
      selectedOrderUuid: '',
      selectedBranchUuid: '',
      totalIncTax: '',
      totalExcTax: '',
      totalGst: '',
      totalComission: '',
      visibleSmsAuthenticationPrompt: false
    };
    this.totalNumberTable = 0;
    this.totalNumberInterval = null;
    // for detecting if scroll is enabled
    this.scrollTableInterval = null;
  }
  componentDidMount() {
    this.mounted = true;
    this.setState({
      pagination: {
        total: 0,
        pageSizeOptions: [10, 30, 50, 100],
        showSizeChanger: true,
        showTotal: (total) => {
          return <p id="totalNumber" style={{display: 'none'}}>{this.renderTotal(total)}</p>
        }
      }
    })
    this.scrollTableInterval = setInterval(this.checkIfScrollOn, 100);
    this.setFilter();
  }
  componentWillUnmount() {
    this.mounted = false;
    clearInterval(this.totalNumberInterval);
    clearInterval(this.scrollTableInterval);
  }
  setFilter = () => {
    const paymentMethodsList = [];
    const temp = [];
    Object.keys(paymentMethodsLabels).forEach((key) => {
      if (temp.indexOf(paymentMethodsLabels[key]) === -1) {
        temp.push(paymentMethodsLabels[key]);
        paymentMethodsList.push({ text: paymentMethodsLabels[key], value: paymentMethodsLabels[key] });
      }
    });

    this.setState({
      paymentMethodsList
    });
  }
  checkIfScrollOn = () => {
    const tableContent = document.getElementsByClassName("ant-table-content");
    if (tableContent && tableContent.length) {
      if (this.isEllipsisActive(tableContent[0])) {
        this.setState({
          scrollTable: true
        })
      }
      clearInterval(this.scrollTableInterval);
    }
  }
  trackTotalNumberValue = () => {
    clearInterval(this.totalNumberInterval);
    const { totalNumberTable } = this.state;
    if (totalNumberTable !== this.totalNumberTable) {
      this.setState({
        totalNumberTable: this.totalNumberTable
      })
    }
    this.totalNumberInterval = null;
  }
  renderTotal = (total) => {
   this.totalNumberTable = total;
   const { totalNumberTable } = this.state;
   if (totalNumberTable !== this.totalNumberTable && !this.totalNumberInterval) {
    setTimeout(this.updateTotalStats, 500);
    this.totalNumberInterval = setInterval(this.trackTotalNumberValue, 100);
   }
   return total;
  }
  isEllipsisActive = (e, test = false) => {
    if (test) {
      console.log(e)
    }
    if (e) {
      return (e.offsetWidth < e.scrollWidth)
    }
    else {
      return false
    }
  }
  downloadFile = () => {
    const { filteredDataRows } = this.state;
    const data = [];

    filteredDataRows.forEach(row => {
      data.push({
        'Order ID': row.index_id,
        'Date': row.date ? moment(row.date).format('D MMM YY, h:mma') : '',
        'User': row.customer_id,
        'Order type': row.order_type_display,
        'Total Inc Tax': row.total_inc_tax ? '$' + row.total_inc_tax.toFixed(2) : '',
        'GST': row.gst ? '$' + row.gst.toFixed(2) : '',
        'Processing': row.processing ? '$' + row.processing.toFixed(2) : '-',
        'Total Exc Tax & Processing': row.total_tax_exc_processing ? '$' + row.total_tax_exc_processing.toFixed(2) : '',
        'Payment method': row.payment_method_display
      })
    });

    const fields = [
      'Order ID',
      'Date',
      'User',
      'Order type',
      'Total Inc Tax',
      'GST',
      'Processing',
      'Total Exc Tax & Processing',
      'Payment method'
    ];
    var fileTitle = 'Transactions report';

    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    const json2csvParser = new Parser({ fields });
    const csv = json2csvParser.parse(data);
    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
  columns = () => {
    let {
      sortedInfo,
      filteredInfo,
      paymentMethodsList
    } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};

    const idItem = {
      title: 'Order ID',
      dataIndex: 'index_id',
      key: 'index_id',
      sortOrder: sortedInfo.columnKey === 'index_id' && sortedInfo.order,
      sorter: (a, b) => a.index_id > b.index_id ? 1 : -1,
      render: (text, record) => {
        return <Button type="link" onClick={() => this.openOrder(record)}>#{record.index_id}</Button>
      }
    };
    const dateItem = {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sortOrder: sortedInfo.columnKey === 'date' && sortedInfo.order,
      sorter: (a, b) => moment(a.date).isAfter(moment(b.date)) ? 1 : -1,
      render: (text, record) => {
        return <span>{record.date ? moment.utc(record.date, 'MM/DD/YY h:mm a').format('D MMM YY, h:mma') : ''}</span>
      }
    };
    const userItem = {
      title: 'User',
      dataIndex: 'customer_id',
      key: 'customer_id',
      align: 'center',
      render: (text, record) => {
        if (record.customer_uuid) return <Button type="link" onClick={() => {window.open(`/users/${record.customer_uuid}`)}}>{record.customer_id}</Button>
        return text
      }
    };
    const orderTypeItem = {
      title: 'Order type',
      dataIndex: 'order_type_display',
      key: 'order_type_display',
      filters:  [
        { text: 'Dine in', value: 'dine_in' },
        { text: 'Takeaway', value: 'takeout' },
        { text: 'Delivery', value: 'delivery' }
      ],
      filteredValue: filteredInfo.order_type_display || null,
      onFilter: (value, record) => {
        if (typeof record.order_type === 'number') {
          return record.order_type === value;
        }
        return record.order_type.includes(value)
      },
      render: (text, record) => {
        let type = 'orange';
        if (record.order_type === 'takeout') {
          type = 'blue';
        }
        if (typeof record.order_type === 'number') {
          type = 'success';
        }
        return <Tag color={type}>{text}</Tag>
      }
    };
    const totalIncTaxItem = {
      title: 'Total Inc Tax',
      dataIndex: 'total_inc_tax',
      key: 'total_inc_tax',
      align: 'center',
      render: (text, record) => <span>{record.total_inc_tax ? '$' + record.total_inc_tax.toFixed(2) : ''}</span>
    };
    const gstItem = {
      title: 'GST',
      dataIndex: 'gst',
      key: 'gst',
      align: 'center',
      render: (text, record) => <span>{record.gst ? '$' + record.gst.toFixed(2) : ''}</span>
    };
    const comissionItem = {
      title: 'Processing',
      dataIndex: 'processing',
      key: 'processing',
      align: 'center',
      render: (text, record) => <span>{record.processing ? '$' + record.processing.toFixed(2) : '-'}</span>
    };
    const totalTaxComissionItem = {
      title: 'Total Exc Tax & Processing',
      dataIndex: 'total_tax_exc_processing',
      key: 'total_tax_exc_processing',
      align: 'center',
      render: (text, record) => <span>{record.total_tax_exc_processing ? '$' + record.total_tax_exc_processing.toFixed(2) : ''}</span>
    };
    const paymentMethodItem = {
      title: 'Payment method',
      dataIndex: 'payment_method_display',
      key: 'payment_method_display',
      filters: paymentMethodsList,
      filteredValue: filteredInfo.payment_method_display || null,
      onFilter: (value, record) => {
        return record.payment_method_display.includes(value)
      }
    };
    const itemsItem = {
      title: 'Items',
      dataIndex: 'items',
      key: 'items',
      render: (text, record) => {
        return <Button type="link" onClick={() => this.openOrder(record)}>{record.items}</Button>
      }
    };
    const actionsItem = {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (<Button size="small" onClick={() => this.confirmRefund(record.order_uuid, record.branch_uuid)}>Refund</Button>)
      }
    };
    const columns = [idItem, dateItem, userItem, orderTypeItem, totalIncTaxItem, gstItem,
      comissionItem, totalTaxComissionItem, paymentMethodItem, itemsItem, actionsItem];
    return columns;
  }
  confirmRefund = (orderUuid, branchUuid) => {
    this.setState({
      visibleSmsAuthenticationPrompt: true,
      smsAuthenticationAction: 'confirm_refund',
      doAfterSmsAuthentication: () => {
        fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/orders/refund/', {
          method: 'POST',
          body: {
            branchUuid,
            orderUuid,
          }
        }).then(response => {
          console.log(response);
          if (response.code === 200) {
            this.props.updateList();
            notification.success({
              message: 'Success',
            });
            return;
          }
          notification.error({
            message: 'Something went wrong, please contact support',
          });
         }).catch(error => {
            console.log(error);
            notification.error({
              message: 'Something went wrong, please contact support',
            });
         });
      }
    });
  }

  handleTableChange = (pagination, filters, sorter) => {
    //console.log('handleTableChange', pagination, filters, sorter);
    setTimeout(() => {
      if (!document.getElementById("totalNumber")) {
        this.setState({
          totalNumberTable: 0,
          totalExcTax: 0,
          totalIncTax: 0,
          totalGst: 0,
          totalComission: 0
        })
      }
    }, 100)
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    }, this.updateTotalStats);
  }
  getData = () => {
    const data = [];
    const orders_transactions = this.props.reportsData;
    if (!orders_transactions || !Array.isArray(orders_transactions)) return data;
    orders_transactions.forEach((order, i) => {
      if (order.transaction_id && order.transaction && !order.refund_transaction_id && !order.refund_transaction) {
        const comission = order.paid_online && order.total ? (order.total * 0.029) : 0;
        const vat = order.vat_amount ? order.vat_amount : 0;
        const totalExcTax = order.total ? (order.total - vat - comission) : 0;
        const customer_uuid = _.get(order, 'customer.uuid');
        let itemsQuantity = 0;
        if (order.order_items && order.order_items.length) {
          order.order_items.forEach((item) => {
            if (typeof item.quantity === 'number') {
              itemsQuantity += item.quantity;
            }
          });
        }
        const obj = {
          key: i,
          order_uuid: order.uuid,
          id: order.id,
          customer_id: order.customer_id,
          branch_uuid: order.branch_uuid,
          index_id: order.index_id,
          order_type: order.order_type,
          order_type_display: order.order_type_display,
          date: order.is_submitted_at,
          payment_method: order.payment_method,
          payment_method_display: order.payment_method_display,
          items: itemsQuantity,
          total_inc_tax: order.total + order.processing_fee,
          gst: vat,
          processing: comission,
          total_tax_exc_processing: totalExcTax,
          payment_comment: order.payment_comment,
          customer_uuid
        };
        data.push(obj);
      }
    });
    return data;
  }
  updateTotalStats = () => {
    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    const filteredDataRows = [];
    let totalIncTax = 0;
    let totalExcTax = 0;
    let totalGst = 0;
    let totalComission = 0;
    const fullData = this.getData();
    fullData.forEach((row) => {
      if (Object.keys(filteredInfo).length === 0) {
        filteredDataRows.push(row);
        totalIncTax += row.total_inc_tax;
        totalExcTax += row.total_tax_exc_processing;
        totalGst += row.gst;
        totalComission += row.processing;
      } else {
        let shouldCount = true;
        Object.keys(filteredInfo).forEach((key) => {
          if (filteredInfo[key]) {
            if (key === 'order_type_display' && filteredInfo[key].indexOf(row.order_type) === -1) shouldCount = false;
            if (key === 'payment_method_display' && filteredInfo[key].indexOf(row[key]) === -1) shouldCount = false;
          }
        })
        if (shouldCount) {
          filteredDataRows.push(row);
          totalIncTax += row.total_inc_tax;
          totalExcTax += row.total_tax_exc_processing;
          totalGst += row.gst;
          totalComission += row.processing;
        }
      }
    });
    this.setState({
      totalIncTax: totalIncTax.toFixed(2),
      totalExcTax: totalExcTax.toFixed(2),
      totalGst: totalGst.toFixed(2),
      totalComission: totalComission.toFixed(2),
      filteredDataRows
    })
  }

  openOrder = (record) => {
    //const { orderObj } = record;
    this.setState({
      selectedOrderUuid: record.order_uuid,
      selectedBranchUuid: record.branch_uuid
    }, () => {
      this.updateState('visibleSingleOrder', true);
    })
  }
  updateState = (key, value) => {
    this.setState({
      [key]: value
    });
  }
  render() {
    const windowWidth = this.props.windowSize.windowWidth;
    const isMobile = windowWidth < 900 ? true : false;
    const {
      pagination,
      totalNumberTable,
      visibleSingleOrder,
      selectedOrderUuid,
      selectedBranchUuid,
      totalIncTax,
      totalExcTax,
      totalGst,
      totalComission,
      visibleSmsAuthenticationPrompt,
      smsAuthenticationAction,
      doAfterSmsAuthentication,
      filteredDataRows
    } = this.state;
    return (
      <Row style={{width: '100%'}}>
        <Col span={24}>
          <Row>
            <h2 className="header-margin-same-as-table">Transactions</h2>
          </Row>
          <Row>
            <p className="tableHeader__quantity-text">Quantity: {totalNumberTable}</p>
          </Row>
          <Row>
            <p className="tableHeader__quantity-text">Total Inc Tax: ${totalIncTax || 0}</p>
          </Row>
          <Row>
            <p className="tableHeader__quantity-text">GST 10%: ${totalGst || 0}</p>
          </Row>
          <Row>
            <p className="tableHeader__quantity-text">Processing: ${totalComission || 0}</p>
          </Row>
          <Row>
            <p className="tableHeader__quantity-text">Total Exc Tax & Processing: ${totalExcTax || 0}</p>
          </Row>
          <Row>
            <Button className="header-margin-same-as-table" style={{marginTop: '12px'}} disabled={!filteredDataRows || (filteredDataRows && !filteredDataRows.length)} onClick={this.downloadFile} type="primary">Download</Button>
          </Row>
          <Row>
            <Table
              className="generalTable innerTable"
              size="middle"
              columns={ this.columns() }
              dataSource={ this.getData() }
              onChange={ this.handleTableChange }
              style={{minWidth: '100%', marginTop: '24px'}}
              pagination={{
                ...pagination,
                showSizeChanger: totalNumberTable > 10 ? true : false}
              }
              scroll={ isMobile ? { x: 'fit-content' } : false}
            />
          </Row>
        </Col>
        { visibleSingleOrder ? <SingleOrder
          branchUuid={selectedBranchUuid}
          visible={visibleSingleOrder}
          orderUuid={selectedOrderUuid}
          handleClose={ () => this.updateState('visibleSingleOrder', false) }
          shouldHideActions={true} />
          : null }
        { visibleSmsAuthenticationPrompt ? <SmsAuthenticationPrompt
          visible={visibleSmsAuthenticationPrompt}
          action={ smsAuthenticationAction }
          doAfter={ doAfterSmsAuthentication }
          handleClose={ () => { this.updateState('visibleSmsAuthenticationPrompt', false) } } />
          : null }
      </Row>
    )
  }
}

export default withTranslation()(withWindowSizeListener(TransactionsReport));
