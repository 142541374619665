import React from 'react';
import Settings from 'services/config/Settings';
import { withTranslation, Trans } from 'react-i18next';
import * as _sharedModules from 'screens/Dashboard/modules/_modules';
import { Row, Col, Button, Table, Tag } from 'antd';
import AddPrinter from './components/AddPrinter';
import { GlobalOutlined } from '@ant-design/icons';
import EditPrinter from './components/EditPrinter';
import Socket from 'services/utils/Socket';

class Printing extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
      isFetching: false,
      workshopsList: [],
      targetBranchUuid: null,
      targetRestaurantId: this.props.restaurantId,
      addWorkshopProgress: false,
      totalNumberTable: 0,
      visibleEdit: false,
      editWorkshopUuid: null
    };
    Object.keys(_sharedModules).forEach((_moduleName) => {
      this[_moduleName] = _sharedModules[_moduleName].bind(this);
    });
    this.userRole = this.props.role;
    this.totalNumberTable = 0;
    this.totalNumberInterval = null;
	}
	componentDidMount() {
		this.mounted = true;
		const { t } = this.props;
		document.title = `${t('shareable.printing', { defaultValue: 'Printing' })} | ${Settings.title}`;
		this.getListWorkshops();
		this.setState({
      pagination: {
        total: 0,
        pageSizeOptions: [10, 30, 50, 100],
        showSizeChanger: false,
        showTotal: (total) => {
          return <p id="totalNumber" style={{display: 'none'}}>{this.renderTotal(total)}</p>
        }
      }
    })
    this.subscribeToSocket();
	}
  subscribeToSocket = () => {
    const { branchUuid } = this.props;
    Socket.on(`printers_${branchUuid}`, this.getListWorkshops);
  }
  unsubscribeFromSocket = () => {
    const { branchUuid } = this.props;
    Socket.off(`printers_${branchUuid}`, this.getListWorkshops);
  }
	componentWillUnmout() {
    this.mounted = false;
    this.unsubscribeFromSocket()
    clearInterval(this.totalNumberInterval);
  }
  trackTotalNumberValue = () => {
    clearInterval(this.totalNumberInterval);
    const { totalNumberTable } = this.state;
    if (totalNumberTable !== this.totalNumberTable) {
      this.setState({
        totalNumberTable: this.totalNumberTable
      });
    }
    this.totalNumberInterval = null;
  }
  renderTotal = (total) => {
   this.totalNumberTable = total;
   const { totalNumberTable } = this.state;
   if (totalNumberTable !== this.totalNumberTable && !this.totalNumberInterval) {
    this.totalNumberInterval = setInterval(this.trackTotalNumberValue, 100); 
   }
   return total;
  }
  onBranchSelectChange = (targetBranchUuid) => {
    this.setState({
      targetBranchUuid
    });
  }
  startAddWorkshop = () => {
  	this.setState({
  		addWorkshopProgress: true
  	})
  }
  columns = (platform = false) => {
    // let { filteredInfo, branchFilters } = this.state;
    // filteredInfo = filteredInfo || {};
    const columns = [];
    const infoColumns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (text, record) => (
        <span style={{color: record.status === 'offline' ? 'red' : 'green'}}>
          <GlobalOutlined />
        </span>
      )
    },
    {
      title: 'Name',
      dataIndex: 'display_name',
      key: 'display_name',
    },
    {
      title: 'Categories',
      dataIndex: 'categories',
      key: 'categories',
      render: (text, record) => {
      	if (record.is_receipt_station) {
        	return 'Receipt';
        }
        if (record.categories && Array.isArray(record.categories) && record.categories.length) {
          return record.categories.map((category, i) => {
            return <Tag key={i} color="success">{category}</Tag>;
          });
        }
        return null;
      }
    }, {
      title: 'Model',
      dataIndex: 'printer_name',
      key: 'printer_name',
    },];
    const restColumns = [ {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <React.Fragment>
          {!record.is_deleted ? <React.Fragment>
            <Button onClick={() => this.openWindowEditPrinter(record)} size="small">
              Edit
            </Button>

            <Button onClick={() => this.resetHostWorkshop(record.uuid)} size="small" style={{ marginLeft: '8px' }}>
              { record.status === 'online' ? 'Reset host' : 'Reset all connections' }
            </Button>
            <Button size="small" onClick={() => this.deleteWorkshop(record.uuid)} style={{ marginLeft: '8px' }}>
              Delete
            </Button>
          </React.Fragment> : ''}
          </React.Fragment>
        )
      }
    }];
    columns.push(...infoColumns, ...restColumns);
    return columns;
  }
  openWindowEditPrinter = (record) => {
    this.setState({
      editWorkshopUuid: record.uuid,
      visibleEdit: true
    })
  }
  getData = () => {
    const data = [];
    const { workshopsList } = this.state;

    if (workshopsList && Array.isArray(workshopsList) && workshopsList.length) {    	

    	workshopsList.forEach((workshop, i) => {
    		let categories = [];
    		if (workshop.categories && Array.isArray(workshop.categories) && workshop.categories.length) {
    			// console.log('workshop.categories', workshop.categories);
    			// console.log('categories', categories);

    			workshop.categories.forEach((category) => {
    				if (category.category && category.category.display_name) {
    					categories.push(category.category.display_name);
    				}
    			});
    		}

        let printer_name = '';
        let status = 'offline';
        if (workshop.printer) {
          const { printer } = workshop.printer;
          if (printer) {
            const { model, driver } = printer;
            if (model) {
              printer_name = `${model.type} ${model.model} (${printer.serial_number})`;
            }
            if (driver && Array.isArray(driver) && driver.length) {
              const { is_online } = driver[0].host;              
              if (is_online) {
                status = 'online';
              }
            }
          }
        }

    		const obj = {
	        key: i,
	        uuid: workshop.uuid,
	        id: workshop.id,
	        display_name: workshop.display_name,
	        is_receipt_station: workshop.is_receipt_station,
	        status,
	        categories,
          printer_name
	      };
	      data.push(obj);
    	});
    }
    return data;
  }
  handleTableChange = (pagination, filters, sorter) => {
    console.log('handleTableChange', pagination, filters, sorter);
    setTimeout(() => {
      if (!document.getElementById("totalNumber")) {
        this.setState({
          totalNumberTable: 0
        })
      }
    }, 100)
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    })
  }
  updateState = (key, value) => {
    this.setState({
      [key]: value
    });
  }
	render() {
		const {
			totalNumberTable,
			isFetching,
			addWorkshopProgress,
			pagination,
			restaurantId,
      editWorkshopUuid,
      visibleEdit
		} = this.state;
		//restaurantId
		const { branchUuid } = this.props;

		return (
			<React.Fragment>
      	{ !addWorkshopProgress && <React.Fragment>
				{ branchUuid ? 
					<React.Fragment>
						<Row gutter={16}>
							<Col>
								<Row className="tableHeader__header-text" style={{padding: '10px 0'}}>
				          <Trans i18nKey="shareable.workshops">Printing</Trans>
				        </Row>
				        <Row>
			            <Col sm={3} xs={12}>
			              <Button onClick={this.startAddWorkshop}>Add printer</Button>
			            </Col>
			          </Row>
			        </Col>
		        </Row>
		        <Row>
		        	<Col span={20}>
			          <Table
			            size={"middle"}
			            className="generalTable innerTable"
			            columns={ this.columns() }
			            loading={ isFetching }
			            dataSource={ this.getData() }
			            pagination={ pagination }
			            onChange={ this.handleTableChange }
			            footer={() => {}}
			            scroll={this.state.scrollTable ? {x: 'fit-content'} : false}
			            title={() => 
			              <div>
			                <Row className="tableHeader__container">
			                  <div className="tableHeader__start-container">
			                    <p className="tableHeader__quantity-text" style={{marginLeft: '0px'}}>
			                      Quantity: {totalNumberTable}
			                    </p>     
			                  </div>
			                </Row>       
			              </div>
			            }
			          />
			        </Col>
		        </Row>
	        </React.Fragment>
	       : null }
	       </React.Fragment> }
       	{ addWorkshopProgress && <AddPrinter {...this.props} branchUuid={branchUuid} restaurantId={restaurantId} /> }
        { visibleEdit ? <EditPrinter
          {...this.props}
          branchUuid={branchUuid}
          restaurantId={restaurantId}
          workshopUuid={editWorkshopUuid}
          visible={visibleEdit}
          renderList = { this.getListWorkshops }
          handleClose={ () => { this.updateState('visibleEdit', false) } } />
          : null
        }
			</React.Fragment>
		);
	}

}


export default withTranslation()(Printing);