import React from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Button, Modal, Spin, Collapse, Col, Select } from 'antd';
import _ from 'lodash';
import { withWindowSizeListener } from 'react-window-size-listener';
import AddMenu from './components/AddMenu/AddMenu';
import * as _sharedModules from '../../../../../modules/_modules';
import ProductsDraggableTable from './components/ProductsDraggableTable/ProductsDraggableTable';
import CategoriesDraggableTable from './components/CategoriesDraggableTable/CategoriesDraggableTable';

import './styles.less';

const { Panel } = Collapse;
const { Option } = Select;

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      productsLoaded: false,
      showAddMenu: false,
      products: [],
      categoriesList: [],
      shouldShowCategoriesTable: false,
      branchUuid: null
    };
    Object.keys(_sharedModules).forEach((_moduleName) => {
      this[_moduleName] = _sharedModules[_moduleName].bind(this);
    });
  }
  componentDidMount() {
    this.mounted = true;
    this.getProducts();
    this.getCategories();

    const { branchUuid } = this.props;
    if (branchUuid) {
      this.setState({
        branchUuid
      });
    }

    if (this.props.menuId) {
      this.getMenu(this.props.menuId);
    }
    if (this.props.showAddMenu) {
      this.setState({
        showAddMenu: true
      })
    }
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  componentDidUpdate(prevProps) {
    if (!prevProps.showAddMenu && this.props.showAddMenu) {
      this.setState({
        showAddMenu: true
      })
    }
    if (prevProps.menuId !== this.props.menuId) {
      this.getMenu(this.props.menuId);
    }
  }
  renderCollapsePanels = () => {
    const { menuObj, products } = this.state;
    const render = [];
    const categoriesOrder = _.get(menuObj, 'categories_order');
    if (categoriesOrder && Array.isArray(categoriesOrder) && categoriesOrder.length) {
      categoriesOrder.forEach((category, i) => {
        const categoryName = _.get(category, 'category.display_name');
        const categoryId = category.category_id;
        if (categoryName && categoryId) {
          render.push(
            <Panel key={i} header={categoryName}>
              <ProductsDraggableTable
                role={this.props.role}
                branchUuid={this.props.branchUuid}
                menuId={menuObj.id}
                productsInMenu={menuObj.products}
                categoryId={categoryId}
                productsCategory={products[categoryId]}
                updateMenu={() => this.getMenu(menuObj.id)}
                />
            </Panel>);
        }
      })
    }
    return render
  }
  openAddMenu = () => {
    const { productsLoaded, products } = this.state;
    if (productsLoaded && typeof products === 'object' && !Object.keys(products).length) {
      Modal.confirm({
        title: 'You have no products',
        content: 'To create menu you should add products first.',
        okButtonText: 'Add',
        onOk: () => {
          window.location.href = `/products?addProduct=1`;
        }
      });
    } else {
      this.props.history.push(`${this.props.history.location.pathname}${this.props.history.location.search}&addMenu=1&restId=${this.props.restaurantId}`)
      this.setState({
        showAddMenu: true
      })
    }
  }
  showDraggableCategoriesTable = () => {
    const { shouldShowCategoriesTable } = this.state;
    this.setState({
      shouldShowCategoriesTable: !shouldShowCategoriesTable
    })
  }
  renderAddCategoriesOptions = () => {
    const { categories, categoriesList } = this.state;
    const render = [];
    if (categories && categories.length) {
      categories.forEach((category, i) => {
        const categoryName = _.get(category, 'category.display_name');
        const categoryId = category.category_id;
        if (categoryId && categoryName && categoriesList.indexOf(categoryId) === -1) {
          render.push(<Option key={i} value={categoryId}>{categoryName}</Option>)
        }
      })
    }
    return render
  }

  addCategories = () => {
    const { categoriesList, categoriesToAdd } = this.state;
    const list = [];
    if (!categoriesToAdd.length) return;
    categoriesToAdd.forEach((categoryId) => {
      if (categoriesList.indexOf(categoryId) === -1) {
        list.push(...categoriesList, categoryId);
      }
    })
    this.setState({ categoriesList: list, categoriesToAdd: [],  showAddCategoryToList: false });
  }
  render() {
    const { menuId, restaurantId, history, role } = this.props;
    const {
      showAddMenu,
      products,
      isFetching,
      menu,
      menuLoaded,
      categoriesList,
      showAddCategory,
      categories,
      menuObj,
      categoriesLoaded,
      shouldShowCategoriesTable,
      showAddCategoryToList
    } = this.state;
    const {
      itsModalWindow
    } = this.props;

  	return (
      <div>
        <Spin spinning={isFetching}>
          { !showAddMenu ?
        		<div>
              { !menuId && !itsModalWindow ?
                <Row>
                  <Button onClick={this.openAddMenu}>Add menu</Button>
                </Row> : null }
              { menuId ?
                <div>
                  <Row style={{display: this.props.role !== 'manager' ? 'none' : ''}}>
                    <h2>{menuObj ? menuObj.display_name : null}</h2>
                  </Row>
                  { categoriesList.length ?
                  <div>
                    <Row style={{display: this.props.role === 'manager' ? 'none' : ''}}>
                      <Button onClick={this.showDraggableCategoriesTable}>
                        { shouldShowCategoriesTable ? 'Close' : 'Edit categories' }
                      </Button>
                      <Button style={{display: shouldShowCategoriesTable ? '' : 'none', marginLeft: '8px'}} onClick={() => this.setState({ showAddCategoryToList: true })}>Add category</Button>
                    </Row>
                    { shouldShowCategoriesTable && this.props.role !== 'manager' ?
                      <Row style={{marginTop: '20px'}}>
                        <Col xs={24} sm={20}>
                          { categoriesLoaded && menuLoaded ?
                          <Row>
                            <Col xs={24} sm={24}>
                              <CategoriesDraggableTable
                                history={this.props.history}
                                addCategoryVisible={showAddCategory}
                                categories={categories}
                                menuId={menuObj.id}
                                categoriesOrder={menuObj.categories_order}
                                categoriesInMenu={categoriesList}
                                updateMenu={() => this.getMenu(menuObj.id)}
                                closeAddCategory={() => this.setState({ showAddCategory: false })}
                                updateCategoriesList={this.getCategories}
                                canRemoveCategories={true}
                                restaurantId={this.props.restaurantId}
                                />
                            </Col>
                          </Row> : null }
                      </Col>
                    </Row> : null }
                  <Row style={{marginTop: '20px'}}>
                    <Col sm={24} xs={24}>
                      <Collapse className="collapse-gray" destroyInactivePanel={true} onChange={this.testCollapse}>
                        {this.renderCollapsePanels()}
                      </Collapse>
                    </Col>
                  </Row>
                  <Modal
                    title="Add category"
                    className="general-modal-btns-center"
                    visible={showAddCategoryToList}
                    okText="Add"
                    onCancel={() => this.setState({ showAddCategoryToList: false }) }
                    onOk={this.addCategories}
                    destroyOnClose={true}
                  >
                    <Row style={{marginBottom: '4px', fontSize: '15px'}}>
                      Choose categories you want to add
                    </Row>
                    <Row style={{marginTop: '8px'}}>
                      <Select
                        style={{width: '100%'}}
                        onChange={(values) => this.setState({ categoriesToAdd: values})}
                        placeholder="Select categories"
                        allowClear={true}
                        showArrow={true}
                        mode="multiple"
                        >
                        {this.renderAddCategoriesOptions()}
                      </Select>
                    </Row>
                  </Modal>
                  </div>
                  :
                  <Row>
                    <Button
                      style={{display: isFetching ? 'none' : ''}}
                      onClick={() => {
                        window.location.href = `${this.props.history.location.pathname}${this.props.history.location.search}&add=1&step=2`;
                      }}>Continue adding menu</Button>
                  </Row> }
                </div>
              : null }
      		  </div>
            :
            <Col sm={18} xs={24}>
            <AddMenu
              products={products}
              restaurantId={restaurantId}
              role={role}
              history={history}
              addStep={this.props.addStep}
              menuId={this.props.menuId}
              getMenu={() => this.getMenu(menuId)}
              menuObj={menu}
            /></Col> }
        </Spin>
      </div>
  	)
  }
}
export default  withTranslation()(withWindowSizeListener(Menu));
