import React from 'react';
import store from 'store';
import Settings from 'services/config/Settings';
import {
	Layout,
	Row,
	Select,
	Col,
	Alert,
} from 'antd';
import { withTranslation } from 'react-i18next';
import { withWindowSizeListener } from 'react-window-size-listener';
import * as _sharedModules from '../../../modules/_modules';
import BranchMenus from './components/BranchMenus';

const { Content } = Layout;

class Menus extends React.Component {
	constructor(props) {
    super(props);
    
    this.state = {
    	targetBranchUuid: null,
    	targetRestaurantId: null,
    	branchesList: [],
    	disabledVenueSelect: false
    };
    Object.keys(_sharedModules).forEach((_moduleName) => {
      this[_moduleName] = _sharedModules[_moduleName].bind(this);
    });
    this.userRole = this.props.role;
  }
	componentDidMount() {  
    const { t, restaurantId, branchesList } = this.props;
    this.mounted = true;
    document.title = `${t('shareable.menuView', { defaultValue: 'Menu view' })} | ${Settings.title}`;

    const copy = [...branchesList];

    this.setState({
    	targetRestaurantId: restaurantId,
    	branchesList: copy
    }, this.restoreScreen);
  }
  restoreScreen = () => {
    const targetBranchUuid = store.get('menus_targetBranchUuid');
    const targetBranchId = store.get('menus_targetBranchId');

    const state = {};
    if (targetBranchUuid) state.targetBranchUuid = targetBranchUuid;
    if (targetBranchId) state.targetBranchId = targetBranchId;

    this.setState(state);
  }
  componentWillUnmount() {
    this.mounted = false;
    store.remove('menus_targetBranchUuid')
    store.remove('menus_targetBranchId')
  }
  onBranchSelectChange = (targetBranchUuid) => {
  	const { branchesList } = this.state;
  	let targetBranchId = null;
  	const filtred = branchesList.filter((branch) => branch.uuid === targetBranchUuid);
  	if (filtred) {
  		targetBranchId = filtred[0].id
  	}

    store.set('menus_targetBranchUuid', targetBranchUuid);
    store.set('menus_targetBranchId', targetBranchId);
    this.setState({
      targetBranchUuid,
      targetBranchId
    });
  }
  stateLocationSelects = (freeze = true) => {
  	this.setState({
  		disabledVenueSelect: freeze
  	}, () => {
  		this.props.setRestaurantChoiceDisabled(freeze);
  	});
  }
  render() {
  	const {
  		targetBranchUuid,
  		targetRestaurantId,
  		disabledVenueSelect,
  		targetBranchId
  	} = this.state;

  	const {
  		branchesList
  	} = this.props;

  	const contentClass = !this.props.outer ? "bg-transparent table-content innerContent" : "bg-transparent table-content";

  	return (
			<Content className={contentClass} style={{marginTop: '12px'}}>
				<Row gutter={16}>
					{ branchesList && branchesList.length ?
						<Col sm={4} xs={12}>
	            <Row style={{marginBottom: '4px', fontSize: '15px'}}>
	              <label>Venue:</label>
	            </Row>
	            <Row>
	              <Select 
	                onChange={(value) => this.onBranchSelectChange(value)} 
	                value={targetBranchUuid} 
	                disabled={disabledVenueSelect || !targetRestaurantId || (targetRestaurantId && branchesList && !branchesList.length)} 
	                loading={targetRestaurantId && !branchesList} style={{width: '100%'}} placeholder="Venue"
	              >
	                {this.renderBranchesOptionsUuid()}
	              </Select>
	            </Row>
	          </Col> : 
	          <Col>
	          	<Alert message="To operate menues please add branches first." type="warning" />
	          </Col>
           }
				</Row>

				{ targetBranchUuid && targetRestaurantId ?
					<div style={{marginTop: '24px'}}>
          	<BranchMenus
          		role={this.props.role}
          		history={this.props.history}
	          	branchUuid={targetBranchUuid}
	          	branchId={targetBranchId}
	          	restaurantId={targetRestaurantId}
	          	stateLocationSelects={this.stateLocationSelects}
          	/>
        </div>: null }
			</Content>
  	)
  }
}

export default withTranslation()(withWindowSizeListener(Menus));