import React from 'react';
import { Trans } from 'react-i18next';
import _ from 'lodash';

const checkAddress = function checkAddress(rule, value) {
	console.log('value', value);
  const { addressGroup } = this.state;
  console.log('addressGroup', addressGroup);

  if (!value.correct) {
    return Promise.reject(<p><Trans i18nKey="shareable.selectCorrectAddress">Select correct address</Trans></p>);
  }
  if (value.address) {
    return Promise.resolve();
  }
  return Promise.reject(<p><Trans i18nKey="shareable.enterAddressPlease">Please enter address</Trans></p>);
  //return Promise.resolve();
}
const triggerAddressChanges = function triggerAddressChanges(value) {
  // console.log('triggerAddressChanges', value);
  const { currencyCode } = this.state;

  const opening_hours = _.get(value, 'opening_hours');

  this.adjustGoogleHours(opening_hours);

  const currency_code = _.get(value, 'currency_code');
  if (currency_code) {
   if ((currencyCode !== currency_code)) {
      this.getPricingSettings && this.getPricingSettings(currency_code);
    }
  }
  this.formRef.current.setFieldsValue({
    addressInput: value.address
  });
 

  this.setState({
    addressGroup: value,
    currencyCode: currency_code,
    addressInput: value.address,
    formatted_address: value.formatted_address
  });

}

export {
  checkAddress,
  triggerAddressChanges
};