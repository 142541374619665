import fetchJSON from 'services/utils/fetchJSON';
import { message } from 'antd';

const  getCategories = function getCategories() {
  const bodyObj = {};
  if (this.props.restaurantId) { 
    bodyObj.restaurantId = this.props.restaurantId;
  }
  this.setState({
    isFetching: true,
    categoriesLoaded: false
  });
  fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/products/categories/list', {
    method: 'post',
    body: bodyObj
  }).then(response => {
    if (this.mounted) {
      if (response.success) {
        //console.log('response.data', response.data);
        this.setState({ 
          isFetching: false,
          categories: response.data,
          categoriesLoaded: true
        });
      } else {
        this.setState({ 
          isFetching: false,
          categories: [] 
        });
      } 
    }    
  }).catch(error => {
    console.log(error);
    if (this.mounted) { 
      this.setState({
        isFetching: false,
        categories: [] 
      });
    }
  });
}

const saveCategoriesOrder = function saveCategoriesOrder(menuId, menuCategoriesOrderObj, shouldUpdateParent = false) {
  const { t } = this.props;
  fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/menus/addCategories`, {
    method: 'post',
    body: { menuCategoriesOrderObj }
  }).then(response => {
    console.log(response);
    if (response.success) {
      this.setState({ 
        menuCategoriesOrderObj
      }, () => {
        if (shouldUpdateParent) this.props.updateMenu();
      })
    } else {
      message.error(t('shareable.error', { defaultValue: 'Error' }));
    }
   }).catch(error => {
      console.log(error);
      message.error(t('shareable.error', { defaultValue: 'Error' }));
   });
}

const getMenu = function getMenu(menuId, shouldProductsUpdate = false) {
  if (!menuId) return {};
  this.setState({
    isFetching: true
  });

  const { branchUuid } = this.props;
  console.log('branchUuid', branchUuid)

  fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/menus/${menuId}`, {
    method: 'post',
    body: {
      branchUuid
    }
  }).then(response => {
    if (this.mounted) {
      const menu = response.data;
      const categoriesList = [];
      if (menu && menu.categories_order && Array.isArray(menu.categories_order)) {
        menu.categories_order.forEach((category) => {
          if (categoriesList.indexOf(category.category_id) === -1) {
            categoriesList.push(category.category_id);
          }
        })
      }
      if (!shouldProductsUpdate) {
        this.setState({ 
          isFetching: false, 
          menuObj: menu || {},
          menuLoaded: true,
          categoriesList
        })
      } else {
        this.setState({
          addingProductsProccess: false,
          showAddProducts: false,
          isFetching: false,
          productsInMenu: menu ? menu.products : [],
          menuLoaded: true,
          categoriesList
        }, () => {
          this.getInitialData();
          this.props.updateMenu();
        })
      }
    }
  }).catch(error => {
    //console.log(error);
    if (this.mounted) { 
      if (!shouldProductsUpdate) {
        this.setState({
          isFetching: false,
          menuLoaded: false
        });        
      } else {
        this.setState({
          addingProductsProccess: false,
          isFetching: false,
          menuLoaded: false
        })
      }
    }
  })
}

const updateProductsOrder = function updateProductsOrder(menuId, categoryId, dataToUpload) {
  if (!menuId || !categoryId) return {};
  const { t } = this.props;
  this.setState({
    putREQUEST: true
  });
  fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/menus/updateProductsOrder`, {
    method: 'post',
    body: {
      categoryId,
      menuId,
      dataToUpload
    }
  }).then(response => {
    console.log(response);
    if (response.success) {
      this.setState({ 
        putREQUEST: false,
        putSUCCESS: true,
        putFAILURE: false
      }, this.getMenu(menuId, true));
    } else {
      this.setState({
        putREQUEST: false,
        putFAILURE: true,
        putSUCCESS: false
      });
      message.error(t('shareable.error', { defaultValue: 'Error' }));
    }
   }).catch(error => {
      console.log(error);
      this.setState({
        putSUCCESS: false,
        putREQUEST: false,
        putFAILURE: true,
      });
      message.error(t('shareable.error', { defaultValue: 'Error' }));
   });
}

const removeProductFromMenu = function removeProductFromMenu(menuId, categoryId, productId) {
  if (!menuId || !categoryId || !productId) return;
  const { t } = this.props;
  this.setState({
    putREQUEST: true
  });
  fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/menus/removeProductMenu`, {
    method: 'post',
    body: {
      categoryId,
      menuId,
      productId
    }
  }).then(response => {
    console.log(response);
    if (response.success) {
      this.setState({ 
        putREQUEST: false,
        putSUCCESS: true,
        putFAILURE: false
      }, this.getMenu(menuId, true));
    } else {
      this.setState({
        putREQUEST: false,
        putFAILURE: true,
        putSUCCESS: false
      });
      message.error(t('shareable.error', { defaultValue: 'Error' }));
    }
   }).catch(error => {
      console.log(error);
      this.setState({
        putSUCCESS: false,
        putREQUEST: false,
        putFAILURE: true,
      });
      message.error(t('shareable.error', { defaultValue: 'Error' }));
   });
}
const removeCategoryFromMenu = function removeCategoryFromMenu(menuId, categoryId) {
  if (!menuId || !categoryId) return;
  const { t } = this.props;
  this.setState({
    putREQUEST: true
  });
  fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/menus/removeCategoryMenu`, {
    method: 'post',
    body: {
      categoryId,
      menuId
    }
  }).then(response => {
    console.log(response);
    if (response.success) {
      this.setState({ 
        putREQUEST: false,
        putSUCCESS: true,
        putFAILURE: false
      }, () => {
        //this.props.updateCategoriesList();
        this.props.updateMenu();
      });
    } else {
      this.setState({
        putREQUEST: false,
        putFAILURE: true,
        putSUCCESS: false
      });
      message.error(t('shareable.error', { defaultValue: 'Error' }));
    }
   }).catch(error => {
      console.log(error);
      this.setState({
        putSUCCESS: false,
        putREQUEST: false,
        putFAILURE: true,
      });
      message.error(t('shareable.error', { defaultValue: 'Error' }));
   });
}

const addProductsBackend = function addProductsBackend(productsToAdd, categoryId, menuId) {
  const { t } = this.props;
  this.setState({
    putREQUEST: true
  });
  fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/menus/addProduct`, {
    method: 'post',
    body: {
      productsToAdd,
      categoryId,
      menuId
    }
  }).then(response => {
    console.log(response);
    if (response.success) {
      this.setState({ 
        putREQUEST: false,
        putSUCCESS: true,
        putFAILURE: false
      }, this.getMenu(menuId, true));
    } else {
      this.setState({
        putREQUEST: false,
        putFAILURE: true,
        putSUCCESS: false,
        addingProductsProccess: false
      });
      message.error(t('shareable.error', { defaultValue: 'Error' }));
    }
   }).catch(error => {
      console.log(error);
      this.setState({
        putSUCCESS: false,
        putREQUEST: false,
        putFAILURE: true,
        addingProductsProccess: false
      });
      message.error(t('shareable.error', { defaultValue: 'Error' }));
   });
}
const getProducts = function getProducts() {
  const bodyObj = {};
  const sortedProducts = {};
  if (this.props.restaurantId) { 
    bodyObj.restaurantId = this.props.restaurantId;
  }

  this.setState({
    isFetching: true
  });
  fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/products/list', {
    method: 'put',
    body: bodyObj
  }).then(response => {
    console.log('getProducts', response);
    if (this.mounted) {
      const products = response.data;
      if (this.renderTotal && products && Array.isArray(products)) {
        // console.log('test total');
        this.renderTotal(products.length);
        this.setState({
          totalNumberTable: products.length
        })
      }
      if (products && products.length) {
        products.forEach((product) => {
          const categories = product.category;
          if (categories && categories.length) {
            categories.forEach((category) => {
              const categoryId = category.category_id;
              if (categoryId) {
                if (!sortedProducts[categoryId]) {
                  sortedProducts[categoryId] = [];
                }
                sortedProducts[categoryId].push(product);
              }
            })
          }
        })
        this.setState({ 
          isFetching: false,
          productsLoaded: true,
          products: sortedProducts
        });
      } else {
        this.setState({ 
          isFetching: false,
          productsLoaded: true,
          products: [] 
        });
      } 
    }    
  }).catch(error => {
    //console.log(error);
    if (this.mounted) { 
      this.setState({
        isFetching: false,
        products: [] 
      });
    }
  });
}
const createMenu = function createMenu(values, inMenuAdding = false) {
  const { t } = this.props;
  this.setState({
    putREQUEST: true
  });
  fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/menus/create`, {
    method: 'post',
    body: values
  }).then(response => {
    console.log(response);
    if (response.success) {
      this.setState({ 
        currentStep: 1,
        putREQUEST: false,
        putSUCCESS: true,
        putFAILURE: false,
        menuId: response.createdMenuId
      }, () => this.getMenu(response.createdMenuId))
      // if (inMenuAdding) {
      //   this.props.history.push(`${this.props.history.location.pathname}?menu=1&add=1&step=2`);
      // }
    } else {
      this.setState({
        putREQUEST: false,
        putFAILURE: true,
        putSUCCESS: false,
      });
      message.error(t('shareable.error', { defaultValue: 'Error' }));
    }
   }).catch(error => {
      console.log(error);
      this.setState({
        putSUCCESS: false,
        putREQUEST: false,
        putFAILURE: true,
      });
      message.error(t('shareable.error', { defaultValue: 'Error' }));
   });
}
const getMenusList = function getMenusList() {
  const {
    t,
    restaurantId,
    branchUuid
  } = this.props;

  const { targetMenuId } = this.state;

  this.setState({
    isFetching: true
  });
  fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/menus/getMenusList`, {
    method: 'post',
    body: {
      restaurantId,
      branchUuid
    }
  }).then(response => {
    console.log('getMenusList', response);
    if (response.success) {
      this.setState({ 
        isFetching: false,
        menusList: response.data.menus,
        branchObj: response.data.branch
      })
      if (this.onMenuSelectChange && targetMenuId) {
        this.onMenuSelectChange(targetMenuId);
      }
    } else {
      this.setState({
        isFetching: false,
        menusList: []
      });
      message.error(t('shareable.error', { defaultValue: 'Error' }));
    }
   }).catch(error => {
      console.log(error);
      this.setState({
        isFetching: false,
        menusList: []
      });
      message.error(t('shareable.error', { defaultValue: 'Error' }));
   });
}

export {
  getMenu,
  getProducts,
  getCategories,
  saveCategoriesOrder,
  createMenu,
  addProductsBackend,
  removeProductFromMenu,
  updateProductsOrder,
  removeCategoryFromMenu,
  getMenusList
};