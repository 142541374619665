import React from 'react';
import Settings from 'services/config/Settings';
import fetchJSON from 'services/utils/fetchJSON';

import { withWindowSizeListener } from 'react-window-size-listener';
import { withTranslation } from 'react-i18next';
import { Layout, Row, Input, Button, Form, Card, Col, message } from 'antd';
import PhoneNumberChange from './PhoneNumberChange/PhoneNumberChange';

import { 
  PlusOutlined,
  EditOutlined
} from '@ant-design/icons';

import './styles.less';

const FormItem = Form.Item;

class UserSettings extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      isFetching: false,
      userObj: {},
      PhoneNumberChangeVisible: false
    };
  }
  componentDidMount = () => {
    const { t } = this.props;
    this.mounted = true;
    document.title = `${t('userSettings.documentTitle', { defaultValue: 'Settings' })} | ${Settings.title}`;
    document.body.addEventListener('touchmove', this.preventDefault, { passive: false });
    this.getProfile();

  }
  preventDefault = (e) => {
    e.preventDefault();
  }
  componentWillUnmount = () => {
    this.mounted = false;
    document.body.removeEventListener('touchmove', this.preventDefault);
  }
  getProfile = () => {
    this.setState({
      isFetching: true
    });
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/worker/profile', {
      method: 'get'
    }).then(response => {
      if (this.mounted) {
        if (response.data) {
          const userObj = response.data;
          const initialValues = {
            name: userObj.name,
            phone: userObj.phone,
            email: userObj.email
          };
          this.setState({
            isFetching: false,
            userObj,
            initialValues
          })
        } else {
          this.setState({ 
            isFetching: false
          });
        }
      }    
    }).catch(error => {
      console.log(error);
      if (this.mounted) { 
        this.setState({
          isFetching: false
        });
      }
    });
  }
  handleSubmit = values => {
    const { t } = this.props;
    console.log(values, 'values')
    this.setState({
      putREQUEST: true
    });
    fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/worker/update`, {
      method: 'post',
      body: values
    }).then(response => {
      console.log(response);
      if (response.success) {
        this.setState({
          putREQUEST: false,
          putSUCCESS: true,
          putFAILURE: false,
          menuAdded: false,
          shouldAskSaving: false,
          isFetching: false,
          shouldShowTakeoutSnooze: false
          //errorMessage: 'Saved successfully'
        }, () => this.getProfile());
          message.success(t('singleLocation.feedback.saveSuccess', { defaultValue: 'Saved successfully' }));
      } else {
        this.setState({
          putREQUEST: false,
          putFAILURE: true,
          putSUCCESS: false,
          menuAdded: false,
          isFetching: false
          //errorMessage: response.message
        });
        message.error(response.message);
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          putSUCCESS: false,
          putREQUEST: false,
          putFAILURE: true,
          menuAdded: false,
          isFetching: false
        });
        message.error(t('shareable.ERR', { defaultValue: 'ERR' }));
     });
  }
  render() {
    const { t } = this.props;
    const { 
      isFetching,
      initialValues,
      PhoneNumberChangeVisible
    } = this.state;

    return (
      <Layout className="bg-transparent">
        <Card
          className="general-card" 
          loading={isFetching}
          title={
            <Row>
              <span className="general-card__title-text">Settings</span>
            </Row>
          }
          >
            <Form 
              requiredMark={false} 
              className="general-form" 
              layout="vertical" 
              ref={this.formRef} 
              initialValues={initialValues} 
              onFinish={this.handleSubmit}
            >
              <Row>
                <h3>Personal info</h3>
              </Row>
              <Row className="error-fix">
                <Col sm={8} xs={24}>
                  <FormItem 
                    rules={[{ required: true, message: t('shareable.required.name', { defaultValue: 'Name is required!' }) }]}
                    name="name" 
                    label={t('shareable.name', { defaultValue: 'Name' })}
                    >
                      <Input placeholder="Name"/>
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Button type="primary" htmlType="submit">Update</Button>
              </Row>
              <Row style={{marginTop: '16px'}}>
                <h3>Contact info</h3>
              </Row>
              <Row className="disabled-form-input">
                <Col sm={8} xs={24}>
                  <FormItem 
                    rules={[{ required: false, message: '' }]}
                    label={t('shareable.email', { defaultValue: 'Email' })}
                  >
                    <Input value={initialValues ? initialValues.email : ''}/>
                  </FormItem>
                </Col>
              </Row>
              <Row className="disabled-form-input">
                <Col sm={8} xs={24}>
                  { initialValues && initialValues.phone ?
                    <FormItem 
                      rules={[{ required: false, message: '' }]}
                      label={
                        <Row>
                          <p style={{height: 'fit-content', display: 'flex', margin: 'auto 0'}}>{t('shareable.phone', { defaultValue: 'Phone' })}</p>
                          <Button 
                            onClick={() => this.setState({ PhoneNumberChangeVisible: true })}
                            icon={<EditOutlined style={{fontSize: '16px'}} />} 
                            type="link"/>
                        </Row>
                      } 
                      >
                        <Input value={initialValues ? initialValues.phone : ''}/>  
                    </FormItem>
                      : 
                    <Button onClick={() => this.setState({ PhoneNumberChangeVisible: true })} icon={<PlusOutlined/>}>Add phone number</Button> }
                </Col>
              </Row>
            </Form>
        </Card>
          <PhoneNumberChange
            visible={PhoneNumberChangeVisible}
            handleClose={() => this.setState({ PhoneNumberChangeVisible: false })}
            updateProfile={this.getProfile} />
      </Layout>
    );
  }
}

export default withTranslation()(withWindowSizeListener(UserSettings));
