import React from 'react';
import { Modal, Button, Input, message, Spin, Alert } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';
import { Form } from '@ant-design/compatible';
import { getFingerprint } from 'services/utils/Analytics';

const FormItem = Form.Item;

class SmsAuthenticationPrompt extends React.Component {
  state = {
    putREQUEST: false,
    putFAILURE: false,
    putSUCCESS: false,
    putMessage: '',
    getListRequest: false,
    listNotes: [],
    phone: '',
    code: '',
    stage: 1,
    showCode: false
  }
  componentDidMount() {
    this.getProfile();
  }
  getProfile = () => {
    this.setState({
      putREQUEST: true
    });
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/worker/profile', {
      method: 'GET'
    }).then(response => {
      console.log(response.data);
      this.setState({
        putREQUEST: false
      });
      if (response.code === 200) {
        let phone = '';
        if (response.data.phone) {
          phone = response.data.phone;
          phone = `***${phone.slice(phone.length - 4)}`;
        }
        this.setState({
          phone
        });
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          putREQUEST: false
        });
     });
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, fieldsValue) => {
      if (err) {
        return;
      }
      const { stage } = this.state;
      const { action, doAfter } = this.props;
      this.setState({
        putREQUEST: true
      });

      let fingerprint = '';
      try {
        fingerprint = await getFingerprint();
      } catch (error) {
        console.log('error', error);
      }

      fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/worker/smsAuthentication', {
        method: 'POST',
        body: {
          code: fieldsValue['code'],
          stage: stage,
          action: action,
          fingerprint
        }
      }).then(response => {
        console.log(response);
        if (response.success) {
          this.setState({
            putREQUEST: false,
            putSUCCESS: true,
            putFAILURE: false,
            stage: response.stage
          });
          message.success(response.message);
          
          if (response.stage === 3) {
            if (doAfter && typeof doAfter === 'function') {
              console.log('typeof doAfter',  typeof doAfter);
              doAfter();
              this.props.handleClose(false)
            }
          }
        } else {
          this.setState({
            putREQUEST: false,
            putFAILURE: true,
            putSUCCESS: false,
            stage: response.stage || 1
          });
          message.error(response.message);
        }
       }).catch(error => {
          console.log(error);
          message.error('Something wrong, try later');
          this.setState({
            putSUCCESS: false,
            putREQUEST: false,
            putFAILURE: true,
          });
       });
    })
  }
  render() {
    const {
      putREQUEST,
      phone,
      code,
      stage
    } = this.state;
    const { visible, form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Modal
        className="nocardPadding"
        style={{ top: 20 }}
        width={ 400 }
        visible={ visible }
        title='This action requires sms authentification'
        onOk={() => this.props.handleClose(false)}
        onCancel={() => this.props.handleClose('cancel')}
        footer={false}
        centered={true}
      >
        <Spin spinning={putREQUEST}>
        <Form layout="vertical" onSubmit={this.handleSubmit}>
          {phone ? 
            <div>
              <p>To confirm this operation, we will send you one time password to the number <b>{phone}</b></p>
              {stage === 2 ? <FormItem label="Confirmation code">
                  {getFieldDecorator('code', {
                    rules: [{ required: true, message: 'Please enter a code!' }],
                    initialValue: code
                  })(
                    <Input name="code" onChange={this.onChange} style={{marginLeft: '10px'}} />
                  )}
                </FormItem> : null }
              <Button type="primary" htmlType="submit">{stage === 2 ? 'Submit' : 'Request one time password'}</Button>
            </div>
            : 
            <Alert message="Please, contact Dezy team to setup your phone authentication number." type="error" />
          }
          
        </Form>
        </Spin>
      </Modal>
    )
  }
};
export default Form.create()(SmsAuthenticationPrompt);