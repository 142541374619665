import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Row, Modal, Form, Input, Col, Checkbox, Radio } from 'antd';
import formModifierPriceString from 'helpers/formModifierPriceString'
import {correctModifierSurcharges} from "../../../../../../../helpers/correctModifierSurcharges";
const FormItem = Form.Item;
const { TextArea } = Input;

class EditOrderAddons extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      putREQUEST: false,
      modifiers: [],
      radioSelectorHandler: {},
      listOfModifiers: {}
    };
  }
  componentDidMount = () => {
    this.mounted = true;
    const { modifiers, item } = this.props.selectedItem;
    const listOfModifiers = {};
    const { modifierSets } = item;

    const radioSelectorHandler = {};
    if (modifierSets && modifierSets.length) {
      modifierSets.forEach((modifierSet) => {
        modifierSet.modifiers.forEach((modifier) => {
          if (Object.keys(listOfModifiers).indexOf(modifier.id) === -1) {
            listOfModifiers[modifier.id] = modifier;
          }
          if (modifiers && modifiers.length) {
            if (modifiers.indexOf(modifier.id) > -1 && modifierSet.single_select) {
              radioSelectorHandler[modifierSet.id] = modifier.id;
            }
          }

        });
      })
    }

    this.setState({
      modifiers: [...modifiers],
      radioSelectorHandler,
      listOfModifiers
    });
  }
  componentWillUnmount = () => {
    this.mounted = false;
  }
  handleSubmit = () => {
    return this.formRef.current.validateFields()
    .then((values) => {
      //debugger;
      const { selectedItem, submitOrderAddons } = this.props;
      const { modifiers, listOfModifiers } = this.state;
      values.modifiers = modifiers;

      values.modifiersRaw = [];
      if (modifiers) {
        modifiers.forEach((modifier_id) => {
          values.modifiersRaw.push(listOfModifiers[modifier_id]);
        });
      }

      submitOrderAddons(selectedItem.order_item_id, values);
      this.props.handleClose(false);

    }).catch((err) => console.log('validate err', err))
  }
  validatePhone = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || !value.phone) {
        return Promise.reject('Phone is required!')
      } else {
        if (value.phone.length < 7) {
          return Promise.reject(<p><Trans i18nKey="shareable.reqPhone.minLength">Phone should be 8 symbols and longer</Trans></p>);
        }
        const isnum = /^\d+$/.test(value.phone);
        if (!isnum) {
          return Promise.reject(<p><Trans i18nKey="shareable.reqPhone.onlyNumber">Please, enter correct phone</Trans></p>);
        }
      }
      return Promise.resolve();
    },
    validateTrigger: 'onSubmit'
  })
  addModifierRadio = (modifierSetId, e) => {
    const { radioSelectorHandler } = this.state;

    const modifier_id = parseInt(e.target.value, 10);
    const copy = {...radioSelectorHandler};

    if (copy.hasOwnProperty(modifierSetId)) {
      const oldModifierId = copy[modifierSetId];
      this.addModifierToItem(oldModifierId);
    }

    copy[modifierSetId] = modifier_id;

    this.setState({
      radioSelectorHandler: copy
    }, () => {
      this.addModifierToItem(modifier_id);
    })
  }

  addModifierToItem = (modifier_id) => {
    const { modifiers } = this.state;
    const copy = [...modifiers];
    const index = copy.indexOf(modifier_id);

    if (index > -1) {
      delete copy[index];
    } else {
      copy.push(modifier_id);
    }

    const final = copy.filter(el => !!el);

    this.setState({
      modifiers: final
    });
  }
  checkIfChecked = (modifier_id) => {
    const { modifiers } = this.state;
    return modifiers.indexOf(modifier_id) > -1;
  }
  findRadioChecked = (totalList) => {
    const { modifiers } = this.state;
    let findAny = null;
    totalList.forEach((item) => {
      if (modifiers.indexOf(item.id) > -1) {
        findAny = item.id;
      }
    })
    return findAny;
  }
  resetRadioChoice = (modifierSetId, modifier_id) => {
    const { radioSelectorHandler } = this.state;
    const copy = {...radioSelectorHandler};

    copy[modifierSetId] = null;
    this.addModifierToItem(modifier_id);

    this.setState({
      radioSelectorHandler: copy
    })
  }
  resetCheckboxChoice = (modifierSet) => {
    const { modifiers } = this.state;
    const modifiersInSet = modifierSet.modifiers.map(m => m.id);
    const newModifiers = modifiers.filter(item => {
      return modifiersInSet.indexOf(item) === -1
    })
    this.setState({
      modifiers: newModifiers
    });
  }

  checkedModifiersFromSetCount = (modifierSet) => {
    const { modifiers } = this.state;
    const modifiersInSet = modifierSet.modifiers.map(m => m.id);
    let count = 0;
    modifiers.forEach(modifier => {
      if (modifiersInSet.indexOf(modifier) !== -1) count++;
    });
    return count
  }

  renderModifierSets = () => {
    const { selectedItem, surcharges } = this.props;
    const { modifierSets } = selectedItem.item;

    if (modifierSets && Array.isArray(modifierSets) && modifierSets.length) {
      return <div style={{marginBottom: '24px'}}>

        {modifierSets.map((modifierSet, key) => {
          const checkedRadioValue = this.findRadioChecked(modifierSet.modifiers);

          return (<Row key={key} style={{marginBottom: '10px'}}>
            <Col span={24}>
              <Row>
                <div className="ant-col ant-form-item-label">
                  <label className="ant-form-item-required" style={{fontWeight: 'bold'}} title={modifierSet.display_name}>
                    {modifierSet.display_name}
                    {modifierSet.required && <span style={{color: "#E01491", marginLeft: '10px' }}>(Required)</span>}
                    { (modifierSet.single_select && checkedRadioValue) ?
                      <a
                        href="#reset"
                        style={{ marginLeft: '10px' }}
                        onClick={e => this.resetRadioChoice(modifierSet.id, checkedRadioValue)}>Reset choice</a>
                      : null }
                    { (!modifierSet.single_select && this.checkedModifiersFromSetCount(modifierSet) > 1) ?
                        <a
                            href="#reset"
                            style={{ marginLeft: '10px' }}
                            onClick={e => this.resetCheckboxChoice(modifierSet)}>Reset choice</a>
                        : null }
                  </label>
                  </div>
              </Row>
              <Row>
                {!modifierSet.single_select ?
                  <ul style={{listStyle: 'none', padding: 0, margin: 0 }}>
                  {modifierSet.modifiers.map((modifier, i) => <li key={i} style={{padding: '5px 0'}}>
                    <Checkbox
                      onChange={e => this.addModifierToItem(modifier.id)}
                      checked={this.checkIfChecked(modifier.id)}
                      > { modifier.display_name } {modifier.price_add ? `(${formModifierPriceString(correctModifierSurcharges(modifier.price_add, surcharges))})` : ''}</Checkbox>
                  </li>)}
                </ul>
                :
                <ul style={{listStyle: 'none', padding: 0, margin: 0 }}>
                  <Radio.Group
                    value={this.findRadioChecked(modifierSet.modifiers)}
                    >
                    {modifierSet.modifiers.map((modifier, i) => <li key={i} style={{padding: '5px 0'}}>
                      <Radio value={modifier.id}
                        onChange={e => this.addModifierRadio(modifierSet.id, e)}
                        checked={this.checkIfChecked(modifier.id)}>{ modifier.display_name } {modifier.price_add ? `(${formModifierPriceString(correctModifierSurcharges(modifier.price_add, surcharges))})` : ''}</Radio>
                    </li>)}
                  </Radio.Group>
                </ul>
                }
              </Row>
            </Col>
          </Row>);
        }
      )}
      </div>
    }
    return null;
  }

  checkIsSubmitDisabled = () => {
    const {item} = this.props.selectedItem;
    //debugger;
    const {modifierSets} = item;

    return modifierSets.some(set => {  // if some modifierSets are required...
      if (set.required) {
        const {modifiers} = set;

        return modifiers.every(modifier => {
          return !this.checkIfChecked(modifier.id) // ... and no checkboxes are checked, disable submit
        })
      };
      return false;
    })
  }

  render() {
    const {
      putREQUEST
    } = this.state;
    const { selectedItem } = this.props;
    const { comments } = selectedItem;

    return (
      <Modal
        className="general-modal-btns-center general-card"
        title="Order Addons"
        centered={true}
        visible={this.props.visible}
        okButtonProps={{ htmlType: "submit", disabled:  this.checkIsSubmitDisabled() }}
        okText="Submit"
        onOk={() => this.handleSubmit()}
        onCancel={()=>{this.props.handleClose(true)}}
        destroyOnClose={true}
        confirmLoading={putREQUEST}
      >
        <Form
          requiredMark={false}
          className="general-form"
          layout="vertical"
          ref={this.formRef}
          initialValues={{
            comments
          }}
        >
          {this.renderModifierSets()}
          <Row>
            <Col span={24}>
              <FormItem rules={[{
                required: false,
                // message: t('shareable.required.note', { defaultValue: 'Note is required!' })
              }]} name="comments" label="Note">
                <TextArea rows={4} placeholder="Note"/>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    )
  }
};

export default withTranslation()(EditOrderAddons);
