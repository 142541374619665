import React from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Button, Modal, Col, Table, Spin } from 'antd';
import { withWindowSizeListener } from 'react-window-size-listener';
import AddCategory from '../../../../../../../Products/components/ProductsCategories/components/AddCategory';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';
import * as _sharedModules from '../../../../../../../modules/_modules';

import './styles.less';

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));
const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

class CategoriesDraggableTable extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      isFetching: false,
      categories: [],
      showAddCategory: false,
      categoriesInMenu: [],
      dataSourceCategories: []
    };
    Object.keys(_sharedModules).forEach((_moduleName) => {
      this[_moduleName] = _sharedModules[_moduleName].bind(this);
    });
  }
  componentDidMount() {
  	this.mounted = true;
  	const { categories } = this.props;
  	this.setState({
  		categories
  	}, this.getDataCategories);
  }
  componentDidUpdate(prevProps) {
  	let shouldUpdate = false;
  	const categoriesInMenuCurrent = this.props.categoriesInMenu;
  	const categoriesInMenuPrev = prevProps.categoriesInMenu;
  	if (categoriesInMenuPrev.length !== categoriesInMenuCurrent.length) {
  		shouldUpdate = true;
  	}
  	categoriesInMenuPrev.forEach((id) => {
  		if (categoriesInMenuCurrent.indexOf(id) === -1) {
  			shouldUpdate = true;
  		}
  	});
  	categoriesInMenuCurrent.forEach((id) => {
  		if (categoriesInMenuPrev.indexOf(id) === -1) {
  			shouldUpdate = true;
  		}
  	});
    if (shouldUpdate) {
    	this.getDataCategories();
    }
  }
  columns = () => {
  	const columns = [{
      title: 'Sort',
      dataIndex: 'sort',
      width: 60,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      className: 'drag-visible',
  	}];
  	if (this.props.canRemoveCategories) {
  		columns.push({
      dataIndex: 'action',
      render: (text, record) => {
        return (
          <Button type="link" onClick={() => this.removeCategoryConfirm(record.id)}>Remove</Button>
        )}
    	})
  	}
  	return columns;
  }
  removeCategoryConfirm = (categoryId) => {
    const { menuId } = this.props;
    Modal.confirm({
      title: 'Are you sure you want to remove category from menu?',
      okText: 'Yes',
      onOk: () => {
        this.removeCategoryFromMenu(menuId, categoryId);
      },
      className: "confirm-modal-btns-center"
    })
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { dataSourceCategories } = this.state;
    if (oldIndex !== newIndex) {
      const newData = arrayMove([].concat(dataSourceCategories), oldIndex, newIndex).filter(el => !!el);
      this.setState({ dataSourceCategories: newData }, this.addCategoriesAndOrder);
    }
  };

  DraggableBodyRow = ({ className, style, ...restProps }) => {
    const { dataSourceCategories } = this.state;
    const index = dataSourceCategories.findIndex(x => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };
  getDataCategories = () => {
  	const { categoriesInMenu, categoriesOrder } = this.props;
  	const { categories } = this.state;
    const data = [];
    const categoriesWithOrder = [];
    let i = 0;
    categoriesOrder.forEach((category) => {
      if (category.category_id && categoriesInMenu.indexOf(category.category_id) > -1) {
      	if (categoriesWithOrder.indexOf(category.category_id) === -1) {
      		categoriesWithOrder.push(category.category_id);
      	}
        data.push({
          key: i,
          index: i,
          id: category.category_id,
          name: category.category ? category.category.display_name : ''
        });
        i += 1;    
      } 
    })
    if (categoriesInMenu.length !== categoriesOrder.length) {
	    categories.forEach((category) => {
	      if (category.category_id && categoriesInMenu.indexOf(category.category_id) > -1 && categoriesWithOrder.indexOf(category.category_id) === -1) {
	        data.push({
	          key: i,
	          index: i,
	          id: category.category_id,
	          name: category.category ? category.category.display_name : ''
	        });
	        i += 1;    
	      } 
	    })
    }
    this.setState({
      dataSourceCategories: data
    }, this.addCategoriesAndOrder)
  }
  addCategoriesAndOrder = () => {
    const { dataSourceCategories } = this.state;
    const { menuId } = this.props;
    if (!menuId) return;
    const menuCategoriesOrderObj = [];
    dataSourceCategories.forEach((category, i) => {
      menuCategoriesOrderObj.push({
        order_index: i,
        category_id: category.id,
        menu_id: menuId,
        name: category.name
      });
    });
    if (!menuCategoriesOrderObj.length) {
      return;
    }
    this.saveCategoriesOrder(menuId, menuCategoriesOrderObj, true);
  }
  render() {
    const { addCategoryVisible, categoriesInMenu } = this.props;
    const { dataSourceCategories, isFetching } = this.state;
    const DraggableContainer = props => (
      <SortableContainer
        useDragHandle
        helperClass="row-dragging"
        onSortEnd={this.onSortEnd}
        {...props}
      />
    );
  	return (
  		<div>
        <Spin spinning={isFetching}>
          <div>
            <Row>
              <Col xs={24} sm={12}>
            { categoriesInMenu && categoriesInMenu.length ?
              <Table
                pagination={false}
                style={{width: '100%'}}
                size="small"
                dataSource={dataSourceCategories}
                columns={this.columns()}
                rowKey="index"
                components={{
                  body: {
                    wrapper: DraggableContainer,
                    row: this.DraggableBodyRow,
                  },
                }}
              /> : null }
              </Col>
            </Row>
						<AddCategory
              history={this.props.history}
              handleClose={this.props.closeAddCategory}
              visible={addCategoryVisible}  
              updateCategoriesList={this.props.updateCategoriesList}
              restaurantId={this.props.restaurantId}
            /> 
          </div>
        </Spin>
		  </div>
  	)
  }
}
export default  withTranslation()(withWindowSizeListener(CategoriesDraggableTable));