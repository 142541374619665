import React, { Component } from 'react';
import { Layout, Spin, Col, Row, Button, Card, Radio, notification } from 'antd';
import Settings from 'services/config/Settings';
import { withTranslation } from 'react-i18next';
import fetchJSON from 'services/utils/fetchJSON';
import moment from 'moment-timezone';
import _ from 'lodash';
import { PrinterOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import Masonry from 'react-masonry-component';
import Socket from 'services/utils/Socket';

import './styles.less';
const { Content } = Layout;
// const confirm = Modal.confirm;

class Kitchen extends Component {
  constructor(props) {
    super(props);
  	this.state = {
  		isFetching: false,
  		menu: {
  			menu_id: '',
  			categories: [],
  		},
  		fetchingProducts: false,
  		filterTypeOrder: 'all',
  		orderBy: 'ASC',
      completeOrders: [],
      canceledOrders: [],
      updatedOrderUuid: null
  	}
    this.timerUpdated = 0;
  }
	componentDidMount() {  
    this.mounted = true;
    const { t } = this.props;
    document.title = `${t('shareable.kitchen', { defaultValue: 'Kitchen' })} | ${Settings.title}`;
    this.getOrders();
    this.subscribeToSocket();
  }
  subscribeToSocket = () => {
    const { branchUuid } = this.props;
    console.log('branchUuid', branchUuid);
    Socket.on(`notification_${branchUuid}`, this.updateScreen);
  }
  unsubscribeFromSocket = () => {
    const { branchUuid } = this.props;
    Socket.off(`notification_${branchUuid}`, this.updateScreen);
  }
  updateScreen = (data) => {
    console.log('notification', data);
    const { type, orderUuid } = data;
    if (type === 'new_order' || type === 'order_updated') {
      notification.info({
        message: type === 'new_order' ? 'New order added' : 'Order updated'
      });
      this.setState({
        updatedOrderUuid: orderUuid
      }, () => {
        this.timer = setTimeout(this.cleanUpdatedUuid, 3000);
        this.getOrders();
      });
    }
  }
  cleanUpdatedUuid = () => {
    this.setState({
      updatedOrderUuid: null
    });
  }
  componentWillUnmount() {
    this.unsubscribeFromSocket()
    this.mounted = false;
    clearInterval(this.timer);
  }
  getOrders = () => {
    this.setState({
      isFetching: true
    });
    const { branchUuid } = this.props;
    fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches-orders/list/${branchUuid}`, {
      method: 'get'
    }).then(response => {
      console.log(response);
      if (this.mounted) {
        this.setState({
          tables: response.data.tables,
          orders: response.data.orders,
          completeOrders: response.data.completeOrders,
          canceledOrders: response.data.canceledOrders,
          branch: response.data,
          menu: response.data.menu,
          isFetching: false
        });
      }
    }).catch(error => {
      console.log(error);
      if (this.mounted) {
        this.setState({
          isFetching: false
        });
      }
    });
  }
  renderTable = (table_id) => {
  	if (!table_id) {
  		return 'Dine In';
  	}
    const { tables } = this.state;
    const table = tables.filter((item) => {
      return item.id === table_id;
    });
    if (table) {
      return table[0].display_name;
    }
    return null;
  }
  renderOrderItemsList = (order_items) => {
  	const { menu } = this.state;
  	const { categories } = menu;
  	const render = [];

  	if (categories && Array.isArray(categories) && categories.length && Array.isArray(order_items) && order_items.length) {
  		const catList = {};

  		categories.forEach((category) => {
  			catList[category.id] = category.displayName;
  		});
  		const tmp = [];
  		const listToRender = {};

  		order_items.forEach((item) => {
  			const catId = _.get(item, 'product.category[0].category_id');
  			if (catId) {
  				if (tmp.indexOf(catId) === -1) {
  					tmp.push(catId);
  					listToRender[catId] = {
  						title: catList[catId],
  						list: []
  					};
  				}
  				listToRender[catId].list.push({
  					id: item.product.id,
  					title: item.product.display_name,
  					quantity: item.quantity,
  					item_id: item.id,
  					comments: item.comments,
            modifiers: item.modifiers
  				});
  			}
  		});
  		// console.log('listToRender', listToRender);
  		Object.keys(listToRender).forEach((catId, i) => {
  			render.push(<div className="kitchenScreenItem__catTitle" key={catId}>{listToRender[catId].title}</div>);
  			listToRender[catId].list.forEach((item, k) => {

          let modifiersRender = [];
          if (item.modifiers && item.modifiers.length) {
            item.modifiers.forEach((modifier) => {
              const str = !!modifier.modifier_set ? modifier.modifier_set + ': ' : ''
              modifiersRender.push(str + modifier.display_name);
            });
          }

  				render.push(<div key={item.id} className="kitchenScreenItem__item">
  					{item.quantity} {item.title}
  					{item.comments && <span> (<b>Note:</b> {item.comments})</span>}
            {modifiersRender.length ? <div style={{marginTop: '5px'}}>{modifiersRender.join(', ')}</div> : null}
					</div>);
  			});
  		});
  	}
  	return render;
  }
  renderOrders = () => {
    const {
      orders,
      filterTypeOrder,
      orderBy,
      completeOrders,
      canceledOrders,
      updatedOrderUuid
    } = this.state;

    const render = [];
    const orderTypes = {
      takeout: 'Take Out',
      delivery: 'Delivery'
    }
    console.log('completeOrders', completeOrders);

    if ((orders && Array.isArray(orders) && orders.length)
      || (filterTypeOrder === 'completed' && completeOrders)
      || (filterTypeOrder === 'canceled' && canceledOrders)) {
    	let orderSorted = [...orders];
      if (filterTypeOrder === 'completed') {
        orderSorted = [...completeOrders];
      }
       if (filterTypeOrder === 'canceled') {
        orderSorted = [...canceledOrders];
      }
      const {
        searchOrderId,
        filteredById
      } = this.props;
      

    	orderSorted.sort((a, b) => {
    		if (orderBy === 'ASC') {
    			return a.submitted_at > b.submitted_at ? 1 : -1
    		}
    		return a.submitted_at < b.submitted_at ? 1 : -1
    	});
    	// is_kitched_completed
      orderSorted.forEach((order, i) => {
        if (filterTypeOrder !== 'completed') {
          if (order.is_kitched_completed) return null;
        }
        if (filterTypeOrder !== 'canceled') {
          if (order.is_canceled) return null;
        }
      	
      	if (filterTypeOrder !== 'all' && filterTypeOrder !== 'completed' && filterTypeOrder !== 'canceled') {
      		if (filterTypeOrder !== order.order_type) {
      			return;
      		}
      	}
        if (filteredById) {
          const orderId = order.index_id.toString();
          if (orderId.indexOf(searchOrderId) === -1) return; 
        }

        let formatDate = "h [h] m [min]";
        const start = moment.utc(order.submitted_at);
        const ms = moment().diff(start);
        const dur = moment.duration(ms);
        const durationSession = dur.format(formatDate);

        let orderDue = moment.utc(order.submitted_at);
        if (order.order_type === 'takeout') {
          if (order.take_out_delay && order.order_type === 'takeout') {
            orderDue.add(order.take_out_delay, 'minutes')
          }
        }
        if (order.serving_time) {
          orderDue.add(order.serving_time, 'minutes')
        }
        const ms2 = moment().diff(orderDue);
        const dur2 = moment.duration(ms2);
        orderDue = dur2.format(formatDate);

        let orderUpdated = '';
        if (updatedOrderUuid === order.uuid) {
          orderUpdated = ' updatedOrder';
        } 

        render.push(<div key={i} className="kitchenScreen__item">
        	<Card
	        	className={"kitchenScreen__card kitchenScreen__card--" + (order.is_canceled ? 'canceled' : order.order_type) + orderUpdated }
	        	title={
        			order.order_type === 'dine_in' ?
        			this.renderTable(order.table_id)
        			: orderTypes[order.order_type]
        		}
	        	actions={[
	        		<Button
                onClick={() => this.isCompleted(order.uuid)}
                style={{
                  display: ['completed', 'canceled'].indexOf(filterTypeOrder) > -1 && 'none'
                }}
              >{order.is_canceled ? 'Hide' : 'Prepared' }</Button>,
	        		<Button onClick={() => this.printWorkshopReceipt(order.uuid)}><PrinterOutlined /></Button>
	      		]}
	        	>
	        	<div className="kitchenScreen__cardHead">
              { order.is_canceled && <div className="kitchenScreen__сanceled">Сanceled</div> }
              { order.is_kitched_completed && !order.is_canceled && <div className="kitchenScreen__completed">Completed</div> }
              <div className="kitchenScreen__orderId">Order #{order.index_id}</div>
	        		{ order.waiter_id && <div className="orderItem__line"><b>Placed by:</b> {order.server_name}</div> }
              { filterTypeOrder !== 'completed'  && <React.Fragment>
		          <div className="orderItem__line"><b>Order Placed:</b> {durationSession}</div>
              <div className="orderItem__line"><b>Order Due:</b> {orderDue}</div>
              { order.customer_name ? <div className="orderItem__line"><b>Customer name:</b> {order.customer_name}</div> : null }
              </React.Fragment> }
	          </div>
	          <div className="kitchenScreenItem__list">
	          	{this.renderOrderItemsList(order.order_items)}
	          </div>
	        </Card>
        </div>);
      });
    }
    return render;
  }
  setIsFetching = (param) => { 
    this.setState({
      fetchingProducts: param
    });
  }
  printWorkshopReceipt = (orderUuid) => {
    const { branchUuid } = this.props;
    this.setIsFetching(true);
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches-orders/submit/', {
      method: 'POST',
      body: {
        branchUuid,
        orderUuid,
        key: 'print_workshop_receipt',
        value: true
      }
    }).then(response => {
      console.log(response);
      this.setIsFetching(false);
      notification.success({
        message: 'Ok',
      });
     }).catch(error => {
        console.log(error);
        this.setIsFetching(false);
     });
  }
  isCompleted = (orderUuid) => {
    const { branchUuid } = this.props;
     // confirm({
     //  title: `Are you sure you want to mark it as "completed"?`,
     //  onOk: () => {
     //    return new Promise((resolve, reject) => {
  	this.setIsFetching(true);
  	fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches-orders/submit/', {
      method: 'POST',
      body: {
        branchUuid,
        orderUuid,
        key: 'is_kitched_completed',
        value: true
      }
    }).then(response => {
      console.log(response);
      this.setIsFetching(false);
      if (response.code === 200) {
      	this.getOrders();
        // const { selectedOrder } = this.props;
        // const { is_paid } = selectedOrder;

        // this.setState({
        //   showList: false,
        //   checkoutOpen: is_paid && is_delivered,
        //   paymentMethod: is_delivered && !is_paid
        // });
        //resolve()
      }
      // reject();
     }).catch(error => {
        console.log(error);
        this.setIsFetching(false);
        //reject();
     });
    //     }).catch(() => console.log('Oops errors!'));
    //   },
    //   onCancel() {},
    // });
  }
  filterType = (e) => {
  	this.setState({
  		filterTypeOrder: e.target.value
  	});
  }
  getOrderAmount = () => {
  	let amount = 0;
  	const {
      orders,
      filterTypeOrder
    } = this.state;

    if (orders && Array.isArray(orders) && orders.length) {
      const {
        searchOrderId,
        filteredById
      } = this.props;
      orders.forEach((order) => {
        if (filterTypeOrder !== 'completed') {
          if (order.is_kitched_completed) return null;
        }

        if (filterTypeOrder !== 'canceled') {
          if (order.is_canceled) return null;
        }
        
        if (filterTypeOrder !== 'all' && filterTypeOrder !== 'completed' && filterTypeOrder !== 'canceled') {
          if (filterTypeOrder !== order.order_type) {
            return;
          }
        }
        if (filteredById) {
          const orderId = order.index_id.toString();
          if (orderId.indexOf(searchOrderId) === -1) return; 
        }

        amount += 1;
      });
    }
  	return amount;
  }
  getOrderItemAmount = () => {
  	let amount = 0;
  	const {
      orders,
      filterTypeOrder
    } = this.state;
    if (orders && Array.isArray(orders) && orders.length) {
      const {
        searchOrderId,
        filteredById
      } = this.props;
    	orders.forEach((order, i) => {
        if (filterTypeOrder !== 'completed') {
          if (order.is_kitched_completed) return null;
        }

        if (filterTypeOrder !== 'canceled') {
          if (order.is_canceled) return null;
        }
        
        if (filterTypeOrder !== 'all' && filterTypeOrder !== 'completed' && filterTypeOrder !== 'canceled') {
          if (filterTypeOrder !== order.order_type) {
            return;
          }
        }
        if (filteredById) {
          const orderId = order.index_id.toString();
          if (orderId.indexOf(searchOrderId) === -1) return; 
        }

    		amount += order.order_items.length;
    	});
    }
  	return amount;
  }
  reOrder = () => {
  	const {
      orderBy
    } = this.state;

    this.setState({
    	orderBy: orderBy === 'ASC' ? 'DESC' : 'ASC'
    });

  }
  render() {
    const {
      isFetching,
      filterTypeOrder,
      orderBy
    } = this.state;
    console.log('orderBy', orderBy);

    return (
      <Content className="bg-transparent table-content">
        <Spin spinning={isFetching}>
          <Row gutter={8}>
            <Col span={24}>
              <Row gutter={8}>
                <Col span={24}>
                  <h3 style={{color: '#454545'}}>
                    Orders
                  </h3>
                  <div className="kitchenScreenHead waiterListFilter">
                  	<Radio.Group className="waiterListFilter__type zoomUpBtns" value={filterTypeOrder} buttonStyle="solid" size="large" onChange={this.filterType} style={{marginLeft: 0}}>
								      <Radio.Button value="all">All</Radio.Button>
								      <Radio.Button value="dine_in">Dine In</Radio.Button>
								      <Radio.Button value="takeout">Takeaway</Radio.Button>
                      <Radio.Button value="completed">Closed</Radio.Button>
                      <Radio.Button value="canceled">Cancelled</Radio.Button>
								    </Radio.Group>

								    <div className="kitchenScreenHead__order waiterListFilter__order zoomUpBtns" onClick={this.reOrder}>
								    	<CaretUpOutlined style={{ color: orderBy === 'ASC' ? '#E01491' : ''}} />
								    	<CaretDownOutlined style={{ color: orderBy === 'DESC' ? '#E01491' : ''}}/>
								    </div>

								    <div className="kitchenScreenHead__title kitchenScreenHead__title--first">
								    	Orders: <b>{this.getOrderAmount()}</b>
								    </div>
								    <div className="kitchenScreenHead__title kitchenScreenHead__title--second">
								    	Items to prepare: <b>{this.getOrderItemAmount()}</b>
								    </div>
                  </div>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24} >
                	<Masonry
			                className={'kitchenScreen'}
			            >
                  {this.renderOrders()}
                  </Masonry>
                </Col>
              </Row>
            </Col>
            </Row>
        </Spin>
      </Content>
    )
  }
}


export default withTranslation()(Kitchen);