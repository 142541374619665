import React from 'react';
import axios from 'axios'
import _ from 'lodash';
import Auth from 'services/utils/Auth';
import { Upload, Modal, message, Button, Row } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

class PdfBranch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
			previewImage: '',
			previewVisible: false,
			getWidth: true,
			fileList: []
		}
  }
	componentDidMount() {
		this.mounted = true;
		if (this.props.file && typeof this.props.file === 'object' && Object.keys(this.props.file).length) {
			let fileList = [{
				uid: this.props.file.id,
				name: `menu_${this.props.branchTitle}`,
				status: 'done',
				url: `${this.props.file.sizes.origin}`,
			}];
			this.setState({
				fileList
			})
		}
	}
  componentWillUnmount() {
    this.mounted = false;
  }
  componentDidUpdate(prevProps) {
  	const fileIdOld = _.get(prevProps, 'file.id');
  	const fileIdNew = _.get(this.props, 'file.id');
  	if (fileIdNew && fileIdNew !== fileIdOld) {
  		let newFileList = [{
				uid: this.props.file.id,
				name: `menu_${this.props.branchTitle}`,
				status: 'done',
				url: `${this.props.file.sizes.origin}`
			}];
			this.setState({
				fileList: newFileList
			})
  	}
  	return null;
  }

  handleDrop = data => {
  	// console.log(data);
  	const file = data.file;
  	const { fileList } = this.state;
  	const { t } = this.props;
  	console.log(fileList, this.props.file, 'test')
		if ( !file ) {
			let errorLoadMessage =  t('error.fileNull', { defaultValue: 'File was null!' });
			message.error(errorLoadMessage);
			data.onError();
			return false;
		}
		// Initial FormData
		const formData = new FormData();
		formData.append("file", file);
		console.log(file, 'file')
		const config = {
			headers: {
				"X-Requested-With" : "XMLHttpRequest",
				"Authorization" : `bearer ${Auth.getToken()}`
			},
			onUploadProgress: data.onProgress,
			params: {
				branchId: this.props.branchId,
				type: 'pdf',
				role: 'menu'
			}
		};
		this.props.changeUploadStatus(true);
		return axios.put(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/files/upload`, formData, config)
			.then(response => {
				data.onSuccess(response);
				const { fileList } = this.state;
				fileList.map(file => {
					if (file.uid === data.file.uid) {
						file.uid = response.data.uid;
					}
					return file;
				});
				this.setState({ fileList })	
				if (!this.props.setFieldsValue) {
					if (this.props.branchUpdate) {
						this.props.branchUpdate()
					} else {
						this.props.changeUploadStatus(false);
					}		
				}	else {
					this.props.setFieldsValue('file_id_menu', response.data.uid);
					this.props.changeUploadStatus(false);
				}
			})
			.catch(error => {
				console.log(error);
				this.props.changeUploadStatus(false);
				let errorLoadMessage = t('venueImage.error.couldntUpload', { defaultValue: 'Could not upload file. Please try again!' });
				message.error(errorLoadMessage);
				data.onError();
		})
	}
  handleChange = ({ fileList }) => this.setState({ fileList })
  onRemove = params => {
  	Modal.info({ title: 'Menu can be only replaced. Upload new pdf file first.'});
  	return false
  }
  render() {
  	const { fileList } = this.state;
  	const uploadButton = (
      <div style={{height: 'fit-content'}}>
        <Button icon={<UploadOutlined />}>Upload new menu</Button>
      </div>
    );
    const classNameDiv = this.props.extra === 'on_first_add' ? "clearfix pdf-fix no-margin-pdf" : "clearfix pdf-fix";
    return (
      <div>
        <h3 style={{color: '#5B5B5B', display: this.props.extra === 'on_first_add' && !fileList.length ? 'none' : ''}}>
        	PDF Menu
        </h3>
				<div id="demo" className={classNameDiv} style={{marginTop: this.props.extra === 'on_first_add' ? '-18px' : ''}}>
					{ this.props.extra === 'on_first_add' && !fileList.length ?
					<Row> 
					{ this.props.placement ?
						<p>Please upload menu to go further.</p> :
						<p>Please upload menu to save settings.</p>
					}
					</Row> : null }
	        <Upload
	          action="https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/files/upload"
	          accept=".pdf"
	          multiple={false}
	          customRequest={this.handleDrop}
	          fileList={fileList}
	          onRemove={this.onRemove}
	          onChange={this.props.extra === 'on_first_add' ? this.handleChange : null}
	          listType="picture"
	        >
          	{ this.props.extra !== 'on_first_add' ? uploadButton : null }
          	{ (this.props.extra === 'on_first_add' && fileList.length > 0) || this.props.extra !== 'on_first_add' ? null : uploadButton }
	        </Upload>
	      </div>
      </div>
    )
  }
};

export default withTranslation()(PdfBranch);