import React from 'react';
import Settings from 'services/config/Settings';
import fetchJSON from 'services/utils/fetchJSON';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
import qs from 'qs';
import {
  Layout,
  Card,
  Row,
  Form,
  Input,
  InputNumber,
  Col,
  Empty,
  Button,
  Select,
  Alert,
  message,
  Radio,
  Spin,
  Modal,
  Divider,
  notification
} from 'antd';
import AddressField from '../../../components/AddressField/AddressField';
import WorkingHours from '../../../components/WorkingHours/WorkingHours';
import * as _sharedModules from '../../../modules/_modules';
import TablesList from './components/TablesList/TablesList';
import isValidABN from 'is-valid-abn';
import Menu from './components/Menu/Menu';
import ImageBranch from './components/ImageBranch';
import PdfBranch from './components/PdfBranch';
import { CompactPicker } from 'react-color';
import Printing from './components/Printing/Printing';

import './styles.less';
import {
  LeftOutlined,
  GoogleOutlined,
  MobileOutlined,
  SnippetsOutlined,
  PlusOutlined,
  CopyOutlined,
  InfoCircleTwoTone
} from '@ant-design/icons';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

class SingleBranch extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      isFetching: false,
      branch: {},
      initialValues: {},
      addressGroup: {},
      activeTab: 'info',
      businessHours: [],
      shouldChooseMenuType: false,
      userRole: false,
      bannerChangingProccess: false,
      logoChangingProccess: false,
      menuChangingProccess: false,
      shouldAskSaving: false,
      takeOutSnoozeItems: [30, 60, 90, 120],
      takeOutSnoozeName: '',
      takeOutDelayItems: [30, 45, 60],
      takeOutDelayName: '',
      shouldShowTakeoutSnooze: false,
      payment_in_app: 0,
      is_alcohol_sales_only_by_foh: false,
      is_alcohol_sales_for_takeaway: true,
      surcharges_comment: ''
    };
    Object.keys(_sharedModules).forEach((_moduleName) => {
      this[_moduleName] = _sharedModules[_moduleName].bind(this);
    });
  }
	componentDidMount = () => {
    this.mounted = true;
    let branchUuid = this.props.match.params.branchUuid;
    let userRole = false;

    if (!branchUuid) {
      branchUuid = this.props.branchUuid;
      userRole = this.props.role;
    }

    let activeTab = 'info';
    let paramFromUrl = {};
    if (window.location.search) {
      paramFromUrl = qs.parse(window.location.search, { ignoreQueryPrefix: true });

      const tabList = [
      'info',
      'tables',
      'business_hours',
      'ordering_settings',
      'customization',
      'printing'];

      if (Object.keys(paramFromUrl).length) {
        Object.keys(paramFromUrl).forEach((param) => {
          if (paramFromUrl[param] && tabList.indexOf(param) > -1) {
            activeTab = param;
          }
          if (param === 'add' && paramFromUrl[param]) {
            const stepAdd = paramFromUrl.step ? parseInt(paramFromUrl.step) - 1 : 0;
            this.setState({
              addingMenuInProcess: true,
              stepAdd
            })
          }
        });
      }
    }
    this.setState({
      branchUuid,
      activeTab,
      userRole
    })
    this.getBranch(branchUuid);
    this.getBusinessCategories();
  }
  getBranch = (branchUuid) => {
    this.setState({
      isFetching: true
    });
    fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches/${branchUuid}`, {
      method: 'get'
    }).then(response => {
      if (this.mounted) {
        if (response.data) {
          const branch = response.data;

          document.title = `${branch.title} | ${Settings.title}`;
          const addressGroup = {
            address: branch.address,
            addressComponents: branch.address_components,
            coordinates: branch.coordinates && branch.coordinates.lat ? branch.coordinates : {},
            timezone: branch.timezone,
            place_id: branch.place_id,
            currency_code: branch.currency_code,
            opening_hours: branch.opening_hours,
            country_code: branch.country,
            correct: true
          };

          const initialValues = {
            title: branch.title,
            status: branch.status !== null ? branch.status : 1,
            address: branch.formatted_address,
            description: branch.description,
            opening_hours: branch.opening_hours || {},
            addressInput: branch.address,
            business_category_id: branch.business_category_id,
            contact_name: branch.contacts.name,
            contact_phone: branch.contacts.phone,
            contact_email: branch.contacts.email,
            abn: branch.abn,
            online_order: branch.online_order,
            covid_checkin: branch.covid_checkin,
            payment_in_app: branch.payment_in_app,
            payment_manual: branch.payment_manual,
            take_out: branch.take_out,
            menu_type: branch.menu_type,
            url: branch.url,
            main_color_theme: branch.main_color_theme,
            title_in_app: branch.title_in_app,
            take_out_delay: branch.take_out_delay !== 0 ? branch.take_out_delay.toString() : 0,
            is_alcohol_active: branch.is_alcohol_active,
            surcharges: branch.surcharges,
            payment_processing_by_client: branch.payment_processing_by_client,
            is_discount_for_foh_active: branch.is_discount_for_foh_active,
            is_alcohol_sales_only_by_foh: branch.is_alcohol_sales_only_by_foh,
            is_alcohol_sales_for_takeaway: branch.is_alcohol_sales_for_takeaway,
            surcharges_comment: branch.surcharges_comment
          };
          if (branch.files && branch.files.length) {
            branch.files.forEach((file) => {
              if (file.type === 'image') {
                if (file.role === 'banner') {
                  branch.banner_image = file;
                } else if (file.role === 'logo') {
                  branch.logo_image = file;
                }
              }
              if (file.type === 'pdf' && file.role === 'menu') {
                branch.menu = file;
              }
            })
          }
          const menuId = _.get(branch, 'menues[0].id');
          const tablesQuantity = branch.tables && Array.isArray(branch.tables) ? branch.tables.length : 0;
          const tables = branch.tables;
          const businessHours = response.data.working_hours && Array.isArray(response.data.working_hours) ? response.data.working_hours : [];
          this.setState({
            isFetching: false,
            branch,
            initialValues,
            addressGroup,
            businessHours,
            opening_hours: branch.opening_hours || {},
            addressInput: branch.address,
            tablesQuantity,
            tables,
            menuId,
            showOrderingOptions: branch.menu_type === 'online',
            shouldAskPayment: branch.menu_type === 'online' && branch.online_order,
            shouldChooseMenuType: !branch.menu_type,
            menuChangingProccess: false,
            shouldShowTakeoutSnooze: false,
            payment_in_app: branch.payment_in_app,
            surcharges_string: this.calculateSurcharges(branch.surcharges),
            payment_processing_by_client: branch.payment_processing_by_client,
            is_discount_for_foh_active: branch.is_discount_for_foh_active,
            is_alcohol_sales_only_by_foh: branch.is_alcohol_sales_only_by_foh,
            is_alcohol_sales_for_takeaway: branch.is_alcohol_sales_for_takeaway,
            surcharges_comment: branch.surcharges_comment
          })
        } else {
          this.setState({ isFetching: false });
        }
      }
    }).catch(error => {
      console.log(error);
      if (this.mounted) {
        this.setState({
          isFetching: false
        });
      }
    });
  }
  handleSubmit = values => {
    const { t } = this.props;
    //console.log('values', values);
    values.working_hours = this.state.businessHours;
    values.addressGroup = this.state.addressGroup;
    values.formatted_address = this.state.formatted_address;
    values.take_out = values.take_out === 2 && !values.take_out_snooze ? 1 : values.take_out;
    //console.log(values, 'form values')
    //console.log('values.addressGroup', values.addressGroup);
    if (values.online_order === 0) {
      values.take_out = 0;
      values.payment_in_app = 0;
      values.payment_manual = 1;
      values.payment_processing_by_client = 0;
    }

    this.setState({
      putREQUEST: true
    });

    return Promise.resolve()
    .then(() => {
      const lat = _.get(values.addressGroup, 'coordinates.lat');
      if (!lat) {
        const msg = t('shareable.enterSelectAddressPlease', { defaultValue: 'Please, enter correct address and select right option from a dropdown list' });
        message.error(msg);
        const error = new Error(msg);
        error.name = 'FormIncompleteError';
        throw error;
      }

      if (values.hasOwnProperty('payment_in_app') && values.hasOwnProperty('payment_manual')) {
         if (!values.payment_in_app && !values.payment_manual) {
          const msg = 'No payment methods have chosen';
          message.error(msg);
          const error = new Error(msg);
          error.name = 'FormIncompleteError';
          throw error;
        }
      }


      return fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches/${this.state.branchUuid}`, {
        method: 'post',
        body: values
      });
    })
    .then(response => {
      console.log(response);
      if (response.success) {
        this.setState({
          putREQUEST: false,
          putSUCCESS: true,
          putFAILURE: false,
          menuAdded: false,
          shouldAskSaving: false,
          isFetching: false,
          shouldShowTakeoutSnooze: false
          //errorMessage: 'Saved successfully'
        }, () => this.getBranch(this.state.branchUuid));
          message.success(t('singleLocation.feedback.saveSuccess', { defaultValue: 'Saved successfully' }));
      } else {
        this.setState({
          putREQUEST: false,
          putFAILURE: true,
          putSUCCESS: false,
          menuAdded: false,
          isFetching: false
          //errorMessage: response.message
        });
        message.error(response.message);
      }
     }).catch(error => {
        if (error.name === 'FormIncompleteError') {
          console.log('err.message', error.message);
          return;
        }
        console.log(error);
        this.setState({
          putSUCCESS: false,
          putREQUEST: false,
          putFAILURE: true,
          menuAdded: false,
          isFetching: false
        });
        message.error(t('shareable.ERR', { defaultValue: 'ERR' }));
     });
  }
  askAndSave = (branchUuid, shouldAskSaving) => new Promise((resolve, reject) => {
    if (shouldAskSaving) {
      this.setState({
        shouldAskSaving: false
      })
      Modal.confirm({
        title: 'You have made some changes. Do you want to save them?',
        okButtonText: 'Save',
        onOk: () => {
          if (this.formRef && this.formRef.current) {
            return this.formRef.current.validateFields()
            .then((values) => {
              this.setState({
                isFetching: true
              })
              return Promise.all([this.handleSubmit(values)])
            })
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
              this.setState({
                shouldAskSaving: true
              });
            });
          }
        },
        onCancel: () => {
          if (branchUuid) {
            return Promise.all([this.getBranch(branchUuid)])
            .then(() => {
              resolve();
            })
          } else {
            resolve();
          }
        }
      });
    } else {
      if (branchUuid) {
        return Promise.all([this.getBranch(branchUuid)])
        .then(() => {
          resolve();
        })
      }
    }
  })

  onTabChange = (key, type) => {
    const { branchUuid, userRole, shouldAskSaving } = this.state;
    return this.askAndSave(branchUuid, shouldAskSaving)
    .then(() => {
      if (userRole === 'manager') {
        this.props.history.push(`/manager/location?${key}=1`);
      } else {
        this.props.history.push(`/branches/${branchUuid}?${key}=1`);
      }

      this.setState({
        [type]: key,
        addingMenuInProcess: false
      });
    })
  }
  onlineOrderChange = (e) => {
    this.setState({
      shouldAskPayment: parseInt(e.target.value, 10)
    })
  }
  validateABN = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value) return Promise.resolve();
      if (isValidABN(value)) {
        return Promise.resolve();
      } else {
        return Promise.reject(<span><Trans i18nKey="addBranch.abn_not_valid">ABN is not valid</Trans></span>);
      }
    },
    validateTrigger: 'onSubmit'
  })
  confirmMenu = () => {
    const menu_type = this.formRef.current.getFieldValue('menu_type');
    const online_order = this.formRef.current.getFieldValue('online_order');
    if (menu_type === 'online') {
      this.setState({
        shouldChooseMenuType: false,
        showOrderingOptions: true,
        shouldAskPayment: online_order
      });
    } else {
      this.setState({
        shouldChooseMenuType: false,
        showOrderingOptions: false
      })
    }
  }
  shouldAskSaving = (e, takeOut = false) => {
    let shouldShowTakeoutSnooze = this.state.shouldShowTakeoutSnooze;
    if (takeOut) {
      shouldShowTakeoutSnooze = e.target.value === "2";
    }
    this.setState({
      shouldAskSaving: true,
      shouldShowTakeoutSnooze
    })
  }
  renderTabList = () => {
    const { t } = this.props;
    const { userRole } = this.state;
    const tabList = [{
      key: 'info',
      tab: t('shareable.info', { defaultValue: 'Info' }),
    }, {
      key: 'ordering_settings',
      tab: t('shareable.ordering_settings', { defaultValue: 'Ordering settings' })
    }];
    if (userRole !== 'manager') {
      tabList.push({
        key: 'customization',
        tab: t('shareable.customization', { defaultValue: 'Customization' })
      });
    }
    tabList.push({
      key: 'business_hours',
      tab: t('shareable.business_hours', { defaultValue: 'Business hours' }),
    }, {
      key: 'tables',
      tab: t('shareable.tables', { defaultValue: 'Tables' })
    });

    if (userRole !== 'manager') {
      tabList.push({
        key: 'printing',
        tab: t('shareable.printing', { defaultValue: 'Printing' }),
      });
    }

    return tabList;
  }
  slugify = (string) => {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')

    return string.toString().toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w-]+/g, '') // Remove all non-word characters
      .replace(/--+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
  }
  urlify = ({ getFieldValue }) => ({
    validateTrigger: 'onBlur',
    validator: (rule, url) => {
      if (url) {
        const slugify = this.slugify(url);
        if (slugify) {
          this.formRef.current.setFieldsValue({
            url: slugify
          });
        }
      }
      return Promise.resolve();
    }
  })
  copyUrl = (url) => {
    var inp = document.createElement('input');
    document.body.appendChild(inp)
    inp.value = 'https://app.dezy.com.au/i/' + url;
    inp.select();
    document.execCommand('copy', false);
    message.success('Link copied!');
    inp.remove();
  }
  handleColorChange = (color) => {
    const { branch } = this.state;
    const copyBranch = {...branch};
    copyBranch.main_color_theme = color.hex

    this.formRef.current.setFieldsValue({
      main_color_theme: color.hex
    });

    this.setState({
      branch: copyBranch
    });
  }
  onNameChangeDelay = event => {
    this.setState({
      takeOutDelayName: event.target.value,
    });
  };

  addItemDelay = () => {
    const { takeOutDelayItems, takeOutDelayName } = this.state;
    if (takeOutDelayName) {
      if (parseInt(takeOutDelayName, 10) && typeof parseInt(takeOutDelayName, 10) === 'number' && Number.isInteger(parseFloat(takeOutDelayName, 10))) {
        this.setState({
          takeOutDelayItems: [...takeOutDelayItems, takeOutDelayName],
          takeOutDelayName: '',
        });
      } else {
        Modal.warning({ title: 'Please enter only an amount of minutes. This value must be a real number.'})
      }
    }
  };
  onNameChange = event => {
    this.setState({
      takeOutSnoozeName: event.target.value,
    });
  };

  addItem = () => {
    const { takeOutSnoozeItems, takeOutSnoozeName } = this.state;
    if (takeOutSnoozeName) {
      if (parseInt(takeOutSnoozeName, 10) && typeof parseInt(takeOutSnoozeName, 10) === 'number' && Number.isInteger(parseFloat(takeOutSnoozeName, 10))) {
        this.setState({
          takeOutSnoozeItems: [...takeOutSnoozeItems, takeOutSnoozeName],
          takeOutSnoozeName: '',
        });
      } else {
        Modal.warning({ title: 'Please enter only an amount of minutes. This value must be a real number.'})
      }
    }
  }

  archiveVenue = () => {
    const { t } = this.props;
    const { branchUuid, branch } = this.state;
    console.log('archiveVenue', branchUuid)
    const unlinked = _.get(branch, 'unlinked');

    Modal.confirm({
      title: `Are you sure you want to ${unlinked ? 'recover' : 'archive'} the venue?`,
      okButtonText: 'Yes',
      onOk: () => {
        fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches/${branchUuid}`, {
          method: 'post',
          body: {
            unlinked: unlinked ? 0 : 1
          }
        }).then(response => {
          console.log(response);
          if (response.success) {
            this.setState({
              putREQUEST: false,
              putSUCCESS: true,
              putFAILURE: false,
              menuAdded: false,
              shouldAskSaving: false,
              isFetching: false,
              shouldShowTakeoutSnooze: false
              //errorMessage: 'Saved successfully'
            }, () => this.getBranch(this.state.branchUuid));
            message.success(t('singleLocation.feedback.saveSuccess', { defaultValue: 'Saved successfully' }));
            if (!unlinked) {

            }
          } else {
            this.setState({
              putREQUEST: false,
              putFAILURE: true,
              putSUCCESS: false,
              menuAdded: false,
              isFetching: false
              //errorMessage: response.message
            });
            message.error(response.message);
          }
         }).catch(error => {
            console.log(error);
            this.setState({
              putSUCCESS: false,
              putREQUEST: false,
              putFAILURE: true,
              menuAdded: false,
              isFetching: false
            });
            message.error(t('shareable.ERR', { defaultValue: 'ERR' }));
         });
      },
    });
  }
  proceedToStripe = () => {
    const { branchUuid } = this.state;
    console.log('proceedToStripe', branchUuid);
    const { t } = this.props;

    this.setState({
      putREQUEST: true
    });
    console.log('window.location.href', window.location.href)

    fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches/stripe/${branchUuid}`, {
      method: 'post',
      body: {
        url: window.location.href
      }
    }).then(response => {
      console.log(response);
      if (response.success) {
        const newWindow = window.open(response.data, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
        // this.setState({
        //   putREQUEST: false,
        // }, () => this.getBranch(branchUuid));
        //message.success(t('singleLocation.feedback.saveSuccess', { defaultValue: 'Saved successfully' }));

      } else {
        this.setState({
          putREQUEST: false,
        });
        message.error(response.message);
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          putSUCCESS: false,
          putREQUEST: false,
          putFAILURE: true,
          menuAdded: false,
          isFetching: false
        });
        message.error(t('shareable.ERR', { defaultValue: 'ERR' }));
     });
  }
  paymentInAppChange = (e) => {
    console.log('paymentInAppChange', e.target.value);
    this.setState({
      payment_in_app: parseInt(e.target.value, 10)
    });
  }
  paymentProcessingByClientChange = (e) => {
    console.log('paymentProcessingByClientChange', e.target.value);
    this.setState({
      payment_processing_by_client: parseInt(e.target.value, 10)
    });
  }
  checkPaymentSettings = ({ getFieldValue }) => ({
    validator(rule, value) {
      const payment_manual = getFieldValue('payment_manual');
      if (!payment_manual && !value) {
        const msg = 'At least one payment method must be enabled, online or manual.';
        notification.error({
          message: msg
        })
        return Promise.reject(<p>{msg}</p>);
      }
      else { return Promise.resolve() }
    }
  })
  calculateSurcharges = (e) => {
    this.setState({ surcharges: e })
    this.setState({
      surcharges_string : !!e ? (e < 1 ? `${((1-e)*100).toFixed()}%` : `${((e-1) * 100).toFixed()}%`) : '100%'})
  }
  render() {
    const { t } = this.props;
    const {
      isFetching,
      branch,
      initialValues,
      activeTab,
      addressError,
      addressGroup,
      businessHours,
      opening_hours,
      tables,
      showOrderingOptions,
      shouldAskPayment,
      shouldChooseMenuType,
      userRole,
      menuId,
      addingMenuInProcess,
      stepAdd,
      logoChangingProccess,
      bannerChangingProccess,
      menuChangingProccess,
      menuAdded,
      takeOutSnoozeItems,
      takeOutSnoozeName,
      shouldShowTakeoutSnooze,
      takeOutDelayName,
      takeOutDelayItems,
      payment_in_app,
      is_alcohol_sales_only_by_foh,
      is_alcohol_sales_for_takeaway,
      surcharges_comment
    } = this.state;

    const shouldShowSaveButtonOnOfflineMenu = !(initialValues.menu_type === 'scan' && (!this.formRef.current || (this.formRef.current && this.formRef.current.getFieldValue('menu_type') === 'scan')));
    const canUseGoogleOpenHours = _.get(opening_hours, 'can_use_google');
    const restaurantId = _.get(branch, 'restaurant.restaurant_id');
    const restaurantUuid = _.get(branch, 'restaurant.restaurant.uuid');

    const unlinked = _.get(branch, 'unlinked');

    const stripe_charges_enabled = _.get(branch, 'stripe_charges_enabled');

    const isInputsDisabled = this.props.role === 'manager';
    const inputsClassnameAdditive = isInputsDisabled ? 'disabled-form-input' : '';

    return (
    	<Layout className="bg-transparent">
      { initialValues && Object.keys(initialValues).length ?
        <React.Fragment>
         {unlinked ?
            <Alert message="The venue is archived and hidden from the system" type="warning" showIcon style={{ 'margin': '20px 0' }} />
          : null }
        <Card
          className="general-card"
          loading={isFetching}
          tabList={this.renderTabList()}
          activeTabKey={activeTab}
          onTabChange={(key) => { this.onTabChange(key, 'activeTab'); }}
          title={
            <Row>
              {!userRole ? <Link to="/branches" className="general-card__back-btn"><LeftOutlined /></Link> : null }
              <span className="general-card__title-text" style={{ margin: userRole ? 0 : '' }}>{branch.title}</span>
            </Row>
          }
          extra={
            <a
              href="#arhiveVenue"
              onClick={this.archiveVenue}
              style={{ color: !unlinked ? 'red' : '' }}
            >{!unlinked ? 'Archive venue' : 'Recover archived venue' }</a>
            }
          >
          { activeTab === 'info' ?
          <Form requiredMark={false} className="general-form" layout="vertical" ref={this.formRef} initialValues={initialValues} onFinish={this.handleSubmit}>
            <Row gutter={16}>
              <Col sm={6} xs={12}>
                <FormItem className={inputsClassnameAdditive} rules={[{ required: true, message: t('shareable.required.locName', { defaultValue: 'Location name is required!' }) }]} name="title" label="Location name">
                  <Input disabled={isInputsDisabled} />
                </FormItem>
              </Col>
              <Col sm={6} xs={12}>
                <FormItem className={isInputsDisabled ? 'disabled-select-input' : ''} name="status" label="Status">
                  <Select disabled={isInputsDisabled}>
                    <Option value={1}>Active</Option>
                    <Option value={0}>Inactive</Option>
                  </Select>
                </FormItem>
              </Col>
            </Row>
            <Row className="error-fix">
              <Col sm={6} xs={24}>
                <FormItem
                  className={inputsClassnameAdditive}
                  rules={[this.urlify, {
                    required: true,
                    message: t('shareable.required.url', { defaultValue: 'Url link is required!' })
                  }]}
                  name="url" label="Url link" style={{marginBottom: '4px'}}>
                  <Input disabled={isInputsDisabled}/>
                </FormItem>
                <div style={{cursor: 'pointer', color: '#1C8DCC', display: '-webkit-inline-box'}} onClick={() => this.copyUrl(branch.url)}>
                  <CopyOutlined />
                  <p style={{marginLeft: '4px'}}>Click to share the link to your Online Ordering page</p>
                </div>
              </Col>
            </Row>
            <Row className="error-fix" gutter={16}>
              <Col sm={6} xs={12}>
                <FormItem className="addLocation__pd15 addLocation__xs--fw">
                  <Row id='formitem_businessType'>
                    <label className="formDefault__label">
                      <Trans i18nKey="shareable.location_type.label">ABN</Trans>
                    </label>
                  </Row>
                  <FormItem
                    className={inputsClassnameAdditive}
                    name="abn"
                    rules={[{required: true, message: t('shareable.tables.required', { defaultValue: 'Please enter ABN'}) }, this.validateABN ]}
                    style={{marginBottom: '0px'}}
                    >
                    <Input disabled={isInputsDisabled} placeholder="ABN"/>
                  </FormItem>
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col sm={12} xs={24}>
                <FormItem
                  className={isInputsDisabled ? 'disabled-select-input' : ''}
                  label="Type of business"
                  name="business_category_id"
                  rules={[{required: true, message: t('shareable.business_type.required', { defaultValue: 'Please choose your type of business'}) }]}
                  >
                  <Select
                    disabled={isInputsDisabled}
                    placeholder={t('shareable.business_type.placeholder', { defaultValue: 'Select type'})}
                  >
                    {this.renderBusinessCategoriesOptions()}
                  </Select>
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col sm={12} xs={24}>
                <FormItem className={inputsClassnameAdditive}  name="description" label="Description" style={{marginBottom: '0px'}}>
                  <TextArea disabled={isInputsDisabled} placeholder="E.g.: Quiet place with exquisite cuisine..." showCount maxLength={255}/>
                </FormItem>
              </Col>
            </Row>
            <FormItem>
              <Row gutter={8}>
                <Col sm={15} xs={24}>
                  <Row>
                    <h3 style={{color: '#454545'}}><Trans i18nKey="shareable.address">Address</Trans></h3>
                  </Row>
                  <Row>
                     <AddressField
                        t={this.props.t}
                        value={addressGroup}
                        triggerChanges={this.triggerAddressChanges}
                        wrapIntoFormItem={true}
                        isDisabled={isInputsDisabled}
                      />
                      <Row style={{display: 'none'}}>
                        <WorkingHours
                          businessHours={businessHours}
                          setBusinessHours={this.setBusinessHours}
                        />
                      </Row>
                  </Row>
                  { addressError ?
                    <div style={{marginTop: '20px'}}><Alert message={addressError} type="error" /></div>
                  : null }
                </Col>
              </Row>
            </FormItem>
            <Row>
              <Col sm={15} xs={24}>
                <Row>
                  <h3 style={{color: '#454545', marginBottom: '1px'}}><Trans i18nKey="shareable.contact">Contact</Trans></h3>
                </Row>
                <Row>
                  <p>This phone number will be shown for customers to call you if they need any help or have questions.</p>
                </Row>
                <Row gutter={16}>
                  <Col sm={8} xs={24}>
                    <FormItem className={inputsClassnameAdditive}  rules={[{ required: true, message: t('shareable.required.name', { defaultValue: 'Name is required!' }) }]} name="contact_name" label={t('shareable.name', { defaultValue: 'Name' })}>
                      <Input disabled={isInputsDisabled} placeholder={ t('shareable.name', { defaultValue: 'Name' }) } />
                    </FormItem>
                  </Col>
                  <Col sm={8} xs={24}>
                    <FormItem className={inputsClassnameAdditive}  rules={[{ required: true, message: t('shareable.required.phone', { defaultValue: 'Phone is required!' }) }]} name="contact_phone" label={t('shareable.phone', { defaultValue: 'Phone' })}>
                      <Input disabled={isInputsDisabled} placeholder={t('shareable.phone', { defaultValue: 'Phone' })}/>
                    </FormItem>
                  </Col>
                  <Col sm={8} xs={24}>
                    <FormItem className={inputsClassnameAdditive}  rules={[{
                        type: 'email',
                        message: t('login.email.wrongFormat', { defaultValue: 'Wrong format of email' }),
                        validateTrigger: 'onSubmit'
                      }, { required: true, message: t('shareable.required.email', { defaultValue: 'Email is required!' }) }]} name="contact_email" label={t('shareable.email', { defaultValue: 'Email' })}>
                      <Input disabled={isInputsDisabled} placeholder={ t('shareable.email', { defaultValue: 'Email' }) } />
                    </FormItem>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{display: isInputsDisabled ? 'none' : ''}}>
              <Button className="general-card__submit-btn" htmlType="submit" type="primary">Save</Button>
            </Row>
          </Form> : null}
          { activeTab === 'tables' ?
            <TablesList tables={tables} branchUuid={branch.uuid} branchUrl={branch.url} branchTitle={branch.title} updateBranch={() => this.getBranch(branch.uuid)}/>
            : null }
          { activeTab === 'business_hours' ?
            <Form requiredMark={false} className="general-form" layout="vertical" ref={this.formRef} initialValues={initialValues} onFinish={this.handleSubmit}>
              <Row gutter={8}>
                <Col sm={12} xs={24}>
                  <Row>
                    <h3 style={{color: '#454545'}}><Trans i18nKey="shareable.businessHours">Business hours</Trans>
                      {canUseGoogleOpenHours ? <span style={{fontSize: '14px', color:'green','paddingLeft': '10px'}}><GoogleOutlined /><Trans i18nKey="shareable.from">From</Trans> Google Places</span> : null}
                    </h3>
                  </Row>
                  <Row>
                    <WorkingHours
                      businessHours={businessHours}
                      setBusinessHours={this.setBusinessHours}
                    />
                  </Row>
                </Col>
              </Row>
              <Row>
                <Button className="general-card__submit-btn" htmlType="submit" type="primary">Save</Button>
              </Row>
            </Form>
            : null }

            { activeTab === 'printing' && <Printing {...this.props} branchUuid={branch.uuid} /> }

            { activeTab === 'customization' && branch ?
            <div>
              <Spin size="large" spinning={bannerChangingProccess || logoChangingProccess} tip="Please do not leave this page">
              <Row style={{marginTop: '8px'}}>
                <h2>Images</h2>
              </Row>
              <Row>
                <h3 style={{color: '#1C8DCC'}}>
                  <InfoCircleTwoTone twoToneColor="#1C8DCC" style={{marginRight: '6px'}} />
                  Images will be saved automatically after loading.
                </h3>
              </Row>
               <Row>
                  <Col xs={24} sm={12}>
                    <ImageBranch
                      file={branch.logo_image}
                      branchId={branch.id}
                      type="logo"
                      changeUploadStatus={(value) => this.setState({ logoChangingProccess: value })}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={12}>
                    <ImageBranch
                      file={branch.banner_image}
                      branchId={branch.id}
                      type="banner"
                      changeUploadStatus={(value) => this.setState({ bannerChangingProccess: value })}
                    />
                  </Col>
                </Row>
              <Row>
               <Col sm={24}>
                  <Form
                  requiredMark={false}
                  style={{marginTop: '8px'}}
                  className="general-form"
                  layout="vertical"
                  ref={this.formRef}
                  initialValues={initialValues}
                  onFinish={this.handleSubmit}
                  >
                  <Row>
                    <h2>General</h2>
                  </Row>
                  <Row>
                    <Col span={9}>
                      <Row>
                        <label className="orgering-settings-label">
                          Choose the main color for the menu theme
                        </label>
                      </Row>
                      <Row>
                        <Form.Item className="addLocation__xs--fw" name="main_color_theme" rules={[{ required: false }]} >
                          <CompactPicker
                            color={ branch.main_color_theme || false }
                            onChangeComplete={ this.handleColorChange }
                          />
                        </Form.Item>
                      </Row>
                    </Col>
                    <Col span={8}>
                      <Row>
                        <label className="orgering-settings-label">
                          Show the name of the resturant
                        </label>
                      </Row>
                      <Row>
                        <Form.Item className="addLocation__xs--fw" name="title_in_app" rules={[{ required: true }]} >
                          <Radio.Group buttonStyle="solid">
                            <Radio.Button value={false}>Off</Radio.Button>
                            <Radio.Button value={true}>On</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Row>
                      </Col>
                    </Row>
                     <Row>
                      <Button className="general-card__submit-btn" htmlType="submit" type="primary">Save</Button>
                    </Row>
                  </Form>
                </Col>
              </Row>
              </Spin>
            </div>
            : null }
          { activeTab === 'ordering_settings' ?
            <Form
              requiredMark={false}
              style={{marginTop: '8px'}}
              className="general-form"
              layout="vertical"
              ref={this.formRef}
              initialValues={initialValues}
              onFinish={this.handleSubmit}
              onFinishFailed={() => Modal.destroyAll()}
              >

              <div style={{display: shouldChooseMenuType ? '' : 'none'}}>
                <Row align="center">
                  <h2>What type of menu would you like to use?</h2>
                </Row>
                <Row align="center">
                  <Col xs={24} sm={16}>
                    <FormItem
                      name="menu_type"
                      rules={[{ required: true, message: ' ' }]}
                      style={{margin: '20px 0px 40px 0px'}}
                      >
                      <Radio.Group className="locationRadioButton" style={{marginLeft: 'auto', marginRight: 'auto'}}>
                        <Radio value="online" className="locationRadioButton__item locationRadioButton__item--menuType locationRadioButton__item--online">
                          <span className="locationRadioButton__title"><Trans i18nKey="shareable.directWall">Online ordering</Trans></span>
                          <span className="locationRadioButton__descr" style={{visibility: 'hidden'}}>Comission 1.75/2.9%+$0.3 per transaction</span>
                          <span className="locationRadioButton__icon locationRadioButton__icon--online"><MobileOutlined  className="locationRadioButton--iconOnline"/></span>
                        </Radio>
                        <Radio value="scan" className="locationRadioButton__item locationRadioButton__item--menuType locationRadioButton__item--scan">
                          <span className="locationRadioButton__title"><Trans i18nKey="shareable.wallPanel">Scan copy of your menu</Trans></span>
                          <span className="locationRadioButton__descr">Online ordering is not available for customers</span>
                          <span className="locationRadioButton__icon locationRadioButton__icon--scan"><SnippetsOutlined className="locationRadioButton--iconScan"/></span>
                        </Radio>
                      </Radio.Group>
                    </FormItem>
                  </Col>
                </Row>
                  <FormItem>
                    <Row align="center">
                      <Button onClick={this.confirmMenu} className="general-card__submit-btn" type="primary">
                        <Trans i18nKey="shareable.confirm">Confirm</Trans>
                      </Button>
                    </Row>
                  </FormItem>
                </div>
              { showOrderingOptions ?
                <React.Fragment>
                <Row style={{marginTop: '8px'}}>
                  <h2>Ordering settings</h2>
                </Row>
                <Row style={{display: shouldChooseMenuType || isInputsDisabled ? 'none' : ''}}>
                  <Col span={24}>
                    <Row>
                      <label className="orgering-settings-label">
                        <Trans i18nKey="shareable.restaurant_name">Covid-19 checkin</Trans>
                      </label>
                    </Row>
                    <Row id='formitem_covid_checkin'>
                      <Form.Item className="addLocation__xs--fw"
                        onChange={(e) => {
                          //this.onlineOrderChange(e);
                          this.shouldAskSaving();
                        }}
                        name="covid_checkin" rules={[{ required: true }]} >
                        <Radio.Group buttonStyle="solid">
                          <Radio.Button value={false}>Off</Radio.Button>
                          <Radio.Button value={true}>On</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>

                <Row style={{display: shouldChooseMenuType || isInputsDisabled ? 'none' : ''}}>
                  <Col span={24}>
                    <Row>
                      <label className="orgering-settings-label">
                        Turn on Discount for FOH
                      </label>
                    </Row>
                    <Row id='formitem_is_discount_for_foh_active'>
                      <Form.Item className="addLocation__xs--fw"
                        onChange={(e) => {
                          this.shouldAskSaving();
                        }}
                        name="is_discount_for_foh_active" rules={[{ required: true }]} >
                        <Radio.Group buttonStyle="solid">
                          <Radio.Button value={false}>Off</Radio.Button>
                          <Radio.Button value={true}>On</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>


                <Row style={{display: shouldChooseMenuType ? 'none' : ''}}>
                  <Col span={24} style={{display: isInputsDisabled ? 'none' : ''}}>
                    <Row>
                      <label className="orgering-settings-label">
                        <Trans i18nKey="shareable.restaurant_name">Online ordering via app</Trans>
                      </label>
                    </Row>
                    <Row id='formitem_online_order'>
                      <Form.Item className="addLocation__xs--fw"
                        onChange={(e) => {
                          this.onlineOrderChange(e);
                          this.shouldAskSaving();
                        }}
                        name="online_order" rules={[{ required: true }]} >
                        <Radio.Group buttonStyle="solid">
                          <Radio.Button value={0}>Off</Radio.Button>
                          <Radio.Button value={1}>On</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    </Row>
                  </Col>
                  { shouldAskPayment ?
                  <div className="orderingBranchSection">
                    <Row style={{marginTop: '8px'}}>
                      <h2>Payment</h2>
                    </Row>
                    <Row>
                      <Col span={24} sm={24} xs={24} style={{display: isInputsDisabled ? 'none' : ''}}>
                        <Row>
                          <label className="orgering-settings-label">
                            <Trans i18nKey="shareable.payment_manual">Manual ordering</Trans>
                          </label>
                        </Row>
                        <Row>
                          <p style={{marginBottom: '8px'}}>Manual payments: Cash, Card at counter</p>
                        </Row>
                        <Row id='formitem_payment_in_app'>
                          <Form.Item onChange={this.shouldAskSaving} className="addLocation__xs--fw" name="payment_manual"
                          rules={[{ required: false }]}>
                            <Radio.Group buttonStyle="solid">
                              <Radio.Button value={0}>Off</Radio.Button>
                              <Radio.Button value={1}>On</Radio.Button>
                            </Radio.Group>
                          </Form.Item>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8} sm={8} xs={24} style={{display: isInputsDisabled ? 'none' : ''}}>
                        <Row>
                          <label className="orgering-settings-label">
                            <Trans i18nKey="shareable.payment_via_app">Payment via app</Trans>
                          </label>
                        </Row>
                        <Row>
                          <p style={{marginBottom: '8px'}}>Comission 1.75/2.9%+$0.3 per transaction</p>
                        </Row>
                        <Row id='formitem_payment_in_app'>
                          <Form.Item onChange={(e) => {
                              this.paymentInAppChange(e);
                              this.shouldAskSaving();
                            }} className="addLocation__xs--fw" name="payment_in_app"
                            rules={[{
                              required: false
                            }, this.checkPaymentSettings]}>
                            <Radio.Group buttonStyle="solid">
                              <Radio.Button value={0}>Off</Radio.Button>
                              <Radio.Button value={1}>On</Radio.Button>
                            </Radio.Group>
                          </Form.Item>
                        </Row>
                      </Col>
                      {
                        payment_in_app ?
                        <React.Fragment>
                          { !stripe_charges_enabled ? <Col span={8} sm={8} xs={24} style={{display: isInputsDisabled ? 'none' : ''}}>
                            <Row>
                              <label className="orgering-settings-label">
                                <Trans i18nKey="shareable.payout_status">Pay Out Settings</Trans>
                              </label>
                            </Row>
                            <Row>
                              <p style={{ marginBottom: '8px' }}>Apply for pay out program</p>
                            </Row>
                            <Row id='formitem_payment_in_app'>
                              <Button htmlType="button" type="primary" onClick={this.proceedToStripe}>
                                Proceed to setup
                              </Button>
                            </Row>
                          </Col> : null }
                          <Col span={8} sm={8} xs={24} style={{display: isInputsDisabled ? 'none' : ''}}>
                            <Row>
                              <label className="orgering-settings-label">
                                <Trans i18nKey="shareable.online_paymetns_status">Online Payments Status</Trans>
                              </label>
                            </Row>
                            <Row style={{display: stripe_charges_enabled ?  "none" : "block"}}>
                              <p style={{ marginBottom: '8px' }}>
                                {
                                  !stripe_charges_enabled ?
                                  'No mobile payments'
                                  :
                                  ''
                                }
                              </p>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row id='formitem_payment_in_app'>
                              <Alert message={`${!stripe_charges_enabled ? 'Not ' : '' }Active`} type={stripe_charges_enabled ? "success" : "error"} />
                            </Row>
                          </Col>
                          <Col span={8} sm={8} xs={24} style={{display: isInputsDisabled ? 'none' : ''}}>
                            <Row>
                              <label className="orgering-settings-label">
                                <Trans i18nKey="shareable.payment_processing_by_client">Commission processing by client</Trans>
                              </label>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row id='formitem_payment_in_app'>
                              <Form.Item onChange={(e) => {
                                this.paymentProcessingByClientChange(e);
                                this.shouldAskSaving();
                              }} className="addLocation__xs--fw" name="payment_processing_by_client"
                                         rules={[{
                                           required: false
                                         }, this.checkPaymentSettings]}>
                                <Radio.Group buttonStyle="solid">
                                  <Radio.Button value={0}>Off</Radio.Button>
                                  <Radio.Button value={1}>On</Radio.Button>
                                </Radio.Group>
                              </Form.Item>
                            </Row>
                          </Col>
                        </React.Fragment>
                        : null
                      }
                    </Row>
                    <Row>
                      <Col sm={8} xs={12}>
                        <Row>
                          <label className="orgering-settings-label">
                            <Trans i18nKey="shareable.surcharges"> Venue Wide Surcharges & Deductions  {this.state.surcharges > 1 ? `Surcharges` : `Discount`} ({this.state?.surcharges_string ? this.state.surcharges_string : this.calculateSurcharges(this.state.surcharges)})</Trans>
                          </label>
                        </Row>
                        <FormItem
                          className={inputsClassnameAdditive}
                          name="surcharges"
                        >
                          <InputNumber
                             disabled={isInputsDisabled}
                             placeholder="surcharges"
                             rules={[{required: true}]}
                             min={0}
                             precision={2}
                             onChange={(e) => this.calculateSurcharges(e)}
                             step={0.01}/>
                        </FormItem>
                      </Col>
                      <Col>
                        <Row>
                          <label className="orgering-settings-label">
                            <Trans i18nKey="shareable.surcharges_comment">Comment</Trans>
                          </label>
                        </Row>
                        <Row>&nbsp;</Row>
                        <FormItem
                            className={inputsClassnameAdditive}
                            name="surcharges_comment"
                        >
                          <Input
                              disabled={isInputsDisabled}
                              maxLength={80}
                          />
                        </FormItem>
                      </Col>
                    </Row>
                    <Row style={{marginTop: '8px'}}>
                      <h2>Takeaway</h2>
                    </Row>
                    <Row>
                      <Col sm={24} xs={24}>
                        <Row>
                          <label className="orgering-settings-label">
                            <Trans i18nKey="shareable.takeaway">Takeaway</Trans>
                          </label>
                        </Row>
                        { branch.take_out === 2 && branch.snoozeExplanation ?
                          <Row>
                            <p style={{marginBottom: '4px'}}>{branch.snoozeExplanation}</p>
                          </Row>
                          : null }
                        <Row id='formitem_take_out'>
                          <Form.Item onChange={(e) => this.shouldAskSaving(e, true)} className="addLocation__xs--fw" name="take_out" rules={[{ required: true }]} >
                            <Radio.Group buttonStyle="solid">
                              <Radio.Button value={0}>Off</Radio.Button>
                              <Radio.Button value={2}>Snooze</Radio.Button>
                              <Radio.Button value={1}>On</Radio.Button>
                            </Radio.Group>
                          </Form.Item>
                        </Row>
                      </Col>
                    </Row>
                    { shouldShowTakeoutSnooze ?
                      <Row>
                      <Col sm={24} xs={24}>
                        <Row>
                          <label className="orgering-settings-label">
                            Takeaway snooze time
                          </label>
                        </Row>
                        <Row>
                          <Form.Item onChange={this.shouldAskSaving} className="addLocation__xs--fw" name="take_out_snooze" rules={[{ required: shouldShowTakeoutSnooze, message: 'Time is required!' }]} >
                            <Select
                              style={{ width: 240 }}
                              placeholder="Takeaway snooze time"
                              dropdownRender={menu => (
                                <div>
                                  {menu}
                                  <Divider style={{ margin: '4px 0' }} />
                                  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                    <Input style={{ flex: 'auto' }} placeholder="Minutes" value={takeOutSnoozeName} onChange={this.onNameChange} />

                                    <Button
                                      type="link"
                                      icon={<PlusOutlined style={{display: 'flex', margin: 'auto 0'}}/>}
                                      style={{ flex: 'none', margin: 'auto 0', display: 'flex', cursor: 'pointer' }}
                                      onClick={this.addItem}
                                    >
                                       <span style={{display: 'flex', margin: 'auto 0px', lineHeight: '1.8'}}>Add custom</span>
                                    </Button>
                                  </div>
                                </div>
                              )}
                            >
                              {takeOutSnoozeItems.map(item => (
                                <Option key={item}>{item + ' min'}</Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Row>
                      </Col>
                    </Row>
                    : null }
                    <Row>
                      <Col sm={24} xs={24}>
                        <Row>
                          <label className="orgering-settings-label">
                            Takeaway delay
                          </label>
                        </Row>
                        <Row>
                          <Form.Item onChange={this.shouldAskSaving} className="addLocation__xs--fw" name="take_out_delay" rules={[{ required: true, message: 'Delay setting is required!' }]} >
                            <Select
                              style={{ width: 240 }}
                              placeholder="Takeaway delay"
                              dropdownRender={menu => (
                                <div>
                                  {menu}
                                  <Divider style={{ margin: '4px 0' }} />
                                  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                    <Input style={{ flex: 'auto' }} placeholder="Minutes" value={takeOutDelayName} onChange={this.onNameChangeDelay} />
                                    <Button
                                      type="link"
                                      icon={<PlusOutlined style={{display: 'flex', margin: 'auto 0'}}/>}
                                      style={{ flex: 'none', margin: 'auto 0', display: 'flex', cursor: 'pointer' }}
                                      onClick={this.addItemDelay}
                                    >
                                       <span style={{display: 'flex', maegin: 'auto 0px', lineHeight: '1.8'}}>Add custom</span>
                                    </Button>
                                  </div>
                                </div>
                              )}
                            >
                              <Option key={0} value={0}>No delay</Option>
                              {takeOutDelayItems.map(item => (
                                <Option key={item}>{item + ' min'}</Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Row>
                      </Col>
                    </Row>
                  </div> : null }
                </Row>

                <Row style={{marginTop: '8px'}}>
                  <h2>Alcohol</h2>
                </Row>
                <Row style={{display: shouldChooseMenuType || isInputsDisabled ? 'none' : ''}}>
                    <Col span={24}>
                      <Row>
                        <label className="orgering-settings-label">
                          Alcohol Sales
                        </label>
                      </Row>
                      <Row id='formitem_is_alcohol_active'>
                        <Form.Item className="addLocation__xs--fw"
                                   onChange={(e) => {
                                     this.shouldAskSaving();
                                   }}
                                   name="is_alcohol_active" rules={[{ required: true }]} >
                          <Radio.Group buttonStyle="solid">
                            <Radio.Button value={false}>No</Radio.Button>
                            <Radio.Button value={true}>Yes</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Row>
                    </Col>
                  <Col span={24}>
                      <Row>
                        <label className="orgering-settings-label">
                          Alcohol Sales Only by FOH
                        </label>
                      </Row>
                      <Row id='formitem_is_alcohol_sales_only_by_foh'>
                        <Form.Item className="addLocation__xs--fw"
                                   onChange={(e) => {
                                     this.shouldAskSaving();
                                   }}
                                   name="is_alcohol_sales_only_by_foh" rules={[{ required: true }]} >
                          <Radio.Group buttonStyle="solid">
                            <Radio.Button value={false}>No</Radio.Button>
                            <Radio.Button value={true}>Yes</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Row>
                    </Col>
                  <Col span={24}>
                      <Row>
                        <label className="orgering-settings-label">
                          Alcohol Sales for Takeaway
                        </label>
                      </Row>
                      <Row id='formitem_is_alcohol_sales_for_takeaway'>
                        <Form.Item className="addLocation__xs--fw"
                                   onChange={(e) => {
                                     this.shouldAskSaving();
                                   }}
                                   name="is_alcohol_sales_for_takeaway" rules={[{ required: true }]} >
                          <Radio.Group buttonStyle="solid">
                            <Radio.Button value={false}>No</Radio.Button>
                            <Radio.Button value={true}>Yes</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Row>
                    </Col>
                  </Row>
                </React.Fragment>
                : null }
                { ((initialValues.menu_type === 'scan' && !this.formRef.current) || (this.formRef.current && this.formRef.current.getFieldValue('menu_type') === 'scan')) ?
                  <div style={{ display: shouldChooseMenuType ? 'none' : ''}}>
                    <Row>
                      { !shouldShowSaveButtonOnOfflineMenu ?
                        'Online ordering is disabled. To operate with offline menu, please go to the menu settings. PDF menu is required.' : null }
                    </Row>
                    { shouldShowSaveButtonOnOfflineMenu ?
                    <Col xs={24} sm={8}>
                    <PdfBranch
                      branchId={branch.id}
                      branchTitle={branch.title}
                      type="menu"
                      extra="on_first_add"
                      changeUploadStatus={(value) => this.setState({ menuChangingProccess: value, menuAdded: !value })}
                    /></Col> : null }
                  </div>
                : null }
                <Row style={{display: shouldChooseMenuType ? 'none' : '', marginTop: '8px'}}>
                  <Button
                    className="general-card__submit-btn"
                    disabled={(this.formRef.current && this.formRef.current.getFieldValue('menu_type') === 'scan') && (menuChangingProccess || !menuAdded)}
                    style={{marginRight: '16px', display: shouldShowSaveButtonOnOfflineMenu ? '' : 'none'}}
                    htmlType="submit" type="primary">
                    Save
                  </Button>
                  <Button className="general-card__submit-btn" style={{width: '160px', display: isInputsDisabled ? 'none' : ''}} onClick={() => this.setState({ shouldChooseMenuType: true })}>
                    Change menu type
                  </Button>
                </Row>
              </Form>
          : null }
          { activeTab === 'menu' && branch && branch.id ?
            <div>
            { ((initialValues.menu_type === 'scan' && !this.formRef.current) || (this.formRef.current && this.formRef.current.getFieldValue('menu_type') === 'scan')) ?
              <span>
                <Spin spinning={menuChangingProccess} tip="Please do not leave this page">
                  <Col xs={24} sm={8}>
                    <PdfBranch
                      file={branch.menu}
                      branchId={branch.id}
                      branchTitle={branch.title}
                      branchUpdate={() => this.getBranch(branch.uuid)}
                      type="menu"
                      changeUploadStatus={(value) => this.setState({ menuChangingProccess: value })}
                    />
                  </Col>
                </Spin>
              </span>
            :
            <Menu
              role={this.props.role}
              menuId={menuId}
              branchId={branch.id}
              showAddMenu={addingMenuInProcess}
              addStep={stepAdd}
              restaurantId={restaurantId}
              restaurantUuid={restaurantUuid}
              history={this.props.history}
              /> }
            </div>
            : null }
          { [
              'info',
              'tables',
              'business_hours',
              'customization',
              'ordering_settings',
              'printing'
            ].indexOf(activeTab) === -1 && <Empty/> }

        </Card>
        </React.Fragment>
        : null }
      </Layout>
    )
  }
}

export default  withTranslation()(SingleBranch);
