import fetchJSON from 'services/utils/fetchJSON';
import { message, Modal, notification } from 'antd';

const getListWorkshops = function getListWorkshops() {
	const { t, branchUuid } = this.props;

	fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/workshops/getList`, {
    method: 'post',
    body: {branchUuid}
  }).then(response => {
    console.log(response);
    if (response.success) {
      this.setState({ 
        isFetching: false,
        workshopsList: response.data.list,
        restaurantId: response.data.restaurantId
      })
    } else {
      this.setState({
        isFetching: false,
        workshopsList: []
      });
      message.error(t('shareable.error', { defaultValue: 'Error' }));
    }
   }).catch(error => {
      console.log(error);
      this.setState({
        isFetching: false,
        workshopsList: []
      });
      message.error(t('shareable.error', { defaultValue: 'Error' }));
   });
}
const getWorkshop = function getWorkshop(workshopUuid, fulfil = false) {
  const { t } = this.props;

  console.log('getWorkshop', workshopUuid);
  fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/workshops/getSingle`, {
    method: 'post',
    body: {workshopUuid}
  }).then(response => {
    console.log(response);
    if (response.success) {
      this.setState({ 
        isFetching: false,
        workshopObj: response.data
      });
      if (fulfil && this.formRef && this.formRef.current) {
        const {
          display_name,
          is_receipt_station,
          categories,
          uuid,
          printer
        } = response.data;
        const categoriesList = [];
        this.setState({
          is_receipt_station
        });
        if (categories && Array.isArray(categories) && categories.length) {
          categories.forEach((category) => {
            categoriesList.push(category.category_id.toString());
          });
        }
        this.formRef.current.setFieldsValue({
          display_name,
          is_receipt_station,
          categoriesList,
          workshopUuid: uuid
        });

        if (printer && printer.printer) {
          console.log('printer.printer', printer.printer);
          const {
            id,
            serial_number,
            device_id,
            ip_address,
            model
          } = printer.printer;

          let printer_model = '';
          if (model) {
            printer_model = `${model.type} ${model.model}`;
          }
          this.formRef.current.setFieldsValue({
            printer: {
              id,
              serial_number,
              device_id,
              ip_address,
              printer_model
            }
          });
        }

      }
    } else {
      this.setState({
        isFetching: false,
        workshopObj: {}
      });
      message.error(t('shareable.error', { defaultValue: 'Error' }));
    }
   }).catch(error => {
      console.log(error);
      this.setState({
        isFetching: false,
        workshopObj: {}
      });
      message.error(t('shareable.error', { defaultValue: 'Error' }));
   });
}
const createWorkshop = function createWorkshop(values) {
  const { t } = this.props;
  this.setState({
    isFetching: true
  });
  const { is_receipt_station } = values;

  fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/workshops/create', {
    method: 'post',
    body: values
  }).then(response => {
    console.log(response);
    if (response.success) {
      this.setState({ 
        currentStep: !is_receipt_station ? 1 : 2,
        isFetching: false,
        putSUCCESS: true,
        putFAILURE: false,
        workshopUuid: response.createdWorkshopUuid
      }, () => this.getWorkshop(response.createdWorkshopUuid))
    } else {
      this.setState({
        isFetching: false,
        putFAILURE: true,
        putSUCCESS: false,
      });
      message.error(t('shareable.error', { defaultValue: 'Error' }));
    }
   }).catch(error => {
      console.log(error);
      this.setState({
        putSUCCESS: false,
        isFetching: false,
        putFAILURE: true,
      });
      message.error(t('shareable.error', { defaultValue: 'Error' }));
   });
}

const deleteWorkshop = function deleteWorkshop(workshopUuid) {
  const { t } = this.props;

  Modal.confirm({
    title: 'Are you sure you want to delete it?',
    okButtonText: 'Yes',
    onOk: () => {
      fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/workshops/delete`, {
        method: 'post',
        body: {workshopUuid}
      }).then(response => {
        console.log(response);
        if (response.success) {
          this.setState({ 
            isFetching: false
          }, this.getListWorkshops);

        } else {
          this.setState({
            isFetching: false
          });
          message.error(t('shareable.error', { defaultValue: 'Error' }));
        }
       }).catch(error => {
          console.log(error);
          this.setState({
            isFetching: false
          });
          message.error(t('shareable.error', { defaultValue: 'Error' }));
       });
    }
  });
}
const resetHostWorkshop = function deleteWorkshop(workshopUuid) {
  const { t } = this.props;

  Modal.confirm({
    title: 'Are you sure you want to reset host user for this workshop?',
    okButtonText: 'Yes',
    onOk: () => {
      fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/workshops/resetHost`, {
        method: 'post',
        body: {workshopUuid}
      }).then(response => {
        console.log(response);
        if (response.success) {
          this.setState({ 
            isFetching: false
          }, () => {
            window.location.reload(false); 
          });

        } else {
          this.setState({
            isFetching: false
          });
          message.error(t('shareable.error', { defaultValue: 'Error' }));
        }
       }).catch(error => {
          console.log(error);
          this.setState({
            isFetching: false
          });
          message.error(t('shareable.error', { defaultValue: 'Error' }));
       });
    }
  });
}

const updateWorkshop = function updateWorkshop(values, callback = () => {}) {
  const { t } = this.props;

  fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/workshops/update`, {
    method: 'post',
    body: {values}
  }).then(response => {
    console.log(response);
    if (response.success) {
      this.setState({ 
        isFetching: false,
        putREQUEST: false
      }, this.getListWorkshops);
      callback();
    } else {
      this.setState({
        isFetching: false,
        putREQUEST: false
      });
      if (response.message) {
        notification.error({
          message: response.message
        });
      } else {
        message.error(t('shareable.error', { defaultValue: 'Error' }));
      }
      
    }
   }).catch(error => {
      console.log(error);
      this.setState({
        putREQUEST: false,
        isFetching: false
      });
      message.error(t('shareable.error', { defaultValue: 'Error' }));
   });
  
}

export {
  getListWorkshops,
  createWorkshop,
  getWorkshop,
  deleteWorkshop,
  updateWorkshop,
  resetHostWorkshop
};