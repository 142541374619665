import React from 'react';
import { Table, Tag } from 'antd';
import { withTranslation } from 'react-i18next';
import { withWindowSizeListener } from 'react-window-size-listener';

import '../styles.less';

class ProductsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredInfo: {},
      sortedInfo: {}
    };
  }
  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  columns = () => {
    let {
      sortedInfo
    } = this.state;
    sortedInfo = sortedInfo || {};

    const indexItem = {
      title: '#',
      dataIndex: 'index',
      key: 'index'
    };
    const nameItem = {
      title: 'Item',
      dataIndex: 'name',
      key: 'name'
    };
    const categoryItem = {
      title: 'Category',
      dataIndex: 'categoriesList',
      key: 'categoriesList',
      align: 'center',
      width: 130,
      render: (text, record) => {
        if (!record.categoriesRender) return null;
        return (<span>{record.categoriesRender}</span>);
      },
    };
    let soldItem = {
      title: 'Sold',
      dataIndex: 'sold',
      key: 'sold'
    };
    let revenueItem = {
      title:'Revenue',
      dataIndex: 'revenue',
      key: 'revenue',
      render: (text, record) => <span>${!!record.revenue?record.revenue.toFixed(2):`0.00`}</span>
    };
    if (this.props.shouldShowSorters) {
      soldItem = {
        ...soldItem,
        sorter: (a, b) => a.sold > b.sold ? 1 : -1,
        sortOrder: sortedInfo.columnKey === 'sold' && sortedInfo.order
      };
      revenueItem = {
        ...revenueItem,
        sorter: (a, b) => a.revenue > b.revenue ? 1 : -1,
        sortOrder: sortedInfo.columnKey === 'revenue' && sortedInfo.order
      };
    }
    const columns = [indexItem, nameItem, categoryItem, soldItem, revenueItem];
    return columns;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    })
  }
  getData = () => {
    const data = [];
    const products = this.props.products;
    if (!products || !Array.isArray(products)) return data;

    products.forEach((product, i) => {
      const categoriesRender = [];
      const categoriesList = [];
      const tagColors = ['geekblue', 'cyan', 'magenta', 'purple'];
      product.categories.forEach((category, k) => {
        if (categoriesList.indexOf(category.name) === -1) {
          if (categoriesList.length < 4) {
            categoriesRender.push(<Tag key={k} color={tagColors[categoriesList.length % tagColors.length]} style={{margin: '3px'}}>
              {categoriesList.length === 3 ? '...' : category.name}
            </Tag>)
          }
          categoriesList.push(category.name)
        }
      })
      data.push({
        key: product.index,
        index: product.index + 1,
        id: product.id,
        name: product.name,
        sold: product.sold,
        revenue: product.revenue,
        categoriesRender,
        categoriesList
      })
    });
    return data;
  }
  render() {
    return (
      <div style={{width: '100%'}}>
        <Table
          className="generalTable innerTable"
          size="middle"
          columns={ this.columns() }
          dataSource={ this.getData() }
          onChange={ this.handleTableChange }
          style={{minWidth: '100%'}}
          pagination={false}
        />
      </div>
    )
  }
}

export default withTranslation()(withWindowSizeListener(ProductsTable));
