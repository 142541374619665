import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import Auth from 'services/utils/Auth';
import { withTranslation } from 'react-i18next';
import { withWindowSizeListener } from 'react-window-size-listener';
import { MenuContainer } from './components/Breadcrumb';
// import PrintHost from '../PrintHost/PrintHost';
import DezyIcon from 'components/images/dezy-logo-only.svg';
import './styles.less';

const { Content, Header } = Layout;

class AppWrapper extends React.Component {
  componentDidMount() {
    this.mounted = true;
  }

  render() {
    const { t, role } = this.props;
    const isAuthenticated = Auth.isUserAuthenticated();
    let menu = {};
    switch (role) {
      case 'admin': 
        menu = {
          '/': t('shareable.restaurants', { defaultValue: 'Restaurants' }),
          '/branches': t('shareable.vanues', { defaultValue: 'Venues' }),
          '/products': t('shareable.products', { defaultValue: 'Products' }),
          '/orders': t('shareable.orders', { defaultValue: 'Orders' }),
          '/employees': t('shareable.employees', { defaultValue: 'Employees' }),
          '/reports': t('shareable.reports', { defaultValue: 'Reports' }),
          '/alignRight': '',
          '/settings': t('shareable.settings', { defaultValue: 'Settings' }),
          '/logout':  t('shareable.logout', { defaultValue: 'Logout' })
        };
        break;
      case 'owner':
        menu = {
          '/': t('shareable.venues', { defaultValue: 'Venues' }),
          '/products': t('shareable.products', { defaultValue: 'Products' }),
          '/orders': t('shareable.orders', { defaultValue: 'Orders' }),
          '/employees': t('shareable.employees', { defaultValue: 'Employees' }),
          '/reports': t('shareable.reports', { defaultValue: 'Reports' }),
          '/alignRight': '',
          '/settings': t('shareable.settings', { defaultValue: 'Settings' }),
          '/logout':  t('shareable.logout', { defaultValue: 'Logout' })
        };
        break;
      case 'waiter':
        menu = {
          '/': t('shareable.orders', { defaultValue: 'Orders' }),
          '/alignRight': '',
          '/logout':  t('shareable.logout', { defaultValue: 'Logout' })
        };
        break;
      case 'manager':
        menu = {
          '/': t('shareable.orders', { defaultValue: 'Orders' }),
          '/manager/menu': t('shareable.menu', { defaultValue: 'Menu' }),
          '/manager/reports': t('shareable.reports', { defaultValue: 'Reports' }),
          '/manager/location': t('shareable.myLocation', { defaultValue: 'My Location' }),
          '/employees': t('shareable.employees', { defaultValue: 'Employees' }),
          '/alignRight': '',
          '/logout':  t('shareable.logout', { defaultValue: 'Logout' })
        };
        break;
      case 'kitchen':
        menu = {
          '/': t('shareable.orders', { defaultValue: 'Orders' }),
          '/alignRight': '',
          '/logout':  t('shareable.logout', { defaultValue: 'Logout' })
        };
        break;
      default:
        menu = {
          '/': t('shareable.main', { defaultValue: 'Main' }),
          '/alignRight': '',
          '/logout':  t('shareable.logout', { defaultValue: 'Logout' })
        };
        break; 
    }
    
    const containerStyle = {
      width: this.props.windowSize.windowWidth,
      height: this.props.windowSize.windowHeight,
      minHeight: this.props.windowSize.windowHeight
    };

    return (
      <div>
    	{ isAuthenticated ? 
    		<Layout className="main-layout" style={containerStyle}>
      		<Header className="header">
            <Link to="/" className="header__logo">
              <img src={DezyIcon} alt="Logo" style={{width: '38px'}} />
            </Link>
            <MenuContainer routes={menu} />
          </Header>
          <Content className="layoutApp">
            { this.props.children }
          </Content>
      </Layout> 
      : <Redirect to='/login' /> }
      </div>
    )
  }
};

export default withTranslation()(withWindowSizeListener(AppWrapper));