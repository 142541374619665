import React from 'react';
import { Input, Button, Form, Row, Spin, Alert } from 'antd';
import { Link } from 'react-router-dom';

import fetchJSON from 'services/utils/fetchJSON';
import { withTranslation, Trans } from 'react-i18next';

import '../styles.less';
const FormItem = Form.Item;

class SendEmailConfirmation extends React.Component {
	formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      serverMessage: '',
      serverMessageType: ''
    };
  }
  componentDidMount() {
  	this.mounted = true;
  }
  componentWillUnmount = () => {
    this.mounted = false;
  }
  handleSubmit = async (values) => {
    const { t } = this.props;
    if (!values.email) return false;
    return new Promise((resolve, reject) => {
      this.setState({
        isFetching: true
      })
      fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/auth/sendResetPass', {
        method: 'POST',
        body: {
          email: values.email
        }
      }).then(response => {
        if (response.success) {
          this.setState({
            isFetching: false,
            serverMessage: response.message,
            serverMessageType: 'success'
          })
        this.props.history.push(`/login?resetemail=success`)
        resolve();
        }
        else { 
          this.setState({
            isFetching: false,
            serverMessage: response.message,
            serverMessageType: 'error'
          })
         }
        setTimeout(() => {
          if (this.mounted) {
            this.setState({
              serverMessage: ''
            });
          }
        }, 3000); 
      }).catch(error => {
      	console.log('error', error);
      	if (this.mounted) {
	        this.setState({
	          isFetching: false,
	          serverMessage: t('shareable.tryAgainPlease', { defaultValue: 'Please try again' }),
	          serverMessageType: 'error'
	        }, () => {
		        setTimeout(() => {       
	            this.setState({
	              serverMessage: ''
	            });
		        }, 3000);  	
	        })
      	}
        reject();
      })
    }).catch(() => console.log('Oops errors!'));
  }
  render() {
    const { t } = this.props;
    const {
      isFetching,
      serverMessage,
      serverMessageType
    } = this.state;

    return (
      <div>
      	<Spin spinning = { isFetching }>
        <div style={{marginRight: '40px'}}>
          <img className="layoutLogin__logo-img" src={this.props.logo} alt="Logo" />
        </div>
        <div>
          <h2>
            <Trans i18nKey="resetpass.header.forgotPass">Forgot your password?</Trans> 
          </h2>
        </div>
          <Form ref={this.formRef} onFinish={this.handleSubmit}>
            { serverMessage ? 
              <Row>
                <FormItem>
                    <Alert className="alert-animation" message={serverMessage} type={ serverMessageType } />
                 </FormItem>
              </Row> : null }
            <Row className="layoutLogin__input-container">
              <p style={{marginBottom: '4px'}}>
                <Trans i18nKey="resetpass.description.resetPassTellEmail">We'll help you reset it. Please tell us your email address.</Trans>
              </p>
              <FormItem
                name="email"
                rules={[{required: true, message:  t('shareable.enterYourEmailPlease', { defaultValue: 'Please enter your email!' })}, 
                {type: 'email', message: t('shareable.invalidEmail', { defaultValue: 'Email is not valid' }), validateTrigger: 'onSubmit'}]}
              >
                <Input className="layoutLogin__input" placeholder={t('shareable.email', { defaultValue: 'Email' })}/>
              </FormItem>  
            </Row>
            <FormItem>
              <Row align="center">
                <Button className="layoutOnboarding__submit-button" style={{width: '140px'}} type="primary" shape="round" htmlType="submit">
                  { t('shareable.resetPassword', { defaultValue: 'Reset password' }) }
                </Button>
              </Row>
            </FormItem> 
        </Form>
        <Row>
          <Link className="layoutLogin__link" to='/login'>
            { t('resetpass.link.back', { defaultValue: 'Go back' }) }
          </Link>
        </Row>
      </Spin>
    </div>
    );
  }
}

export default withTranslation()(SendEmailConfirmation);