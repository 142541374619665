import React from 'react';
import { withTranslation } from 'react-i18next';
import { Modal, Form, Radio, Button, Input, Spin } from 'antd';
import { withWindowSizeListener } from 'react-window-size-listener';

const FormItem = Form.Item;

class Receipt extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      putREQUEST: false,
      receipt_option: null,
    };
  }
  componentDidMount = () => {
    this.mounted = true;
  }
  componentWillUnmount = () => {
    this.mounted = false;
  }

  handleSubmit = () => {
    return this.formRef.current.validateFields()
    .then((values) => {
      console.log('values', values);
      const { customers_email, receipt } = values;
      this.setState({
        putREQUEST: true
      });
      if (receipt === 'print_receipt') {
        this.props.printReceipt();
      }
      if (receipt === 'email_receipt') {
        this.props.sendEmailReceiptNow(customers_email);
      }
      
      setTimeout(() => {
        this.props.handleClose();
      }, 1000);

    }).catch((err) => console.log('validate err', err))
  }
  
  receiptOption = (e) => {
    this.setState({
      receipt_option: e.target.value
    });
    //this.handleSubmit();
  }
  
  render() {
    const { 
      putREQUEST,
      receipt_option,
    } = this.state;

    const { windowWidth, windowHeight } = this.props.windowSize;
    const containerStyle = {
      width: windowWidth,
      height: this.props.windowSize.windowHeight,
      minHeight: this.props.windowSize.windowHeight
    };
    const formCss = {
      width: windowWidth < 768 && windowWidth,
      height: windowWidth < 768 && `calc(${windowHeight}px - 60px)`,
      minHeight: windowWidth < 768 && `calc(${windowHeight}px - 60px)`
    };

    return (
      <Modal 
        className="general-modal-btns-center general-card createModalOrder createModalOrder--noCart"
        title=""
        centered={true}
        visible={this.props.visible}
        footer={null} 
        onCancel={this.props.handleClose}
        destroyOnClose={true}
        style={ windowWidth < 769 ? containerStyle : {} }
      >
        <React.Fragment>
          <Spin spinning={putREQUEST}>
             <Form 
                requiredMark={false} 
                className="general-form" 
                layout="vertical" 
                ref={this.formRef}
                onFinish={this.handleSubmit}
                initialValues={{
                  receipt: receipt_option,
                }}
                style={formCss}
              > 
                <div>
                  <h2 className="orderTypeRadio__title">Receipt</h2>
                  <FormItem rules={[{
                    required: true,
                    message: 'Payment type is required!'
                  }]} name="receipt" label="">
                    <Radio.Group
                      className="orderTypeRadio"
                      size="large"
                      buttonStyle="solid"
                      onChange={this.receiptOption}
                      >
                      <Radio.Button value="print_receipt">Print receipt</Radio.Button>
                      <Radio.Button value="email_receipt">Email receipt</Radio.Button>
                    </Radio.Group>
                  </FormItem>
                  <FormItem rules={[{
                    required: receipt_option === 'email_receipt',
                    message: 'The email is required!'
                  }]} name="customers_email" type="email" label="Customer's email" style={{display: receipt_option !== 'email_receipt' ? 'none' : ''}}>
                    <Input placeholder="Email"/>
                  </FormItem>
                  <Button className="createModalOrder__submit" type="primary" size="large" htmlType="submit" style={{ width: '100%' }}>Submit</Button>
                </div>
                
              </Form>
          </Spin>
          </React.Fragment>
      </Modal>
    )
  }
};

export default withTranslation()(withWindowSizeListener(Receipt));
