/*
 * API for communicating with the server via socket.io
 * (both proactive and reactive)
 */
import io from 'socket.io-client';
// import _ from 'lodash';
import Auth from 'services/utils/Auth';
import { getUniqueKey } from 'services/utils/Analytics';
import { v4 as uuidv4 } from 'uuid';
import {
  notification,
} from 'antd';
// import static env
import Settings from 'services/config/Settings';


class Socket {
  constructor() {
    console.log('SOCKET START');
    this.tabId = uuidv4();
    this.ePosDev = null;
    try {
      this.ePosDev = new epson.ePOSDevice(); // eslint-disable-line
    } catch (err) {
      // err
    }

    this.socket = io(Settings.api.socketUrl, {
      'query': `token=${Auth.getToken()}&UserKey=${getUniqueKey()}&tabId=${this.tabId}`,
      'timeout': '5000'
    });
    this.id = this.socket.id;
    this.socket.on('connect', () => {
      console.log(`Connected to socket.io with id ${this.socket.id}`);
      if (this.ePosDev) {
        this.subscribeToSocket();
      }
    });
    this.socket.on('unauthorized', (error) => {
      console.log('unauthorized', error);
      if (error.data.type === 'UnauthorizedError' || error.data.code === 'invalid_token') {
        // redirect user to login page perhaps?
        console.log('User token has expired');
      }
    });
    this.socket.on('disconnect', () => {
      this.unsubscribeFromSocket();
    })
    this.socket.on('error', (err) => {
      console.log('error', err);
    });
    this.hostUuid = null;
    this.printers = {};
  }
  emit(k, v) {
    this.socket.emit(k, v);
  }
  unsubscribeFromSocket = () => {
    if (this.hostUuid) {
      this.socket.off(`printer_host_${this.hostUuid}`, this.receiveData);
      this.socket.off(`printer_host_${this.hostUuid}_ping`, this.pingRecevier);
      this.socket.off(`printer_host_${this.hostUuid}_reload`, this.reloadPage);
      this.socket.off(`create_connection_to_printer_${this.hostUuid}`, this.startConnection);
      if (this.printers && Object.keys(this.printers).length) {
        Object.keys(this.printers).forEach((printerUuid) => {
          this.socket.off(this.printers[printerUuid].event_name, this.printJob);
        })
      }
    }
  }
  youAreHost = (driver) => {
    console.log('youAreHost', driver);
    notification.success({
      message: 'You are host for printer now',
    });
    // window.onbeforeunload = confirmExit;
    // function confirmExit() {
    //   return "You have attempted to leave this page. Are you sure?";
    // }
    // var div = document.createElement('div');
    // div.className = 'printerHostActive';
    // div.innerHTML = '<span>Host Printer</span>';

    // document.body.appendChild(div);
  }
  subscribeToSocket = () => {  
    console.log('PRINT HOST'); 
    this.socket.emit('register_as_print_host', {}, (data) => {
      const {
        hostUuid,
        driversList
      } = data;

      console.log('host uuid', data) //this.tabId
      this.hostUuid = hostUuid;
      this.socket.on(`printer_host_${hostUuid}`, this.receiveData);
      this.socket.on(`printer_host_${hostUuid}_reload`, this.reloadPage);
      this.socket.on(`create_connection_to_printer_${hostUuid}`, this.startConnection);
      this.socket.on(`printer_host_${hostUuid}_ping`, this.pingRecevier);
      if (driversList && Array.isArray(driversList) && driversList.length) {
        driversList.forEach((driver) => {
          if (driver.host_tab === this.tabId) {
            const event_name = `printer_host_${hostUuid}_${driver.printer_uuid}_job`;
            this.printers[driver.printer_uuid] = {
              event_name
            };
            this.youAreHost(driver);
            this.socket.on(event_name, this.printJob);
          }
          
        })
      }
    });
  }
  reloadPage = () => {
    window.location.reload(false); 
  }
  pingRecevier = () => {
    this.socket.emit('printer_host_pong');
  }
  startConnection = (data, callback) => {
    console.log('startConnection', data);
    const { printer } = data;
    const {
      ip_address,
    } = printer;
    
    if (process.env.NODE_ENV === 'development') {
      setTimeout(() => {
        console.log('emit back')
        // here we need printer setup
        const event_name = `printer_host_${this.hostUuid}_${printer.uuid}_job`;
        this.printers[printer.uuid] = {
          event_name
        };
        console.log('event_name', event_name);
        this.youAreHost();
        this.socket.on(event_name, this.printJob);

        callback({
          code: 200,
          printer: printer
        });
        
      }, 5000);
    } else {
      const ePosDev = new epson.ePOSDevice(); // eslint-disable-line
      console.log('SEND CONNECT REQUEST TO THE PRINTER');
      
      const cbConnect = (resultConnect) => {
        console.log('resultConnect', resultConnect, 'length', resultConnect.length, 'printer', printer);
        if ((resultConnect === 'OK') || (resultConnect.startsWith('SSL_CONNECT_OK'))) {
          console.log('THATS OK NOW, NEXT')
          const event_name = `printer_host_${this.hostUuid}_${printer.uuid}_job`;
          console.log('event_name', event_name);

          this.printers[printer.uuid] = {
            event_name
          };
          this.youAreHost();
          this.socket.on(event_name, this.printJob);

          callback({
            code: 200,
            printer: printer
          });
        } else {
          notification.error({
            message: `Printer ${ip_address} is not responding, reload it. Status: ${resultConnect}`,
          });
          callback({
            code: 400,
            printer: printer
          });
          // notification.error({
          //   message: resultConnect,
          // });
        }
      }
      ePosDev.connect(ip_address, 8043, cbConnect);
    }
  }
  receiveData = (data) => {
    console.log('receiveData', data);
  }
  printJob = (data) => {
    return Promise.resolve(data)
    .then((data) => {
      console.log('printJob 1', data);

      const {
        ip_address,
        device_id,
        settings,
        printer_uuid
      } = data;

      console.log('printer_uuid', printer_uuid, 'this.printers', this.printers);

      if (!this.printers.hasOwnProperty(printer_uuid)) {
        this.printers = {};
      }

      this.printers[printer_uuid].printer = null;

      const {
        toPrint
      } = settings;

      let testReceipt = '';
      toPrint.filter((item) => item[0] === 'addText').forEach((item) => {
        testReceipt += item[1];
      });

      console.log('toPrint', testReceipt);

      if (process.env.NODE_ENV === 'development') return;

      const ePosDev = new epson.ePOSDevice(); // eslint-disable-line

      return new Promise((resolve, reject) => {
        try {
          ePosDev.connect(ip_address, 8043, (resultConnect) => {
            resolve(resultConnect);
          });
        } catch (err) {
          console.log('err', err);
          reject();
        }
      })
      .then((resultConnect) => {
        console.log('resultConnect', resultConnect);
        if ((resultConnect === 'OK') || (resultConnect === 'SSL_CONNECT_OK')) {
          return new Promise((resolve, reject) => {
            ePosDev.createDevice(device_id, ePosDev.DEVICE_TYPE_PRINTER, {'crypto' : true, 'buffer' : false}, (devobj, retcode) => {
              resolve({ devobj, retcode });
            });
          })
        } else {
          // notification.error({
          //   message: resultConnect,
          // });
        }
        throw new Error('bad connection');
      })
      .then(({ devobj, retcode }) => {
        if( retcode === 'OK' ) {
          this.printers[printer_uuid].printer = devobj;
          return devobj;
        } else {
          // notification.error({
          //   message: retcode,
          // });
          // alert(retcode);
        }
        return null;
      })
      .then((printer) => {
        if (printer) {
          console.log('printer', printer);
          console.log('executeAddedCode now');

          toPrint.forEach((item) => {
            const key = item[0];
            const value = item[1];

            if (['addTextAlign'].indexOf(key) === -1) {
              printer[key](value);
            } else if (['addTextAlign', 'addTextFont'].indexOf(key) > -1) {
              printer[key](printer[value]);
            } else if (key === 'addPulse') {
              printer.addPulse(printer.DRAWER_1, printer.PULSE_500);
            } else if (['addTextDouble', 'addTextSize'].indexOf(key) > -1) {
              printer[key](printer[value], printer[value]);
            }
          });

          printer.addCut(printer.CUT_FEED);
          printer.send();
          console.log('SENT TO PRINTER', printer);

        }
      })
    })
    .catch((error) => {
      console.log('error', error);
    })
  }
}

const handle = new Socket();

export default handle.socket;
