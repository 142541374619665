import React from 'react';
import fetchJSON from 'services/utils/fetchJSON';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { Row, Modal, Form, Col, message, Checkbox, TimePicker, notification } from 'antd';

const FormItem = Form.Item;

class ScheduleSettings extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      putREQUEST: false,
      timeRange: []
    };
  }
	componentDidMount = () => {
    this.mounted = true;
    const { menuItem } = this.props;
    const { schedule } = menuItem;
    if (schedule && schedule.timeRange && schedule.timeRange.length) {
      this.setState({
        timeRange: schedule.timeRange
      })
    }
    
  }
  componentWillUnmount = () => {
    this.mounted = false;
  }
  handleSubmit = () => {
    const { timeRange } = this.state;

    const { t, menuId, branchUuid } = this.props;
    return this.formRef.current.validateFields()
    .then((values) => {
      console.log('values', values);

      values.menuId = menuId;
      values.branchUuid = branchUuid;
      values.timeRange = timeRange;

      this.setState({
        putREQUEST: true
      });
      fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/menus/setScheduleMenu', {
        method: 'post',
        body: values
      }).then(response => {
        console.log(response);
        if (response.success) {
          this.setState({
            // putREQUEST: false,
            putSUCCESS: true,
            putFAILURE: false
          }, () => {
            setTimeout(() => {
              message.success(t('singleLocation.feedback.saveSuccess', { defaultValue: 'Saved successfully' }));
              this.props.handleClose();
              this.props.getMenusList();
            }, 500);
          });          
        } else {
          this.setState({
            putREQUEST: false,
            putFAILURE: true,
            putSUCCESS: false,
            //errorMessage: response.message
          });
          notification.error({
            message: response.message,
          })
        }
       }).catch(error => {
          console.log(error);
          this.setState({
            putSUCCESS: false,
            putREQUEST: false,
            putFAILURE: true,
          });
          message.error(t('shareable.ERR', { defaultValue: 'ERR' }));
       });
    }).catch((err) => console.log('validate err', err))
  }
  timeChange = (_, timeString) => {
    if (timeString && Array.isArray(timeString)) {
      const value = [...timeString];
      this.setState({
        timeRange: value
      });
      return;
    }

    this.setState({
      timeRange: []
    })
  }
  readTime = (timeRange) => {
    if (Array.isArray(timeRange) && timeRange.length === 2) {
      return [moment(timeRange[0], 'hh:mm A'), moment(timeRange[1], 'hh:mm A')]
    }
    return [];
  }
  render() {
    const { t, menuItem } = this.props;
    const { 
      putREQUEST
    } = this.state;
    const { schedule } = menuItem;

    const optionsCheckbox = [
      { label: 'Mon', value: 'Mon' },
      { label: 'Tue', value: 'Tue' },
      { label: 'Wed', value: 'Wed' },
      { label: 'Thu', value: 'Thu' },
      { label: 'Fri', value: 'Fri' },
      { label: 'Sat', value: 'Sat' },
      { label: 'Sun', value: 'Sun' }
    ];


    return (
      <Modal 
        className="general-modal-btns-center general-card"
        title="Menu schedule"
        centered={true}
        visible={true}
        okButtonProps={{htmlType: "submit"}}
        okText="Update"
        onOk={() => this.handleSubmit()}
        onCancel={this.props.handleClose}
        destroyOnClose={true}
        confirmLoading={putREQUEST}
        maskClosable={false}
      >
        <Form 
          requiredMark={false} 
          className="general-form" 
          layout="vertical" 
          ref={this.formRef}
          initialValues={{
            days: schedule && schedule.days ? schedule.days : [],
            time: schedule && schedule.timeRange ? this.readTime(schedule.timeRange) : [],
          }}
        >
          <Row>
            <Col span={24}>
              <FormItem rules={[{
                required: true,
                message: t('shareable.required.name', { defaultValue: 'Select days!' }) }]}
                name="days"
                label="Day of week">
                <Checkbox.Group options={optionsCheckbox} />
              </FormItem>
              <FormItem rules={[{
                required: true,
                message: t('shareable.required.name', { defaultValue: 'Select a time range!' }) }]}
                name="time"
                label="Time range">

                <TimePicker.RangePicker
                onChange={this.timeChange}
                use12Hours={true}
                minuteStep={15}
                format="hh:mm A"

                />
              </FormItem>
            </Col>
          </Row>
        </Form> 
      </Modal>
    )
  }
};

export default withTranslation()(ScheduleSettings);