import React from 'react';
import _ from 'lodash';
import Settings from 'services/config/Settings';
import { Table, Layout, Input, Row, Tag, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import fetchJSON from 'services/utils/fetchJSON';

import { withTranslation, Trans } from 'react-i18next';
import { withWindowSizeListener } from 'react-window-size-listener';

import './styles.less';

const { Content } = Layout;
const Search = Input.Search;

class Restaurants extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredInfo: {},
      sortedInfo: {},
      searchText: '',
      filtered: false,
      result: [],
      data: [],
      totalNumberTable: 0,
      countRestaurants: 0,
      scrollTable: false
    };
    this.totalNumberTable = 0;
    this.totalNumberInterval = null;
    // for detecting if scroll is enabled
    this.scrollTableInterval = null;
    this.tableScrollChecked = false;
  }
  componentDidMount() {
    this.mounted = true;
    const { t } = this.props;
    document.title = `${t('shareable.restaurants', { defaultValue: 'Restaurants' })} | ${Settings.title}`;
    this.setState({
      pagination: {
        total: 0,
        pageSizeOptions: [10, 30, 50, 100],
        showSizeChanger: false,
        showTotal: (total) => {
          return <p id="totalNumber" style={{display: 'none'}}>{this.renderTotal(total)}</p>
        }
      }
    })
    this.scrollTableInterval = setInterval(this.checkIfScrollOn, 100);
    this.getRestaurants();
  }
  componentWillUnmount() {
    this.mounted = false;
    clearInterval(this.totalNumberInterval);
    clearInterval(this.scrollTableInterval);
  }
  checkIfScrollOn = () => {
    const { isFetching } = this.state;
    const tableContent = document.getElementsByClassName("ant-table-content");
    if (tableContent && tableContent.length && !isFetching) {
      if (this.checkTableScroll(tableContent[0])) {
        this.setState({
          scrollTable: true
        })
      }  else {
        this.setState({
          scrollTable: false
        })
      }
      clearInterval(this.scrollTableInterval);
    }
  }
  checkTableScroll = (e) => {
    const tableWrapper = document.getElementsByClassName('ant-table-wrapper generalTable');
    this.tableScrollChecked = true;
    if (tableWrapper && tableWrapper[0] && e) {
      return (tableWrapper[0].offsetWidth < e.scrollWidth)
    }
    return false
  }
  trackTotalNumberValue = () => {
    clearInterval(this.totalNumberInterval);
    const { totalNumberTable } = this.state;
    if (totalNumberTable !== this.totalNumberTable) {
      this.setState({
        totalNumberTable: this.totalNumberTable
      });
    }
    this.totalNumberInterval = null;
  }
  renderTotal = (total) => {
   this.totalNumberTable = total;
   const { totalNumberTable } = this.state;
   if (totalNumberTable !== this.totalNumberTable && !this.totalNumberInterval) {
    this.totalNumberInterval = setInterval(this.trackTotalNumberValue, 100);
   }
   return total;
  }
  getRestaurants = () => {
    const countryFilters = [];
    const countryStorage = [];
    const categoriesFilters = [];
    const categoriesStorage = [];

    this.setState({
      isFetching: true
    });
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/restaurants/list', {
      method: 'get'
    }).then(response => {
      if (this.mounted) {
        if (response.data && response.data.length) {
          this.setState({
            countRestaurants: response.data.length,
          });
          response.data.forEach((restaurant) => {
            if (restaurant.address_components && restaurant.address_components.country) {
              if (countryStorage.indexOf(restaurant.address_components.country) === -1) {
                countryStorage.push(restaurant.address_components.country);
                countryFilters.push({
                  text: restaurant.address_components.country, value: restaurant.address_components.country
                })
              }
            }
            if (restaurant.category && restaurant.category.length) {
              restaurant.category.forEach((relation) => {
                const category = _.get(relation, 'category.display_name');
                if (category && categoriesStorage.indexOf(category) === -1) {
                  categoriesStorage.push(category);
                  categoriesFilters.push({ text: category, value: category })
                }
              })
            }
          })
          this.setState({
            isFetching: false,
            result: response.data,
            countryFilters,
            categoriesFilters
          })
        } else {
          this.setState({ isFetching: false });
        }
      }
    }).catch(error => {
      console.log(error);
      if (this.mounted) {
        this.setState({
          isFetching: false
        });
      }
    });
  }

  isEllipsisActive = (e) => {
    if (e) {
      return (e.offsetWidth < e.scrollWidth)
    }
    else {
      return false
    }
  }
  columns = () => {
    const { t } = this.props;
    let {
      sortedInfo,
      filteredInfo,
      countryFilters,
      categoriesFilters
    } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    countryFilters = countryFilters || [];
    categoriesFilters = categoriesFilters || [];

    let titleItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            { t('shareable.name', { defaultValue: 'Name' })}
          </div>
          )
      },
      dataIndex: 'title',
      key: 'title',
      width: 157,
      sorter: (a, b) => a.title > b.title ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'title' && sortedInfo.order,
      render: (text, record) => (
        <div>
          <Tooltip title={this.isEllipsisActive(document.getElementById(`venueTitleText_${record.key}}`)) ? record.title : ''}>
            <Link to={`/restaurants/${record.uuid}`}>
              <p id={`restaurantTitleText_${record.key}}`} style={{margin:0, width: '140px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                {record.title}
              </p>
            </Link>
          </Tooltip>
        </div>
      )
    };
    const branches = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            { t('shareable.venues', { defaultValue: 'Venues' })}
          </div>
          )
      },
      dataIndex: 'branchesQuantity',
      key: 'branchesQuantity',
      width: 157,
      align: 'center',
      sorter: (a, b) => a.branchesQuantity > b.branchesQuantity ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'branchesQuantity' && sortedInfo.order
    };
    const addressItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            { t('shareable.address', { defaultValue: 'Address'})}
          </div>
          )
      },
      dataIndex: 'address',
      key: 'address',
      filters:  countryFilters,
      filteredValue: filteredInfo.address || null,
      onFilter: (value, record) => {
        return record.country.includes(value)
      },
      align: 'center',
      width: 170,
      render: (text, record) => (
        <div>
          <Tooltip title={this.isEllipsisActive(document.getElementById(`addressText_${record.key}}`)) ? record.address : ''}>
            <p id={`addressText_${record.key}}`} style={{margin:0, width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
              {record.address}
            </p>
          </Tooltip>
        </div>
      )
    };
    const menuItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            { t('shareable.menues', { defaultValue: 'Menues' })}
          </div>
          )
      },
      dataIndex: 'menu',
      key: 'menu',
      width: 157,
      align: 'center',
      sorter: (a, b) => a.menu > b.menu ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'menu' && sortedInfo.order
    };
    const productsItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            { t('shareable.products', { defaultValue: 'Products' }) }
          </div>
        )
      },
      dataIndex: 'products',
      key: 'products',
      align: 'center',
      width: 130,
      sorter: (a, b) => a.products > b.products ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'products' && sortedInfo.order,
    }
    const categoriesItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            { t('shareable.categories', { defaultValue: 'Categories' }) }
          </div>
        )
      },
      dataIndex: 'categoriesList',
      key: 'categoriesList',
      filters:  categoriesFilters,
      filteredValue: filteredInfo.categoriesList || null,
      onFilter: (value, record) => {
        return record.categoriesList.indexOf(value) > -1
      },
      align: 'center',
      width: 130,
      render: (text, record) => {
        if (!record.categoriesRender) return null;
        return (<span>{record.categoriesRender}</span>);
      },
    };
    const employeeItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            { t('shareable.employees', { defaultValue: 'Employees' }) }
          </div>
        )
      },
      dataIndex: 'employees',
      key: 'employees',
      align: 'center',
      width: 130,
      sorter: (a, b) => a.employees > b.employees ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'employees' && sortedInfo.order,
      render: (text, record) => {
        return (<span>0</span>)
      },
    };
    const revenueItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            { t('shareable.revenue', { defaultValue: 'Revenue' }) }
          </div>
        )
      },
      dataIndex: 'revenue',
      key: 'revenue',
      align: 'center',
      width: 130,
      sorter: (a, b) => a.revenue > b.revenue ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'revenue' && sortedInfo.order
    }
    if (this.state.scrollTable) {
      titleItem.fixed = "left";
    }

    const columns = [titleItem, branches, revenueItem, menuItem, productsItem, categoriesItem, addressItem, employeeItem];

    return columns;
  }
  getData = () => {
    const data = [];
    if (!this.state.result) return data;

    let restaurants = this.state.result;
    if (this.state.filtered) {
      restaurants =  this.state.data;
    }
    if (!Array.isArray(restaurants) || !restaurants.length) {
      return data;
    }
    restaurants.forEach((restaurant, i) => {
      let branchesQuantity = 0;
      let menuesQuantity = 0;
      if (restaurant.branch && restaurant.branch.length) {
        branchesQuantity = restaurant.branch.length;
        restaurant.branch.forEach((branch_relation) => {
          const branch = branch_relation.branch;
          if (branch && branch.menues && branch.menues.length > 0) {
            menuesQuantity += branch.menues.length;
          }
        })
      }
      const categoriesRender = [];
      const categoriesList = [];
      const tagColors = ['geekblue', 'cyan', 'magenta', 'purple'];
      if (restaurant.category && restaurant.category.length) {
        restaurant.category.forEach((relation, k) => {
          if (relation.category) {
            if (categoriesList.indexOf(relation.category.display_name) === -1) {
              if (categoriesList.length < 4) {
                categoriesRender.push(<Tag key={k} color={tagColors[categoriesList.length % tagColors.length]} style={{margin: '3px'}}>
                  {categoriesList.length === 3 ? '...' : relation.category.display_name}
                </Tag>)
              }
              categoriesList.push(relation.category.display_name)
            }
          }
        })
      }
      let revenue = '';
      if (typeof restaurant.revenue === 'object' && Object.keys(restaurant.revenue).length) {
        const revenueArr = [];
        Object.keys(restaurant.revenue).forEach((key) => {
          revenueArr.push(`${!!restaurant.revenue[key]?restaurant.revenue[key].toFixed(2):''} ${key}`);
        })
        revenue = revenueArr.join(', ');
      }
      data.push({
        key: i,
        id: restaurant.id,
        title: restaurant.name,
        uuid: restaurant.uuid,
        branchesQuantity,
        address: restaurant.address,
        country: restaurant.address_components ? restaurant.address_components.country : '',
        status: restaurant.status,
        categoriesRender,
        categoriesList,
        revenue,
        menu: menuesQuantity,
        products: restaurant.products
      })
    });
    return data;
  }

  handleTableChange = (pagination, filters, sorter) => {
    //console.log('handleTableChange', pagination, filters, sorter);
    setTimeout(() => {
      if (!document.getElementById("totalNumber")) {
        this.setState({
          totalNumberTable: 0
        })
      }
    }, 100)
    // used for tooltips in cells (because it searchs for html element)
    setTimeout(() => {
      const { pagination } = this.state;
      this.setState({
        pagination: {
          ...pagination,
          update: !pagination.update
        }
      })
    }, 100)
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    })
  }
  onSearch = (e) => {
    this.setState({ searchText: e.target.value });
    const searchText = e.target.value;
    const reg = new RegExp(e.target.value, 'gi');
    this.setState({
      filtered: !!searchText,
      data: _.flatMap(this.state.result, record => {
        const nameMatch = record.name && record.name.length ? record.name.match(reg) : false;
        if (!nameMatch) {
          return null;
        }
        return {
          ...record,
          };
      }).filter(record => !!record),
    });
    if (e.target.value === ''){
      this.setState({
        data: this.state.result,
        filtered: null
      });
    }
  }
  modalVisible = (key, value) => {
    this.setState({ [key]: value });
  }
  render() {
    const { t } = this.props;
    const {
      searchText,
      isFetching,
      pagination,
      totalNumberTable
    } = this.state;
    const visiblleTable = !isFetching && !this.tableScrollChecked;
    return (
      <Content className="bg-transparent table-content">
        <Table
          style={{visibility: !visiblleTable ? '' : 'hidden'}}
          columns={ this.columns() }
          loading={ isFetching }
          dataSource={ isFetching ? null : this.getData() }
          pagination={{
            ...pagination,
            showSizeChanger: totalNumberTable > 10 ? true : false,
            }
          }
          onChange={ this.handleTableChange }
          showSorterTooltip={false}
          size={"middle"}
          scroll={this.state.scrollTable ? {x: 'fit-content'} : false}
          className="generalTable"
          footer={() => {}}
          title={() =>
            <Row className="tableHeader__container">
              <div className="tableHeader__start-container">
                <p className="tableHeader__header-text">
                  <Trans i18nKey="shareable.restaurants">Restaurants</Trans>
                </p>
                <p className="tableHeader__quantity-text">
                  Quantity: {totalNumberTable}
                </p>
              </div>
              <div className="tableHeader__end-container">
                <Link className="ant-btn ant-btn-primary ant-btn-sm tableHeader__add-button" to="/restaurants/add">
                  <p><Trans i18nKey="shareable.addRestaurant">Add restaurant</Trans></p>
                </Link>
                 <Search allowClear className="tableHeader__search" size="middle" disabled={isFetching} ref={ele => this.searchText = ele} onChange={this.onSearch} placeholder={t('shareable.search', { defaultValue: 'Search' })} value={searchText}
                 onPressEnter={this.onSearch} />
              </div>
            </Row>
          }
        />
      </Content>
    )
  }
};

export default withTranslation()(withWindowSizeListener(Restaurants));
