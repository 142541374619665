import React from 'react';
import { Row, Col } from 'antd';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { withWindowSizeListener } from 'react-window-size-listener';
import ProductsTable from './components/ProductsTable';

import './styles.less';

class ProductsReport extends React.Component {

  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  render() {
    const products = _.get(this.props, `reportsData.productsByCount`);
    return (
      <div style={{width: '100%'}}>
        <Row className='reports-data-row first-row-fix'>
          <Col sm={24} xs={24}>
            <Row>
              <h2 className="header-margin-same-as-table">Products</h2>
            </Row>
              <ProductsTable
                products={products}
                shouldShowSorters={true}
                history={this.props.history}
              />
            <Row>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }
}

export default withTranslation()(withWindowSizeListener(ProductsReport));