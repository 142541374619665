import React from 'react';
import _ from 'lodash'
import { Input, Row, Col, Collapse, Form } from 'antd';
import {Map, Marker} from 'google-maps-react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import fetchJSON from 'services/utils/fetchJSON';
import countryByCurrencyCode from './components/country-by-currency-code.json';
import { Trans } from 'react-i18next';

import './styles.less';
const { Panel } = Collapse;

class AddressField extends React.Component {
  constructor(props) {
    super(props);

    const value = this.props.value || {};

    const { t } = this.props;
		var markers = [];
		if (value.coordinates) {
			markers.push({
				name: t('addressField.currentPosition', { defaultValue: 'Current position' }),
        position: value.coordinates
			})
		} 
    this.state = {
      address: value.address || '',
      addressComponents: value.addressComponents,
      coordinates: value.coordinates || { lat: '', lng: '' },
      correct: false,
      markers,
      timezone: value.timezone,
      place_id: value.place_id,
      currency_code: value.currency_code,
      opening_hours: value.opening_hours || {},
      country_code: value.country
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    // Should be a controlled component.
    if ('value' in nextProps) {
      const value = nextProps.value;
      this.setState(value);
    }
  }
  handleAddressChange = (address) => {
    if (!('value' in this.props)) {
      this.setState({ address, addressComponents: {}, coordinates: {}, correct: true });
    }

    if (!address) {
    	this.triggerChange({ address, addressComponents: {}, coordinates: {}, correct: false });
    } else {
    	this.triggerChange({ address, addressComponents: {}, coordinates: {}, correct: true });
    }    
  }
  fillInAddressComponents = (result) => {
  	let addressComponents = result.address_components;
  	const { place_id } = result;
  	let postal_code = _.find(addressComponents, function(ac) {
			return ac.types[0] === 'postal_code'
		})
		if (postal_code) {
			postal_code = postal_code.short_name;
		} else {
			postal_code = '';
		}
		let locality = _.find(addressComponents, function(ac) {
			return ac.types[0] === 'locality'
		})
		if (locality) {
			locality = locality.long_name;
		} else {
			locality = ''
		}
		let street_number = _.find(addressComponents, function(ac) {
			return ac.types[0] === 'street_number'
		});
		if (street_number) {
			street_number = street_number.short_name;
		} else {
			street_number = ''
		}
		let route = _.find(addressComponents, function(ac) {
			return ac.types[0] === 'route'
		});
		if (route) {
			route = route.short_name;
		} else {
			route = '';
		}
		let administrative_area_level_1 = _.find(addressComponents, function(ac) {
			return ac.types[0] === 'administrative_area_level_1'
		});
		if (administrative_area_level_1) {
			administrative_area_level_1 = administrative_area_level_1.long_name;
		} else {
			administrative_area_level_1 = '';
		}

		let country = _.find(addressComponents, function(ac) {
			return ac.types[0] === 'country'
		});
		let country_code = '';

		if (country) {
			country_code = country.short_name;
			country = country.long_name;
		} else {
			country = '';
		}

		var lat = result.geometry.location.lat();
		var lng = result.geometry.location.lng();
		const _addressComponents = {
			postal_code,
			locality,
			street_number,
			route,
			administrative_area_level_1,
			country
		};
		const coordinates = {
			lat,
			lng
		};
		let currency_code = '';
		if (country) {
			currency_code = this.getCurrencyCode(country);
		}
		
		const markers = [...this.state.markers];
		markers[0] = { ...markers[0], position: coordinates };
		this.setState({
			addressComponents: _addressComponents,
			coordinates,
			country_code,
			markers,
			place_id,
			currency_code,
			correct: true
		});

		if (place_id) {
			this.getOpeningHours(place_id);
		}

		this.triggerChange({
			formatted_address: result.formatted_address,
			addressComponents: _addressComponents,
			coordinates,
			country_code,
			place_id,
			currency_code,
			correct: true
		});
  }
  handleSelect = (address) => {
	  this.setState({ address })
	  this.triggerChange({ address });

	  geocodeByAddress(address)
		  .then(results => {
		  	if (results.length) {
		  		this.fillInAddressComponents(results[0]);
		  		if (results[0]) {
		  			var lat = results[0].geometry.location.lat();
						var lng = results[0].geometry.location.lng();
						this.getTimeZone(lat, lng);
		  		}
		  	}
		  })
		  .catch(error => console.error(error));
	}
	getCurrencyCode = (country) => {
		const currency_code = countryByCurrencyCode.filter((item) => {
			return item.country === country;
		});
		if (currency_code.length) {
			return currency_code[0].currency_code;
		}
		return 'USD';
	}
	getTimeZone = (lat, lng) => {		
		fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches/timezone', {
      method: 'post',
      body: {
      	lat,
      	lng
      }
    })
    .then(response => {
      //console.log(response);
      if (response.success) {
        //console.log('response', response.data);				
        this.setState({
        	timezone: response.data.timeZoneId
        }, () => {
        	this.triggerChange({
        		timezone: response.data.timeZoneId
        	});
        });
      }
     }).catch(error => {
        console.log(error);
     });
	}
	getOpeningHours = (place_id) => {
		fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches/placeApiData', {
      method: 'post',
      body: {
      	place_id
      }
    })
    .then(response => {
      // console.log(response);
      if (response.success) {
        const { opening_hours } = response.data;
        if (typeof opening_hours === 'object') {
        	if (Object.keys(opening_hours).indexOf('open_now') > -1) {
        		delete opening_hours.open_now;
        		opening_hours.can_use_google = true;
        	}
        }
        this.setState({
        	opening_hours: opening_hours || {}
        }, () => {
        	this.triggerChange({
        		opening_hours: opening_hours || {}
        	});
        });
      }
     }).catch(error => {
        console.log(error);
     });
	}
  handleCurrencyChange = (currency) => {
    if (!('value' in this.props)) {
      this.setState({ currency });
    }
    this.triggerChange({ currency });
  }
  triggerChange = (changedValue) => {
    // Should provide an event to pass value to Form.
    const onChange = this.props.onChange;
    if (onChange) {
      onChange(Object.assign({}, this.state, changedValue));
    } else {
    	const triggerChanges = this.props.triggerChanges;
	    if (triggerChanges) {
	      triggerChanges(Object.assign({}, this.state, changedValue));
	    }
    }
  }
  onError = (status, clearSuggestions) => {
  	console.log(status, this.props);
  	this.triggerChange({ addressComponents: {}, coordinates: {}, correct: false });
  }
  onMarkerDragEnd = (coord, index) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();

		const coordinates = {
			lat,
			lng
		};
		this.setState(prevState => {
      const markers = [...this.state.markers];
      markers[index] = { ...markers[index], position: { lat, lng } };
      return { markers, coordinates, correct: true };
    }, () => {
    	this.triggerChange({
				coordinates,
				correct: true
			});
    });
		console.log('onMarkerDragEnd', coordinates);
    this.getTimeZone(coordinates.lat, coordinates.lng);
  }
	getInput = (getInputProps) => {
		const { t } = this.props;
		// const additiveStyle = isDisabled ? {cursor: 'default !important', pointerEvents: 'none !important'} : {};
		return (<input {...getInputProps({
	      placeholder: t('addressField.searchPlaces', { defaultValue: 'Search Places ...' }),
	      className: 'ant-input'
	    })}
	  />)
	}
  render() {
  	const { t, wrapIntoFormItem, isDisabled } = this.props;
    const {
    	address,
    	coordinates,
    	timezone,
    	currency_code,
    	place_id,
    } = this.state;
    let addressComponents = this.state.addressComponents;
	  if (!addressComponents) {
	    addressComponents = {
	      street_number: '',
	      route: '',
	      locality: '',
	      administrative_area_level_1: '',
	      country: '',
	      postal_code: ''
	    };
	  }
	  const google = window.google;
	  const headerTitle = t('addressField.checkDetailsAndPosition', { defaultValue: 'Check the address details and position on the map' });
		const addressErrorMessage = t('shareable.enterSelectAddressPlease',{ defaultValue: 'Please, enter correct address and select right option from a dropdown list' });
    return (
      <Col span={24} >
        <Row>
        	<PlacesAutocomplete
		        value={address}
		        onChange={this.handleAddressChange}
		        onSelect={this.handleSelect}
		        onError={this.onError}
		      >
		        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
		          <div className="ant-row address-container">
		          	{ wrapIntoFormItem ? 
									<Form.Item
                    name="addressInput"
                    className={isDisabled ? 'disabled-form-input' : ''}
                    rules={[{
                    	required: true,
                    	message: addressErrorMessage,
                    }, ({ getFieldValue }) => ({
                    	validateTrigger: 'onSubmit',
					            validator(rule, value) {
					            	//. console.log('value', value, 'coordinates', coordinates);
					            	if (coordinates.lat) {
					            		return Promise.resolve();
					            	}
					              if (!value) return Promise.reject();
					              return Promise.reject(addressErrorMessage);
					            },
					          }) ]}
                    style={{ marginBottom: 0, width: '100%' }}>
										{this.getInput(getInputProps)}
									</Form.Item>
		          	: this.getInput(getInputProps) }
							  { suggestions && suggestions.length ?<div className="ant-select-dropdown address-select-dropdown">
							    {loading && <div className="ant-select-dropdown-menu-item"><Trans i18nKey="shareable.loading">Loading...</Trans></div>}
							    {suggestions.map((suggestion, index) => {
							    	suggestion.id = index;
							      const className = suggestion.active
							        ? 'ant-select-item ant-select-item-option ant-select-item-option-active'
							        : 'ant-select-item ant-select-item-option';
							      return (
							        <div
							          {...getSuggestionItemProps(suggestion, {
							            className
							          })}

							        >
							          <span>{suggestion.description}</span>
							        </div>
							      );
							    })}
							  </div> : null }
		          </div>
		        )}
		      </PlacesAutocomplete>
        </Row>
        <div className="address-collapse-block" style={{ display: address && address.length && !isDisabled ? 'block' : 'none' }}>
        <Collapse accordion  >
			    <Panel header={headerTitle} key="1">
	        	<Row gutter={8}>
							<Col xs={24} sm={this.props.isOnboarding ? 24 : 14}>
				        <Row gutter={8}>
				          <Col xs={24} sm={12}>
				            <Row>
				              <label className="form-label-custom"><Trans i18nKey="addressField.street_number">Street number</Trans></label>
				            </Row>
				            <Row >
				            	<Input value={addressComponents.street_number} readOnly={true} />
				            </Row>
				          </Col>
				          <Col xs={24} sm={12}>
				            <Row>
				              <label className="form-label-custom"><Trans i18nKey="addressField.route">Route</Trans></label>
				            </Row>
				            <Row >
				            	<Input value={addressComponents.route} readOnly={true} />
				            </Row>
				          </Col>
				        </Row>
				        <Row gutter={8}>
				          <Col xs={24} sm={12} style={{marginTop: '10px'}}>
				            <Row>
				              <label className="form-label-custom"><Trans i18nKey="addressField.city">City</Trans></label>
				            </Row>
				            <Row >
				            	<Input value={addressComponents.locality} readOnly={true} />
				            </Row>
				          </Col>
				          <Col xs={24} sm={12} style={{marginTop: '10px'}}>
				            <Row>
				              <label className="form-label-custom"><Trans i18nKey="addressField.regionState">Region/State</Trans></label>
				            </Row>
				            <Row >
				            	<Input value={addressComponents.administrative_area_level_1} readOnly={true} />
				            </Row>
				          </Col>
				        </Row>
				        <Row gutter={8}>
				          <Col xs={24} sm={12} style={{marginTop: '10px'}}>
				            <Row>
				              <label className="form-label-custom"><Trans i18nKey="addressField.zipcode">Zip code</Trans></label>
				            </Row>
				            <Row >
				            	<Input value={addressComponents.postal_code} readOnly={true} />
				            </Row>
				          </Col>
				          <Col xs={24} sm={12} style={{marginTop: '10px'}}>
				            <Row>
				              <label className="form-label-custom"><Trans i18nKey="addressField.country">Country</Trans></label>
				            </Row>
				            <Row >
				            	<Input value={addressComponents.country} readOnly={true} />
				            </Row>
				          </Col>
				        </Row>
				        { !this.props.isOnboarding ?
				        <Row gutter={8}>
				          <Col xs={24} sm={12} style={{marginTop: '10px'}}>
				            <Row>
				              <label className="form-label-custom"><Trans i18nKey="addressField.lat">Lat</Trans></label>
				            </Row>
				            <Row >
				            	<Input value={coordinates.lat} readOnly={true} />
				            </Row>
				          </Col>
				          <Col xs={24} sm={12} style={{marginTop: '10px'}}>
				            <Row>
				              <label className="form-label-custom"><Trans i18nKey="addressField.lng">Lng</Trans></label>
				            </Row>
				            <Row >
				            	<Input value={coordinates.lng} readOnly={true} />
				            </Row>
				          </Col>
				        </Row> : null }
				        { !this.props.isOnboarding ?
				        <Row gutter={8}>
				          <Col xs={24} sm={12} style={{marginTop: '10px'}}>
				            <Row>
				              <label className="form-label-custom"><Trans i18nKey="shareable.timezone">Timezone</Trans></label>
				            </Row>
				            <Row >
				            	<Input value={timezone ? timezone : ''} readOnly={true} />
				            </Row>
				          </Col>
				          <Col xs={24} sm={12} style={{marginTop: '10px'}}>
				            <Row>
				              <label className="form-label-custom"><Trans i18nKey="addressField.currency">Currency</Trans></label>
				            </Row>
				            <Row >
				            	<Input value={currency_code ? currency_code : ''} readOnly={true} />
				            </Row>
				          </Col>
				        </Row> : null }
				        { !this.props.isOnboarding ?
				        <Row gutter={8}>
				          <Col span={24} style={{marginTop: '10px'}}>
				            <Row>
				              <label className="form-label-custom">Google Places ID</label>
				            </Row>
				            <Row >
				            	<Input value={place_id ? place_id : ''} readOnly={true} />
				            </Row>
				          </Col>
				        </Row> : null }
			        </Col>
			        { !this.props.isOnboarding ?
			        <Col xs={0} sm={10}>
			        	<Row style={{'position': 'relative', 'padding': '20px'}}>
			        		<Col span={24}>
										<Map
											google={google}
							        style={{
							          width: "100%",
							          height: "350px"
							        }}
							        zoom={16}
							        initialCenter={coordinates}
							        center={coordinates}
							        streetViewControl={false}
	      							mapTypeId={google.maps.MapTypeId.ROADMAP}
									    mapTypeControl={false}
							      >
							        {this.state.markers.map((marker, index) => (
							          <Marker
							          	key={index}
							            position={marker.position}
							            draggable={true}
							            onDragend={(t, map, coord) => this.onMarkerDragEnd(coord, index)}
							            name={marker.name}
							          />
							        ))}
							      </Map>
						      </Col>
								</Row>
			        </Col> : null }
		        </Row>
			    </Panel>
			  </Collapse>
        </div>
      </Col>
    );
  }
}
export default AddressField;