import React from 'react';
import _ from 'lodash';
import fetchJSON from 'services/utils/fetchJSON';
import { withTranslation } from 'react-i18next';
import { Spin, Checkbox, Modal } from 'antd';
import { withWindowSizeListener } from 'react-window-size-listener';
import {  MinusOutlined, PlusOutlined } from '@ant-design/icons';
import formModifierPriceString from "../../../../../../../helpers/formModifierPriceString";
import {correctModifierSurcharges} from "../../../../../../../helpers/correctModifierSurcharges";

const confirm = Modal.confirm;


class SplitOrder extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      putREQUEST: false,
      cartList: {},
      guestIndex: 1,
      guestCartList: {},
      stage: 0,
      changedFields: 0,
      currentGuestFulfilled: 0,
      isFetching: false,
      haveReminderItems: false,
      reminderCartList: {}
    };
  }
  componentDidMount = () => {
    this.mounted = true;
    this.prepareCart();
  }
  prepareCart = () => {
    const { cartListArr } = this.props;

    const list = JSON.parse(JSON.stringify(cartListArr));
    const listByIDs = {};

    list.forEach((line) => {
      const { order_item_id } = line;
      listByIDs[order_item_id] = { ...line };
      listByIDs[order_item_id].taken = 0;
      listByIDs[order_item_id].is_taken = false;
      listByIDs[order_item_id].quantity_left = listByIDs[order_item_id].quantity;
    });

    this.setState({
      cartList: listByIDs,
      guestCartList: {},
      guestIndex: 1,
      stage: 0,
      changedFields: 0,
      currentGuestFulfilled: 0
    });
  }
  reset = () => {
    this.prepareCart();
  }
  componentWillUnmount = () => {
    this.mounted = false;
  }
  addGuest = () => {
    const {
      guestIndex
    } = this.state;

    const {
      cartList
    } = this.state;

    const copyCart = JSON.parse(JSON.stringify(cartList));
    if (copyCart && typeof copyCart === 'object' && Object.keys(copyCart).length) {
      Object.keys(copyCart).forEach((key) => {
        const line = copyCart[key];
        copyCart[key].quantity_left = line.quantity - line.taken;
      });
    }

    this.setState({
      guestIndex: guestIndex + 1,
      currentGuestFulfilled: 0,
      cartList: copyCart
    });
  }
  selectItem = (e, line) => {
    const operation = e.target.checked ? 'add' : 'remove';
    this.changeLineSelector(line, operation);
  }
  changeLineSelector = (line, operation = 'add') => {
     const {
      guestCartList,
      guestIndex,
      cartList,
      changedFields,
      currentGuestFulfilled
    } = this.state;

    let cgf = currentGuestFulfilled;

    let copyChangedFields = changedFields;
    const { order_item_id } = line;
    const copyCart = Object.assign({},cartList);
    const copyGuestCart =  Object.assign({},guestCartList);

    if (!copyGuestCart.hasOwnProperty(guestIndex)) {
      copyGuestCart[guestIndex] = {};
    }

    if (!copyGuestCart[guestIndex].hasOwnProperty(order_item_id)) {
      copyGuestCart[guestIndex][order_item_id] = {
        order_item_id: order_item_id,
        quantity: 0,
        item: line.item,
        modifiers: line.modifiers,
        totalQuantity: line.quantity
      };
    }

    if (operation === 'add') {
      copyCart[order_item_id].taken += 1;
      copyGuestCart[guestIndex][order_item_id].quantity += 1;
      copyChangedFields += 1;
      cgf += 1;
    } else {
      copyCart[order_item_id].taken -= 1;
      copyGuestCart[guestIndex][order_item_id].quantity -= 1;
      copyChangedFields -= 1;
      cgf -= 1;
    }

    if (!copyGuestCart[guestIndex][order_item_id].quantity) {
      delete copyGuestCart[guestIndex][order_item_id];
    }

    if (!Object.keys(copyGuestCart[guestIndex]).length) {
      delete copyGuestCart[guestIndex];
    }

    if (copyCart[order_item_id].taken === copyCart[order_item_id].quantity) {
      copyCart[order_item_id].is_taken = true;
    } else {
      copyCart[order_item_id].is_taken = false;
    }

    this.setState({
      guestCartList: copyGuestCart,
      cartList: copyCart,
      changedFields: copyChangedFields,
      currentGuestFulfilled: cgf
    }, this.checkSomethingLeft);
  }
  checkSomethingLeft = () => {
    //haveReminderItems
    const {
      cartList
    } = this.state;
    let haveReminderItems = false;
    const copyCart =  Object.assign({},cartList);

    const list = {};
    if (copyCart && typeof copyCart === 'object' && Object.keys(copyCart).length) {
      Object.keys(copyCart).forEach((key) => {
        const line = copyCart[key];

        if (line.is_taken) return null;
        const { order_item_id } = line;
        const quantity = line.quantity - line.taken;

        list[order_item_id] = {
          order_item_id,
          quantity,
          item: line.item,
          modifiers: line.modifiers,
        };
      });
      if (list && Object.keys(list).length) {
        haveReminderItems = true;
        this.setState({
          reminderCartList: list
        })
      }
    }
    return this.setState({
      haveReminderItems
    });
  }
  showReview = () => {
    this.setState({
      stage: 1
    })
  }
  countTotal = (list, surcharges) => {
    let total = 0;
    if (list && typeof list === 'object' && Object.keys(list).length) {
      Object.keys(list).forEach((key) => {
        const line = list[key];
        total += line.quantity * line.item.price;
        if (line.modifiers && line.modifiers.length) {
          line.modifiers.forEach(modifier => {
            total += modifier.price;
          })
        }
      });
    }
    total *= surcharges
    return `$${total.toFixed(2)}`;
  }
  renderRemainder = () => {
    const {
      reminderCartList
    } = this.state;

    if (reminderCartList && typeof reminderCartList === 'object' && Object.keys(reminderCartList).length) {
      return this.renderItemReview({
        title: 'Remaining order',
        list: reminderCartList
      })
    }
    return null;
  }

  renderItemReview = ({
    title,
    list
  }) => {
    const { surcharges } = this.props
    return (<React.Fragment>
      <div className="cartScreen__header" style={{
        textAlign: 'left',
        fontSize: '18px',
        paddingTop: 0,
        paddingBottom: '20px',
        fontWeight: 500
      }}>
        {title}
      </div>
       <div className="cartScreenList">
        { Object.keys(list).map((key, index) => {
          const line = list[key];
          return (<React.Fragment key={index}><div className="cartScreenItem">
            <div className="cartScreenItem__middle">
              <div className="cartScreenItem__title" style={{ fontWeight: 'normal' }}>{line.item.displayName}</div>
            </div>
            <div className="cartScreenItem__itemMiddle" style={{marginRight: '20px' }}>{line.quantity}</div>
            <div className="cartScreenItem__price" style={{
              fontSize: '20px',
              lineHeight: 'normal',
              fontWeight: 'normal'
            }}>
              ${(line.quantity * line.item.price * surcharges).toFixed(2)}
            </div>
          </div>
          {Array.isArray(line.modifiers) && line.modifiers.length ?
              line.modifiers.map((modifier) => <div className="cartScreenItem" key={modifier.id} style={{ paddingLeft: '35px' }}>
                <div className="cartScreenItem__middle">
                  <div className="cartScreenItem__title">{!!modifier.modifier_set ? modifier.modifier_set + ': ' : ''}{modifier.display_name}</div>
                </div>
                <div className="cartScreenItem__itemMiddle"></div>
                <div className="cartScreenItem__price">
                  {modifier.price ? `${formModifierPriceString(correctModifierSurcharges(modifier.price, surcharges))}` : ''}
                </div>
              </div>)
              : null}
        </React.Fragment>
        )}) }
        <div className="cartScreenItem" key='total'>
          <div className="cartScreenItem__middle">
            <div className="cartScreenItem__title" style={{ fontWeight: 'normal' }}>Total</div>
          </div>
          <div className="cartScreenItem__price" style={{
            fontSize: '20px',
            lineHeight: 'bold'
          }}>
            {this.countTotal(list, surcharges)}
          </div>
        </div>
      </div>
    </React.Fragment>)
  }
  changeProductCardQuantity = (line, operation) => {
    console.log('changeProductCardQuantity', line, operation);
    this.changeLineSelector(line, operation)
  }
  confirmSplit = () => {
    confirm({
      title: `Apply the changes with the current guest split?`,
      onOk: this.applySplit,
      onCancel() {},
    });
  }
  setIsFetching = (param) => {
    this.setState({
      isFetching: param
    });
  }
  applySplit = () => {
    const {
      branchUuid,
      orderUuid,
    } = this.props;
    const {
      guestCartList,
      haveReminderItems
    } = this.state;

    console.log('applySplit', haveReminderItems);
    this.setIsFetching(true);
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches-orders/split/', {
      method: 'POST',
      body: {
        branchUuid,
        orderUuid,
        guestCartList,
        haveReminderItems
      }
    }).then(response => {
      if (response.code === 200) {
        this.props.renderList();
        this.props.updateCurrentSplitOrders([...response.data, orderUuid], haveReminderItems);
        this.props.handleClose();
      }
      this.setIsFetching(false);
     }).catch(error => {
      console.log(error);
      this.setIsFetching(false);
     });
  }
  render() {
    const {
      cartList,
      guestIndex,
      guestCartList,
      stage,
      changedFields,
      currentGuestFulfilled,
      isFetching,
      haveReminderItems
    } = this.state;

    const {
      cartScreenCss,
      surcharges
    } = this.props;

    return (
      <React.Fragment>
        <Spin spinning={isFetching}>
           <div className="cartScreen" style={cartScreenCss}>
            <div className="cartScreen__header" style={{
              textAlign: 'center',
              fontSize: '20px',
              paddingTop: 0,
              paddingBottom: '20px'
            }}>
              Split order
            </div>

            { stage === 0 ? <React.Fragment>
              <div className="cartScreen__header" style={{
                textAlign: 'center',
                fontSize: '18px',
                paddingTop: 0,
                paddingBottom: '20px',
                fontWeight: 500
              }}>
                Select items of Guest #{guestIndex}:
              </div>

              <div className="cartScreenBody">
                <div style={{ width: '100%'}}>
                  <div className="cartScreenList">
                    { Object.keys(cartList).map((key, index) => {
                      const line = cartList[key];
                      console.log('cartListItem',line)

                      const quantity = _.get(guestCartList, `${guestIndex}.${line.order_item_id}.quantity`);

                      const is_taken = _.get(line, 'is_taken');

                      return (<div className="cartScreenItem" key={index} style={{
                        display: is_taken && !quantity ? 'none' : ''
                      }}>
                        <div className="cartScreenItem__middle">
                          <div className="cartScreenItem__title" style={{ fontWeight: 'normal' }}>{line.item.displayName}</div>
                        </div>
                        {
                          !quantity || line.quantity_left === 1 ?
                          <React.Fragment>
                            <div className="cartScreenItem__itemMiddle" style={{marginRight: '20px' }}>{line.quantity_left}</div>
                            <div className="cartScreenItem__itemMiddle">
                              <Checkbox onChange={(e) => this.selectItem(e, line)} checked={quantity} style={{zoom: '1.5', marginTop: '1px'}} />
                            </div>
                          </React.Fragment>
                          :
                          <div className="cartScreenItem__itemMiddle">
                            <div className="cartScreenItem__quantity" style={{marginTop: 0}}>
                              <button
                                className="cartScreenItem__quantityBtn cartScreenItem__quantityBtn--remove"
                                onClick={() => this.changeProductCardQuantity(line, 'remove')}
                                alt="reduce product quantity"
                                disabled={!line.quantity_left}
                                ><MinusOutlined /></button>
                              <div className="cartScreenItem__quantityNumber">{quantity} of {line.quantity_left}</div>
                              <button
                                className="cartScreenItem__quantityBtn cartScreenItem__quantityBtn--add"
                                onClick={() => this.changeProductCardQuantity(line, 'add')}
                                alt="increase product quantity"
                                disabled={quantity >= line.quantity_left}
                              ><PlusOutlined /></button>
                            </div>
                          </div>
                        }
                      </div>
                    )}) }
                  </div>
                </div>

              </div>

              <div className="cartScreenFooter">
                <React.Fragment>
                  <div className="cartScreenFooter__box" style={{flexDirection: 'column'}}>
                    <button
                      className="cartScreenFooter__checkout cartScreenFooter__checkout--reverse"
                      style={{marginBottom: '10px', width: '100%'}}
                      disabled={!currentGuestFulfilled || !haveReminderItems}
                      onClick={this.addGuest}>Next guest</button>
                    <button
                      className="cartScreenFooter__checkout"
                      onClick={() => this.showReview()}
                      style={{marginBottom: '20px', width: '100%'}}
                      disabled={!changedFields}
                      >
                      Apply split
                    </button>
                  </div>
                </React.Fragment>
              </div>
            </React.Fragment> : null }

            { stage === 1 ? <React.Fragment>
            <div className="cartScreenBody">
              <div style={{ width: '100%'}}>
                { Object.keys(guestCartList).map((guestId, i) => {
                  const list = guestCartList[guestId];
                  return (
                    <React.Fragment key={i}>
                      {this.renderItemReview({
                        title: `Guest #${guestId}:`,
                        list
                      })}
                    </React.Fragment>
                  )
                })}
                { haveReminderItems ? this.renderRemainder() : null }
                </div>
              </div>

              <div className="cartScreenFooter">
                <React.Fragment>
                  <div className="cartScreenFooter__box" style={{flexDirection: 'column'}}>
                    <button
                      className="cartScreenFooter__checkout cartScreenFooter__checkout--reverse"
                      style={{marginBottom: '10px', width: '100%'}}
                      onClick={this.reset}>Start over</button>
                    <button
                      className="cartScreenFooter__checkout cartScreenFooter__checkout--reverse"
                      onClick={() => this.confirmSplit()}
                      style={{marginBottom: '20px', width: '100%'}}
                      >
                      Pay Now
                    </button>
                  </div>
                </React.Fragment>
              </div>
            </React.Fragment> : null }

          </div>
        </Spin>
      </React.Fragment>
    )
  }
};

export default withTranslation()(withWindowSizeListener(SplitOrder));
