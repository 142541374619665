import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Menu } from 'antd';
import Icon from '@ant-design/icons';

const getMenuItems = ({ routes, match, location }) => {
  const menus = [];
  let defaultIndex = 0;
  Object.keys(routes).forEach((path, index) => {
    let active = false;
    if (location.pathname === '/' && path === '/') {
      active = true;
    } else {
      active = path === '/' ? false : location.pathname.match(path) !== null;
    }
    if (active) defaultIndex = index;
    menus.push({
      active: active,
      path: path,
      name: routes[path]
    })
  
  })
  return { items: menus, defaultIndex };
}
const customIcon = () => (
  <svg width="24" height="7" viewBox="0 0 24 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.1111 3.22223C15.1111 4.93778 13.7156 6.33334 12 6.33334C10.2844 6.33334 8.88889 4.93778 8.88889 3.22223C8.88889 1.50667 10.2844 0.111115 12 0.111115C13.7156 0.111115 15.1111 1.50667 15.1111 3.22223ZM13.3333 3.22223C13.3333 2.48889 12.7333 1.88889 12 1.88889C11.2667 1.88889 10.6667 2.48889 10.6667 3.22223C10.6667 3.95556 11.2667 4.55556 12 4.55556C12.7333 4.55556 13.3333 3.95556 13.3333 3.22223ZM6.22222 3.22223C6.22222 4.93778 4.82667 6.33334 3.11111 6.33334C1.39556 6.33334 0 4.93778 0 3.22223C0 1.50667 1.39556 0.111115 3.11111 0.111115C4.82667 0.111115 6.22222 1.50667 6.22222 3.22223ZM4.44444 3.22223C4.44444 2.48889 3.84444 1.88889 3.11111 1.88889C2.37778 1.88889 1.77778 2.48889 1.77778 3.22223C1.77778 3.95556 2.37778 4.55556 3.11111 4.55556C3.84444 4.55556 4.44444 3.95556 4.44444 3.22223ZM20.8889 6.33334C19.1733 6.33334 17.7778 4.93778 17.7778 3.22223C17.7778 1.50667 19.1733 0.111115 20.8889 0.111115C22.6044 0.111115 24 1.50667 24 3.22223C24 4.93778 22.6044 6.33334 20.8889 6.33334ZM22.2222 3.22223C22.2222 2.48889 21.6222 1.88889 20.8889 1.88889C20.1556 1.88889 19.5556 2.48889 19.5556 3.22223C19.5556 3.95556 20.1556 4.55556 20.8889 4.55556C21.6222 4.55556 22.2222 3.95556 22.2222 3.22223Z" fill="#E01491"/>
  </svg>
)
const Menus = ({ role, routes, match, location }) => {
  const menu = getMenuItems({ routes, match, location });
  const renderItems = () => menu.items.map((item, key) => {
    return <Menu.Item key={key} data-item-path={item.path}><Link to={item.path}>{item.name}</Link></Menu.Item>;
  });
  return (
    <Menu
      className="menuDezy"
      overflowedIndicator={<Icon component={customIcon} className="menu-icon" />}
      mode={'horizontal'}
      defaultSelectedKeys={[menu.defaultIndex.toString()]}
    >
      { renderItems() }
    </Menu>
  );
}
export const MenuContainer = withRouter(Menus);

