import React from 'react';
import fetchJSON from 'services/utils/fetchJSON';
import { withTranslation } from 'react-i18next';
import {
  Modal,
  Spin,
  Tag,
  Descriptions,
  Col,
  Row,
  notification,
  Steps
} from 'antd';
import { withWindowSizeListener } from 'react-window-size-listener';
import SmsAuthenticationPrompt from 'components/SmsAuthenticationPrompt/SmsAuthenticationPrompt';
import EditOrder from '../../Waiter/components/EditOrder';
import Receipt from './Receipt';
import { surchargesToString } from '../../../../../../../helpers/surchargesToString'
import formModifierPriceString from '../../../../../../../helpers/formModifierPriceString'
import {calculateSurcharges} from "../../../../../../../helpers/calculateSurcharges";
import {correctModifierSurcharges} from "../../../../../../../helpers/correctModifierSurcharges";

const confirm = Modal.confirm;
const { Step } = Steps;

require("moment-duration-format");

class SingleOrder extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      putREQUEST: false,
      payment_type: '',
      orderUuid: null,
      selectedCategory: null,
      cartListArr: [],
      fetchingProducts: false,
      menuId: null,
      paymentMethod: false,
      showList: false,
      checkoutOpen: true,
      visibleSmsAuthenticationPrompt: false,
      visibleEmailForm: false,
      smsAuthenticationAction: '',
      doAfterSmsAuthentication: () => {},
      selectedOrder: false,
      visibleEditOrder: false,
      visibleReceipt: false,
      order_surcharges: 1,
      payment_processing_by_client: 0,
      surcharges: 1,
      processing_fee: 0,
      discount_amount: 0,
      discount_comment: '',
      discount_value: 0,
      order_total: 0,
      order_discount_value: 0,
      order_discount_amount: 0,
      w_discount_value: 0,
      w_discount_amount: 0,
      surcharges_amount: 0
    };
  }
  componentDidMount = () => {
    this.mounted = true;
    const { orderUuid } = this.props;
    this.setState({
      orderUuid: orderUuid
    }, this.getOrder);
  }
  getOrder = () => {
    const { orderUuid } = this.state;
    const { branchUuid } = this.props;
    this.setIsFetching(true);
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/orders/single/', {
      method: 'post',
      body: {
        branchUuid,
        orderUuid
      }
    }).then(response => {
      console.log(response);
      this.setIsFetching(false);
      if (this.mounted) {
        const { timezone } = response.data.branch;
        this.setState({
          branch: response.data,
          selectedOrder: response.data.order,
          order_surcharges: response.data.order.surcharges,
          order_discount_value: response.data.order.discount_value,
          payment_processing_by_client: response.data.order.payment_processing_by_client,
          processing_fee: response.data.order.processing_fee,
          timezone,
        }, this.restoreItemsToCart);
      }
    }).catch(error => {
      console.log(error);
      if (this.mounted) {
        this.setIsFetching(false);
      }
    });
  }
  restoreItemsToCart = () => {
    const { cartListArr, selectedOrder, branch } = this.state;
    const newCartListArr = [...cartListArr];
    const { menu } = branch;
    const { order_items } = selectedOrder;
    order_items.forEach((item) => {
      const addToCart = {
        item: false,
        quantity: item.quantity,
        comments: item.comments,
        order_item_id: item.id,
        is_ready: item.is_ready,
        modifiers: item.modifiers
      };

      if (menu.constructor === Object && Object.entries(menu).length !== 0) {
        if (menu.categories && Array.isArray(menu.categories) && menu.categories.length)  {
          menu.categories.forEach((category) => {
            const { items } = category;
            if (items && Array.isArray(items) && items.length)  {
              category.items.forEach((itemObj) => {
                if (itemObj.id === item.product_id && !addToCart.item) {
                  addToCart.item = {...itemObj};
                }
              });
            }
          });
          if (addToCart.item) {
            newCartListArr.push(addToCart);
          }
        }
      }
    });
    this.setState({
      cartListArr: newCartListArr
    });
  }
  componentWillUnmount = () => {
    this.mounted = false;
  }

  setIsFetching = (param) => {
    this.setState({
      fetchingProducts: param
    });
  }
  cartEmpty = () => {
    const { cartListArr } = this.state;
    let empty = 1;
    if (cartListArr && typeof cartListArr === 'object' && cartListArr.length) {
      empty = 0;
    }
    return empty;
  }

  countTotal = (params = false) => {
    const { cartListArr, order_surcharges, processing_fee } = this.state;
    let total = 0;
    if (cartListArr && typeof cartListArr === 'object' && cartListArr.length) {
      cartListArr.forEach((line) => {
        const { modifiers } = line;
        let totalModifiers = 0;
        if (modifiers && modifiers.length) {
          modifiers.forEach((modifier) => {
            totalModifiers += correctModifierSurcharges(modifier.price, order_surcharges);
          });
        }

        if (!params) {
          total += correctModifierSurcharges(line.quantity * parseFloat(line.item.price), order_surcharges)  + totalModifiers * line.quantity
        }

        if (params === 'noTaxes') {
          const gross = correctModifierSurcharges(line.quantity * parseFloat(line.item.price), order_surcharges)  + totalModifiers * line.quantity
          let net = gross;
          if (line.item.vat) {
            net = gross - (gross * line.item.vat / 100);
          }
          total += net;
          return `$${total.toFixed(2)}`;
        }
        if (params === 'onlyTaxes') {
          const gross = correctModifierSurcharges(line.quantity * parseFloat(line.item.price), order_surcharges)  + totalModifiers * line.quantity
          let taxes = 0;
          if (line.item.vat) {
            taxes = (gross * line.item.vat / 100);
          }
          total += taxes;
          return `$${total.toFixed(2)}`;
        }
      });
    }
    total += processing_fee
    return `$${total.toFixed(2)}`;
  }
  switchCheckout = (param) => {
    this.setState({
      checkoutOpen: param
    })
  }
  confirmRefund = () => {
    this.setState({
      visibleSmsAuthenticationPrompt: true,
      smsAuthenticationAction: 'confirm_refund',
      doAfterSmsAuthentication: () => {
        this.setIsFetching(true);
        const { branchUuid } = this.props;
        const { orderUuid } = this.state;
        fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/orders/refund/', {
          method: 'POST',
          body: {
            branchUuid,
            orderUuid,
          }
        }).then(response => {
          console.log(response);
          this.setIsFetching(false);
          if (response.code === 200) {
            // selectedOrder.is_refunded = true;
            // selectedOrder.is_paid = false;
            this.getOrder();
            this.props.renderList();
            notification.success({
              message: 'Success',
            });
            return;
          }
          notification.error({
            message: 'Something went wrong, please contact support',
          });
         }).catch(error => {
            console.log(error);
            notification.error({
              message: 'Something went wrong, please contact support',
            });
            this.setIsFetching(false);
         });
      }
    });
  }

  getOrderStatus = (order) => {
    const { order_items } = order;
    let status = '';
    if (order_items && order_items.length) {
      const ready = [];
      order_items.forEach((item) => {
        if (item.is_ready) {
          ready.push(item.id);
        }
      });
      if (ready.length > 0 && ready.length !== order_items.length) {
        status = `(${ready.length} DELIVERED, ${order_items.length - ready.length} LEFT)`;
      }
    }
    return status;
  }

  printOrderStatus = () => {
    const { selectedOrder } = this.state;
    const order = selectedOrder;
    if (!selectedOrder) return;

    console.log('__ORDER__', order)
    return (<React.Fragment>
      <h2>Order #{order.index_id}</h2>
      <Descriptions bordered size="small" layout="vertical" column="2">
        <Descriptions.Item label="Order Type" >{order.order_type_display}</Descriptions.Item>
        { order.server_name ? <Descriptions.Item label="Server">{order.server_name}</Descriptions.Item> : null }
        <Descriptions.Item label="Order Placed" >{order.is_submitted_at}</Descriptions.Item>
        <Descriptions.Item label="Order Due" >{order.order_due}</Descriptions.Item>
        <Descriptions.Item label="Order Status" ><Tag color={order.orderStatusType}>{order.orderStatus}</Tag> {this.getOrderStatus(order)}</Descriptions.Item>
        <Descriptions.Item label="Payment">{order.payment_status ? <React.Fragment><Tag color={this.tagPayment(selectedOrder)}>{order.payment_status}</Tag> {order.payment_method_display}&nbsp;
          {order.discount_comment ? `(Discount ${order.discount_value}%, Note: ${order.discount_comment})` : null} </React.Fragment> : null }
        </Descriptions.Item>
        { order.customerData ? <Descriptions.Item label="App Customer" >{order.customerData}</Descriptions.Item> : null }
      </Descriptions>
    </React.Fragment>);
  }
  tagPayment = (order) => {
    let type = 'error';
    if (order.is_paid) {
      type = 'success';
    }
    if (order.is_refunded) {
      type = "#6B6F6E";
    }
    return type;
  }
  restoreOrder = () => {
    confirm({
      title: `Are you sure you want to restore this order?`,
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.setIsFetching(true);
          const { branchUuid } = this.props;
          const { orderUuid } = this.state;
          fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches-orders/submit/', {
            method: 'POST',
            body: {
              branchUuid,
              orderUuid,
              key: 'is_recovered',
              value: true
            }
          }).then(response => {
            console.log(response);
            this.setIsFetching(false);
            if (response.code === 200) {
              // selectedOrder.is_canceled = true;
              // selectedOrder.orderStatus = 'Canceled';
              this.getOrder();
              this.props.renderList();
              resolve();
            }
            reject();
           }).catch(error => {
              reject();
              console.log(error);
              this.setIsFetching(false);
           });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }
  cancelOrder = () => {
    const { selectedOrder } = this.state;
    if (!selectedOrder) return;
    if (selectedOrder.is_paid && !selectedOrder.is_refunded) {
      confirm({
        title: 'Please, make a refund first',
        cancelButtonProps: {
          style: {
            display: 'none'
          }
        }
      })
      return;
    }
    confirm({
      title: `Are you sure you want to cancel this order?`,
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.setIsFetching(true);
          const { branchUuid } = this.props;
          const { orderUuid } = this.state;
          fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches-orders/submit/', {
            method: 'POST',
            body: {
              branchUuid,
              orderUuid,
              key: 'is_canceled',
              value: true
            }
          }).then(response => {
            console.log(response);
            this.setIsFetching(false);
            if (response.code === 200) {
              // selectedOrder.is_canceled = true;
              // selectedOrder.orderStatus = 'Canceled';
              this.getOrder();
              this.props.renderList();
              resolve();
            }
            reject();
           }).catch(error => {
              reject();
              console.log(error);
              this.setIsFetching(false);
           });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }
  updateState = (key, value) => {
    this.setState({
      [key]: value
    });
  }
  printReceipt = () => {
    const { branchUuid } = this.props;
    const { orderUuid } = this.state;
    this.setIsFetching(true);
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches-orders/submit/', {
      method: 'POST',
      body: {
        branchUuid,
        orderUuid,
        key: 'print_counter_receipt',
        value: true
      }
    }).then(response => {
      console.log(response);
      this.setIsFetching(false);
      notification.success({
        message: 'Ok',
      });
     }).catch(error => {
        console.log(error);
        this.setIsFetching(false);
     });
  }
  renderTimeline = () => {
    const { selectedOrder } = this.state;
    if (!selectedOrder) return;
    const { orderTimeline, orderStatus, is_refunded } = selectedOrder;
    const steps = [];
    let current = 0;
    orderTimeline.forEach((item, index) => {
      if ((['Cancelled', 'Refunded', 'Restored'].indexOf(item.title) > -1) && !item.date) return;

      if (orderStatus === item.title) current = index;

      if (item.title === 'Refunded' && is_refunded) current = index;
      steps.push(<Step
        key={index}
        title={item.title}
        status={(item.title === 'Cancelled' || item.title === 'Refunded') && 'error'}
        description={<React.Fragment>
        {item.date}
        {item.by && <React.Fragment>
          . By {item.by}
        </React.Fragment>}
      </React.Fragment>} />)
    });
    console.log('current', current);
    return <Steps style={{paddingRight: "20px"}} direction="vertical" size="small" current={current}>
      {steps}
    </Steps>
  }
  sendEmailReceiptNow = (email) => {
    const { orderUuid } = this.state;
    const { branchUuid } = this.props;
    console.log('orderUuid', orderUuid, 'branchUuid', branchUuid);

    this.setIsFetching(true);
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/orders/emailReceipt/', {
      method: 'post',
      body: {
        branchUuid,
        orderUuid,
        email
      }
    }).then(response => {
      console.log(response);
      this.setIsFetching(false);
      if (this.mounted) {
        notification.success({
          message: 'Ok',
        });
      }
    }).catch(error => {
      console.log(error);
      if (this.mounted) {
        this.setIsFetching(false);
      }
    });

  }
  emailReceipt = () => {
    const { selectedOrder } = this.state;
    // if (!selectedOrder) return;
    console.log('emailReceipt', selectedOrder.customer);

    if (selectedOrder.customer && selectedOrder.customer.email) {
      this.sendEmailReceiptNow(selectedOrder.customer.email);
      return;
    }

    this.setState({
      visibleEmailForm: true
    })

  }
  showReceiptOptions = () => {
    this.setState({
      visibleReceipt: true
    })
  }
  closeOrderScenario = () => {
    console.log('closeOrderScenario');
    this.setState({
      visibleEditOrder: true
    })
  }
  reRender = () => {
    this.getOrder();
    this.props.renderList();
  }

  calculateSurchargesValue(surcharges, discount_value) {
    console.log('calculateSurchargesValue', surcharges, discount_value)
    return surcharges - discount_value/100
  }

  calculateSurchargesAmount(surcharges, surcharges_amount, discount_value, discount_amount) {
    surcharges -= discount_value/100
    if (surcharges < 1) {
      return Math.abs(surcharges_amount + discount_amount)
    } else if (surcharges > 1 ) {
      return Math.abs(Math.abs(surcharges_amount) - discount_amount)
    } else {
      return Math.abs(discount_amount)
    }
  }


  render() {
    const {
      putREQUEST,
      orderUuid,
      fetchingProducts,
      checkoutOpen,
      cartListArr,
      showList,
      visibleSmsAuthenticationPrompt,
      smsAuthenticationAction,
      doAfterSmsAuthentication,
      visibleReceipt,
      selectedOrder,
      visibleEditOrder,
      branch,
      processing_fee,
      order_surcharges,
      order_discount_value
    } = this.state;
    console.log('STATE', this.state)
    const { branchUuid } = this.props;
    const { windowWidth, windowHeight } = this.props.windowSize;
    const containerStyle = {
      width: windowWidth,
      height: this.props.windowSize.windowHeight,
      minHeight: this.props.windowSize.windowHeight
    };
    const formCss = {
      width: windowWidth < 768 && windowWidth,
      height: windowWidth < 768 && `calc(${windowHeight}px - 60px)`,
      minHeight: windowWidth < 768 && `calc(${windowHeight}px - 60px)`
    };
    const cartScreenCss = {
      ...formCss,
      opacity: showList || checkoutOpen ? 1 : 0,
      zIndex: showList || checkoutOpen ? 1009 : -100
    };
    return (
      <Modal
        className="general-modal-btns-center general-card createModalOrder createModalOrder--noCart"
        title="Order Details"
        centered={true}
        visible={this.props.visible}
        footer={null}
        onCancel={this.props.handleClose}
        destroyOnClose={true}
        confirmLoading={putREQUEST}
        style={ windowWidth < 769 ? containerStyle : {} }
        width={"auto"}
      >
        { orderUuid ?
          <React.Fragment>
          <Spin spinning={fetchingProducts}>
            <React.Fragment>
              <Row gutter={40}>
                <Col span={ windowWidth > 1200 ? 16 : 24 }>
                  <div className="cartScreen" style={cartScreenCss}>
                    <div style={{ color: '#000', 'paddingBottom': '20px' }}>
                      {this.printOrderStatus()}
                    </div>
                    <div className="cartScreenBody">
                    { this.cartEmpty() ?
                      <div className="cartScreenBody__empty"></div>
                    :
                    <div style={{ width: '100%'}}>
                      <div className="cartScreenList">
                        {cartListArr.map((line, index) => {
                          return (<React.Fragment key={index}><div className="cartScreenItem" key={index}>
                            <div className="cartScreenItem__middle">
                              <div className="cartScreenItem__title">{line.item.displayName}</div>
                              { line.comments && <div className="cartScreenItem__comments">{line.comments}</div> }
                            </div>
                            <div className="cartScreenItem__itemMiddle" style={{marginRight: '20px'}}>{line.quantity}</div>
                            <div className="cartScreenItem__price">
                              ${(line.quantity * line.item.price * order_surcharges).toFixed(2)}
                            </div>
                          </div>
                          {Array.isArray(line.modifiers) && line.modifiers.length ?
                            line.modifiers.map((modifier) => <div className="cartScreenItem" key={modifier.id} style={{ paddingLeft: '35px' }}>
                              <div className="cartScreenItem__middle">
                                <div className="cartScreenItem__title">{!!modifier.modifier_set ? modifier.modifier_set + ': ' : ''}{modifier.display_name}</div>
                              </div>
                              <div className="cartScreenItem__itemMiddle"></div>
                              <div className="cartScreenItem__price">
                                {modifier.price ? `${formModifierPriceString(correctModifierSurcharges(modifier.price, order_surcharges))}` : ''}
                              </div>
                            </div>)
                            : null}
                          </React.Fragment>)
                        }) }
                      </div>
                    </div>
                    }
                  </div>
                  <div className="cartScreenFooter" style={{paddingBottom: 0}}>
                    <div className="cartScreenTotal">
                      <div className="cartScreenTotal__item">
                        GST
                        <span className="cartScreenTotal__price">{this.countTotal('onlyTaxes')}</span>
                      </div>
                      { !!selectedOrder ? selectedOrder.surcharges != 1 ?
                        <div className="cartScreenTotal__item">
                          { selectedOrder.surcharges > 1 ? `Restaurant surcharges` : `Restaurant discount` }
                          <span className="cartScreenTotal__price">{surchargesToString(selectedOrder.surcharges)} | ${Math.abs(selectedOrder.surcharges_amount).toFixed(2)}</span>
                        </div>
                        : null
                      : null }
                      { processing_fee != 0 ?
                        <div className="cartScreenTotal__item">
                          Processing fee
                          <span className="cartScreenTotal__price">${!!processing_fee?processing_fee.toFixed(2):''}</span>
                        </div>
                        : null }
                      <div className="cartScreenTotal__item">
                        Total Amount Payable
                        <span className="cartScreenTotal__price cartScreenTotal__price--red">${!!selectedOrder?.total ? selectedOrder.total.toFixed(2): ''}{selectedOrder.is_paid_online === true && selectedOrder.payment_processing_by_client === 1 ? `*` : null}</span>
                      </div>
                    </div>
                    </div>
                  </div>
                </Col>
                <Col span={ windowWidth > 1200 ? 8 : 24 } className="cartScreenSide">
                  <div>
                    {this.renderTimeline()}
                  </div>
                  <div className="cartScreenSide__footer" style={{display: this.props.shouldHideActions ? 'none' : ''}}>
                    { !fetchingProducts && !selectedOrder.is_canceled ? <Row gutter={20}>
                       <Col span={ 24 }>
                        <button
                          className="cartScreenFooter__checkout"
                          onClick={() => this.cancelOrder()}
                          >
                          Cancel
                        </button>
                      </Col>
                      { !fetchingProducts && selectedOrder.is_paid ? <Col span={24} style={{ marginTop: '12px' }}>
                        <button
                          className="cartScreenFooter__checkout"
                          onClick={() => this.confirmRefund()}
                          >
                          Refund
                        </button>
                      </Col> : null }
                    </Row> : null }
                    { !fetchingProducts && selectedOrder.is_canceled ? <Row gutter={20}>
                       <Col span={ 24 }>
                        <button
                          className="cartScreenFooter__checkout"
                          onClick={() => this.restoreOrder()}
                          >
                          Restore Order
                        </button>
                      </Col>
                    </Row> : null }
                    <Row gutter={20} style={{ marginTop: '12px' }}>
                      <Col span={ 24 }>
                        <button
                          className="cartScreenFooter__checkout"
                          onClick={() => this.showReceiptOptions()}
                          >
                          Receipt
                        </button>
                      </Col>
                    </Row>
                    { !selectedOrder.is_closed && !selectedOrder.is_canceled ?<Row gutter={20} style={{ marginTop: '12px' }}>
                      <Col span={ 24 }>
                        <button
                          className="cartScreenFooter__checkout"
                          onClick={() => this.closeOrderScenario()}
                          >
                          Complete order
                        </button>
                      </Col>
                    </Row> : null }
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          </Spin>
          </React.Fragment>
          : null }

        { visibleSmsAuthenticationPrompt ? <SmsAuthenticationPrompt
          visible={visibleSmsAuthenticationPrompt}
          action={ smsAuthenticationAction }
          doAfter={ doAfterSmsAuthentication }
          handleClose={ () => { this.updateState('visibleSmsAuthenticationPrompt', false) } } />
          : null }

        {
          visibleReceipt ?
          <Receipt
          visible={visibleReceipt}
          sendEmailReceiptNow={ this.sendEmailReceiptNow }
          printReceipt={this.printReceipt}
          handleClose={ () => { this.updateState('visibleReceipt', false) } } />
          : null
        }

          { visibleEditOrder ? <EditOrder
            branchUuid={branchUuid}
            visible={visibleEditOrder}
            branch={branch}
            selectedOrder={selectedOrder}
            renderList = { this.reRender }
            payNowScenario={false}
            handleClose={ () => this.updateState('visibleEditOrder', false) } />
            : null }

      </Modal>
    )
  }
};

export default withTranslation()(withWindowSizeListener(SingleOrder));
