import React from 'react';
import Settings from 'services/config/Settings';
import { Layout } from 'antd';
import { withTranslation } from 'react-i18next';
import BranchesTable from './components/BranchesTable';

import './styles.less';

const { Content } = Layout;

class Branches extends React.Component {
  componentDidMount() {  
    const { t } = this.props;
    document.title = `${t('shareable.venues', { defaultValue: 'Venues' })} | ${Settings.title}`;
  }
  render() {
    return (
      <Content className="bg-transparent table-content">
        <BranchesTable
          history={this.props.history}
          role={this.props.role} 
        />
      </Content>
    )
  }
};

export default withTranslation()(Branches);