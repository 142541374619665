import React from 'react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { Card, Row, Button, List, Modal, Spin } from 'antd';
import { withWindowSizeListener } from 'react-window-size-listener';
//import { saveAs } from 'file-saver';
//import QRCode from 'qrcode';
import fetchJSON from 'services/utils/fetchJSON';

import {
  PrinterOutlined,
	QrcodeOutlined,
	//EditOutlined,
  DeleteOutlined
 } from '@ant-design/icons';
import './styles.less';

require('potrace-browser');

const ListItem = List.Item;

class TablesList extends React.Component {
  componentDidMount() {
    this.mounted = true;
    console.log('window.location', this.props);
    //in main window
    window.addEventListener('message', this.receiveMessage);
  }
  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('message', this.receiveMessage);
  }
  receiveMessage = (message) => {
    if (message.data && message.data.type === "png.download.completed") {
      this.resetDownload();
    }
  }
  state = {
    optionsQR: {
      errorCorrectionLevel: 'H',
      type: 'image/png',
      width: 400,
      quality: 1,
      margin: 0,
      color: {
        dark:"#000000",
        light:"#FFFFFF"
      }
    },
    tableTitle: null,
    tableId: null,
    initiateDownload: false
  }
  getListData = () => {
  	const { tables } = this.props;
  	const data = [];
  	if (tables && tables.length) {
  		tables.forEach((table) => {
  			const title = _.get(table, 'table.display_name');
  			const tableId = _.get(table, 'table.id');
  			if (tableId) {
	  			data.push({
	  				title,
	  				tableId
	  			})
  			}
  		})
  	}
  	return data;
  }
  saveQRTable =  (tableId, tableTitle) => {
    // const { branchUrl, branchTitle } = this.props;
    this.setState({
      tableId,
      tableTitle,
      initiateDownload: true,
    });
  }
  resetDownload = () => {
    setTimeout(() => {
      this.setState({
        tableId: null,
        tableTitle: null,
        initiateDownload: false
      });
    }, 1000);
  }
  printBranchQR = () => {
    this.setState({
      initiateDownload: true,
    });
  }
  getActions = (tableId, tableTitle) => {
  	const actions = [
      <QrcodeOutlined style={{color: "#E01491"}} key="print" onClick={ () => {this.saveQRTable(tableId, tableTitle)} }/>,
     // <EditOutlined style={{color: "#1C8DCC"}} key="edit" onClick={ () => {this.turnOnEdit(tableId)} }/>,
      <DeleteOutlined style={{color: "#CF1322"}} key="delete" onClick={ () => {this.deleteTable(tableId)} } />,
  	];
  	return actions
  }
  // turnOnEdit = (tableId) => {
  //   console.log('edit');
  // }
  deleteTable = (tableId) => {
    Modal.confirm({
      className: "modal-btns-center",
      title: 'Are you sure you want to delete this table? If yes to avoid confusion please make sure that correct QR code is removed from the table.',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        this.setState({
          isFetching: true
        });
        fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/tables/delete/${tableId}`, {
          method: 'put'
        }).then(response => {
          if (this.mounted) {
            if (response.success) {
              this.setState({
                isFetching: false
              }, this.props.updateBranch)
            } else {
              this.setState({
                isFetching: false
              })
            }
          }
        }).catch(error => {
          console.log(error);
          if (this.mounted) {
            this.setState({
              isFetching: false
            });
          }
        })
      }
    })
  }
  createTable = () => {
    this.setState({
      isFetching: true
    });
    console.log('tables creste');
    fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/tables/create/${this.props.branchUuid}`, {
      method: 'post',
      body: {}
    }).then(response => {
      console.log(response, 'response')
      if (this.mounted) {
        if (response.success) {
          this.setState({
            isFetching: false
          }, () => {
            this.props.updateBranch();
            Modal.confirm({
              className: "modal-btns-center",
              title: `Please don't forget to print the QR code for the new table`,
              okText: 'Download QR code',
              cancelText: 'Later',
              okButtonProps: {style: {width: '160px'}},
              onOk: () => {
                if (!response.data.id || !response.data.display_name) return;
                this.saveQRTable(response.data.id, response.data.display_name);
              }
            })
          })
        } else {
          this.setState({
            isFetching: false
          })
        }
      }
    }).catch(error => {
      console.log('error', error);
      if (this.mounted) {
        this.setState({
          isFetching: false
        });
      }
    })
  }
  getParams = () => {
    const { branchUrl, branchTitle } = this.props;
    const { tableTitle, tableId } = this.state;

    const params = {
      branchUrl,
      branchTitle,
      parentUrl: window.location.href
    };
    if (tableTitle) params.tableTitle = tableTitle;
    if (tableId) params.tableId = tableId;
    const url = Object.keys(params).map(function(k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(params[k])
    }).join('&');
    return url;
  }
  render() {
  	const { tables } = this.props;
    const { initiateDownload } = this.state;
  	const isMobile = this.props.windowSize.windowWidth < 576;
  	const grid = !isMobile  ? { gutter: 48, column: 5 } : { gutter: 48, column: 2 };
  	return (
  		<div>
  		<Row style={{marginBottom: '8px', marginLeft: 0, marginTop: 0}} className="tableHeader__quantity-text">
  			Quantity: {tables.length}
  		</Row>
       <Spin spinning={initiateDownload}>
  		<Row style={{marginBottom: '8px'}}>
  		<Button
        style={{borderRadius: '4px'}}
        disabled={initiateDownload}
        onClick={this.printBranchQR}>
        <PrinterOutlined style={{fontSize: '16px', color: "#E01491"}}/>Print venue QR</Button>
      <Button style={{marginLeft: '8px'}} onClick={this.createTable}>Add table</Button>
  		</Row>
		  <List
		  	style={{marginTop: isMobile ? '24px' : '12px'}}
		    grid={grid}
		    dataSource={this.getListData()}
		    renderItem={item => (
		      <ListItem>
		        <Card className="general-card table-card" title={item.title} actions={this.getActions(item.tableId, item.title)}/>
		      </ListItem>
		    )}
		  />
      </Spin>

      { initiateDownload ? <iframe src={`/qr-code?${this.getParams()}`} title="QR" style={{width: '1px', height: '1px', border: 'none'}}/> : null }

		  </div>
  	)
  }
}
export default  withTranslation()(withWindowSizeListener(TablesList));
