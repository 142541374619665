import React from 'react';
import Settings from 'services/config/Settings';
import fetchJSON from 'services/utils/fetchJSON';
import { withTranslation } from 'react-i18next';
import {
  Card,
  Row,
  Layout,
  Descriptions,
  Col
} from 'antd';

class SingleUser extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      isFetching: false,
      branch: {},
      initialValues: {},
      addressGroup: {},
      activeTab: 'info'
    };
  }
  componentDidMount = () => {
    this.mounted = true;
    const userUuid = this.props.match.params.userUuid;
    console.log(this.props, userUuid, 'props')
    this.getUser(userUuid);
  }
  getUser = (userUuid) => {
    this.setState({
      isFetching: true
    });
    fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/customers/${userUuid}`, {
      method: 'get'
    }).then(response => {
      if (this.mounted) {
        if (response.data) {
          const customer = response.data;
          let totalOrders = 0;
          let totalPayment = 0;
          document.title = `User #${customer.id} | ${Settings.title}`; 
          if (customer.orders && customer.orders.length) {
            customer.orders.forEach((order) => {
              totalOrders += 1;
              totalPayment += order.total;
            });
          }
          customer.totalOrders = totalOrders;
          customer.totalPayment = '$' + totalPayment;
          this.setState({ 
            isFetching: false, 
            customer
          })
        } else {
          this.setState({ isFetching: false });
        } 
      }    
    }).catch(error => {
      console.log(error);
      if (this.mounted) { 
        this.setState({
          isFetching: false
        });
      }
    });
  }
  render() {
    const { isFetching, customer } = this.state;
    return (
      <Layout className="bg-transparent">
        <Card
          className='general-card'
          loading={isFetching}
          onTabChange={(key) => { this.onTabChange(key, 'activeTab'); }}
          title={
            <Row>
              <span className="general-card__title-text">{customer && typeof customer === 'object' ? `User #${customer.id}` : 'User'}</span>
            </Row>
          }
          >
          { customer && typeof customer === 'object' ? 
            <div>
            <Col xs={24} sm={8}>
              <Row>
                <Descriptions
                  column={1}
                  >
                  <Descriptions.Item label="Name">{customer.name}</Descriptions.Item>
                  <Descriptions.Item label="Phone">{customer.phone}</Descriptions.Item>
                  <Descriptions.Item label="Email">{customer.email}</Descriptions.Item>
                </Descriptions>   
              </Row>
              <Row>
                <Descriptions 
                  column={{ sm: 2, xs: 1 }}
                  >
                  <Descriptions.Item label="Total orders">{customer.totalOrders}</Descriptions.Item>
                  <Descriptions.Item label="Total payment">{customer.totalPayment}</Descriptions.Item>
                </Descriptions>
              </Row>
              </Col>
            </div>
          : null }
        </Card>
      </Layout>
    )
  }
};

export default  withTranslation()(SingleUser);