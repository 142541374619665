import React from 'react';
import Settings from 'services/config/Settings';
import { Layout, Card, Row, Col, Select, Empty } from 'antd';
import { withTranslation } from 'react-i18next';
import store from 'store';
import { getTimeDistance } from 'services/utils/utils';
import { withWindowSizeListener } from 'react-window-size-listener';
import * as _sharedModules from '../modules/_modules';

import BranchOrders from '../Branches/components/SingleBranch/components/Orders';

import './styles.less';

const { Content } = Layout;

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      initialDataLoaded: false,
      branchesList: [],
      targetRestaurantId: null,
      targetBranchUuid: null,
      rangePickerValue: getTimeDistance('week'),
      datePickerSize: 'default',
      timezone: 'Australia/Sydney',
      filterTypeOrder: 'all',
      analiticsLoading: false
    };
    Object.keys(_sharedModules).forEach((_moduleName) => {
      this[_moduleName] = _sharedModules[_moduleName].bind(this);
    });
    this.userRole = this.props.role;
  }
  componentDidMount() {
    const { t } = this.props;
    document.title = `${t('shareable.orders', { defaultValue: 'Orders' })} | ${Settings.title}`;

    this.mounted = true;
    
    if (this.userRole === 'admin') {
      this.getRestaurantsListSimple();
    } else {
      const targetRestaurantId = this.props.related_restaurant_id;

      console.log('targetRestaurantId', targetRestaurantId, this.userRole);
      this.setState({
        targetRestaurantId
      }, () => {
        if (this.userRole === 'owner') {
          this.getBranchesList(false);
        }
        if (this.userRole === 'manager') {
          this.setState({
            targetBranchUuid: this.props.branchUuid,
            initialDataLoaded: true,
          })
        }
      })
    }
    this.restoreScreen();
  }
  componentWillUnmount() {
    this.mounted = false;
    store.remove('orders_targetRestaurantId')
    store.remove('orders_targetBranchUuid')

  }
  restoreScreen = () => {
    const targetRestaurantId = store.get('orders_targetRestaurantId');
    const targetBranchUuid = store.get('orders_targetBranchUuid');

    const state = {};

    if (targetRestaurantId) state.targetRestaurantId = targetRestaurantId;
    if (targetBranchUuid) state.targetBranchUuid = targetBranchUuid;

    this.setState(state, () => {
      if (targetRestaurantId) this.getBranchesList(false);
    });
  }
  onRestaurantSelectChange = (targetRestaurantId) => {
    // ok
    store.set('orders_targetRestaurantId', targetRestaurantId)
    store.set('orders_targetBranchUuid', null)

    this.setState({
      targetRestaurantId,
      targetBranchUuid: null
    }, () => this.getBranchesList(false))
  }
  onBranchSelectChange = (targetBranchUuid) => {
    // ok
    store.set('orders_targetBranchUuid', targetBranchUuid)
    this.setState({
      targetBranchUuid
    });
  }
  render() {
    const { 
      initialDataLoaded,
      branchesListLoaded,
      branchesList,
      targetRestaurantId,
      targetBranchUuid,
    } = this.state;

    const hideSelectBranches = (this.userRole !== 'admin' && branchesListLoaded && branchesList.length === 1) || (this.props.managerReports) || (this.userRole === 'manager');

    return ( 
      <Content className="bg-transparent" style={{paddingLeft: '0px'}}>
        <meta name="viewport" content="target-densitydpi=device-dpi, initial-scale=1.0, user-scalable=no" />
        <Card 
          className="general-card"
          loading={!initialDataLoaded}
          title={
            <Row>
              <span className="general-card__title-text">Orders</span>
            </Row>
          } 
          >
          <Row gutter={16}>
            { this.userRole === 'admin' ?
            <Col className="header-margin-same-as-table"  sm={4} xs={12}>
              <Row style={{marginBottom: '4px', fontSize: '15px'}}>
                <label>Restaurant:</label>
              </Row>
              <Row>
                <Select value={targetRestaurantId} style={{width: '100%'}} placeholder="Restaurant" onChange={(value) => this.onRestaurantSelectChange(value)}>
                  {this.renderRestaurantsOptions()}
                </Select>
              </Row>
            </Col> : null }
            { !hideSelectBranches ?
            <Col className="header-margin-same-as-table" sm={4} xs={12}>
              <Row style={{marginBottom: '4px', fontSize: '15px'}}>
                <label>Venue:</label>
              </Row>
              <Row>
                <Select 
                  onChange={(value) => this.onBranchSelectChange(value)} 
                  value={targetBranchUuid} 
                  disabled={!targetRestaurantId || (targetRestaurantId && branchesListLoaded && !branchesList.length)} 
                  loading={targetRestaurantId && !branchesListLoaded} style={{width: '100%'}} placeholder="Venue"
                >
                  {this.renderBranchesOptionsUuid()}
                </Select>
              </Row>
            </Col> : null }
          </Row>
          <Row style={{marginTop: '24px'}}>
            { targetRestaurantId && branchesListLoaded && !branchesList.length ?
              <div style={{width: '100%', height: '200px', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <Empty/>
              </div> : null }
            { targetBranchUuid && targetRestaurantId && <BranchOrders branchUuid={targetBranchUuid} /> }
          </Row>
        </Card>
      </Content>
    )
  }
};

export default withTranslation()(withWindowSizeListener(Orders));