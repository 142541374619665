import React from 'react';
import Settings from 'services/config/Settings';
import Auth from 'services/utils/Auth';
import fetchJSON from 'services/utils/fetchJSON';

import { withWindowSizeListener } from 'react-window-size-listener';
import { getFingerprint } from 'services/utils/Analytics';
import { withTranslation, Trans } from 'react-i18next';

import { Link } from 'react-router-dom';
import { Layout, Row, Input, Spin, Button, Form, Alert } from 'antd';
import Logo from 'components/images/dezy-header-logo-v2.svg';
import qs from 'qs';

import './styles.less';

const FormItem = Form.Item;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      resetPass: false,
      resetEmail: false
    };
  }
  componentDidMount = () => {
    const { t } = this.props;
    this.mounted = true;
    document.title = `${t('login.documentTitle', { defaultValue: 'Login' })} | ${Settings.title}`;
    document.body.addEventListener('touchmove', this.preventDefault, { passive: false });
    const param = window.location.search;
    if (param) {
      const paramFromUrl = qs.parse(param, { ignoreQueryPrefix: true });
      if (paramFromUrl) {
        this.props.history.push('/login');
        this.setState({serverMessageType: 'success'});
        if (paramFromUrl.resetpass) {
          this.setState({
            resetPass: true,
            serverMessage: t('shareable.passwordUpdated', { defaultValue: 'Password has been updated' })
          })
        }
        if (paramFromUrl.resetemail) {
          this.setState({
            resetEmail: true,
            serverMessage: t('shareable.resetEmailSent', { defaultValue:  `We've send a link and instructions to your email!` })
          })
        }
      }
    }
  }
  preventDefault = (e) => {
    e.preventDefault();
  }
  componentWillUnmount = () => {
    this.mounted = false;
    document.body.removeEventListener('touchmove', this.preventDefault);
  }
  handleSubmit = async (values) => {
    if (!this.mounted) return false;    
    this.setState({
      isFetching: true
    });
    let fingerprint = '';
    try {
      fingerprint = await getFingerprint();
    } catch (error) {
      console.log('error', error);
    }

    values.fingerprint = fingerprint;

    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/auth/login', {
      method: 'POST',
      body: values
    }).then(response => {
      if (!this.mounted) return false;
      this.setState({
        isFetching: false,
        serverMessage: response.message,
        serverMessageType: response.code > 300 ? 'error' : 'success',
      });

      if (response.code === 200) {
        // If login was successful, set the token in cookie
        Auth.loginUser(response.token);
        this.props.history.push('/');
      } else {
        setTimeout(() => {
          if (this.mounted) {
            this.setState({
              serverMessage: ''
            });
          }
        }, 3000);
      }
     }).catch(error => {
        console.log(error);
        if (this.mounted) {
          this.setState({
            isFetching: false
          });
        }
     });
  }
  render() {
    const { t } = this.props;
    const { 
      isFetching,
      serverMessage,
      serverMessageType
    } = this.state;
    const containerStyle = {
      width: this.props.windowSize.windowWidth,
      height: this.props.windowSize.windowHeight,
      minHeight: this.props.windowSize.windowHeight
    };

    return (
      <Layout className="layoutApp layoutLogin" style={containerStyle}>
      <Spin spinning={ isFetching }>
        <div style={{marginRight: '30px'}}>
          <img className="layoutLogin__logo-img" src={Logo} alt="Logo" />
        </div>
        <Form onFinish={this.handleSubmit} >
        { serverMessage ?
        <Row>
          <FormItem><Alert message={serverMessage} type={ serverMessageType } /></FormItem></Row>
        : null }
        <Row className="layoutLogin__input-container">
          <FormItem
            name="email"
            rules={[{
              type: 'email',
              message: t('login.email.wrongFormat', { defaultValue: 'Wrong format of email' }),
              validateTrigger: 'onSubmit'
            },
            {
              required: true,
              message: t('shareable.enterYourEmailPlease', { defaultValue: 'Please enter your email!' }),
            }]}>
              <Input className="layoutLogin__input" placeholder={t('shareable.email', { defaultValue: 'Email' })}/>
          </FormItem>
        </Row>
        <Row className="layoutLogin__input-container">
          <FormItem 
            name='password'
            rules={[{ required: true, message: t('shareable.enterYourPassPlease', { defaultValue: 'Please enter your password!' }) }]} 
          >
            <Input.Password className="layoutLogin__input" placeholder={t('shareable.password', { defaultValue: 'Password' })}/>
          </FormItem>
        </Row>
        <Button style={{width: '120px'}} htmlType="submit">Log in</Button>
        <Link className="layoutLogin__link" to='/resetpass'>
          <Trans i18nKey="login.forgotPass">Forgot password?</Trans>
        </Link>
        <div>
          <Link className="layoutLogin__asEmployee" to='/login-employee'>
            <Trans i18nKey="login.asEmployee">Login as employee</Trans>
          </Link>
        </div>
        </Form>
      </Spin>
      </Layout>
    );
  }
}

export default withTranslation()(withWindowSizeListener(Login));

