import React from 'react';
import Settings from 'services/config/Settings';
import { withTranslation } from 'react-i18next';
import { Layout, Row, Button, Modal, message, List, Skeleton } from 'antd';
import { DeleteOutlined, AppstoreAddOutlined, EditOutlined } from '@ant-design/icons';
import fetchJSON from 'services/utils/fetchJSON';
import AddModifierSets from './components/AddModifierSets';
import EditModifierSets from './components/EditModifierSets';

const { Content } = Layout;


class ModifierSets extends React.Component {
	constructor(props) {
    super(props);
    this.state = {
      data: [],
      totalNumberList: 0,
      showAddModifierSets: false,
      showEditModifierSet: false,
      targetModifierSetId: null
    };
  }
  componentDidMount() {  
    const { t } = this.props;
    this.mounted = true;
    document.title = `${t('shareable.modifiers', { defaultValue: 'Modifiers' })} | ${Settings.title}`;
    this.getModifiers();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.restaurantId !== this.props.restaurantId) {
      this.getModifiers();
    }
  }
  getModifiers = () => {
  	console.log('getModifiers');
  	const bodyObj = {};
    if (this.props.restaurantId) { 
      bodyObj.restaurantId = this.props.restaurantId;
    }
    this.setState({
      isFetching: true
    });
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/products/modifiers/list', {
      method: 'post',
      body: bodyObj
    }).then(response => {
      console.log(response, 'response');
      if (this.mounted) {
        if (response.success) {
          this.setState({ 
            isFetching: false,
            data: response.data,
            totalNumberList: response.data.length
          });
        } else {
          this.setState({ 
            isFetching: false,
            data: [] 
          });
        } 
      }    
    }).catch(error => {
      console.log(error);
      if (this.mounted) { 
        this.setState({
          isFetching: false,
          data: [] 
        });
      }
    });
  }
  deleteModifierSetRequest = (modifierSetId) => {
    Modal.confirm({
      className: "modal-btns-center",
      title: 'Are you sure you want to delete this modifier set?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        this.deleteModifierSet(modifierSetId);
      }
    });
  }
  deleteModifierSet = (modifierSetId, callback = false) => {
    if (!callback) {
      this.setState({ isFetching: true });
    }

    fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/products/modifiers/delete/${modifierSetId}`, {
      method: 'delete'
    }).then(response => {
      if (this.mounted) {
        if (response.success) {
          if (!callback) {
            message.success('Success!');
            this.setState({
              isFetching: false
            }, this.getModifiers);
          } else {
            callback(200)
          }
        } else {
          if (!callback) {
            message.error('Something went wrong, please try again');
            this.setState({
              isFetching: false
            })
          } else {
            callback(400);
          }
        }
      }    
    }).catch(error => {
      console.log(error);
      if (this.mounted) {
        if (!callback) {
          message.error('Something went wrong, please try again');
          this.setState({
            isFetching: false
          });          
        } else {
          callback(400);
        }
      }
    })
  }
  modalVisible = (key, value) => {
    this.setState({ [key]: value });
  }
  getData = () => {
    const data = [];
    if (!this.state.data) return data;

    let modifiers = this.state.data;
    if (!Array.isArray(modifiers) || !modifiers.length) {
      return data;
    }

    modifiers.forEach((modifierSet, i) => {
      data.push({
        key: i,
        id: modifierSet.id,
        display_name: modifierSet.display_name,
      })  
    });
    return data;
  }
  editModifierSet = (item) => {
  	this.setState({
      targetModifierSetId: item.id,
      showEditModifierSet: true
    });
  }
	render() {
		const { 
      isFetching,
      totalNumberList,
      showAddModifierSets,
      showEditModifierSet,
      targetModifierSetId,
    } = this.state;

    const contentClass = !this.props.outer ? "bg-transparent table-content innerContent" : "bg-transparent table-content";

    return (
      <Content className={contentClass + " categories-list"}>
        <List
          loading={isFetching}
          itemLayout="horizontal"
          header={
            <Row className="categories-list--header__container">
              <h3 style={{color: '#6B6F6E'}}>Quantity: {totalNumberList}</h3>             
              <Button
              	onClick={() => this.modalVisible('showAddModifierSets', true)}
              	style={{ width: '166px', maxWidth: '166px' }}
              	>Add Modifier Set</Button>
              <p style={{marginBottom: '0px', marginTop: '16px'}}>Here you can create sets of modifiers that can then be linked to multiple products.</p>
            </Row>
          }
          dataSource={ this.getData() }
          size="large"
          renderItem={item => (
            <List.Item actions={[<span>           
                <Button type="link" onClick={() => this.editModifierSet(item)} style={{color: '#1C8DCC', fontSize: '18px'}}><EditOutlined/></Button>
                <Button danger type="link" onClick={() => this.deleteModifierSetRequest(item.id)} style={{fontSize: '18px'}}><DeleteOutlined/></Button>
              </span>
            	]}
            >
              <Skeleton title={false} loading={isFetching}>
                <List.Item.Meta
                  avatar={<AppstoreAddOutlined style={{fontSize: '20px', color: '#1C8DCC'}} />}
                  title={<span style={{fontSize: '16px', color: 'rgba(0, 0, 0, 0.85)'}}>{item.display_name}</span>}
                  description=''
                />
              </Skeleton>
            </List.Item>
          )}
        />

        { showAddModifierSets && <AddModifierSets
          history={this.props.history}
          handleClose={() => this.modalVisible('showAddModifierSets', false)}
          visible={showAddModifierSets} 
          updateModifiersList={this.getModifiers}
          restaurantId={this.props.restaurantId}
        /> }

        { showEditModifierSet && <EditModifierSets
          history={this.props.history}
          handleClose={() => this.modalVisible('showEditModifierSet', false)}
          visible={showEditModifierSet} 
          updateModifiersList={this.getModifiers}
          restaurantId={this.props.restaurantId}
          modifierSetId={targetModifierSetId}
        /> }
      </Content>
    )
	}
}


export default withTranslation()(ModifierSets);