import React from 'react';
import fetchJSON from 'services/utils/fetchJSON';
import { withWindowSizeListener } from 'react-window-size-listener';
import { withTranslation, Trans } from 'react-i18next';
import { Row, Input, Button, Form, Col, message, Modal, Steps } from 'antd';
import CountryPhoneInput from 'components/AntdCountryPhoneInput';
import '../styles.less';

const FormItem = Form.Item;
const Step = Steps.Step;

class PhoneNumberChange extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      isFetching: false,
      currentStep: 0
    };
  }
  componentDidMount = () => {
    this.mounted = true;
    document.body.addEventListener('touchmove', this.preventDefault, { passive: false });
  }
  preventDefault = (e) => {
    e.preventDefault();
  }
  componentWillUnmount = () => {
    this.mounted = false;
    document.body.removeEventListener('touchmove', this.preventDefault);
  }
  validatePhone = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || !value.phone) {
        return Promise.reject('Phone is required!')
      } else {
        if (value.phone.length < 7) {
          return Promise.reject(<p><Trans i18nKey="shareable.reqPhone.minLength">Phone should be 8 symbols and longer</Trans></p>);
        }
        const isnum = /^\d+$/.test(value.phone);
        if (!isnum) {
          return Promise.reject(<p><Trans i18nKey="shareable.reqPhone.onlyNumber">Please, enter correct phone</Trans></p>);
        }
      }
      return Promise.resolve();
    },
    validateTrigger: 'onSubmit'
  })
  sendSmsCode = () => {
    let phoneObj = {};
    return this.formRef.current.validateFields()
    .then((values) => {
      phoneObj = values.phoneObj;
      fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/worker/sendSmsCode', {
        method: 'POST',
        body: {
          action: 'phone_change',
          phone: phoneObj.code + phoneObj.phone
        }
      }).then(response => {
        console.log(response);
        if (response.success) {
          this.setState({
            putREQUEST: false,
            putSUCCESS: true,
            putFAILURE: false,
            currentStep: 1,
            phoneObj
          });
          message.success(response.message || 'Code sent!');
        } else {
          this.setState({
            putREQUEST: false,
            putFAILURE: true,
            putSUCCESS: false
          });
          message.error(response.message || 'Error');
        }
       }).catch(error => {
          console.log(error);
          message.error('Something wrong, try later');
          this.setState({
            putSUCCESS: false,
            putREQUEST: false,
            putFAILURE: true,
          });
       });
    })
  }
  confirmByCode = () => {
    const { phoneObj } = this.state;
    if (Object.keys(phoneObj).length) {
      const phone = phoneObj.code + phoneObj.phone;
      return this.formRef.current.validateFields()
      .then((values) => {
        fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/worker/updatePhoneNumberConfirmation', {
          method: 'POST',
          body: {
            phone: phone,
            phoneObj,
            action: 'phone_change',
            code: values.code
          }
        }).then(response => {
          console.log(response);
          if (response.success) {
            this.setState({
              putREQUEST: false,
              putSUCCESS: true,
              putFAILURE: false,
              currentStep: 0,
              phoneObj: {}
            }, () => {
              this.props.handleClose();
              this.props.updateProfile();
            });
            message.success('Success!');
          } else {
            this.setState({
              putREQUEST: false,
              putFAILURE: true,
              putSUCCESS: false
            });
            message.error(response.message || 'Error');
          }
         }).catch(error => {
            console.log(error);
            message.error('Something wrong, try later');
            this.setState({
              putSUCCESS: false,
              putREQUEST: false,
              putFAILURE: true,
            });
         });
      })  
    }
  }
  render() {
    const { 
      currentStep
    } = this.state;

    return (
      <Modal
        className="general-modal-btns-center general-card"
        visible={this.props.visible}
        onCancel={this.props.handleClose}
        footer={null}
        title={"Edit phone"}
        destroyOnClose={true}>
        <Steps current={currentStep} style={{marginBottom: '14px'}} direction="vertical">
          <Step title="Enter new phone" disabled={currentStep > 0}/>
          <Step title="Confirm via sms"/>
        </Steps>
        { currentStep === 0 ?
        <Form 
          requiredMark={false} 
          className="general-form" 
          layout="vertical" 
          ref={this.formRef} 
        >
          <Row>
            <Col span={16}>
              <FormItem rules={[this.validatePhone]} name="phoneObj" label="Phone">
                <CountryPhoneInput className="phoneCodeInput" placeholder="Phone" />
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Button type="primary" onClick={this.sendSmsCode}>Next</Button>
            <Button style={{marginLeft: '8px'}} onClick={this.props.handleClose}>Cancel</Button>
          </Row>
        </Form> : null }
        { currentStep === 1 ? 
          <Form
            requiredMark={false} 
            className="general-form" 
            layout="vertical" 
            ref={this.formRef}>
            <Row>
              <Col span={16}>
                <FormItem name="code" label="Sms code" rules={[{ required: true, message: 'Code is required!' }]}>
                  <Input placeholder="Code"/>
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Button type="primary" onClick={this.confirmByCode}>Save</Button>
            </Row>
          </Form> : null }
      </Modal>
    );
  }
}

export default withTranslation()(withWindowSizeListener(PhoneNumberChange));