import React, { Component } from 'react';
import store from 'store';
import { Layout, Spin, Col, Row, Radio, Input } from 'antd';
import Settings from 'services/config/Settings';
import { withTranslation } from 'react-i18next';
import Waiter from '../../Orders/Waiter/Waiter';
import Kitchen from '../../Orders/Kitchen/Kitchen';
import Manager from '../../Orders/Manager/Manager';

const { Content } = Layout;
const Search = Input.Search;

class Orders extends Component {
	state = {
		isFetching: false,
		fetchingProducts: false,
    filterTypeOrder: 'manager',
    searchOrderId: '',
    filteredById: false
	}
	componentDidMount() {
    this.mounted = true;
    const { t } = this.props;
    document.title = `${t('shareable.orders', { defaultValue: 'Orders' })} | ${Settings.title}`;
    console.log(this.props.branchUuid, 'branchUuid')

    const filterTypeOrder = store.get('orders_filterTypeOrder');
    if (filterTypeOrder) {
      this.setState({
        filterTypeOrder
      });
    }
  }
  componentWillUnmount() {
    this.mounted = false;
    store.remove('orders_filterTypeOrder')
  }
  componentDidUpdate(prevProps) {
    if (prevProps.branchUuid && prevProps.branchUuid !== this.props.branchUuid) {
      const { filterTypeOrder } = this.state;
      this.setState({
        filterTypeOrder: ''
      }, () => {
        setTimeout(this.setState({ filterTypeOrder }), 200);
      })
    }
  }
  setIsFetching = (param) => {
    this.setState({
      fetchingProducts: param
    });
  }
  renderOrders = () => {
    const { branchUuid } = this.props;
    const {
      filterTypeOrder,
      searchOrderId,
      filteredById
    } = this.state;

    if (filterTypeOrder === 'manager') {
      return <Manager branchUuid={branchUuid} searchOrderId={searchOrderId} filteredById={filteredById}/>
    }
    if (filterTypeOrder === 'waiter') {
      return <Waiter branchUuid={branchUuid} searchOrderId={searchOrderId} filteredById={filteredById} />
    }
    if (filterTypeOrder === 'kitchen') {
      return <Kitchen branchUuid={branchUuid} searchOrderId={searchOrderId} filteredById={filteredById} />
    }

    return null;
  }
  filterType = (e) => {
    store.set('orders_filterTypeOrder', e.target.value)
    this.setState({
      filterTypeOrder: e.target.value
    });
  }
  onSearch = (e) => {
    const searchOrderId = e.target.value;
    this.setState({
      searchOrderId: e.target.value,
      filteredById: !!searchOrderId,
    });

  }
  render() {
    const {
      isFetching,
      filterTypeOrder,
      searchOrderId
    } = this.state;
    const { t } = this.props;

    return (
      <Content className="bg-transparent table-content">
        <Spin spinning={isFetching}>
          <Row gutter={8}>
            <Col span={24}>
              <Row gutter={8}>
                <Col span={24}>
                  <div className="kitchenScreenHead">
								    <Radio.Group className="kitchenScreenHead__type zoomUpBtns" value={filterTypeOrder} size="large" buttonStyle="solid" onChange={this.filterType}>
                      <Radio.Button value="manager">History</Radio.Button>
                      <Radio.Button value="waiter">Orders</Radio.Button>
                      <Radio.Button value="kitchen">Kitchen</Radio.Button>
                    </Radio.Group>

                    <Search
                      allowClear
                      style={{ marginLeft: 'auto', maxWidth: '300px' }}
                      size="large"
                      className="searchField"
                      disabled={isFetching}
                      // ref={ele => this.searchText = ele}
                      onChange={this.onSearch}
                      placeholder={t('shareable.search_by_order', { defaultValue: 'Search by order ID' })}
                      value={searchOrderId} 
                      onPressEnter={this.onSearch}

                    />
                  </div>
                </Col>
              </Row>
              <div className="containerOrders">
                {this.renderOrders()}
              </div>
            </Col>
            </Row>
        </Spin>
      </Content>
    )
  }
}


export default withTranslation()(Orders);