import React from 'react';
import Settings from 'services/config/Settings';
import { Layout, Row, Button, Modal, message, List, Skeleton } from 'antd';
import { withTranslation } from 'react-i18next';
import { withWindowSizeListener } from 'react-window-size-listener';
import fetchJSON from 'services/utils/fetchJSON';
import { DeleteOutlined, TagOutlined, EditOutlined } from '@ant-design/icons';
import AddCategory from './components/AddCategory';
import EditCategory from './components/EditCategory';

import './styles.less';

const { Content } = Layout;

class ProductsCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      totalNumberList: 0,
      showAddCategory: false,
      showEditCategory: false,
      targetCategoryId: null
    };
  }
  componentDidMount() {  
    const { t } = this.props;
    this.mounted = true;
    document.title = `${t('shareable.prodCategories', { defaultValue: 'Products categories' })} | ${Settings.title}`;
    this.getCategories();
  }
  componentWillUnmout() {
    this.mounted = false;
  }
  componentDidUpdate(prevProps) {
    if (prevProps.restaurantId !== this.props.restaurantId) {
      this.getCategories();
    }
  }
  getCategories = () => {
    const bodyObj = {};
    if (this.props.restaurantId) { 
      bodyObj.restaurantId = this.props.restaurantId;
    }
    this.setState({
      isFetching: true
    });
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/products/categories/list', {
      method: 'post',
      body: bodyObj
    }).then(response => {
      console.log(response, 'response');
      if (this.mounted) {
        if (response.success) {
          this.setState({ 
            isFetching: false,
            data: response.data,
            totalNumberList: response.data.length
          });
        } else {
          this.setState({ 
            isFetching: false,
            data: [] 
          });
        } 
      }    
    }).catch(error => {
      console.log(error);
      if (this.mounted) { 
        this.setState({
          isFetching: false,
          data: [] 
        });
      }
    });
  }
  getData = () => {
    const data = [];
    if (!this.state.data) return data;

    let categories = this.state.data;
    if (!Array.isArray(categories) || !categories.length) {
      return data;
    }
    categories.forEach((categoryObj, i) => {
      const category = categoryObj.category;
      if (category) {
        data.push({
          key: i,
          id: category.id,
          title: category.display_name,
          description: category.description
        })  
      }
    });
    return data;
  }
  deleteCategoryRequest = (categoryId) => {
    Modal.confirm({
      className: "modal-btns-center",
      title: 'Are you sure you want to delete this category?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        this.deleteCategory(categoryId);
      }
    });
  }
  modalVisible = (key, value) => {
    this.setState({ [key]: value });
  }
  deleteCategory = (categoryId, callback = false) => {
    if (!callback) {
      this.setState({ isFetching: true });
    }
    fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/products/categories/delete/${categoryId}`, {
      method: 'put'
    }).then(response => {
      if (this.mounted) {
        if (response.success) {
          if (!callback) {
            message.success('Success!');
            this.setState({
              isFetching: false
            }, this.getCategories);
          } else {
            callback(200)
          }
        } else {
          if (!callback) {
            message.error('Something went wrong, please try again');
            this.setState({
              isFetching: false
            })
          } else {
            callback(400);
          }
        }
      }    
    }).catch(error => {
      console.log(error);
      if (this.mounted) {
        if (!callback) {
          message.error('Something went wrong, please try again');
          this.setState({
            isFetching: false
          });          
        } else {
          callback(400);
        }
      }
    })
  }
  editCategory = (category) => {
    this.setState({
      targetCategory: category,
      showEditCategory: true
    })
  }
  render() {
    const { 
      isFetching,
      totalNumberList,
      showAddCategory,
      targetCategory,
      showEditCategory
    } = this.state;

    const contentClass = !this.props.outer ? "bg-transparent table-content innerContent" : "bg-transparent table-content";
    //  <h2 style={{color: '#585858'}}>Categories</h2>
    return (
      <Content className={contentClass + " categories-list"}>
        <List
          loading={isFetching}
          itemLayout="horizontal"
          header={
            <Row className="categories-list--header__container">
              <h3 style={{color: '#6B6F6E'}}>Quantity: {totalNumberList}</h3>             
              <Button onClick={() => this.modalVisible('showAddCategory', true)}>Add category</Button>
              <p style={{marginBottom: '0px', marginTop: '16px'}}>Categories allow you to group products that you sell and show in the user's app.</p>
            </Row>
          }
          dataSource={ this.getData() }
          size="large"
          renderItem={item => (
            <List.Item
              actions={
                ['Top seller', 'Special'].indexOf(item.title) === -1 ? 
                  [<span>           
                    <Button type="link" onClick={() => this.editCategory(item)} style={{color: '#1C8DCC', fontSize: '18px'}}><EditOutlined/></Button>
                    <Button danger type="link" onClick={() => this.deleteCategoryRequest(item.id)} style={{fontSize: '18px'}}><DeleteOutlined/></Button>
                  </span>
                    ]
                  : []}
            >
              <Skeleton title={false} loading={isFetching}>
                <List.Item.Meta
                  avatar={<TagOutlined style={{fontSize: '20px', color: '#1C8DCC'}} />}
                  title={<span style={{fontSize: '16px', color: 'rgba(0, 0, 0, 0.85)'}}>{item.title}</span>}
                  description={item.description === 'default' ? '' : item.description}
                />
              </Skeleton>
            </List.Item>
          )}
        />
        { showAddCategory && <AddCategory
          history={this.props.history}
          handleClose={() => this.modalVisible('showAddCategory', false)}
          visible={showAddCategory} 
          updateCategoriesList={this.getCategories}
          restaurantId={this.props.restaurantId}
        /> }
        { showEditCategory && <EditCategory
          history={this.props.history}
          handleClose={() => this.modalVisible('showEditCategory', false)}
          visible={showEditCategory} 
          updateCategoriesList={this.getCategories}
          restaurantId={this.props.restaurantId}
          category={targetCategory}
        /> }
      </Content>
    )
  }
};

export default withTranslation()(withWindowSizeListener(ProductsCategories));