import React from 'react';
import fetchJSON from 'services/utils/fetchJSON';
import { withTranslation } from 'react-i18next';
import { Modal, Form, message, Radio, Button, Input, Spin, notification, Row, Col, InputNumber } from 'antd';
import { withWindowSizeListener } from 'react-window-size-listener';
import roundTo5Cents from 'roundto5cents'

const FormItem = Form.Item;

class PayGroup extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      putREQUEST: false,
      payment_type: '',
      orderUuid: null,
      selectedCategory: null,
      cartList: {},
      fetchingProducts: false,
      menuId: null,
      paymentMethod: true,
      receiptScreen: false,
      showList: true,
      table_id: null,
      receipt_option: null,
      customers_email: '',
      payNowScenario: false,
      repeatReceipt: false,
      change_amount: 0,
      remainderPrice: 0,
      orders_discount_amount: 0,
      orders_discount_value: 0,
      userRole: '',
      discount_comment: '',
      surcharges_amount: 0
    };
  }
  componentDidMount = () => {
    this.mounted = true;
    const { remainderPrice, userRole, surchargesAmount } = this.props
    this.setState({
      remainderPrice,
      userRole,
      surcharges_amount: surchargesAmount
    })
  }
  componentWillUnmount = () => {
    this.mounted = false;
  }

  handleSubmit = () => {
    const { t } = this.props;
    return this.formRef.current.validateFields()
    .then((values) => {
      console.log('values', values);
      if (!values.receipt) {
        this.setState({
          receiptScreen: true,
          paymentMethod: false
        });
        return;
      }

      this.setIsFetching(true);
      this.setState({
        putREQUEST: true
      });
      fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches-orders/ordersGroupPaid/`, {
        method: 'POST',
        body: values
      }).then(response => {
        console.log(response);
        this.setIsFetching(false);
        if (response.code === 200) {
          message.success('Success');

          this.props.renderList();
          this.setState({
            // putREQUEST: false,
            putSUCCESS: false,
            putFAILURE: false,
            paymentMethod: false,
            checkoutOpen: true
          }, this.props.handleClose);

        } else {
          this.setState({
            putREQUEST: false,
            putFAILURE: true,
            putSUCCESS: false,
            //errorMessage: response.message
          });
          message.error(response.message);
        }
       }).catch(error => {
          this.setIsFetching(false);
          console.log(error);
          this.setState({
            putSUCCESS: false,
            putREQUEST: false,
            putFAILURE: true,
          });
          message.error(t('shareable.ERR', { defaultValue: 'ERR' }));
       });
    }).catch((err) => console.log('validate err', err))
  }
  paymentTypeChanged = (e) => {
    this.setState({
      payment_type: e.target.value
    });
  }
  setIsFetching = (param) => {
    this.setState({
      fetchingProducts: param
    });
  }
  switchCheckout = (param) => {
    this.setState({
      checkoutOpen: param
    })
  }

  receiptOption = (e) => {
    this.setState({
      receipt_option: e.target.value
    });
    //this.handleSubmit();
  }
  openReceipt = () => {
    this.setState({
      repeatReceipt: true,
      paymentMethod: false,
      receiptScreen: true,
      checkoutOpen: false
    });
  }

  printReceipt = () => {
    const { branchUuid } = this.props;
    const { orderUuid } = this.state;
    this.setIsFetching(true);
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches-orders/submit/', {
      method: 'POST',
      body: {
        branchUuid,
        orderUuid,
        key: 'print_counter_receipt',
        value: true
      }
    }).then(response => {
      console.log(response);
      this.setIsFetching(false);
      notification.success({
        message: 'Ok',
      });
     }).catch(error => {
        console.log(error);
        this.setIsFetching(false);
     });
  }
  cashTendered = (e) => {
    const { remainderPrice } = this.props;
    const { orders_discount_amount } = this.state
    const total = roundTo5Cents(remainderPrice - orders_discount_amount)
    const entered = isNaN(parseFloat(e, 10)) ? 0 : parseFloat(e, 10)
    const change_amount = entered - total
    this.setState({
      change_amount
    })
  }

  showOrderTotal = (total) => {
    const { orders_discount_amount } = this.state

    total = isNaN(parseFloat(total, 10)) ? 0 : parseFloat(total-orders_discount_amount, 10)
    return `$${total.toFixed(2)}`
  }

  updateDiscountComment = (e) => {
    this.setState({
      discount_comment: e.target.value
    })
  }

  discountCalc = (e) => {
    const value = isNaN(parseFloat(e, 10)) ? 0 : parseFloat(e, 10)
    const { remainderPrice } = this.props
    const { surcharges_amount } = this.state
    const price = isNaN(parseFloat(remainderPrice, 10)) ? 0 : parseFloat(remainderPrice, 10)
    const orders_discount_amount = (price + surcharges_amount) * (value/100)

    this.setState({
      orders_discount_value: value,
      orders_discount_amount
    })
  }

  render() {
    const { branchUuid, remainderGroupList, branch } = this.props;
    const {
      putREQUEST,
      payment_type,
      fetchingProducts,
      paymentMethod,
      receiptScreen,
      receipt_option,
      customers_email,
      repeatReceipt,
      change_amount,
      remainderPrice,
      orders_discount_amount,
      orders_discount_value,
      userRole,
      discount_comment,
      surcharges_amount
    } = this.state;
    const { windowWidth, windowHeight } = this.props.windowSize;
    const containerStyle = {
      width: windowWidth,
      height: this.props.windowSize.windowHeight,
      minHeight: this.props.windowSize.windowHeight
    };
    const formCss = {
      width: windowWidth < 768 && windowWidth,
      height: windowWidth < 768 && `calc(${windowHeight}px - 60px)`,
      minHeight: windowWidth < 768 && `calc(${windowHeight}px - 60px)`
    };
    // const cartScreenCss = {
    //   ...formCss,
    //   opacity: showList || checkoutOpen ? 1 : 0,
    //   zIndex: showList || checkoutOpen ? 1009 : -100
    // };
    // remainderPrice
    return (
      <Modal
        className="general-modal-btns-center general-card createModalOrder createModalOrder--noCart"
        title=""
        centered={true}
        visible={this.props.visible}
        footer={null}
        onCancel={this.props.handleClose}
        destroyOnClose={true}
        confirmLoading={putREQUEST}
        style={ windowWidth < 769 ? containerStyle : {} }
      >
        { remainderGroupList && Array.isArray(remainderGroupList) && remainderGroupList.length ?
          <React.Fragment>
          <Spin spinning={fetchingProducts}>
           <Form
              requiredMark={false}
              className="general-form"
              layout="vertical"
              ref={this.formRef}
              onFinish={this.handleSubmit}
              initialValues={{
                branchUuid,
                orderUuids: remainderGroupList,
                customers_email,
                receipt: receipt_option,
                payNowScenario: true
              }}
              style={formCss}
            >
              <FormItem rules={[{
                required: true,
              }]} name="branchUuid" label="branchUuid" style={{display: 'none'}}>
                <Input placeholder="branchUuid"/>
              </FormItem>
              <FormItem rules={[{
                required: true,
              }]} name="orderUuids" label="orderUuids" style={{display: 'none'}}>
                <Input placeholder="orderUuids"/>
              </FormItem>
              <FormItem rules={[{
                required: true,
              }]} name="payNowScenario" label="payNowScenario" style={{display: 'none'}}>
                <Input placeholder="payNowScenario"/>
              </FormItem>
              <div style={{ display: !paymentMethod ? 'none' : ''}}>
                <h2 className="orderTypeRadio__title">Choose type of payment</h2>
                <FormItem rules={[{
                  required: paymentMethod,
                  message: 'Payment type is required!'
                }]} name="paymentType" label="">
                  <Radio.Group
                    className="orderTypeRadio"
                    size="large"
                    buttonStyle="solid"
                    onChange={this.paymentTypeChanged}
                    >
                    <Radio.Button value="card" disabled={payment_type === 'discount' && discount_comment === '' && orders_discount_amount != 0 ? true: false}>Card</Radio.Button>
                    <Radio.Button value="cash" disabled={payment_type === 'discount' && discount_comment === '' && orders_discount_amount != 0 ? true: false}>Cash</Radio.Button>
                    {userRole === 'waiter' ?
                      branch?.branch?.is_discount_for_foh_active === true ?
                        <Radio.Button className="PayDiscountBtn" value="discount">Discount</Radio.Button> : null
                      : <Radio.Button className="PayDiscountBtn" value="discount">Discount</Radio.Button>}
                  </Radio.Group>
                </FormItem>
                <FormItem rules={[{
                  required: payment_type === 'discount',
                  message: 'The note is required!'
                }]} name="discount_comment" label="Discount comments" style={{display: payment_type !== 'discount' ? 'none' : ''}}>
                  <Input
                    placeholder="Discount comments"
                    onChange={this.updateDiscountComment}
                  />
                </FormItem>
                <FormItem rules={[{
                  required: payment_type === 'discount',
                  message: 'The value is required!'
                }]} name="discount" label="Discount %" style={{display: payment_type !== 'discount' ? 'none' : ''}}>
                  <InputNumber
                    min={0}
                    max={100}
                    step={1}
                    defaultValue={orders_discount_value}
                    style={{width: '100%'}}
                    onChange={this.discountCalc}
                  />
                </FormItem>
                <Row gutter={16}>
                  <Col sm={12} xs={12}>
                    <FormItem
                      rules={[{
                        message: 'The note is required!'
                      }]}
                      name='total'
                      label='Total'
                      style={{ display: payment_type !== 'discount' ? 'none': '' }}>
                      <div style={{fontSize: '30px'}}>{this.showOrderTotal(remainderPrice)}</div>
                    </FormItem>
                  </Col>
                  <Col sm={12} xs={12}>
                    <FormItem rules={[{
                      message: 'The note is required!'
                    }]} name='discount_amount' label='Doscount:' style={{ display: payment_type !== 'discount' ? 'none' : '' }}>
                      <div style={{fontSize: '30px'}}>${orders_discount_amount.toFixed(2)}</div>
                    </FormItem>
                  </Col>
                </Row>
                <FormItem rules={[{
                  required: payment_type === 'cash',
                  message: 'Cash tendered is required!'
                }]} name="cash_tendered" label="Cash tendered" style={{ display: payment_type !== 'cash' ? 'none' : '' }}>
                  <InputNumber
                    placeholder="Cash tendered"
                    min={0}
                    step={0.01}
                    style={{width:'100%'}}
                    onChange={this.cashTendered}
                  />
                </FormItem>
                <Row gutter={16}>
                   <Col sm={12} xs={12}>
                    <FormItem rules={[{
                      message: 'The note is required!'
                    }]} name="total" label="Total" style={{ display: payment_type !== 'cash' ? 'none' : '' }}>
                      <div style={{fontSize: '30px'}}>${roundTo5Cents(remainderPrice-orders_discount_amount).toFixed(2)}</div>
                    </FormItem>
                   </Col>
                  <Col sm={12} xs={12}>
                    <FormItem rules={[{
                      message: 'The note is required!'
                    }]} name="change" label="Change" style={{ display: payment_type !== 'cash' ? 'none' : '' }}>
                      <div style={{fontSize: '30px'}}>${!!change_amount?change_amount.toFixed(2):0.00}</div>
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col sm={12} xs={12}>
                    <FormItem rules={[{
                      message: 'The note is required!'
                    }]} name="card_total" label="Total" style={{ display: payment_type !== 'card' ? 'none' : '' }}>
                      <div style={{fontSize: '30px'}}>{this.showOrderTotal(remainderPrice)}</div>
                    </FormItem>
                  </Col>
                </Row>
                <Button
                  className="createModalOrder__submit"
                  type="primary"
                  size="large"
                  htmlType="submit"
                  style={{ width: '100%' }}
                  disabled={ payment_type === 'discount' ? true : payment_type === 'cash' ? change_amount >= 0 ? false : true : false }
                >
                  { payment_type === 'discount' ? `Choose type of payment before Submit` : `Submit` }
                </Button>
              </div>
              <div style={{ display: !receiptScreen ? 'none' : ''}}>
                <h2 className="orderTypeRadio__title">Receipt</h2>
                <FormItem rules={[{
                  required: receiptScreen,
                  message: 'Payment type is required!'
                }]} name="receipt" label="">
                  <Radio.Group
                    className="orderTypeRadio"
                    size="large"
                    buttonStyle="solid"
                    onChange={this.receiptOption}
                    >
                    <Radio.Button value="print_receipt">Print receipt</Radio.Button>
                    <Radio.Button value="email_receipt">Email receipt</Radio.Button>
                    {!repeatReceipt ? <Radio.Button value="no_receipt">No Receipt</Radio.Button> : null }
                  </Radio.Group>
                </FormItem>
                <FormItem rules={[{
                  required: receipt_option === 'email_receipt',
                  message: 'The email is required!'
                }]} name="customers_email" type="email" label="Customer's email" style={{display: receipt_option !== 'email_receipt' ? 'none' : ''}}>
                  <Input placeholder="Email"/>
                </FormItem>
                <Button
                  className="createModalOrder__submit"
                  type="primary"
                  size="large"
                  htmlType="submit"
                  style={{ width: '100%' }}
                >Submit
                </Button>
              </div>

            </Form>
          </Spin>
          </React.Fragment>
          : null }
      </Modal>
    )
  }
};

export default withTranslation()(withWindowSizeListener(PayGroup));
