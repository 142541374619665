import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Input, Select } from 'antd';
import countries from './source';


function CountryPhoneInput({
  onChange,
  value,
  selectProps,
  ...props
}) {
  const defaultCountry = useMemo(() => {
    return countries.find((c) => c.short === 'AU');
  }, []);

  const [country, setCountry] = useState(defaultCountry);
  const [phone, setPhone] = useState();

  useEffect(() => {
    if (value !== undefined) {
      if (value.short) {
        setCountry(countries.find((c) => c.short === value.short));
      } else {
        setCountry(countries.find((c) => Number(c.phoneCode) === value.code));
      }
      setPhone(value.phone);
    }
  }, [value]);

  const triggerChange = useCallback((phone, country) => {
      const result = {
        phone,
        code: country && Number(country.phoneCode),
        short: country && country.short,
      };
      onChange(result);
    },
    [onChange]
  );

  const handleCountryChange = useCallback((value) => {
      const c = countries.find((c) => c.short === value);
      if (!c) {
        throw new Error(`Country not found: ${value}`);
      }
      setCountry(c);
      triggerChange(phone, c);
    },
    [setCountry, triggerChange, phone]
  );

  const handlePhoneChange = useCallback((e) => {
      const currentValue = e.target.value;
      setPhone(currentValue);
      triggerChange(currentValue, country);
    },
    [setPhone, country, triggerChange]
  );

  return (
    <Input
      prefix={
        <Select
          bordered={false}
          dropdownMatchSelectWidth={250}
          {...selectProps}
          optionLabelProp="label"
          value={country && country.short}
          onChange={handleCountryChange}
        >
          {countries.map((item) => {
            const fix = {
              key: item.short,
              value: item.short,
              label: `${item.emoji}  +${item.phoneCode}`,
            };
            return (
              <Select.Option {...props.optionProps} {...fix}>
                {item.emoji} {item.en} {item.phoneCode}
              </Select.Option>
            );
          })}
        </Select>
      }
      {...props}
      value={value && value.phone}
      onChange={handlePhoneChange}
    />
  );
}

export { CountryPhoneInput };
export default CountryPhoneInput;