/**
 * Auth to identify the user 
 * checks for a token in the local store
 */
import store from 'store';
import Cookies from 'js-cookie';

// import static env
import Settings from 'services/config/Settings';

class Authenticate {
  constructor(n){
    this.n = n;
  }
  /**
   * Save the token in Local Storage
   *
   * @param {string} token
   */
  static loginUser(token, userId = null) {
    localStorage.setItem('dashboard_userToken', token);
    Cookies.set('dashboard_userToken', token, { expires: 180, sameSite: 'strict' });
		if (userId) {
      if (Settings.analytics.heap !== 'null') {
        /* eslint-disable */
        try {
          heap.identify(userId);
        } catch(e) { }
        /* eslint-enable */
      }
		}
  }

  /**
   * Checking user authorization
   *
   * @returns {boolean}
   */
  static isUserAuthenticated() {
    return Cookies.get('dashboard_userToken') !== undefined || localStorage.getItem('dashboard_userToken') !== null;
  }

  /**
   * Deauthenticate a user. Remove a token from Local Storage.
   *
   */
  static deauthenticateUser() {
    store.clearAll()
    localStorage.removeItem('dashboard_userToken');
    Cookies.remove('dashboard_userToken');
    Cookies.remove('dontShowShelfTime');
    if (Settings.analytics.heap !== 'null') {
      /* eslint-disable */
      try {
        heap.resetIdentity();
      } catch(e) { }
      /* eslint-enable */
    }
  }

  /**
   * Get a token value.
   *
   * @returns {string}
   */

  static getToken() {
    // return Cookies.get('token');
    if (Cookies.get('dashboard_userToken') !== undefined) {
      return Cookies.get('dashboard_userToken');
    }
    if (localStorage.getItem('dashboard_userToken') !== null) {
      return localStorage.getItem('dashboard_userToken');
    }
    return null;
  }
  

}

export default Authenticate;