import fetchJSON from 'services/utils/fetchJSON';

const getBranches = function getBranches() {
    const bodyObj = {};
    if (this.props.restaurantId) { 
      bodyObj.restaurantId = this.props.restaurantId;
    }
    this.setState({
      isFetching: true
    });
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches/list', {
      method: 'put',
      body: bodyObj
    }).then(response => {
      if (this.mounted) {
        this.setState({ 
          isFetching: false, 
          branches: response.data
        }); 
      }    
    }).catch(error => {
      console.log(error);
      if (this.mounted) { 
        this.setState({
          isFetching: false
        });
      }
    });
  }
export {
  getBranches
};