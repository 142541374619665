import React from 'react';
import axios from 'axios'
import Auth from 'services/utils/Auth';
import { Upload, Modal, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { withTranslation, Trans } from 'react-i18next';

import './styles.less';

const confirm = Modal.confirm;

class ImageUpload extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
			previewImage: '',
			previewVisible: false,
			getWidth: true,
			containerSryle: {
				height: '180px'
			},
			fileList: [],
			notSquare: false
		}
    this.imageStyle = { display: 'none', height: 0 };
    this.intervalHeight = null;
  }
	componentDidMount() {
		this.mounted = true;
		const notSquareTypes = ['branch_banner_new', 'branch_logo_new'];
		if (notSquareTypes.indexOf(this.props.type) === -1) {
			this.intervalHeight = setInterval(this.getUploadWidth, 500);
		} else {
			this.setState({ notSquare: true })
		}
	}
  componentWillUnmount() {
    this.mounted = false;
    clearInterval(this.intervalHeight);
  }
 	getUploadWidth = () => {
 		const imageUpload = document.getElementById("demo");
 		if (imageUpload && imageUpload.offsetWidth) {
 			this.imageStyle = { height: `${imageUpload.offsetWidth}px`};
 			clearInterval(this.intervalHeight);
 			this.setState({
 				containerSryle: this.imageStyle
 			})
 		}
 	}
 	imageOrientation = (file) => new Promise((resolve, reject) => {
    try {
      const image = new Image();
      image.onload = function () {
      	let orientation;

      	if (image.naturalWidth > image.naturalHeight) {
		      orientation = 'landscape';
		    } else if (image.naturalWidth < image.naturalHeight) {
		      orientation = 'portrait';
		    } else {
		      orientation = 'even';
		    }
        resolve(orientation);
      };

      image.onerror = function () {
        reject(null);
      }
      image.src = window.URL.createObjectURL(file);
    } catch (e) {
    	console.error(e);
      reject(null)
    }
	});

  handleDrop = async data => {
  	// console.log(data);
  	const file = data.file;
  	const { t } = this.props;
		if ( file ) {
			if ( file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {
				if ( file.size > 5242880 ) {
					let errorLoadMessage =  t('venueImage.error.tooBig', { defaultValue: 'This image is too big! Max image file size is 5 mb.' });
					message.error(errorLoadMessage);
					data.onError();
					return false;
				}
			} else {
				let errorLoadMessage = t('venueImage.error.fileType', { defaultValue: 'Wrong file type! file type must be png, jpg or jpeg!' });
				message.error(errorLoadMessage);
				data.onError();
				return false;
			}
		} else {
			let errorLoadMessage =  t('venueImage.error.fileNull', { defaultValue: 'File was null!' });
			message.error(errorLoadMessage);
			data.onError();
			return false;
		}
		const orientation = await this.imageOrientation(file);

		// Initial FormData
		const formData = new FormData();
		formData.append("file", file);
		const params = {
			orientation
		};
		if (!this.props.type) {
			params.productId = this.props.productId;
			params.type = 'image';
			params.role = 'product_image';
		} else if (this.props.type === 'branch_logo_new') {
			params.type = 'image';
			params.role = 'logo';		
		} else if (this.props.type === 'branch_banner_new') {
			params.type = 'image';
			params.role = 'banner';	
		}


		const config = {
			headers: {
				"X-Requested-With" : "XMLHttpRequest",
				"Authorization" : `bearer ${Auth.getToken()}`
			},
			onUploadProgress: data.onProgress,
			params
		};
		this.props.changeUploadStatus(true);
		return axios.put(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/files/upload`, formData, config)
			.then(response => {
				console.log(response.data);
				data.onSuccess(response);
				const { fileList } = this.state;
				fileList.map(file => {
					if (file.uid === data.file.uid) {
			      if (!this.props.type) {
			      	this.props.setFieldsValue('file_id', response.data.uid);
			      } else if (this.props.type === 'branch_logo_new') {
			      	this.props.setFieldsValue('file_id_logo', response.data.uid);
			      } else if (this.props.type === 'branch_banner_new') {
			      	this.props.setFieldsValue('file_id_banner', response.data.uid);
			      }
						this.props.setImageCoverUrl(response.data.imageUrl);
						file.uid = response.data.uid;
					}
					return file;
				});
				this.props.changeUploadStatus(false);
				this.setState({ fileList })
			})
			.catch(error => {
				this.props.changeUploadStatus(false);
				console.log(error);
				let errorLoadMessage = t('venueImage.error.couldntUpload', { defaultValue: 'Could not upload file. Please try again!' });
				message.error(errorLoadMessage);
				data.onError();
		})
	}

	handleCancel = () => this.setState({ previewVisible: false })
	handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  }
  handleChange = ({ fileList }) => this.setState({ fileList })
  onRemove = params => {
  	const { t } = this.props;
  	return new Promise((resolve, reject) => {
  		confirm({
		    title: t('venueImage.deleteQuestion', { defaultValue: 'Do you want to delete this image?' }),
		    onOk: () => {
		      message.success(t('venueImage.fileDeleted', { defaultValue: 'File deleted' }));
		      if (!this.props.type) {
		      	this.props.setFieldsValue('file_id', '');
		      } else if (this.props.type === 'branch_logo_new') {
		      	this.props.setFieldsValue('file_id_logo', '');
		      } else if (this.props.type === 'branch_banner_new') {
		      	this.props.setFieldsValue('file_id_banner', '');
		      }
		      this.props.setImageCoverUrl('');
			    resolve();
		    },
		  });
  	})
  }
  render() {
  	const { previewVisible, previewImage, fileList, containerSryle, notSquare } = this.state;
  	const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text"><Trans i18nKey="shareable.upload">Upload</Trans></div>
      </div>
    );
    const divClassName = notSquare ? 'clearfix image-fix not-square' : 'clearfix image-fix';
    return (
      <div>
        <label className="label-image">
        	{this.props.label ? this.props.label : 'Image'}
        </label>
        <p><Trans i18nKey="venueImage.imageRequirements">Maximum size - 5mb</Trans>. <br/>Recommended aspect ratio: 1 x 1 (Square).</p>
				<div id="demo" className={divClassName} style={containerSryle}>
					{ Object.keys(containerSryle).length ?
		        <Upload
		          action="https://server.dezy.com.auhttps://server.dezy.com.au/api/v3/bootstrap/locations/upload"
		          accept="image/*"
		          multiple={false}
		          customRequest={this.handleDrop}
		          listType="picture-card"
		          fileList={fileList}
		          onPreview={this.handlePreview}
		          onChange={this.handleChange}
		          onRemove={this.onRemove}
		        >
	          	{ fileList.length > 0 ? null : uploadButton }
		        </Upload> 
		       : null }
	        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
	          <img alt="example" style={{ width: '100%' }} src={previewImage} />
	        </Modal>
	      </div>
      </div>
    )
  }
};

export default withTranslation()(ImageUpload);