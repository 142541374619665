import fetchJSON from 'services/utils/fetchJSON';
import React from 'react';
import { Select } from 'antd';

const Option = Select.Option;

const defaultGeneralReportObj = {
  revenue: 0,
  totalOrders: 0,
  typeOfOrders: [],
  paymentType: [],
  ordersPerHour: [],
  ordersPerDay: [],
  ordersPerDayByType: [],
  productsByCount: []
};
const defaultProductsReportObj = {
  //productsByRevenue: [],
  productsByCount: []
};
const defaultTransactionsReportObj = {
  transactions: []
}

const getRestaurantsListSimple = function getRestaurantsListSimple() {
  this.setState({
    isFetching: true
  });
  fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/reports/restaurantsList', {
    method: 'get'
  }).then(response => {
    if (this.mounted) {
      this.setState({
        isFetching: false,
        restaurantsList: response.data,
        initialDataLoaded: this.userRole === 'admin'
      });
    }
  }).catch(error => {
    console.log(error);
    if (this.mounted) {
      this.setState({
        isFetching: false,
        restaurantsList: []
      });
    }
  });
}

const getBranchesList = function getBranchesList(isReports = true, restaurantId = null) {
  let { targetRestaurantId, activeTab } = this.state;
  if (restaurantId !== null) {
    targetRestaurantId = restaurantId
  }

  this.setState({
    isFetching: true,
    branchesListLoaded: false
  });
  fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/reports/getListBranches', {
    method: 'put',
    body: {
      restaurantId: targetRestaurantId
    }
  }).then(response => {
    if (this.mounted) {
      const { initialDataLoaded, targetBranchId, targetBranchUuid } = this.state;
      this.setState({
        isFetching: false,
        branchesList: response.data,
        branchesListLoaded: true,
        initialDataLoaded: this.userRole !== 'admin' || initialDataLoaded,
        targetBranchId: response.data && response.data.length === 1 ? response.data[0].id : targetBranchId,
        targetBranchUuid: response.data && response.data.length === 1 ? response.data[0].uuid : targetBranchUuid
      }, () => {
        if (response.data && response.data.length === 1 && isReports) {
          this.getReportData(activeTab);
        }
      });
    }
  }).catch(error => {
    console.log(error);
    if (this.mounted) {
      this.setState({
        isFetching: false,
        branchesList: []
      });
    }
  });
}
const renderRestaurantsOptions = function renderRestaurantsOptions() {
  const { restaurantsList } = this.state;
  const render = [];
  if (restaurantsList) {
    restaurantsList.forEach((restaurant, i) => {
      render.push(<Option key={i} value={restaurant.id}>{restaurant.name}</Option>)
    });
  }
  return render;
}
const renderBranchesOptions = function renderBranchesOptions() {
  const { branchesList } = this.state;
  const render = [];
  if (branchesList) {
    branchesList.forEach((branch, i) => {
      render.push(<Option key={i} value={branch.id}>{branch.title}</Option>)
    });
  }
  if (branchesList.length > 1) {
    render.push(<Option key={-1} value={-1}>All</Option>);
  }
  return render;
}
const renderBranchesOptionsUuid = function renderBranchesOptionsUuid() {
  const { branchesList } = this.state;
  const render = [];
  if (branchesList) {
    branchesList.forEach((branch, i) => {
      render.push(<Option key={i} value={branch.uuid}>{branch.title}</Option>)
    });
  }
  return render;
}
const getEmptyReportObj = function getEmptyReportObj(dataType) {
  let emptyReportObj;
  switch(dataType) {
    case 'general':
      emptyReportObj = defaultGeneralReportObj;
      break;
    case 'transactions':
      emptyReportObj = defaultTransactionsReportObj;
      break;
    case 'products':
      emptyReportObj = defaultProductsReportObj;
      break;
    default:
      break;
  }
  return emptyReportObj;
}

const getReportData = function getReportData(dataType) {
  const { filterTypeOrder, rangePickerValue, targetRestaurantId, targetBranchId } = this.state;
  let urlToFetch;
  let emptyReportObj = this.getEmptyReportObj(dataType);
  switch(dataType) {
    case 'general':
      urlToFetch = 'getFullData';
      break;
    case 'transactions':
      urlToFetch = 'getTransactionsData';
      break;
    case 'products':
      urlToFetch = 'getProductsData';
      break;
    default:
      break;
  }
  if (urlToFetch && emptyReportObj) {
    this.setState({
      analiticsLoading: true,
      reportsData: emptyReportObj
    });
    fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/reports/${urlToFetch}`, {
      method: 'post',
      body: {
        restaurantId: targetRestaurantId,
        branchId: targetBranchId,
        dates: rangePickerValue,
        orderType: filterTypeOrder
      }
    }).then(response => {
      if (this.mounted) {
        this.setState({
          reportsData: response.data,
          analiticsLoading: false
        })
      }
    }).catch(error => {
      console.log(error);
      if (this.mounted) {
        this.setState({
          analiticsLoading: false,
          reportsData: emptyReportObj
        });
      }
    });
  }
}

export {
  getRestaurantsListSimple,
  renderRestaurantsOptions,
  getBranchesList,
  renderBranchesOptions,
  getReportData,
  getEmptyReportObj,
  renderBranchesOptionsUuid,
  // emptyObjectsForTabChange
  defaultGeneralReportObj,
  defaultProductsReportObj
};
