import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Row, Modal, Form, Input, Col, Select } from 'antd';
import CountryPhoneInput from 'components/AntdCountryPhoneInput';
import * as _sharedModulesEmployees from '../../../../modules/employees'; 
import * as _sharedModulesReports from '../../../../modules/reports'; 

const FormItem = Form.Item;
const { Option } = Select;

class EditEmployee extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    const rolesList = this.getRolesList();
    const { branchesList } = this.props;
    const showFieldsObject = this.getInitialShowObject();
    this.state = {
      putREQUEST: false,
      rolesList,
      branchesList,
      showFieldsObject
    };
    Object.keys(_sharedModulesEmployees).forEach((_moduleName) => {
      this[_moduleName] = _sharedModulesEmployees[_moduleName].bind(this);
    });
    this['renderBranchesOptionsUuid'] = _sharedModulesReports['renderBranchesOptionsUuid'].bind(this);
  }
  componentDidMount = () => {
    this.mounted = true;
  }
  componentWillUnmount = () => {
    this.mounted = false;
  }
  getInitialShowObject = () => {
    const { user } = this.props;
    const initialShowObject = {
      branch: ['Manager', 'FOH', 'Kitchen Staff'].indexOf(user.role) > -1 && this.props.role !== 'manager',
      email: user.role === 'Owner'
    }
    return initialShowObject
  }
  getRolesList = () => {
    const { role } = this.props;
    const fullRolesList = [];
    if (['admin', 'owner'].indexOf(role) > -1) {
      fullRolesList.push({
        key: 'manager',
        value: 'Manager'
      });
    }
    fullRolesList.push({
      key: 'waiter',
      value: 'FOH'
    }, {
      key: 'kitchen',
      value: 'Kitchen Staff'
    });
    return fullRolesList;
  }
  validatePhone = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || !value.phone) {
        return Promise.reject('Phone is required!')
      } else {
        if (value.phone.length < 7) {
          return Promise.reject(<p><Trans i18nKey="shareable.reqPhone.minLength">Phone should be 8 symbols and longer</Trans></p>);
        }
        const isnum = /^\d+$/.test(value.phone);
        if (!isnum) {
          return Promise.reject(<p><Trans i18nKey="shareable.reqPhone.onlyNumber">Please, enter correct phone</Trans></p>);
        }
      }
      return Promise.resolve();
    },
    validateTrigger: 'onSubmit'
  })
  updateShowFiledsObject = () => {
    if (this.formRef.current) {
      const choosenRole = this.formRef.current.getFieldValue('role');
      if (choosenRole) {
        const newShowFieldsObject = {};
        newShowFieldsObject.branch = ['manager', 'waiter', 'kitchen'].indexOf(choosenRole) > -1 && this.props.role !== 'manager';
        newShowFieldsObject.email = choosenRole === 'owner';
        this.setState({
          showFieldsObject: newShowFieldsObject
        })
      }
    }
  }
  render() {
    const { t, user } = this.props;
    const { 
      putREQUEST,
      rolesList,
      showFieldsObject
    } = this.state;
    return (
      <Modal 
        className="general-modal-btns-center general-card"
        title="Edit employee"
        centered={true}
        visible={this.props.visible}
        okButtonProps={{ htmlType: "submit" }}
        okText="Save"
        onOk={() => this.editEmployee()}
        onCancel={this.props.handleClose}
        destroyOnClose={true}
        confirmLoading={putREQUEST}
      >
        <Form 
          requiredMark={false} 
          className="general-form" 
          layout="vertical" 
          ref={this.formRef} 
          initialValues={{
            userUuid: user.uuid,
            name: user.name,
            phoneObj: user.phoneObj,
            role: user.role,
            branchUuid: user.branchUuid
          }}
        > 
          <Row>
            <Col span={24}>
              <FormItem rules={[{
                required: true,
                message: t('shareable.required.phone', { defaultValue: 'Role is required!' })
              }]} name="role" label="Role">
                <Select placeholder="Role" onChange={this.updateShowFiledsObject}>
                  {rolesList.map((item, i) => (
                    <Option key={i} value={item.key}>{item.value}</Option>
                  ))}
                 </Select>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormItem rules={[{
                required: true,
                message: t('shareable.required.name', { defaultValue: 'Name is required!' })
              }]} name="name" label="Name">
                <Input placeholder="Name"/>
              </FormItem>
            </Col>
          </Row>
          <Row style={{display: 'none'}}>
            <Col span={24}>
              <FormItem rules={[{
                required: true,
              }]} name="userUuid" label="userUuid">
                <Input placeholder="userUuid"/>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormItem rules={[this.validatePhone]} name="phoneObj" label="Phone">
                <CountryPhoneInput className="phoneCodeInput" placeholder="Phone" />
              </FormItem>
            </Col>
          </Row>
          { showFieldsObject.branch ?
          <Row>
            <Col span={24}>
              <FormItem rules={[{
                required: true,
                message: t('shareable.required.venue', { defaultValue: 'Venue is required!' })
              }]} name="branchUuid" label="Venue">
                <Select placeholder="Venue">
                  {this.renderBranchesOptionsUuid()}
                 </Select>
              </FormItem>
            </Col>
          </Row> : null }
          { showFieldsObject.email ?
          <Row className="error-fix">
            <Col span={24}>
              <FormItem name="email" label="Email" 
              rules={[{
                type: 'email',
                message: t('login.email.wrongFormat', { defaultValue: 'Wrong format of email' }),
                validateTrigger: 'onSubmit'
              }, { required: true, message: t('shareable.enterTitlePlease', { defaultValue: 'Please enter owner email' }) }]} >
                <Input placeholder="Email" />
              </FormItem>
            </Col>
          </Row> : null }
        </Form> 
      </Modal>
    )
  }
};

export default withTranslation()(EditEmployee);