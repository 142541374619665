import React from 'react';
import axios from 'axios'
import Auth from 'services/utils/Auth';
import { Upload, Modal, message } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';
import { PlusOutlined } from '@ant-design/icons';
import { withTranslation, Trans } from 'react-i18next';

const confirm = Modal.confirm;

class ImageBranch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
			previewImage: '',
			previewVisible: false,
			getWidth: true,
			fileList: []
		}
  }
	componentDidMount() {
		this.mounted = true;
		if (this.props.file && typeof this.props.file === 'object' && Object.keys(this.props.file).length) {
			let fileList = [{
				uid: this.props.file.id,
				status: 'done',
				url: `https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/files/origin/${this.props.file.file}`
			}];
			this.setState({
				fileList
			})
		} else if (this.props.images && this.props.images.length) {
			let fileList = this.props.images.map((obj, key) => {
				return {
					uid: obj.image.id,
					status: 'done',
					url: `https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/files/origin/${obj.image.file}`
				}
			})
			this.setState({
				fileList
			})
		}
	}
  componentWillUnmount() {
    this.mounted = false;
  } 

  handleDrop = data => {
  	// console.log(data);
  	const file = data.file;
  	const { t } = this.props;
		if ( file ) {
			if ( file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {
				if ( file.size > 5242880 ) {
					let errorLoadMessage =  t('venueImage.error.tooBig', { defaultValue: 'This image is too big! Max image file size is 5 mb.' });
					message.error(errorLoadMessage);
					data.onError();
					return false;
				}
			} else {
				let errorLoadMessage = t('venueImage.error.fileType', { defaultValue: 'Wrong file type! file type must be png, jpg or jpeg!' });
				message.error(errorLoadMessage);
				data.onError();
				return false;
			}
		} else {
			let errorLoadMessage =  t('venueImage.error.fileNull', { defaultValue: 'File was null!' });
			message.error(errorLoadMessage);
			data.onError();
			return false;
		}

		// Initial FormData
		const formData = new FormData();
		formData.append("file", file);
		const config = {
			headers: {
				"X-Requested-With" : "XMLHttpRequest",
				"Authorization" : `bearer ${Auth.getToken()}`
			},
			onUploadProgress: data.onProgress,
			params: {
				branchId: this.props.branchId,
				type: 'image',
				role: this.props.type
			}
		};
		this.props.changeUploadStatus(true);
		return axios.put(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/files/upload`, formData, config)
			.then(response => {
				// console.log(response);
				data.onSuccess(response);
				const { fileList } = this.state;
				fileList.map(file => {
					if (file.uid === data.file.uid) {
						file.uid = response.data.uid;
					}
					return file;
				});
				this.props.changeUploadStatus(false);
				this.setState({ fileList })
			})
			.catch(error => {
				console.log(error);
				this.props.changeUploadStatus(false);
				let errorLoadMessage = t('venueImage.error.couldntUpload', { defaultValue: 'Could not upload file. Please try again!' });
				message.error(errorLoadMessage);
				data.onError();
		})
	}

	handleCancel = () => this.setState({ previewVisible: false })
	handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  }
  handleChange = ({ fileList }) => this.setState({ fileList })
  onRemove = params => {
  	const { t, changeUploadStatus } = this.props;
  	return new Promise((resolve, reject) => {
  		confirm({
		    title: t('venueImage.deleteQuestion', { defaultValue: 'Do you want to delete this image?' }),
		    onOk() {
		    	changeUploadStatus(true);
		      fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/files/${params.uid}`, {
					  method: 'DELETE'
					}).then(response => {
						changeUploadStatus(false);
					  if (response.success) {
					    message.success(t('venueImage.fileDeleted', { defaultValue: 'File deleted' }));
					    resolve()
					  } else {
					  	reject()
					  }
					 }).catch(error => {
					 		changeUploadStatus(false);
					    console.log(error);
					    reject()
					 })
		    },
		    onCancel() {

		    },
		  });

  	})
		
  }
  render() {
  	const { previewVisible, previewImage, fileList } = this.state;
  	const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text"><Trans i18nKey="venueImage.upload">Upload</Trans></div>
      </div>
    );
    return (
      <div>
        <label className="label-image" style={{color: '#5B5B5B'}}>
        	{this.props.type === 'logo' ? 'Logo image' : 'Banner image'}
        </label>
        <p><Trans i18nKey="venueImage.imageRequirements">Maximum size - 5mb</Trans>. Recommended upload size of {
        	this.props.type === 'logo' ? '400 x 400' : '500 x 1000' 
        } pixels. Recommended aspect ratio: {
        	this.props.type === 'logo' ? '1 x 1' : '1 x 2' 
        }.</p>
				<div id="demo" className="clearfix image-fix not-square">
	        <Upload
	        	accept="image/*"
	          action="https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/files/upload"
	          multiple={false}
	          customRequest={this.handleDrop}
	          listType="picture-card"
	          fileList={fileList}
	          onPreview={this.handlePreview}
	          onChange={this.handleChange}
	          onRemove={this.onRemove}
	        >
          	{ fileList.length > 0 ? null : uploadButton }
	        </Upload>
	        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
	          <img alt="example" style={{ width: '100%' }} src={previewImage} />
	        </Modal>
	      </div>
      </div>
    )
  }
};

export default withTranslation()(ImageBranch);