import React from 'react';
import {
  Row,
  Col,
  Table,
  Button,
  Form,
  Modal,
  message,
  Input,
  Switch,
  Spin,
  Tag
} from 'antd';
import fetchJSON from 'services/utils/fetchJSON';
import ShowMenu from './ShowMenu';
import ScheduleSettings from './ScheduleSettings';
import { withTranslation, Trans } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';

const confirm = Modal.confirm;

class MenusTable extends React.Component {
  constructor() {
    super()
    this.state = {
      isFetching: false,
      showMenu: false,
      showEditName: false,
      selectedMenuId: null,
      selecedMenuItem: {}
    }
    this.formRef2 = React.createRef();
  }
	componentDidMount = () => {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  columns = (platform = false) => {
    // let { filteredInfo, branchFilters } = this.state;
    // filteredInfo = filteredInfo || {};
    const columns = [{
      title: 'Name',
      dataIndex: 'display_name',
      key: 'display_name',
      render: (text, record) => {
        return (
          <a href="#show" onClick={() => this.showMenu(record)}>{text}</a>
        )
      }
    }, {
      title: 'Default',
      dataIndex: 'default',
      key: 'default',
      render: (text, record) => {
        return (
          record.is_default ? 
          <Tag color="processing">DEFAULT</Tag>
          :
          <Switch 
            onChange={(e) => this.changeDefaultMenu(e, record)}
            checked={record.is_default}
          />
        )
      }
    },{
      title: 'Schedule',
      dataIndex: 'schedule',
      key: 'schedule',
      render: (text, record) => {
        if (record.is_default) return null;
        const {schedule} = record;
        return (
          Object.keys(schedule).length > 0 ? 
          <div>
            <span onClick={() => this.showScheduleSetting(record)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
              {Array.isArray(schedule.timeRange) && schedule.timeRange.length ? schedule.timeRange.join(' - ') : null }
              &nbsp;({Array.isArray(schedule.days) && schedule.days.length ? schedule.days.join(', ') : null })
            </span>
            <Button
              danger
              size="small"
              onClick={() => this.deleteSchedule(record)}
              style={{marginLeft: '15px'}}><DeleteOutlined /></Button>
          </div>
          :
          <a href="#set" onClick={() => this.showScheduleSetting(record)}>Set</a>
        )
      }
    }, {
      title: 'Active menu',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (text, record) => {
        if (!record.is_active) return null;
        return <Tag color="success">ACTIVE NOW</Tag>
      }
    }, {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <React.Fragment>
            <Button onClick={() => this.showEditName(record)} size="small">
              Edit menu name
            </Button>
            { record.is_active ? <Button onClick={this.openPreview} style={{marginLeft: '12px'}} size="small">Preview</Button> : null }
          </React.Fragment>
        )
      }
    }];

    return columns;
  }
  openPreview = () => {
    const {
      branchObj
    } = this.props;

    const url = 'https://app.dezy.com.au/i/' + branchObj.url;

    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null
  }
  changeDefaultMenu = (checked, record) => {
    console.log('record', record);
    if (!checked) {
      message.error("You can't uncheck the default option, set a new menu as a default");
      return;
    }
    const {
      t,
      branchUuid
    } = this.props;

    this.setState({
      isFetching: true
    });
    fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/menus/setDefaultMenu`, {
      method: 'post',
      body: {
        menuId: record.menu_id,
        branchUuid
      }
    }).then(response => {
      console.log(response);
      if (response.success) {
        this.setState({ 
          isFetching: false,
        }, () => {
          this.props.getMenusList();
        });
        message.success('Success!');
      } else {
        this.setState({
          isFetching: false,
        });
        message.error(t('shareable.error', { defaultValue: 'Error' }));
      }
     }).catch(error => {
      console.log(error);
      this.setState({
        isFetching: false,
      });
      message.error(t('shareable.error', { defaultValue: 'Error' }));
    });
  }
  showMenu = (record) => {
    this.setState({
      showMenu: true,
      selectedMenuId: record.menu_id
    })
  }
  showEditName = (record) => {
    this.setState({
      showEditName: true,
      menuDisplayName: record.display_name,
      selectedMenuId: record.menu_id
    })
  }
  showScheduleSetting = (record) => {
    this.setState({
      showScheduleSetting: true,
      selectedMenuId: record.menu_id,
      selecedMenuItem: record
    })
  }
  deleteSchedule = (record) => {
    console.log('record', record);
    confirm({
      title: `Are you sure you want to delete the schedule?`,
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.setState({
            isFetching: true
          });
          fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/menus/deleteScheduleMenu', {
            method: 'DELETE',
            body: {
              menuId: record.menu_id
            }
          }).then(response => {
            console.log(response);
            this.setState({
              isFetching: false
            });
            if (response.success) {
              this.props.getMenusList();
              resolve();
            }
            reject();
           }).catch(error => {
              console.log(error);
              reject();
              this.setState({
                isFetching: false
              });
           });
        }).catch((err) => console.log('Oops errors!', err));
      },
      onCancel() {},
    });
  }
  getData = () => {
    let data = [];
    const { menusList } = this.props;
    if (menusList && Array.isArray(menusList) && menusList.length) {
      data = menusList.map((menu, key) => {
        const {
          display_name,
          id,
          is_default,
          schedule,
          is_scheduled,
          is_active
        } = menu;
        return {
          key,
          display_name,
          default: menu.default ? 'yes': 'no',
          menu_id: id,
          is_default,
          schedule,
          is_scheduled,
          is_active
        };
      });
    }
    return data;
  }
  handleTableChange = (pagination, filters, sorter) => {
    console.log('handleTableChange', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  }
  modalVisible = (key, value) => {
    this.setState({ [key]: value });
  }
  submitEditMenu = () => {
    return this.formRef2.current.validateFields()
      .then((values) => {
        const { t } = this.props;
        const { selectedMenuId } = this.state;

        values.menuId = selectedMenuId;

        this.setState({
          putREQUEST: true
        });
        fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/menus/updateSingleMenu`, {
          method: 'post',
          body: values
        }).then(response => {
          console.log(response);
          if (response.success) {
            this.setState({ 
              putREQUEST: false,
              putSUCCESS: true,
              putFAILURE: false
            }, () => {
              this.props.getMenusList();
              this.setState({ showEditName: false})
            });
            message.success('Success!');
          } else {
            this.setState({
              putREQUEST: false,
              putFAILURE: true,
              putSUCCESS: false,
            });
            message.error(t('shareable.error', { defaultValue: 'Error' }));
          }
         }).catch(error => {
            console.log(error);
            this.setState({
              putSUCCESS: false,
              putREQUEST: false,
              putFAILURE: true,
            });
            message.error(t('shareable.error', { defaultValue: 'Error' }));
         });
      })
  }
  render() {
    const {
      showMenu,
      showEditName,
      selectedMenuId,
      menuDisplayName,
      isFetching,
      showScheduleSetting,
      selecedMenuItem
    } = this.state;
    const { t } = this.props;

    return (
    	<Row>
        <Col span={24} style={{ margin: '24px -12px 0 -12px' }}>
          <Spin spinning={isFetching}>
          	<Table
              size={"middle"}
              className="generalTable innerTable"
              columns={ this.columns() }
              dataSource={ this.getData() }
              onChange={ this.handleTableChange }
              pagination={false}
            />
          </Spin>
        </Col>
        <React.Fragment>
        {
          showMenu ?
          <ShowMenu
            role={this.props.role}
            history={this.props.history}
            restaurantId={this.props.restaurantId}
            branchUuid={this.props.branchUuid}
            menuId={selectedMenuId}
            handleClose={() => this.modalVisible('showMenu', false)}
          />
          : null
        }
        </React.Fragment>
        <React.Fragment>
        {
          showScheduleSetting ?
          <ScheduleSettings
            branchUuid={this.props.branchUuid}
            menuId={selectedMenuId}
            menuItem={selecedMenuItem}
            getMenusList={this.props.getMenusList}
            handleClose={() => this.modalVisible('showScheduleSetting', false)}
          />
          : null
        }
        </React.Fragment>
        <React.Fragment>
       {
          showEditName ?
          <Modal
            visible={showEditName} 
            onOk={this.submitEditMenu}
            onCancel={() => this.setState({ showEditName: false})}
            destroyOnClose={true}
            >
              <Form ref={this.formRef2} initialValues={{ display_name: menuDisplayName }}>
                <Row>
                  <h2>Edit menu</h2>
                </Row>
                <div>
                  <Row>
                    <label className="formDefault__label">
                      <Trans i18nKey="shareable.display_name">Display name</Trans>
                    </label>
                  </Row>
                  <Row id='formitem_display_name'>
                    <Form.Item name="display_name" rules={[{
                      required: true,
                      message: t('shareable.required.name', { defaultValue: 'Please enter a name' }) }]}>
                      <Input placeholder={t('shareable.display_name', { defaultValue: 'Display name' })}  />
                    </Form.Item>
                  </Row>
                </div>
              </Form>
          </Modal>
          : null
        }
       </React.Fragment>
      </Row>
    )
  }
};

export default withTranslation()(MenusTable);