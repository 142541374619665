import Cookies from 'js-cookie';
import * as Fingerprint2 from 'fingerprintjs2'
import * as UAParser from 'ua-parser-js'

export const getUniqueKey = () => {
	if (Cookies.get('wheeler') === undefined) {
		const generate = require('nanoid/generate');
		const wheeler = generate('1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', 15);
		Cookies.set('wheeler', wheeler, { expires: 365 });
		return wheeler;
	} else {
		return Cookies.get('wheeler');
	}
}

export const getFingerprint = () => {
	return new Promise((resolve, reject) => {
    async function getHash () {
      const options = {
        excludes: {
          plugins: true,
          localStorage: true,
          adBlock: true,
          screenResolution: true,
          availableScreenResolution: true,
          enumerateDevices: true,
          pixelRatio: true,
          doNotTrack: true,
          preprocessor: (key, value) => {
            if (key === 'userAgent') {
              const parser = new UAParser(value)
              // return customized user agent (without browser version)
              return `${parser.getOS().name} :: ${parser.getBrowser().name} :: ${parser.getEngine().name}`
            }
            return value
          }
        }
      }

      try {
        const components = await Fingerprint2.getPromise(options)
        const values = components.map(component => component.value)
        //console.log('fingerprint hash components', components)

        return String(Fingerprint2.x64hash128(values.join(''), 31))
      } catch (e) {
        reject(e)
      }
    }

    if (window.requestIdleCallback) {
      console.log('get fp hash @ requestIdleCallback')
      requestIdleCallback(async () => resolve(await getHash()))
    } else {
      console.log('get fp hash @ setTimeout')
      setTimeout(async () => resolve(await getHash()), 500)
    }
  })
}

//console.log('analytics file');
export default (event, params = {}) => {
	/* eslint-disable */
	let heapId = '';

	try {
		// console.log('analytics event', event);
		heap.track(event, params);
		const heapObj = JSON.parse(JSON.stringify(heap));
		heapId = heapObj.userId;
	} catch(e) { }	
	
}