import React from 'react';
//import i18n from 'i18next';

class App extends React.Component {
	componentDidMount = () => {		
  }
  render() {
    return (
			<React.Fragment>
				{ this.props.children }
			</React.Fragment>
    )
  }
};

export default App;