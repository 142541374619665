import React from 'react';
import fetchJSON from 'services/utils/fetchJSON';
import { withTranslation, Trans } from 'react-i18next';
import { Row, Modal, Form, Input, Col, Select, message, Switch, Spin, Checkbox, Button } from 'antd';
import ImageUpload from '../../../components/ImageUpload/ImageUpload';
import _ from 'lodash';
import AddModifierSets from '../ModifierSets/components/AddModifierSets';

import './styles.less';

const FormItem = Form.Item;
const Option = Select.Option; 
const { TextArea } = Input;

class AddProduct extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      putREQUEST: false,
      categoriesLoaded: false,
      initialValues: {
        vat: 10,
        show_online: 1,
        is_alcohol: false
      },
      modifierSetsList: [],
      modifierSetsConnected: [],
      showAddModifierSets: false
    };
    this.modifierSetsModal = null;
  }
	componentDidMount = () => {
    this.setState({ putREQUEST: false })
    this.mounted = true;
    this.getCategories();
    this.getModifiers();
  }
  componentWillUnmount = () => {
    this.mounted = false;
  }
  componentDidUpdate(prevProps) {
    if (prevProps.restaurantId !== this.props.restaurantId) {
      this.getCategories();
    }
  }
  getModifiers = (modifier_id = false) => {
    const bodyObj = {};
    if (this.props.restaurantId) { 
      bodyObj.restaurantId = this.props.restaurantId;
    }

    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/products/modifiers/list', {
      method: 'post',
      body: bodyObj
    }).then(response => {
      if (this.mounted) {
        if (response.success) {
          this.setState({ 
            modifierSetsList: response.data,
          }, () => {
            if (modifier_id) {
              console.log('modifier_id', modifier_id);
              this.modifierSetToProduct(modifier_id);
              if (this.modifierSetsModal) {
                this.modifierSetsModal.destroy()
              }
            }
          });
        } else {
          this.setState({ 
            modifierSetsList: [] 
          });
        } 
      }    
    }).catch(error => {
      console.log(error);
      if (this.mounted) { 
        this.setState({
          modifierSetsList: [] 
        });
      }
    });
  }
  getCategories = () => {
    const bodyObj = {};
    const categoriesList = [];
    if (this.props.restaurantId) { 
      bodyObj.restaurantId = this.props.restaurantId;
    }
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/products/categories/list', {
      method: 'post',
      body: bodyObj
    }).then(response => {
      console.log(response, 'response');
      if (this.mounted) {
        if (response.success && response.data && Array.isArray(response.data)) {
          response.data.forEach((category) => {
            const categoryName = _.get(category, 'category.display_name');
            const categoryId = _.get(category, 'category_id');
            if (categoryId && categoryName) {
              categoriesList.push(<Option key={categoryId}>{categoryName}</Option>);
            }
          })
          this.setState({ 
            categoriesLoaded: true,
            categories: response.data,
            categoriesList
          });
        } else {
          this.setState({ 
            categoriesLoaded: false,
            categories: [],
            categoriesList 
          });
        } 
      }    
    }).catch(error => {
      console.log(error);
      if (this.mounted) { 
        this.setState({
          isFetching: false,
          data: [] 
        });
      }
    });
  }
  handleSubmit = () => {
    const { t } = this.props;
    return this.formRef.current.validateFields()
    .then((values) => {
      const { modifierSetsConnected } = this.state;

      values.show_online = 1;
      if (this.props.restaurantId) { 
        values.restaurantId = this.props.restaurantId;
      }
      values.modifierSetsConnected = modifierSetsConnected;

      this.setState({
        putREQUEST: true
      });
      fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/products/create`, {
        method: 'put',
        body: values
      }).then(response => {
        console.log(response);
        if (response.success) {
          this.setState({
            //putREQUEST: false,
            putSUCCESS: true,
            putFAILURE: false,
            //errorMessage: 'Saved successfully'
          }, () => {
            setTimeout(() => {
              message.success(t('singleLocation.feedback.saveSuccess', { defaultValue: 'Saved successfully' }));
              this.props.handleClose();
              this.props.updateProductsList();  
              this.props.showInfoNotification();
            }, 500);
          });
        } else {
          this.setState({
            putREQUEST: false,
            putFAILURE: true,
            putSUCCESS: false,
            //errorMessage: response.message
          });
          message.error(response.message);
        }
       }).catch(error => {
          console.log(error);
          this.setState({
            putSUCCESS: false,
            putREQUEST: false,
            putFAILURE: true,
          });
          message.error(t('shareable.ERR', { defaultValue: 'ERR' }));
       });
    }).catch((err) => console.log('validate err', err))
  }
  validateInteger = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value) return Promise.resolve();
      /* eslint-disable-next-line */
      const reg = /^\d+$/;
      if (reg.test(value) && (value[0] !== '0' || value.length === 1)) {
        return Promise.resolve();
      } else {
        return Promise.reject(<span><Trans i18nKey="addBranch.amountMustBeRealNumber">Amount must be a real number</Trans></span>);
      }
    },
    validateTrigger: 'onSubmit'
  })
  validateFloat = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value) return Promise.resolve();
      /* eslint-disable-next-line */
      const reg = /^\-?[0-9]+(e[0-9]+)?(\.[0-9]+)?$/;
      if (reg.test(value) && (value[0] !== '0' || value.length === 1)) {
        return Promise.resolve();
      } else {
        return Promise.reject(<span><Trans i18nKey="addBranch.amountMustBeNumber">Amount must be number</Trans></span>);
      }
    },
    validateTrigger: 'onSubmit'
  }) 
  
  setFieldsValue = (name, value) => {
    this.formRef.current.setFieldsValue({
      [name]: value
    });
  }
  changeState = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  checkModifierSetChecked = (modifier_set_id) => {
    const { modifierSetsConnected } = this.state;
    return modifierSetsConnected.indexOf(modifier_set_id) > -1;
  }
  modifierSetToProduct = (modifier_set_id) => {
    const { modifierSetsConnected } = this.state;
    const copy = [...modifierSetsConnected];
    const index = copy.indexOf(modifier_set_id);

    if (index > -1) {
      delete copy[index];
    } else {
      copy.push(modifier_set_id);
    }

    const final = copy.filter(el => !!el);
    this.setState({
      modifierSetsConnected: final
    });
  }
  renderConnectedModifierSets = () => {
    const { modifierSetsList, modifierSetsConnected } = this.state;

    return modifierSetsList.map((modifierSet, i) => {
      if (modifierSetsConnected.indexOf(modifierSet.id) > -1) {
        return <span className="ant-select-selection-item" key={i}>
          <span className="ant-select-selection-item-content">{modifierSet.display_name}</span>
        </span>;
      }
      return null;
    })
  }
  modalVisible = (key, value) => {
    this.setState({ [key]: value });
  }
  openModifiersModal = () => {
    const { modifierSetsList } = this.state;

    this.modifierSetsModal = Modal.confirm({
      width: 400,
      title: 'Modifier Sets',
      icon: false,
      centered: true,
      content: (
        <div className="UserManualsAddFirstLocation__body">
          <ul style={{listStyle: 'none', padding: 0}}>
            {modifierSetsList.map((modifierSet, i) => <li key={i} style={{padding: '5px 0'}}>
              <Checkbox
                onChange={e => this.modifierSetToProduct(modifierSet.id)}
                defaultChecked={this.checkModifierSetChecked(modifierSet.id)}
                > { modifierSet.display_name } </Checkbox>
            </li>)}
          </ul>
          <div>
            <Button onClick={e => this.modalVisible('showAddModifierSets', true)}>Add new Modifier Set</Button>
          </div>
        </div>
      ),
      cancelText: 'Cancel',
      cancelButtonProps: {style: {display: 'none'}},
      okText: 'Close',
      onOk: () => {
        console.log('okay openProductsModal');
      },
      maskClosable: true
    });
  }
  render() {
    const { t } = this.props;
    const {  
      initialValues,
      categoriesLoaded,
      categoriesList,
      imageUploadInProccess,
      putREQUEST,
      modifierSetsConnected,
      showAddModifierSets
    } = this.state;
    return (
      <Modal 
        className="general-modal-btns-center general-card "
        title="New product"
        centered={true}
        visible={this.props.visible}
        okButtonProps={{htmlType: "submit", loading: imageUploadInProccess || putREQUEST, disabled: imageUploadInProccess || putREQUEST}}
        okText="Add"
        onOk={() => {
          if (!putREQUEST) {
            this.handleSubmit();
          }
        }}
        onCancel={this.props.handleClose}
        destroyOnClose={true}
        width={800}
      >
        <Spin spinning={!categoriesLoaded}>
          <Form 
            requiredMark={false} 
            className="general-form" 
            layout="vertical" 
            ref={this.formRef}
            initialValues={initialValues} 
          >
            <Row gutter={140}>
              <Col xs={24} sm={12}>
                <Row gutter={16}>
                  <Col span={16}>
                    <FormItem rules={[{ required: true, message: t('shareable.required.prodName', { defaultValue: 'Product name is required!' }) }]} name="display_name" label="Product name">
                      <Input placeholder="Name"/>
                    </FormItem>
                  </Col>
                  <Col span={8} style={{display: 'none'}}>
                    <FormItem style={{marginLeft: '16px'}} valuePropName="checked" rules={[{ required: true }]} name="show_online" label="Show online">
                      <Switch/>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <FormItem name="description" label="Description" style={{marginBottom: '0px'}}>
                      <TextArea placeholder="E.g.: Delicious meal..." showCount maxLength={255}/>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <FormItem 
                      rules={[{required: false, message: '' }]}
                      name="accounting_number" label="Product No">
                      <Input placeholder="Product No"/>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <FormItem 
                      rules={[{required: true, message: 'Serving time is required!' }, this.validateInteger ]}
                      name="serving_time" label="Serving time">
                      <Input placeholder="Serving time in minutes"/>
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <FormItem rules={[{required: true, message: t('shareable.product_price.required', { defaultValue: 'Please, enter price'}) }, this.validateFloat ]}
                       label="Price" name="product_price" className="prefix-input-fix">
                        <Input placeholder="2.50" addonBefore="$"/>
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      label="Sell tax"
                      name="vat"          
                      rules={[{required: true, message: t('shareable.tax_select.required', { defaultValue: 'Please choose tax option'}) }]}
                      >
                      <Select 
                        placeholder={t('shareable.tax_select.placeholder', { defaultValue: 'Select tax'})}
                      >
                        <Option value={0}>Tax free</Option>
                        <Option value={10}>10% GST</Option>
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <FormItem style={{marginLeft: '8px'}} valuePropName="checked" rules={[{ required: true }]} name="is_alcohol" label="It's alcohol">
                      <Switch/>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <FormItem rules={[{ required: true,  message: t('shareable.categories.required', { defaultValue: 'Please, select at least one category'}) }]} name="categories" label="Categories">
                      <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Please select categories"
                        showArrow={true}
                      >
                        {categoriesList}
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
                 <Row>
                  <Col span={24} style={{marginBottom: '24px'}}>
                    <Row>
                      <div className="ant-col ant-form-item-label">
                        <label className="ant-form-item-required" title="Modifiers">Modifiers</label>
                      </div>
                    </Row>
                    <div className="ant-select ant-select-multiple" style={{ width: '100%' }} onClick={ this.openModifiersModal }>
                      <div className="ant-select-selector heightNotLimited">
                        {
                        !modifierSetsConnected.length ? 
                          <span className="ant-select-selection-placeholder">No modifiers connected yet</span>
                        : 
                          this.renderConnectedModifierSets()
                        }
                      </div>

                      <span className="ant-select-arrow" unselectable="on" aria-hidden="true" style={{userSelect: 'none'}}>
                        <span role="img" aria-label="down" className="anticon anticon-down ant-select-suffix">
                          <svg viewBox="64 64 896 896" focusable="false" data-icon="down" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                            <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z" />
                          </svg>
                        </span>
                      </span>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={12}>
                <ImageUpload
                  productId={"new"}
                  changeUploadStatus={(value) => this.changeState('imageUploadInProccess', value)}
                  setFieldsValue={this.setFieldsValue}
                  setImageCoverUrl={(value) => this.changeState('imageCoverUrl', value)}
                  />
                <Form.Item name="file_id" style={{ display: 'none' }}>
                  <Input placeholder="file_id" />
                </Form.Item> 
              </Col>
            </Row>
          </Form>
        </Spin> 
        { showAddModifierSets && <AddModifierSets
          history={this.props.history}
          handleClose={() => this.modalVisible('showAddModifierSets', false)}
          visible={showAddModifierSets} 
          updateModifiersList={this.getModifiers}
          restaurantId={this.props.restaurantId}
          hideProductConnection={true}
        /> }
      </Modal>
    )
  }
};

export default withTranslation()(AddProduct);