import React from 'react';
import { Redirect } from 'react-router-dom';
import i18n from 'i18next';
import AppWrapper from 'components/AppWrapper/AppWrapper';
import qs from 'qs';
import Auth from './Auth';
import fetchJSON from './fetchJSON';

import Main from 'screens/Main/Main';
import Restaurants from 'screens/Dashboard/Restaurants/Restaurants';
import Branches from 'screens/Dashboard/Branches/Branches';
import Waiter from 'screens/Dashboard/Branches/components/Orders/Waiter/Waiter';
import Kitchen from 'screens/Dashboard/Branches/components/Orders/Kitchen/Kitchen';
import Orders from 'screens/Dashboard/Orders/Orders';

/**
 * when entering the page to check if the user has access to them
 */
const checkRole = (next) => {
	// create an AJAX request
	fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/auth/role', {
		method: 'get',
	}).then(data => {
		if (data.language && data.language !== i18n.language) {
			i18n.changeLanguage(data.language);
		}
		if (data.success) return next(data.role, data.success, data);
		if (data.role === 'wrongToken') return next(data.role);
		return next(false);
	}).catch((error) => {
		if (error.status === 500) {
			return next('error500');
		} else {
			return next(false);
		}
	});
};
export const checkAccessWrap = (Component, component_name, noWrap = false) => {
  class AuthenticatedComponent extends React.Component {
		state = {
			isLoggedIn: false,
			role: ''
		}
    UNSAFE_componentWillMount() {
      this.checkAuth();
    }
    checkAuth() {
    	checkRole((role = false, success = false, data = {}) => {
				const location = this.props.location;
				const redirect = location.pathname + location.search;
				// components roles
				const roleComponentsAccess = {
					'admin': ['restaurants', 'restaurant', 'branches', 'branch', 'branches_add', 'settings', 'reports', 'users', 'products', 'orders', 'employees'],
					'owner': ['branches', 'branch', 'products', 'branches_add', 'settings', 'reports', 'users', 'orders', 'employees'],
					'supervisor': ['branches'],
					'employee': [],
					'waiter': [],
					'manager': ['', '/manager/menu', '/manager/reports', '/manager/location', 'users', 'employees']
				};
				if (!success) {
					if (role === 'error500') {
						this.props.history.push(`/error500?redirect=${redirect}`);
					} else if (role === 'wrongToken') {
						this.props.history.push(`/logout`);
					} else if (role) {
						this.props.history.push('/');
					} else {
						this.props.history.push(`/login?redirect=${redirect}`);
					}
				} else {
					if (component_name && roleComponentsAccess[role].indexOf(component_name) > -1) {
						if (roleComponentsAccess[role][0] === component_name) {
							window.history.pushState({}, '', '/');
						}
						this.setState({
							isLoggedIn: true,
							role: role,
							related_restaurant_id: data.related_restaurant_id,
							related_branch_uuid: data.related_branch_uuid,
							related_branch_id: data.related_branch_id,
							related_restaurant_uuid: data.related_restaurant_uuid
						});
					} else {
						this.props.history.push('/');
					}
				}
			})
    }
    render() {
    	const { role, related_restaurant_id, related_branch_uuid, related_branch_id, related_restaurant_uuid } = this.state;
      return this.state.isLoggedIn
        ?
        <React.Fragment>
        	{ noWrap
        	?
					<Component { ...this.props } />
        	: <AppWrapper role={role} related_restaurant_id={related_restaurant_id}>
        		<Component { ...this.props } role={role} related_restaurant_id={related_restaurant_id}
        			branchUuid={related_branch_uuid} related_branch_id={related_branch_id} related_restaurant_uuid={related_restaurant_uuid} />
        	</AppWrapper> }
        </React.Fragment>
        : null;
    }
  }
	return AuthenticatedComponent;
}

export const checkMainDefault = () => {
  class MainComponent extends React.Component {
		state = {
			isLoggedIn: false,
			role: ''
		}
    UNSAFE_componentWillMount() {
      this.checkAuth();
    }
    checkAuth() {
    	checkRole((role = false, success = false, data = {}) => {
				const location = this.props.location;
				const redirect = location.pathname + location.search;

				if (!success) {
					if (role === 'error500') {
						this.props.history.push(`/error500?redirect=${redirect}`);
					} else if (role === 'wrongToken') {
						this.props.history.push(`/logout`);
					} else if (role) {
						this.props.history.push('/');
					} else {
						this.props.history.push(`/login?redirect=${redirect}`);
					}
				} else {
					this.props.history.push('/');
					this.setState({
						isLoggedIn: true,
						role: role,
						related_restaurant_id: data.related_restaurant_id,
						related_branch_uuid: data.related_branch_uuid,
						related_branch_id: data.related_branch_id,
						related_restaurant_uuid: data.related_restaurant_uuid
					});
				}
			})
    }
    render() {
    	const {
    		role,
    		related_branch_uuid,
    		related_branch_id,
    		related_restaurant_uuid,
    		related_restaurant_id
    	} = this.state;
      return this.state.isLoggedIn
        ?
        <React.Fragment>
        	<AppWrapper role={role}>
        	{ role === 'admin' ?
        		<Restaurants { ...this.props } /> : null }
        	{ role === 'owner' ?
        		<Branches { ...this.props } /> : null }
        	{ role === 'supervisor' ?
        		<Main { ...this.props } /> : null }
        	{ role === 'employee' ?
        		<Main { ...this.props } /> : null }
      		{ role === 'waiter' ?
      			<Waiter { ...this.props } branchUuid={related_branch_uuid} related_restaurant_uuid={related_restaurant_uuid}/> : null }
    			{ role === 'kitchen' ?
      			<Kitchen { ...this.props } branchUuid={related_branch_uuid} related_restaurant_uuid={related_restaurant_uuid}/> : null }
      		{ role === 'manager' ?
      			<Orders { ...this.props }
      			role={role}
      			branchUuid={related_branch_uuid}
      			related_branch_id={related_branch_id}
      			related_restaurant_uuid={related_restaurant_uuid}
      			related_restaurant_id={related_restaurant_id}
      			/> : null }
        	</AppWrapper>
        </React.Fragment>
        : null;
    }
  }
	return MainComponent;
}

export const checkLogin = (redirectTo, next) => {
	if (Auth.isUserAuthenticated()) {
		let redurectFromUrl = null;
		if (window.location.search) {
			let param = window.location.search;
			let paramFromUrl = qs.parse(param, { ignoreQueryPrefix: true });
			let redirect = paramFromUrl.redirect ? paramFromUrl.redirect : false;
			if (redirect) {
				redurectFromUrl = redirect;
			}
		}
		return ( <Redirect to={{ pathname: redurectFromUrl ? redurectFromUrl : redirectTo }} /> );
	} else {
		return next();
	}
};

export default checkAccessWrap;
