import React from 'react';
import Settings from 'services/config/Settings';
import {Layout, Spin, Col, Row, Button, Radio, notification, Modal, message} from 'antd';
import { withTranslation } from 'react-i18next';
import fetchJSON from 'services/utils/fetchJSON';
import qs from 'qs';
import AddOrder from './components/AddOrder';
import EditOrder from './components/EditOrder';
import PayGroup from './components/PayGroup';

import moment from 'moment-timezone';
import { RightOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import Socket from 'services/utils/Socket';
import Masonry from 'react-masonry-component';

import './styles.less';
import {getUserRole} from "../../../../../../helpers/getUserRole";
require("moment-duration-format");

const { Content } = Layout;
const { confirm } = Modal;

class Waiter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: {
        categories: []
      },
      orders: [],
      tables: [],
      filterTypeOrder: 'dine_in',
      visibleAddOrder: false,
      visibleEditOrder: false,
      branch: {},
      selectedOrder: {},
      orderBy: 'ASC',
      updatedOrderUuid: null,
      closedCancelledOrders: [],
      canceledOrders: [],
      currentSplitOrders: [],
      currentSplitGuestIndex: 0,
      splitHaveReminder: false,
      payNowScenario: false,
      deliverNowScenario: false,
      visiblePayGroupOrder: false,
      remainderGroupList: [],
      remainderPrice: 0,
      userRole: ''
    };
    this.timerUpdated = 0;

  }
  componentDidMount() {
    this.mounted = true;
    const { t } = this.props;

    document.title = `${t('shareable.orders', { defaultValue: 'Orders' })} | ${Settings.title}`;
    const role = getUserRole()
    this.subscribeToSocket();
    this.setState({
      userRole: role
    }, () => {
      this.getOrders();
      this.detectOrdersType();

    })
  }
  subscribeToSocket = () => {
    const { branchUuid } = this.props;

    console.log('subscribeToSocket', branchUuid);

    Socket.on(`notification_${branchUuid}`, this.updateScreen);
  }
  unsubscribeFromSocket = () => {
    const { branchUuid } = this.props;
    Socket.off(`notification_${branchUuid}`, this.updateScreen);
  }
  updateScreen = (data) => {
    console.log('notification', data);

    const { type, orderUuid } = data;
    if (type === 'new_order') {
      notification.info({
        message: 'New order added'
      });
    }

    if (type === 'ready_to_pay') {
      notification.info({
        message: 'User is ready to pay'
      });
    }
    if (type === 'order_updated') {
      notification.info({
        message: 'Order updated'
      });
    }
    this.setState({
      updatedOrderUuid: orderUuid
    }, () => {
      this.timer = setTimeout(this.cleanUpdatedUuid, 3000);
      this.getOrders();
    });
  }
  cleanUpdatedUuid = () => {
    this.setState({
      updatedOrderUuid: null
    });
  }
  detectOrdersType = () => {
    const { filterTypeOrder } = this.state;
    let ordersType = 'dine_in';
    const param = window.location.search;
    if (param) {
      const paramFromUrl = qs.parse(param, { ignoreQueryPrefix: true });
      if (paramFromUrl) {
        if (paramFromUrl.takeout) {
          ordersType = 'takeout';
        }
      }
    }
    if (filterTypeOrder !== ordersType) {
      this.setState({
        filterTypeOrder: ordersType
      });
    }
  }
  // componentDidUpdate() {
  //   this.detectOrdersType();
  // }
  componentWillUnmount() {
    this.unsubscribeFromSocket()
    this.mounted = false;
    clearInterval(this.timer);
  }
  getOrders = (callback = () => {}) => {
    this.setState({
      isFetching: true
    });
    const { branchUuid } = this.props;
    fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches-orders/list/${branchUuid}`, {
      method: 'get'
    }).then(response => {
      if (this.mounted) {
        this.setState({
          tables: response.data.tables,
          orders: response.data.orders,
          closedCancelledOrders: response.data.closedCancelledOrders,
          branch: response.data,
          isFetching: false
        });
        if (this.state.currentSplitOrders.length > 0 ) this.openNextSplitScenario(this.state.currentSplitOrders[this.state.currentSplitGuestIndex])
      }
      callback();
    }).catch(error => {
      console.log(error);
      if (this.mounted) {
        this.setState({
          isFetching: false
        });
      }
      callback();
    });
  }
  ordersInProgress = (forceFilterType = false) => {
    const { filterTypeOrder, orders } = this.state;
    const in_progress = [];
    if (orders && Array.isArray(orders) && orders.length) {
      orders.forEach((order, i) => {
        if (order.is_canceled) return null;
        if (!forceFilterType && order.order_type !== filterTypeOrder) return;
        if (forceFilterType && order.order_type !== forceFilterType) return;

        in_progress.push(order);
      });
    }
    return in_progress;
  }
  getPageTitle = () => {
    const { filterTypeOrder } = this.state;
    if (filterTypeOrder !== 'all') {
      const in_progress = this.ordersInProgress();
      return `${in_progress.length} orders in progress`;
    }

    const dine_in = this.ordersInProgress('dine_in');
    const takeout = this.ordersInProgress('takeout');
    return <React.Fragment>
      <div>{dine_in.length} Dine In orders in progress</div>
      <div>{takeout.length} Takeaway orders in progress</div>
    </React.Fragment>
  }
  getTablesStatus = () => {
    const { tables } = this.state;
    const allTables = tables.length;
    const in_progress = this.ordersInProgress();
    const busyTables = [];

    if (in_progress && in_progress.length) {
      in_progress.forEach((order, i) => {
        if (busyTables.indexOf(order.order_type_display) === -1) {
          busyTables.push(order.order_type_display);
        }
      });
    }

    return `| Empty Tables: ${allTables - busyTables.length}/${allTables}`;
  }
  updateState = (key, value) => {
    this.setState({
      [key]: value
    });
  }
  closeOrder = (orderUuid, orderType, payNow = false, deliverNow = false) => {
    if (typeof orderType === 'string' && orderType !== this.state.filterTypeOrder) {
      this.setState({
        filterTypeOrder: orderType
      });
    }
    this.updateState('visibleAddOrder', false);
    if (typeof payNow === 'boolean' && payNow) {
      this.openCheckoutScenario(orderUuid, deliverNow);
    }
  }
  openCheckoutScenario = (orderUuid, deliverNow = false) => {
    const {
      orders,
    } = this.state;

    if (orders && Array.isArray(orders) && orders.length) {
      const orderFiltered = [...orders.filter((order) => {
        if (order.uuid !== orderUuid) {
          return false;
        }
        return true;
      })];
      if (orderFiltered && orderFiltered.length) {
        console.log('deliverNow', deliverNow);
        this.openOrder(orderFiltered[0], true, deliverNow);
      }
    }
  }
  getOrderStatus = (order) => {
    const { order_items } = order;
    let status = order.orderStatus;

    if (order_items && order_items.length && order.orderStatus === 'Submitted') {
      const ready = [];
      order_items.forEach((item) => {
        if (item.is_ready) {
          ready.push(item.id);
        }
      });
      if (ready.length > 0 && ready.length !== order_items.length) {
        status = `${ready.length} DELIVERED, ${order_items.length - ready.length} LEFT`;
      }
    }

    return status;
  }
  renderTable = (table_id) => {
    const { tables } = this.state;
    const table = tables.filter((item) => {
      return item.id === parseInt(table_id, 10);
    });
    if (table) {
      return table[0].display_name;
    }
    return null;
  }
  renderOrders = () => {
    const {
      filterTypeOrder,
      orders,
      orderBy,
      closedCancelledOrders
    } = this.state;
    const render = [];

    if ((orders && Array.isArray(orders) && orders.length)
      || (filterTypeOrder === 'closed' && closedCancelledOrders)) {

      const {
        searchOrderId,
        filteredById
      } = this.props;


      let orderSorted = [...orders.filter((order) => {
        if (order.is_canceled) return false;
        if (filterTypeOrder !== 'all') {
          if (filterTypeOrder !== order.order_type) {
            return false;
          }
        }
        if (filteredById) {
          const orderId = order.index_id.toString();
          if (orderId.indexOf(searchOrderId) === -1) return false;
        }

        return true;
      })];

      if (filterTypeOrder === 'closed') {
        orderSorted = [...closedCancelledOrders];
      }

      orderSorted.sort((a, b) => {
        if (orderBy === 'ASC') {
          return a.submitted_at > b.submitted_at ? 1 : -1
        }
        return a.submitted_at < b.submitted_at ? 1 : -1
      });
      // console.log('orderSorted', orderSorted);

      if (filterTypeOrder !== 'dine_in') {
        orderSorted.forEach((order, i) => {
          console.log('order', order);
          // if (filterTypeOrder === 'closed') {
          //   if (!order.is_closed) return null;
          // }
          // if (filterTypeOrder !== 'canceled') {
          //   if (order.is_canceled) return null;
          // }

          render.push(this.renderOrder(order));
        });
      } else {
        const orderGrouped = {};
        orderSorted.forEach((order, i) => {
          if (!orderGrouped.hasOwnProperty(order.table_id)) {
            orderGrouped[order.table_id] = [];
          };
          orderGrouped[order.table_id].push(order);
        });

        Object.keys(orderGrouped).forEach((table_id) => {
          if (orderGrouped[table_id].length > 1) {
            render.push(this.renderGroupedOrders(table_id, orderGrouped[table_id]));
          } else {
            render.push(this.renderOrder(orderGrouped[table_id][0]));
          }
        });
      }
    }
    return render;
  }
  payRemainder = (unpaidList, remainderPrice, surcharges_amount) => {
    console.log('payRemainder', unpaidList);
    this.setState({
      remainderGroupList: unpaidList,
      remainderPrice,
      surcharges_amount
    }, () => {
      this.updateState('visiblePayGroupOrder', true);
    })
  }

  setAllDelivered = (paidList) => {
    this.setIsFetching(true);
    const {branchUuid} = this.props;
    this.setState({
      putREQUEST: true
    });
    fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches-orders/ordersGroupSubmit/`, {
      method: 'POST',
      body: {
        "branchUuid": branchUuid,
        "orderUuids": paidList,
        "key": "is_delivered",
        "value": true
      }
    }).then(response => {
      this.setIsFetching(false);
      if (response.code === 200) {
        message.success('Success');
        this.getOrders();
      } else {
        message.error(response.message);
      }
    }).catch(error => {
      this.setIsFetching(false);
      console.log(error);
    });
  }

  renderGroupedOrders = (table_id, list) => {
    let totalPrice = 0;
    let remainderPrice = 0;
    let surcharges_amount = 0;
    const unpaidList = [];
    const undeliveredList = [];
    const deliveredUnpaidList = []
    list.forEach((order) => {
      totalPrice += (order.total + order.processing_fee);
    });
    list.forEach((order) => {
      if (!order.is_delivered) undeliveredList.push(order.uuid);
      if (!order.is_paid) {
        unpaidList.push(order.uuid);
        if (order.is_delivered) deliveredUnpaidList.push(order.uuid);
      }
      surcharges_amount += order.surcharges_amount
      remainderPrice += (order.total + order.processing_fee);
    });

    const paidAll = remainderPrice === 0;
    const unpaidAll = remainderPrice === totalPrice;
    totalPrice = totalPrice.toFixed(2);
    remainderPrice = remainderPrice.toFixed(2);

    return (
      <div key={table_id} className="ordersGrouped">
        <div className="ordersGrouped__wrapper">
          <div className="ordersGrouped__inner">
            <div className="orderItem__table">
              <div>{this.renderTable(table_id)}</div>
              <span className="orderItem__tablePrice orderItem__tablePrice--left">Total: <span>${totalPrice}</span></span>
            </div>
            <div className="ordersGrouped__list">
            {list.map((order) => this.renderOrder(order, true))}
            </div>
            <div className="orderItem__tableFooter">
            { !paidAll ?
                <div>
                  <div className="orderItem__tablePrice">
                    Balance:&nbsp;
                    { !unpaidAll ?
                      <span>${remainderPrice}</span>
                    :
                      <span>${totalPrice}</span>
                    }
                  </div>
                  { deliveredUnpaidList && deliveredUnpaidList.length > 1 ?
                    <button className="orderItem__payRemainder" onClick={() => this.mergeOrders(deliveredUnpaidList)}>Merge</button>
                  : null }
                  {(unpaidList && unpaidList.length !== 0) && <button className="orderItem__payRemainder" onClick={() => this.payRemainder(unpaidList, remainderPrice, surcharges_amount)}>Pay Balance</button>}
                </div>
             : null }
              {(undeliveredList && undeliveredList.length !== 0) &&
              <button className="orderItem__payRemainder" onClick={() => {this.setAllDelivered(undeliveredList)}}>All delivered</button>}
            </div>
          </div>
        </div>
      </div>
    )
  }
  setIsFetching = (param) => {
    this.setState({
      isFetching: param
    });
  }
  mergeOrders = (deliveredUnpaidList) => {
    console.log('deliveredUnpaidList', deliveredUnpaidList);
    confirm({
      title: 'Are you sure you want to merge all orders on the table?',
      onOk: () => {
        return new Promise((resolve, reject) => {
          this.setIsFetching(true);
          const { branchUuid } = this.props;

          fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches-orders/mergeOrders/', {
            method: 'POST',
            body: {
              branchUuid,
              orderUuids: deliveredUnpaidList,
            }
          }).then(response => {
            console.log(response);
            this.setIsFetching(false);
            if (response.code === 200) {
              this.getOrders();
              resolve();
            }
            reject();
           }).catch(error => {
              reject();
              console.log(error);
              this.setIsFetching(false);
           });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }
  countItems = (order_items) => {
    let count = 0;
    if (order_items && order_items.length) {
      order_items.forEach((order_item) => {
        count += order_item.quantity;
      });
    }
    return count;
  }
  calculateTotal = (total, fee) => (total + fee).toFixed(2)
  renderOrder = (order, grouped = false) => {
    const methodsPay = {
      cash: 'Cash',
      card_pay_at_counter: 'Card pay at counter'
    }
    const {
      updatedOrderUuid
    } = this.state;

    let formatDate = "h [h] m [min]";
    const start = moment.utc(order.submitted_at);
    const ms = moment().diff(start);
    const dur = moment.duration(ms);
    const durationSession = dur.format(formatDate);
    let orderCard = order.is_paid ? 'paid' : 'unpaid';

    let orderDue = moment.utc(order.submitted_at);
    if (order.order_type === 'takeout') {
      console.log('order.take_out_delay', order.take_out_delay);
      if (order.take_out_delay && order.order_type === 'takeout') {
        orderDue.add(order.take_out_delay, 'minutes')
      }
    }
    if (order.serving_time) {
      orderDue.add(order.serving_time, 'minutes')
    }
    const ms2 = moment().diff(orderDue);
    const dur2 = moment.duration(ms2);
    orderDue = dur2.format(formatDate);

    if (order.is_ready_to_pay) {
      orderCard = 'ready_to_pay';
    }

    if (updatedOrderUuid === order.uuid) {
      orderCard += ' updatedOrder';
    }
    if (grouped) {
      orderCard += ' groupedOrder';
    }

    return ( <div key={order.id} className={"orderItem "+ (orderCard)} onClick={() => this.openOrder(order)}>
      <div className="orderItem__wrapper">
        <div className="orderItem__arrow"><RightOutlined /></div>
        { order.table_id && order.order_type === 'dine_in' ? <div className="orderItem__table">{this.renderTable(order.table_id)}</div> : null }
        { order.order_type === 'takeout' &&  <div className="orderItem__table">Takeaway</div> }
        { !grouped ?
          <React.Fragment>
            <div className="orderItem__title">Order #{order.index_id}</div>
            <div className="orderItem__title">{this.countItems(order.order_items)} items | ${this.calculateTotal(order.total, order.processing_fee)}</div>
            <div className="orderItem__line"><b>Order Placed:</b> {durationSession}</div>
            <div className="orderItem__line"><b>Order Due:</b> {orderDue}</div>
            <div className="orderItem__line"><b>Status:</b>&nbsp; {this.getOrderStatus(order)} </div>
            {
              order.customer_name &&
              <div className="orderItem__line"><b>Customer name:</b> {order.customer_name}</div>
            }

            {
              order.pay_later_method &&
              <div className="orderItem__line"><b>Pay later method:</b> {methodsPay[order.pay_later_method]}</div>
            }
            <div className="orderItem__line"><b>Payment:</b>&nbsp;
              <span style={{color: order.is_paid ? '#64D148' : '#F94753' }}>{ order.is_paid ? (order.is_paid && order.is_paid_online ? 'Paid via App' : 'Paid') :
              (!order.is_ready_to_pay ? 'Not Paid' : 'Ready to pay')
            }</span>
            </div>
          </React.Fragment> :
          <React.Fragment>
            <div className="orderItem__title">
              <span className="orderItem__titleId">#{order.index_id}</span>
              {this.countItems(order.order_items)} items | ${this.calculateTotal(order.total, order.processing_fee)}
              { order.is_delivered ? <span className="orderItem__delivered" alt="Order delivered"></span> : null }
            </div>
          </React.Fragment>
        }
      </div>
    </div> );
  }
  openOrder = (order, payNow = false, deliverNow = false) => {
    this.setState({
      selectedOrder: order,
      payNowScenario: payNow,
      deliverNowScenario: deliverNow
    }, () => {
      this.updateState('visibleEditOrder', true);
    })
  }
  reOrder = () => {
    const {
      orderBy
    } = this.state;

    this.setState({
      orderBy: orderBy === 'ASC' ? 'DESC' : 'ASC'
    });

  }
  filterType = (e) => {
    this.setState({
      filterTypeOrder: e.target.value
    });
  }

  updateCurrentSplitOrders = (orders, haveReminder) => {
    this.updateState('currentSplitOrders', orders);
    this.updateState('currentSplitGuestIndex', 0)
    this.updateState('splitHaveReminder', haveReminder)
  }

  openNextSplitScenario = (orderUuid) => {
    const {
      orders,
      currentSplitOrders,
      currentSplitGuestIndex,
      splitHaveReminder
    } = this.state;

    if (orders && Array.isArray(orders) && orders.length) {
      const orderFiltered = orders.find(order => order.uuid === orderUuid)
      if (orderFiltered) {
        const payNow = ((currentSplitOrders.length - 1) !== currentSplitGuestIndex) || !splitHaveReminder
        this.openOrder(orderFiltered, payNow);
      }
    }
  }

  render() {
    const {
      isFetching,
      visibleAddOrder,
      visibleEditOrder,
      branch,
      filterTypeOrder,
      selectedOrder,
      orderBy,
      currentSplitOrders,
      currentSplitGuestIndex,
      payNowScenario,
      deliverNowScenario,
      visiblePayGroupOrder,
      remainderGroupList,
      remainderPrice,
      userRole,
      surcharges_amount
    } = this.state;

    const { branchUuid } = this.props;

    return (
      <Content className="bg-transparent table-content">
        <Spin spinning={isFetching}>
          <Row gutter={8}>
            <Col span={24}>
              <Row gutter={8}>
                <Col span={24}>
                  <h3 style={{color: '#454545'}}>
                    {this.getPageTitle()} {(filterTypeOrder === 'dine_in') && this.getTablesStatus() }
                  </h3>
                  <div className="waiterListFilter">
                    <Button className="waiterListFilter__addOrder zoomUpBtns" onClick={ () => { this.updateState('visibleAddOrder', true) } }  size="large">New Order</Button>

                    <Radio.Group className="waiterListFilter__type zoomUpBtns" value={filterTypeOrder} size="large" buttonStyle="solid" onChange={this.filterType}>
                      <Radio.Button value="all">All Orders</Radio.Button>
                      <Radio.Button value="dine_in">Dine In</Radio.Button>
                      <Radio.Button value="takeout">Takeaway</Radio.Button>
                      <Radio.Button value="closed">Closed</Radio.Button>
                    </Radio.Group>

                    <div className="waiterListFilter__order kitchenScreenHead__order zoomUpBtns" onClick={this.reOrder}>
                      <CaretUpOutlined style={{ color: orderBy === 'ASC' ? '#E01491' : ''}} />
                      <CaretDownOutlined style={{ color: orderBy === 'DESC' ? '#E01491' : ''}}/>
                    </div>

                  </div>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24}>
                  <Masonry className={'orderItems'}>
                    {this.renderOrders()}
                  </Masonry>
                </Col>
              </Row>
            </Col>
            </Row>
        </Spin>
        { visibleAddOrder ? <AddOrder
          branchUuid={branchUuid}
          visible={visibleAddOrder}
          branch={branch}
          orders_type={filterTypeOrder}
          userRole={userRole}
          renderList = { this.getOrders }
          handleClose={ this.closeOrder } />
          : null
        }
        { visibleEditOrder ? <EditOrder
          branchUuid={branchUuid}
          visible={visibleEditOrder}
          branch={branch}
          selectedOrder={selectedOrder}
          orders_type={filterTypeOrder}
          renderList = { this.getOrders }
          payNowScenario={payNowScenario}
          userRole={userRole}
          deliverNowScenario={deliverNowScenario}
          updateCurrentSplitOrders={this.updateCurrentSplitOrders}
          currentSplitGuestIndex={currentSplitGuestIndex+1}
          handleClose={ (haveReminderItems) => {
            if (currentSplitOrders.length > 0) {
              const orderInCurrentSplitIndex = currentSplitOrders.indexOf(selectedOrder.uuid);
              if (selectedOrder.is_paid && orderInCurrentSplitIndex !== -1) {
                if ((currentSplitGuestIndex + 1) < currentSplitOrders.length) {
                  this.updateState('currentSplitGuestIndex', currentSplitGuestIndex + 1)
                } else {
                  this.updateState('currentSplitOrders', []);
                  this.updateState('currentSplitGuestIndex', 0);
                  this.updateState('splitHaveReminder', false);
                }
              }
            }
            this.updateState('visibleEditOrder', false)
          }} />
          : null }
        { visiblePayGroupOrder ? <PayGroup
          branchUuid={branchUuid}
          visible={visiblePayGroupOrder}
          branch={branch}
          remainderGroupList={remainderGroupList}
          remainderPrice={remainderPrice}
          surchargesAmount={surcharges_amount}
          orders_type={filterTypeOrder}
          renderList = { this.getOrders }
          payNowScenario={payNowScenario}
          deliverNowScenario={deliverNowScenario}
          userRole={userRole}
          handleClose={ () => this.updateState('visiblePayGroupOrder', false) } />
          : null }


      </Content>
    )
  }
};

export default withTranslation()(Waiter);
