import React from 'react';
import Settings from 'services/config/Settings';
import { Layout, Card, Row, Col, Select } from 'antd';
import { withTranslation } from 'react-i18next';
import EmployeesTable from './components/EmployeesTable/EmployeesTable';
import * as _sharedModules from '../modules/_modules';

import './styles.less';

const { Content } = Layout;

class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      initialDataLoaded: false,
      employeesList: [],
      targetRestaurantId: null
    };
    Object.keys(_sharedModules).forEach((_moduleName) => {
      this[_moduleName] = _sharedModules[_moduleName].bind(this);
    });
    this.userRole = this.props.role;
  }
  componentDidMount() {
    const { t } = this.props;
    this.mounted = true;
    document.title = `${t('shareable.employees', { defaultValue: 'Employees' })} | ${Settings.title}`;
    if (this.props.role === 'admin') {
      this.getRestaurantsListSimple();
    } else {
      const targetRestaurantId = this.props.related_restaurant_id;
      this.setState({
        targetRestaurantId,
        initialDataLoaded: true
      });
    }
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  onRestaurantSelectChange = (targetRestaurantId) => {
    this.setState({
      targetRestaurantId
    }, this.getBranchesList)
  }
  render() {
    const {
      initialDataLoaded,
      targetRestaurantId
    } = this.state;

    // console.log(this.props, 'props employees')

    return (
      <Content className="bg-transparent" style={{paddingLeft: '0px'}}>
        <meta name="viewport" content="target-densitydpi=device-dpi, initial-scale=1.0, user-scalable=no" />
        <Card
          className="general-card"
          loading={!initialDataLoaded}
          title={
            <Row>
              <span className="general-card__title-text">Employees</span>
            </Row> }
          >
          <Row>
            { this.props.role === 'admin' ?
            <Col className="header-margin-same-as-table" sm={6} xs={12}>
              <Row style={{marginBottom: '4px', fontSize: '15px'}}>
                <label>Restaurant:</label>
              </Row>
              <Row>
                <Select value={targetRestaurantId} style={{width: '100%'}} placeholder="Restaurant" onChange={(value) => this.onRestaurantSelectChange(value)}>
                  {this.renderRestaurantsOptions()}
                </Select>
              </Row>
            </Col> : null }
          </Row>
          { targetRestaurantId ?
          <Row>
            <EmployeesTable
              restaurantId={targetRestaurantId}
              managerBranchUuid={this.props.role === 'manager' ? this.props.branchUuid : null}
              role={this.props.role}
            />
          </Row> : null }
        </Card>
      </Content>
    )
  }
};

export default withTranslation()(Employees);
