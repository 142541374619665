import React, { Component } from 'react';
import { Layout } from 'antd';
import Settings from 'services/config/Settings';
import { withTranslation } from 'react-i18next';
import Reports from '../../../../Reports/Reports';

const { Content } = Layout; 

class ManagerReports extends Component {
	state = {
		isFetching: false,
	}
	componentDidMount() {  
    this.mounted = true;
    const { t } = this.props;
    document.title = `${t('shareable.reports', { defaultValue: 'Reports' })} | ${Settings.title}`;
  }
  render() {
    return (
      <Content>
        <Reports
          branchUuid={this.props.branchUuid}
          history={this.props.history}
          related_restaurant_id={this.props.related_restaurant_id}
          managerReports={true}
          />
      </Content>
    )
  }
}


export default withTranslation()(ManagerReports);