import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Row, Modal, Form, Input, Col, message, Select, Radio } from 'antd';
import * as _sharedModules from 'screens/Dashboard/modules/_modules';
import _ from 'lodash';

const FormItem = Form.Item;
const { Option } = Select;

class EditPrinter extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
    	isFetching: false,
      putREQUEST: false,
      categories: [],
      categoriesList: [],
      workshopObj: {},
      workshopUuid: null,
      restaurantId: null,
      noPrinterType: true,
      printersModelsList: {},
      printersSelectedTypeList: [],
      is_receipt_station: false
    };
    Object.keys(_sharedModules).forEach((_moduleName) => {
      this[_moduleName] = _sharedModules[_moduleName].bind(this);
    });
  }
  componentDidMount = () => {
    this.mounted = true;
    this.getCategories();
    this.getListPrintersModels();
    this.setState({
    	workshopUuid: this.props.workshopUuid
    });
    this.getWorkshop(this.props.workshopUuid, true);
  }
  componentWillUnmount = () => {
    this.mounted = false;
  }

  handleSubmit = () => {
    return this.formRef.current.validateFields()
    .then((values) => {
      console.log('values', values);

      this.setState({
        putREQUEST: true
      });

      this.updateWorkshop(values, () => {
      	message.success('Success!');
        this.props.renderList();
        this.props.handleClose();
      });
    }).catch((err) => console.log('validate err', err))
  }
  validatePhone = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || !value.phone) {
        return Promise.reject('Phone is required!')
      } else {
        if (value.phone.length < 7) {
          return Promise.reject(<p><Trans i18nKey="shareable.reqPhone.minLength">Phone should be 8 symbols and longer</Trans></p>);
        }
        const isnum = /^\d+$/.test(value.phone);
        if (!isnum) {
          return Promise.reject(<p><Trans i18nKey="shareable.reqPhone.onlyNumber">Please, enter correct phone</Trans></p>);
        }
      }
      return Promise.resolve();
    },
    validateTrigger: 'onSubmit'
  })
  stationType = (event) => {
  	this.setState({
  		is_receipt_station: event.target.value
  	});
  	if (event.target.value) {
  		this.formRef.current.setFieldsValue({
	      categoriesList: []
	    })
  	}
  }
  getCategoriesList = () => {
  	const { categories } = this.state;
  	const categoriesList = [];
  	if (categories && Array.isArray(categories) && categories.length) {
  		categories.forEach((category) => {
        const categoryName = _.get(category, 'category.display_name');
        const categoryId = _.get(category, 'category_id');
        if (categoryId && categoryName) {
          categoriesList.push(<Option key={categoryId}>{categoryName}</Option>);
        }
      })
  	}
  	return categoriesList;
  }
  render() {
    const { t } = this.props;
    const { 
      putREQUEST,
      is_receipt_station,
    } = this.state;
    return (
      <Modal 
        className="general-modal-btns-center general-card"
        title="Edit printer"
        centered={true}
        visible={this.props.visible}
        okButtonProps={{ htmlType: "submit" }}
        okText="Save"
        onOk={() => this.handleSubmit()}
        onCancel={this.props.handleClose}
        destroyOnClose={true}
        confirmLoading={putREQUEST}
      >
        <Form 
          requiredMark={false} 
          className="general-form" 
          layout="vertical" 
          ref={this.formRef}
        >
          <Row>
            <Col span={24}>
              <FormItem rules={[{
                required: true,
                message: t('shareable.required.name', { defaultValue: 'Please enter a name' })
              }]} name="display_name" label={t('shareable.display_name', { defaultValue: 'Display name' })}>
                <Input placeholder={t('shareable.display_name', { defaultValue: 'Display name' })}/>
              </FormItem>
            </Col>

            <Col span={24}>
              <FormItem rules={[{
                required: true,
              }]} name="is_receipt_station" label='Type'>
                <Radio.Group buttonStyle="solid" onChange={this.stationType}>
                  <Radio.Button value={false}>Workshop</Radio.Button>
                  <Radio.Button value={true}>Receipt</Radio.Button>
                </Radio.Group>
              </FormItem>
            </Col>
          </Row>

          <Row style={{display: is_receipt_station ? 'none' : ''}}>
            <Col span={24}>
              <FormItem rules={[{
                required: !is_receipt_station,
              }]} name="categoriesList" label="Categories">
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select categories"
                  showArrow={true}
                  size="large"
                >
                  {this.getCategoriesList()}
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
           	 <Col span={12}>
              <FormItem rules={[{
                required: true,
              }]} name={['printer', 'printer_model']} label='Model'>
                <Input placeholder="Model" disabled={true}/>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem rules={[{
                required: true,
              }]} name={['printer', 'serial_number']} label='Serial Number'>
                <Input placeholder='Serial Number' disabled={true}/>
              </FormItem>
            </Col>
        	</Row>
        	<Row>
            <Col span={24}>
              <FormItem rules={[{
                required: true,
              }]} name={['printer', 'device_id']} label='Device ID or MAC address'>
                <Input placeholder='Device ID'/>
              </FormItem>

            </Col>
            <Col span={24}>
              <FormItem rules={[{
                required: true,
              }]} name={['printer', 'ip_address']} label='IP Address'>
                <Input placeholder='IP Address'/>
              </FormItem>

            </Col>


          </Row>

          <Row style={{display: 'none'}}>
            <Col span={24}>
              <FormItem rules={[{
                required: true,
              }]} name="workshopUuid" label="workshopUuid">
                <Input placeholder="workshopUuid"/>
              </FormItem>
            </Col>
          </Row>
          <Row style={{display: 'none'}}>
            <Col span={24}>
              <FormItem rules={[{
                required: true,
              }]} name={['printer', 'id']}  label="printerId">
                <Input placeholder="printerId"/>
              </FormItem>
            </Col>
          </Row>
         
        </Form> 
      </Modal>
    )
  }
};

export default withTranslation()(EditPrinter);
