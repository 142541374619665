import React from 'react';

class ScanMeLogo extends React.Component {
	render() {
		// const { fillColor, bgColor } = this.props;
		const scanMeStyle = `
	    position: absolute; top: 50%;
	    left: 50%;
	    width: 225px;
	    height: 225px; transform: translate(-50%, -50%)`;
	  const svgIcon = `
		<svg width="500px" height="500px" viewBox="0 0 500 500" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="${scanMeStyle}">
    <!-- Generator: Sketch 54 (76480) - https://sketchapp.com -->
    <title>dezy-logo-only</title>
    <desc>Created with Sketch.</desc>
    <defs>
        <linearGradient x1="75%" y1="93.301635%" x2="24.9916583%" y2="6.69002336%" id="linearGradient-1">
            <stop stop-color="#F0592F" offset="0%"></stop>
            <stop stop-color="#EE533A" offset="15%"></stop>
            <stop stop-color="#EA4559" offset="41%"></stop>
            <stop stop-color="#E22D8A" offset="75%"></stop>
            <stop stop-color="#DD1CAD" offset="97%"></stop>
        </linearGradient>
    </defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="dezy-logo-only" fill-rule="nonzero">
            <path d="M249.75,499.5 C111.816884,499.5 0,387.683116 0,249.75 C0,111.816884 111.816884,0 249.75,0 C387.683116,0 499.5,111.816884 499.5,249.75 C499.5,387.683116 387.683116,499.5 249.75,499.5 Z" id="dezy-logo-only-path" fill="url(#linearGradient-1)"></path>
            <path d="M356.708333,149.75 C356.708333,149.75 134.041667,132.416667 80.625,183.75 C80.625,183.75 65.0416667,205.958333 101.916667,227.166667 C101.916667,227.166667 165.083333,264.666667 175.541667,287.791667 C187.333333,310.208333 178.25,383.125 178.25,383.125 C172.416667,425.25 199.541667,426.583333 199.541667,426.583333 C272.791667,415.875 395.666667,229.375 395.666667,229.375 C432.208333,180.25 420.25,168 420.25,168 C420.25,168 417.916667,151.166667 356.708333,149.75 Z" id="dezy-logo-only-path" fill="#FFFFFF"></path>
        </g>
    </g>
</svg>
		`;
		return (
			<div dangerouslySetInnerHTML={{__html: svgIcon}} />
		);
	}
}

export default ScanMeLogo;