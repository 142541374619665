import React from 'react';
import { Button, Row, Col } from 'antd';
import domtoimage from 'dom-to-image';
import qrcode from './QRcode.js';
import { saveAs } from 'file-saver';
import { CompactPicker } from 'react-color';
import ScanMeLogo from './scanMeLogo';
import queryString from 'query-string';

require('potrace-browser');

class QrGenerator extends React.Component {
  state = {
    loading: false,
    fillColor: '#000000',
    bgColor: '#FFFFFF',
    showIconScanMe: true,
    branchUrl: '',
    tableId: 0,
    branchTitle: '',
    tableTitle: '',
    url: '',
    parentUrl: ''
  }
  componentDidMount() {
    console.log('window.location.search', window.location.search);

    const { branchUrl, tableId, branchTitle, tableTitle, parentUrl } = queryString.parse(window.location.search);

      //testapp
    // let baseURL = 'https://app.dezy.com.au/';
    //
    // if (process.env.REACT_APP_ENV === 'staging') {
    //   baseURL = 'https://testapp.dezy.xyz/'
    // }

    const baseURL = process.env.REACT_APP_BASE_URL

    let url = `${baseURL}${branchUrl}`
    if (tableId) {
      url += `?table=${tableId}`;
    }
    this.applyStyles();
    this.setState({
      branchUrl,
      tableId,
      branchTitle,
      tableTitle,
      url,
      parentUrl
    }, this.downloadPNG);
  }
  applyStyles = () => {
    const css = `
      body {
        width: 1500px;
        height: 1500px;
      }
    `;
    const head = document.head || document.getElementsByTagName('head')[0];
    const style = document.createElement('style');

    head.appendChild(style);

    style.type = 'text/css';
    if (style.styleSheet){
      // This is required for IE8 and below.
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }
  }
  generateQR = () => {
    const { fillColor, bgColor, url } = this.state;
    const typeNumber = 0;
    const errorCorrectionLevel = 'H';
    const qr = qrcode(typeNumber, errorCorrectionLevel);

    qr.addData(url);
    qr.make();
    const render = qr.createTableTag(25, 0, fillColor, bgColor);
    if (render) {
      return {
        __html: render
      };
    }
    return {
      __html: 'error'
    };
  }
  downloadPNG = () => {
    const { branchTitle, tableTitle, parentUrl } = this.state;
    this.setState({
      loading: true
    });
    const fileName = `qr-${branchTitle}${tableTitle ? '-' + tableTitle : ''}-${(new Date()).toLocaleTimeString()}.png`;
    domtoimage.toPng(document.getElementById('qrTarget'))
      .then((dataUrl) => {
        return saveAs(dataUrl, fileName);
      }).then(() => {
        this.setState({
          loading: false
        });
        console.log('parentUrl', parentUrl);
        if (parentUrl) {
          try {
            parent.postMessage({// eslint-disable-line
              type: "png.download.completed",
            }, parentUrl);
          } catch (e) {
          }
        }

      });
  }
  downloadSVG = () => {
    const { branchTitle } = this.state;
    this.setState({
      loading: true
    });

    domtoimage.toPng(document.getElementById('qrTarget'))
      .then((dataUrl) => {
        window.Potrace.loadImageFromUrl(dataUrl);
        window.Potrace.process(function(){
          const svg = window.Potrace.getSVG(1);
          saveAs(new Blob([svg], {type: "image/svg+xml"}), `qr-${branchTitle}-${(new Date()).toLocaleTimeString()}.svg`);
        });
      }).then(() => {
        this.setState({
          loading: false
        })
      });
  }
  handleColor = (color, colorName) => {
    this.setState({ [colorName]: color.hex });
  }
  doPreset = (e, presetName) => {
    let fillColor = '#000000';
    let bgColor = '#FFFFFF';
    switch(presetName) {
      case 'Brand':
        fillColor = '#FC4554';
        break;
      case 'WB':
        fillColor = '#FFFFFF';
        bgColor = '#000000';
        break;
      default:
    }
    this.setState({
      fillColor,
      bgColor
    })
  }
  toggleIcon = () => {
    const { showIconScanMe } = this.state;
    this.setState({
      'showIconScanMe': !showIconScanMe
    });
  }
  render() {
    const { loading, fillColor, bgColor, showIconScanMe } = this.state;
    const boxStyle = {
      position: 'relative',
      padding: '20px',
      background: bgColor,
      display: 'inline-block',
    }
    return (
    	<React.Fragment>
        <div style={{
          marginBottom: '30px',
        }}>
          <Row gutter={8}  style={{display: 'none'}}>
            <Col span={4}>
              <h3>Download</h3>
              <Button onClick={ this.downloadPNG } loading={loading} style={{marginBottom: '8px'}}>
                Download PNG
              </Button>
              <Button onClick={ this.downloadSVG } loading={loading} style={{marginBottom: '8px'}}>
                Download SVG (B&W only)
              </Button>
              <h3>Scan Me Icon</h3>
              <Button onClick={ this.toggleIcon } style={{marginBottom: '8px'}}>
                {showIconScanMe ? 'Hide Icon' : 'Show Icon' }
              </Button>
            </Col>
            <Col span={5}>
              <h3>Presets</h3>
              <Button onClick={ e => this.doPreset(e, 'Brand') } style={{marginBottom: '8px'}}>
                Brand Colors
              </Button>
              <Button onClick={ e => this.doPreset(e, 'BW') } style={{marginBottom: '8px'}}>
                Black and White
              </Button>
              <Button onClick={ e => this.doPreset(e, 'WB') } style={{marginBottom: '8px'}}>
                White and Black
              </Button>
            </Col>
            <Col span={7}>
              <h3>Fill color</h3>
              <CompactPicker
                color={ fillColor }
                onChangeComplete={ e => this.handleColor(e, 'fillColor') }
              />
            </Col>
            <Col span={7}>
              <h3>Background Color</h3>
              <CompactPicker
                color={ bgColor }
                onChangeComplete={ e => this.handleColor(e, 'bgColor') }
              />
            </Col>

          </Row>
        </div>
        <div style={{textAlign: 'center'}}>
          <span id="qrTarget" style={boxStyle}>
            <span dangerouslySetInnerHTML={this.generateQR()}></span>
            {showIconScanMe ? <ScanMeLogo fillColor={fillColor} bgColor={bgColor} /> : null }
          </span>
        </div>
      </React.Fragment>
    )
  }
};

export default QrGenerator;
