import React from 'react';
import { withWindowSizeListener } from 'react-window-size-listener';
import Settings from 'services/config/Settings';
import { Layout } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';
import qs from 'qs';
import { withTranslation } from 'react-i18next';
import SendEmailConfirmation from './components/SendEmailConfirmation';
import UpdatePassword from './components/UpdatePassword';
import Logo from 'components/images/logo.svg';

import './styles.less';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resetPassAccess: undefined,
      userId: null
    };
  }
  componentDidMount = async  () => {
    const { t } = this.props;

    this.mounted = true;
    document.title = `${t('resetPassword.documentTitle', { defaultValue: 'Reset password' })} | ${Settings.title}`;
    document.body.addEventListener('touchmove', this.preventDefault, { passive: false });

    let checkPass = false;
    if (window.location.search) {
      let paramFromUrl = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      if (paramFromUrl && paramFromUrl.pass) {
        this.props.history.push('/resetpass');
        checkPass = paramFromUrl.pass;
      }
    }
    if (checkPass) {
      this.checkResetPassAccess(checkPass);
    } else {
      this.setState({ resetPassAccess: false });
    }
  } 
  preventDefault = (e) => {
    e.preventDefault();
  }
  componentWillUnmount = () => {
    this.mounted = false;
    document.body.removeEventListener('touchmove', this.preventDefault)
  }
  checkResetPassAccess = (pass) => {
    return new Promise((resolve, reject) => {
      fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/auth/checkResetPasswordAccess', {
        method: 'PUT',
        body: {
          pass: pass
        }
      }).then(response => {
        if (response && this.mounted) {
          this.setState({
            resetPassAccess: response.access ? true : false,
            userId: response.worker_id ? response.worker_id : null
          })
        }
        resolve();
      }).catch(error => {
          reject(error);
      })
    }).catch(() => console.log('error'));
  }
  render() {
    const {
      resetPassAccess,
      userId
    } = this.state;

    const containerStyle = {
      width: this.props.windowSize.windowWidth,
      height: this.props.windowSize.windowHeight,
      minHeight: this.props.windowSize.windowHeight
    }; 
    return (
      <Layout className="layoutApp layoutLogin layoutResetPass" style={containerStyle}>
        { typeof resetPassAccess !== 'undefined' && !resetPassAccess ? 
          <SendEmailConfirmation
            history={this.props.history}
            logo={Logo}
            /> : null }
        { typeof resetPassAccess !== 'undefined' && resetPassAccess ? 
          <UpdatePassword
            history={this.props.history}
            userId = {userId}
            logo={Logo} 
            /> : null }
      </Layout>
    );
  }
}

export default  withTranslation()(withWindowSizeListener(ResetPassword));