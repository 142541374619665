import React from 'react';
import { Table, Form, Input, Button } from 'antd';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined, DeleteOutlined } from '@ant-design/icons';

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

class MovableTableList extends React.Component {
	columns = () => {
		return [{
		    title: '',
		    dataIndex: 'sort',
		    width: 30,
		    className: 'drag-visible',
		    render: () => <DragHandle />,
		  },
		  {
		    title: 'Display name',
		    dataIndex: 'display_name',
		    className: 'drag-visible',
		    render: (text, record, index) => {
		    	// console.log('record', record.key)
		      return (<React.Fragment>
		      	<Form.Item
			        name={['modifiers', record.sort_order, 'display_name']}
			        rules={[{ required: true }]}
			        noStyle>
			         <Input type="text" placeholder="Display name" onChange={e => this.props.changeModifier('display_name', e.target.value, record.sort_order)} />
			      </Form.Item>
			      <div style={{ display: 'none' }}>
				      <Form.Item
				        name={['modifiers', record.sort_order, 'sort_order']}
				        rules={[{ required: false }]}
				        noStyle
				        >
				         <Input type="text" />
				      </Form.Item>

				      {
			      	 record.modifier_id ? 
			      	 <Form.Item
				        name={['modifiers', record.sort_order, 'modifier_id']}
				        rules={[{ required: false }]}
				        noStyle
				        >
				         <Input type="text" />
				       </Form.Item>
				      : null
			      	}
			      </div>

	      	</React.Fragment>)
		    } 
		  },
		  {
		    title: 'Price',
		    dataIndex: 'price',
		    className: 'drag-visible',
		    width: '150px',
		    render: (text, record, index) => {
		      return (<Form.Item
		        name={['modifiers', record.sort_order, 'price_add']}
		        rules={[{ required: false }]}
		        noStyle>
		         <Input type="number" addonBefore="$" onChange={e => this.props.changeModifier('price_add', e.target.value, record.sort_order)} />
		      </Form.Item>)
		    } 
		  },
		  {
		  	title: '',
		  	dataIndex: 'action',
		  	key: 'action',
		  	width: '150px',
		  	render: (text, record) => (
			   	<Button className="contacts__actions--btn" onClick={ () => this.props.deleteModifier(record.sort_order) } type="danger">
		        <DeleteOutlined /> Delete
		      </Button>
		  	)
		  }
		]
	}

  DraggableContainer = props => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={this.props.onSortEnd}
      {...props}
    />
  );

  DraggableBodyRow = ({ className, style, ...restProps }) => {
    const { modifierList } = this.props;
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = modifierList.findIndex(x => x.sort_order === restProps['data-row-key']);

    return <SortableItem index={index} {...restProps} />;
  };

  render() {
    const { modifierList, footer } = this.props;

    return (
      <Table
        pagination={false}
        dataSource={modifierList}
        columns={this.columns()}
        rowKey="sort_order"
        footer={footer}
        components={{
          body: {
            wrapper: this.DraggableContainer,
            row: this.DraggableBodyRow,
          },
        }}
      />
    );
  }
}

export default MovableTableList;
