import moment from 'moment-timezone';

export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}

export function getTimeDistance(type, timezone = 'Australia/Brisbane') {
  if (type === 'today') {
    const start = moment().tz(timezone).startOf('day'); 
    const end = moment().tz(timezone).endOf('day'); 

    return [start, end];
  }

  if (type === 'week') {
    const beginTime = moment().tz(timezone).endOf('day');
    const weekAgo = moment().tz(timezone).subtract(7,'d').startOf('day');
    return [weekAgo, beginTime];
  }

  if (type === 'month') {
    const beginTime = moment().tz(timezone).endOf('day');
    const monthAgo = moment().tz(timezone).subtract(1, 'months').startOf('day');
    return [monthAgo, beginTime];
  }
  if (type === 'sixmonths') {
    const beginTime = moment().tz(timezone).endOf('day');
    const sixmonthsAgo = moment().tz(timezone).subtract(6, 'months').startOf('day');
    return [sixmonthsAgo, beginTime];
  }

  if (type === 'year') {
    const beginTime = moment().tz(timezone).endOf('day');
    const yearAgo = moment().tz(timezone).subtract(1, 'years').startOf('day');

    return [yearAgo, beginTime];
  }

  if (type === 'total') {
    const now = moment().tz(timezone).endOf('day');
    const from2017 = moment('2017-01-01').tz(timezone).startOf('day');
    return [from2017, now];
  }
}