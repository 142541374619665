import React from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Button, Col } from 'antd';
import * as _sharedModules from '../../../../modules/_modules';
//import Menu from 'screens/Dashboard/Branches/components/SingleBranch/components/Menu/Menu';
import AddMenu from 'screens/Dashboard/Branches/components/SingleBranch/components/Menu/components/AddMenu/AddMenu';
import MenusTable from './MenusTable';

class BranchMenus extends React.Component {
	constructor(props) {
    super(props);
    
    this.state = {
    	isFetching: true,
      addingMenuInProcess: false,
      products: [],
      menusList: {},
      branchObj: {}
    };

    Object.keys(_sharedModules).forEach((_moduleName) => {
      this[_moduleName] = _sharedModules[_moduleName].bind(this);
    });
  }
  componentDidMount() {
    this.mounted = true;
  	this.getMenusList();
    this.getProducts();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.restaurantId !== this.props.restaurantId ||
    	prevProps.branchUuid !== this.props.branchUuid) {
      this.getMenusList();
    }
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  startAddMenu = () => {
    this.setState({ addingMenuInProcess: true });
    this.props.stateLocationSelects(true);
  }
	render() {
    const {
      addingMenuInProcess,
      products,
      menusList,
      branchObj
    } = this.state;

    const {
      history,
      role,
      restaurantId,
      branchId
    } = this.props;

		return (
      <React.Fragment>
        { !addingMenuInProcess ?
          <Row>
            <Col>
              <Button onClick={this.startAddMenu}>Add menu</Button>
            </Col>
          </Row>
        : null }
        <div>
          { !addingMenuInProcess ?
            <MenusTable 
              role={this.props.role}
              history={this.props.history}
              restaurantId={this.props.restaurantId}
              branchUuid={this.props.branchUuid}
              menusList={menusList}
              getMenusList={this.getMenusList}
              branchObj={branchObj}
            />
          :
            <AddMenu
              products={products}
              restaurantId={restaurantId}
              branchId={branchId}
              role={role}
              history={history}
              addStep={null}
              menuId={false}
              getMenu={() => {}}
              menuObj={false}
            />
          }
        </div>
		 </React.Fragment>
		)
	}
}


export default withTranslation()(BranchMenus);