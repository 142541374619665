import React from 'react';
import { Select } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';

const Option = Select.Option;

const getBusinessCategories = function getBusinessCategories() {
	fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/general/businessCategoriesList', {
		method: 'get'
	}).then(response => {
		console.log('response categories', response);
		if (response.success && this.mounted) {
			this.setState({
				business_categories: response.data
			});
		}
	 }).catch(error => {
			console.log(error);
	 });
}
const renderBusinessCategoriesOptions = function renderBusinessCategoriesOptions(value) {
	const { business_categories } = this.state;
	const options = [];
	if (business_categories && business_categories.length) {
    business_categories.forEach((category) => {
      options.push(<Option key={category.id} value={category.id}>{category.title}</Option>)
    })
  }
  return (options)
}

export {
	getBusinessCategories,
	renderBusinessCategoriesOptions
};