import React from 'react';
import _ from 'lodash';
import { Table, Layout, Input, Row, Tag, Tooltip, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import fetchJSON from 'services/utils/fetchJSON';
import { withTranslation, Trans } from 'react-i18next';
import { withWindowSizeListener } from 'react-window-size-listener';

import '../styles.less';

const { Content } = Layout;
const Search = Input.Search;

class BranchesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredInfo: {},
      sortedInfo: {},
      searchText: '',
      filtered: false,
      result: [],
      data: [],
      totalNumberTable: 0,
      countRestaurants: 0,
      scrollTable: false,
      showArchived: false
    };
    this.totalNumberTable = 0;
    this.totalNumberInterval = null;
    // for detecting if scroll is enabled
    this.scrollTableInterval = null;
    this.tableScrollChecked = false;
  }
  componentDidMount() {
    this.mounted = true;
    this.setState({
      pagination: {
        total: 0,
        pageSizeOptions: [10, 30, 50, 100],
        showSizeChanger: true,
        showTotal: (total) => {
          return <p id="totalNumber" style={{display: 'none'}}>{this.renderTotal(total)}</p>
        }
      }
    })
    this.scrollTableInterval = setInterval(this.checkIfScrollOn, 100);
    this.getBranches();
  }
  componentWillUnmount() {
    this.mounted = false;
    clearInterval(this.totalNumberInterval);
    clearInterval(this.scrollTableInterval);
  }
  getBranches = (showArchived = false) => {
    const countryFilters = [];
    const countryStorage = [];
    const restaurantFilters = [];
    const restaurantStorage = [];
    const bodyObj = {};
    if (this.props.restaurantId) {
      bodyObj.restaurantId = this.props.restaurantId;
    }
    bodyObj.showArchived = showArchived;

    this.setState({
      isFetching: true
    });
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches/list', {
      method: 'put',
      body: bodyObj
    }).then(response => {
      if (this.mounted) {
        if (response.data && response.data.length) {
          this.setState({
            countRestaurants: response.data.length,
          });
          response.data.forEach((branch) => {
            if (branch.address_components && branch.address_components.country) {
              if (countryStorage.indexOf(branch.address_components.country) === -1) {
                countryStorage.push(branch.address_components.country);
                countryFilters.push({
                  text: branch.address_components.country, value: branch.address_components.country
                })
              }
            }
            const restaurantUuid = _.get(branch, 'restaurant.restaurant.uuid');
            const restaurantTitle = _.get(branch, 'restaurant.restaurant.name');
            if (restaurantTitle && restaurantUuid && restaurantStorage.indexOf(restaurantUuid) === -1) {
              restaurantFilters.push({ text: restaurantTitle, value: restaurantUuid });
              restaurantStorage.push(restaurantUuid);
            }
          })
          this.setState({
            isFetching: false,
            result: response.data,
            countryFilters,
            restaurantFilters
          })
        } else {
          this.setState({ isFetching: false });
        }
      }
    }).catch(error => {
      console.log(error);
      if (this.mounted) {
        this.setState({
          isFetching: false
        });
      }
    });
  }
  checkIfScrollOn = () => {
    const { isFetching } = this.state;
    const tableContent = document.getElementsByClassName("ant-table-content");
    if (tableContent && tableContent.length && !isFetching) {
      if (this.checkTableScroll(tableContent[0])) {
        this.setState({
          scrollTable: true
        })
      }  else {
        this.setState({
          scrollTable: false
        })
      }
      clearInterval(this.scrollTableInterval);
    }
  }
  checkTableScroll = (e) => {
    const tableClass = this.props.inner ? "ant-table-wrapper generalTable innerTable" : 'ant-table-wrapper generalTable';
    const tableWrapper = document.getElementsByClassName(tableClass);
    this.tableScrollChecked = true;
    if (tableWrapper && tableWrapper[0] && e) {
      return (tableWrapper[0].offsetWidth < e.scrollWidth)
    }
    return false
  }
  trackTotalNumberValue = () => {
    clearInterval(this.totalNumberInterval);
    const { totalNumberTable } = this.state;
    if (totalNumberTable !== this.totalNumberTable) {
      this.setState({
        totalNumberTable: this.totalNumberTable
      });
    }
    this.totalNumberInterval = null;
  }
  renderTotal = (total) => {
   this.totalNumberTable = total;
   const { totalNumberTable } = this.state;
   if (totalNumberTable !== this.totalNumberTable && !this.totalNumberInterval) {
    this.totalNumberInterval = setInterval(this.trackTotalNumberValue, 100);
   }
   return total;
  }
  isEllipsisActive = (e) => {
    if (e) {
      return (e.offsetWidth < e.scrollWidth)
    }
    else {
      return false
    }
  }
  columns = () => {
    const { t } = this.props;
    let {
      sortedInfo,
      filteredInfo,
      countryFilters,
      restaurantFilters
    } = this.state;

    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    countryFilters = countryFilters || [];
    restaurantFilters = restaurantFilters || [];

    let titleItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            { t('shareable.title', { defaultValue: 'Title' })}
          </div>
          )
      },
      dataIndex: 'title',
      key: 'title',
      width: 157,
      sorter: (a, b) => a.title > b.title ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'title' && sortedInfo.order,
      render: (text, record) => (
        <div>
          <Tooltip title={this.isEllipsisActive(document.getElementById(`restaurantTitleText_${record.key}}`)) ? record.title : ''}>
            <Link to={`/branches/${record.uuid}`}>
              <p id={`restaurantTitleText_${record.key}}`} style={{
                  margin:0,
                  width: '140px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: record.unlinked ? 'red' : ''
                }}>
                {record.title}
              </p>
            </Link>
          </Tooltip>
        </div>
      )
    };
    const restaurantItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            { t('shareable.restaurant', { defaultValue: 'Restaurant'})}
          </div>
          )
      },
      dataIndex: 'restaurantUuid',
      key: 'restaurantUuid',
      filters:  restaurantFilters,
      filteredValue: filteredInfo.restaurantUuid || null,
      onFilter: (value, record) => {
        return record.restaurantUuid === value
      },
      align: 'center',
      width: 170,
      render: (text, record) => (
        <div>
          <Tooltip title={this.isEllipsisActive(document.getElementById(`restaurantText__${record.key}}`)) ? record.restaurant : ''}>
            <Link to={`/restaurants/${record.restaurantUuid}`}>
              <p id={`restaurantText__${record.key}}`} style={{margin:0, width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                {record.restaurant}
              </p>
            </Link>
          </Tooltip>
        </div>
      )
    };
    const addressItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            { t('shareable.address', { defaultValue: 'Address'})}
          </div>
          )
      },
      dataIndex: 'address',
      key: 'address',
      filters:  countryFilters,
      filteredValue: filteredInfo.address || null,
      onFilter: (value, record) => {
        return record.country.includes(value)
      },
      align: 'center',
      width: 170,
      render: (text, record) => (
        <div>
          <Tooltip title={this.isEllipsisActive(document.getElementById(`addressText_${record.key}}`)) ? record.address : ''}>
            <p id={`addressText_${record.key}}`} style={{margin:0, width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
              {record.address}
            </p>
          </Tooltip>
        </div>
      )
    };
    const menuItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            { t('shareable.menues', { defaultValue: 'Menues' })}
          </div>
          )
      },
      dataIndex: 'menu',
      key: 'menu',
      width: 157,
      align: 'center',
      sorter: (a, b) => a.menu > b.menu ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'menu' && sortedInfo.order
    };
    const employeeItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            { t('shareable.employees', { defaultValue: 'Employees' }) }
          </div>
        )
      },
      dataIndex: 'employees',
      key: 'employees',
      align: 'center',
      width: 130,
      sorter: (a, b) => a.employees > b.employees ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'employees' && sortedInfo.order,
      render: (text, record) => {
        return (<span>0</span>)
      },
    };
    const revenueItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            { t('shareable.revenue', { defaultValue: 'Revenue' }) }
          </div>
        )
      },
      dataIndex: 'revenue',
      key: 'revenue',
      align: 'center',
      width: 130,
      sorter: (a, b) => a.revenue > b.revenue ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'revenue' && sortedInfo.order
    }
    const ordersItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            { t('shareable.orders', { defaultValue: 'Orders' }) }
          </div>
        )
      },
      dataIndex: 'orders',
      key: 'orders',
      align: 'center',
      width: 130,
      sorter: (a, b) => a.orders > b.orders ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'orders' && sortedInfo.order
    }
    const tablesItem = {
      title: () => {
        return (
          <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            { t('shareable.tables', { defaultValue: 'Tables' }) }
          </div>
        )
      },
      dataIndex: 'tables',
      key: 'tables',
      align: 'center',
      width: 130,
      sorter: (a, b) => a.tables > b.tables ? 1 : -1,
      sortOrder: sortedInfo.columnKey === 'tables' && sortedInfo.order,
    }
    if (this.state.scrollTable) {
      titleItem.fixed = "left";
    }

    const columns = [titleItem, tablesItem, menuItem, ordersItem, revenueItem];
    if (!this.props.restaurantId && this.props.role === 'admin') columns.push(restaurantItem);
    columns.push(addressItem, employeeItem);

    return columns;
  }
  getData = () => {
    const data = [];
    if (!this.state.result) return data;

    let branches = this.state.result;
    if (this.state.filtered) {
      branches =  this.state.data;
    }
    if (!Array.isArray(branches) || !branches.length) {
      return data;
    }
    branches.forEach((branch, i) => {
      const categoriesRender = [];
      const categoriesList = [];
      const tagColors = ['geekblue', 'cyan', 'magenta', 'purple'];
      if (branch.category && branch.category.length) {
        branch.category.forEach((relation, k) => {
          if (relation.category) {
            if (categoriesList.indexOf(relation.category.display_name) === -1) {
              if (categoriesList.length < 4) {
                categoriesRender.push(<Tag key={k} color={tagColors[categoriesList.length]} style={{margin: '3px'}}>
                  {categoriesList.length === 3 ? '...' : relation.category.display_name}
                </Tag>)
              }
              categoriesList.push(relation.category.display_name)
            }
          }
        })
      }
      const restaurantTitle = _.get(branch, 'restaurant.restaurant.name');
      const restaurantUuid = _.get(branch, 'restaurant.restaurant.uuid');

      let revenue = '';
      if (typeof branch.revenue === 'object' && Object.keys(branch.revenue).length) {
        const revenueArr = [];
        Object.keys(branch.revenue).forEach((key) => {
          revenueArr.push(`${!!branch.revenue[key]?branch.revenue[key].toFixed(2):''} ${key}`);
        })
        revenue = revenueArr.join(', ');
      }
      data.push({
        key: i,
        id: branch.id,
        title: branch.title,
        uuid: branch.uuid,
        address: branch.formatted_address,
        country: branch.address_components ? branch.address_components.country : '',
        status: branch.status,
        menu: branch.menues ? branch.menues.length : 0,
        categoriesRender,
        categoriesList,
        tables: branch.tables ? branch.tables.length : 0,
        restaurant: restaurantTitle || '',
        restaurantUuid,
        revenue,
        orders: branch.orders ? branch.orders : 0,
        unlinked: branch.unlinked
      })
    });
    return data;
  }

  handleTableChange = (pagination, filters, sorter) => {
    //console.log('handleTableChange', pagination, filters, sorter);
    setTimeout(() => {
      if (!document.getElementById("totalNumber")) {
        this.setState({
          totalNumberTable: 0
        })
      }
    }, 100)
    // used for tooltips in cells (because it searchs for html element)
    setTimeout(() => {
      const { pagination } = this.state;
      this.setState({
        pagination: {
          ...pagination,
          update: !pagination.update
        }
      })
    }, 100)
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    })
  }
  onSearch = (e) => {
    this.setState({ searchText: e.target.value });
    const searchText = e.target.value;
    const reg = new RegExp(e.target.value, 'gi');
    this.setState({
      filtered: !!searchText,
      data: _.flatMap(this.state.result, record => {
        const nameMatch = record.title && record.title.length ? record.title.match(reg) : false;
        if (!nameMatch) {
          return null;
        }
        return {
          ...record,
          };
      }).filter(record => !!record),
    });
    if (e.target.value === ''){
      this.setState({
        data: this.state.result,
        filtered: null
      });
    }
  }
  modalVisible = (key, value) => {
    this.setState({ [key]: value });
  }
  onChangeArchived = () => {
    const {
      showArchived
    } = this.state;

    this.setState({
      showArchived: !showArchived
    }, () => {
      this.getBranches(!showArchived);
    });
  }
  render() {
    const { t } = this.props;
    const {
      searchText,
      isFetching,
      pagination,
      totalNumberTable
    } = this.state;
    const visiblleTable = !isFetching && !this.tableScrollChecked;
    const tableClass = this.props.inner ? "generalTable innerTable" : 'generalTable';
    const contentClass = this.props.inner ? "bg-transparent table-content innerContent" : "bg-transparent table-content";
    const addBranchLink = this.props.restaurantUuid && this.props.role === 'admin' ? `/branches/add?uuid=${this.props.restaurantUuid}` :
      '/branches/add';
    return (
      <Content className={contentClass}>
        <Table
          style={{visibility: !visiblleTable ? '' : 'hidden'}}
          columns={ this.columns() }
          loading={ isFetching }
          dataSource={ isFetching ? null : this.getData() }
          pagination={{
            ...pagination,
            showSizeChanger: totalNumberTable > 10 ? true : false}
          }
          onChange={ this.handleTableChange }
          showSorterTooltip={false}
          size={"middle"}
          scroll={this.state.scrollTable ? {x: 'fit-content'} : false}
          className={tableClass}
          footer={() => {
           return (<div style={{ display: 'flex' }}>
            <Checkbox
              checked={this.state.showArchived}
              onChange={this.onChangeArchived}
              style={{ marginLeft: 'auto' }}
            >
              Archived
            </Checkbox>
            </div>)
          }}
          title={() =>
            <Row className="tableHeader__container">
              <div className="tableHeader__start-container">
                <p className="tableHeader__header-text">
                  <Trans i18nKey="shareable.venues">Venues</Trans>
                </p>
                <p className="tableHeader__quantity-text">
                  Quantity: {totalNumberTable}
                </p>
              </div>
              <div className="tableHeader__end-container">
                <Link className="ant-btn ant-btn-primary ant-btn-sm tableHeader__add-button" to={addBranchLink}>
                  <p><Trans i18nKey="shareable.addRestaurant">Add branch</Trans></p>
                </Link>
                 <Search allowClear className="tableHeader__search" size="middle" disabled={isFetching} ref={ele => this.searchText = ele} onChange={this.onSearch} placeholder={t('shareable.search', { defaultValue: 'Search' })} value={searchText}
                 onPressEnter={this.onSearch} />
              </div>
            </Row>
          }
        />
      </Content>
    )
  }
};

export default withTranslation()(withWindowSizeListener(BranchesTable));
