import React from 'react';
import { Row, Col, Button } from 'antd';
import { withTranslation } from 'react-i18next';
import { withWindowSizeListener } from 'react-window-size-listener';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Pie,
  linearGradient,
  PieChart,
  Cell,
  AreaChart,
  Area
} from 'recharts';

import ProductsTable from '../ProductsReport/components/ProductsTable';

import './styles.less';

class GeneralReport extends React.Component {

  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  render() {
    const { reportsData, filterTypeOrder } = this.props;
    const COLORS = ['#1C8DCC', '#E01491', '#783E79', '#FF8042'];

    return (
      <div style={{width: '100%'}}>
        <Row className='reports-data-row first-row-fix'>
          <Col sm={6} xs={12}>
            <div>
              <label className="reports-label circle-label">Orders</label>
              <div className="circle-data">
                <span>{reportsData.totalOrders}</span>
              </div>
            </div>
          </Col>
          <Col sm={6} xs={12}>
            <div>
              <label className="reports-label circle-label">Revenue</label>
              <div className="circle-data">
                <span>{reportsData.revenue}</span>
              </div>
            </div>
          </Col>
          { filterTypeOrder === 'all' && reportsData.typeOfOrders && reportsData.typeOfOrders.length ?
            <Col sm={6} xs={24}>
              <div>
                <label className="reports-label">Type of orders</label>
              </div>
              <div style={{height: '300px'}}>
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      dataKey="count"
                      data={reportsData.typeOfOrders}
                      cx="50%" cy="50%"
                      innerRadius={50}
                      outerRadius={90}
                      paddingAngle={5}
                      fill="#8884d8"
                      labelLine={false}
                      legendType="diamond"
                    >
                      { reportsData.typeOfOrders.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} /> ) }
                    </Pie>
                    <Legend align="left" layout='vertical' verticalAlign='middle' />
                    <Tooltip/>
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </Col> : null }
          { reportsData.paymentType && reportsData.paymentType.length ?
            <Col sm={6} xs={24}>
              <div>
                <label className="reports-label">Type of payment</label>
              </div>
              <div style={{height: '300px'}}>
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      dataKey="count"
                      data={reportsData.paymentType}
                      cx="50%" cy="50%"
                      innerRadius={50}
                      outerRadius={90}
                      paddingAngle={5}
                      fill="#8884d8"
                      legendType="diamond"
                      labelLine={false}
                    >
                      { reportsData.paymentType.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} /> ) }
                    </Pie>
                    <Tooltip/>
                    <Legend align="left" layout='vertical' verticalAlign='middle' />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </Col> : null }
        </Row>

        <Row className='reports-data-row'>
          <Col span={22}>
            <Row>
              <label className="reports-label">Orders per hour</label>
            </Row>
            <Row className="graphics-container">
              <ResponsiveContainer>
                <BarChart data={reportsData.ordersPerHour} margin={{top: 30, right: 0, left: 0, bottom: 0}}>
                  <XAxis dataKey="time"/>
                  <YAxis/>
                  <Tooltip/>
                  <Bar name="Orders" dataKey="count" fill="#1C8DCC" />
                </BarChart>
              </ResponsiveContainer>
            </Row>
          </Col>
        </Row>
        { reportsData.ordersPerDay && reportsData.ordersPerDay.length ?
        <Row className='reports-data-row'>
          <Col span={22}>
            <Row>
              <label className="reports-label">Orders</label>
            </Row>
            <Row className="graphics-container">
              <ResponsiveContainer>
                <AreaChart data={reportsData.ordersPerDay} margin={{top: 30, right: 0, left: 0, bottom: 0}}>
                  <defs>
                    <linearGradient id="ordersGrad" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#1C8DCC" stopOpacity={0.8}/>
                      <stop offset="95%" stopColor="#1C8DCC" stopOpacity={0}/>
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="time"/>
                  <YAxis/>
                  <Tooltip/>
                  <Area type="monotone" name="Orders" dataKey="count" fillOpacity={1} fill="url(#ordersGrad)" stroke="#1C8DCC" activeDot={{r: 4}}/>
                </AreaChart>
              </ResponsiveContainer>
            </Row>
          </Col>
        </Row> : null }
        { filterTypeOrder === 'all' ?
        <Row className='reports-data-row'>
          <Col span={22}>
            <Row>
              <label className="reports-label">Dine in & Takeaway</label>
            </Row>
            <Row className="graphics-container">
              <ResponsiveContainer>
                <AreaChart data={reportsData.ordersPerDayByType} margin={{top: 20, right: 0, left: 0, bottom: 0}}>
                  <defs>
                    <linearGradient id="takeOutGrad" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#1C8DCC" stopOpacity={0.8}/>
                      <stop offset="95%" stopColor="#1C8DCC" stopOpacity={0}/>
                    </linearGradient>
                    <linearGradient id="dineInGrad" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#783E79" stopOpacity={0.8}/>
                      <stop offset="95%" stopColor="#783E79" stopOpacity={0}/>
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="time"/>
                  <YAxis/>
                  <Tooltip/>
                  <Legend align="left" layout='vertical' verticalAlign='middle' />
                  <Area type="monotone" name="Takeaway" dataKey="countTakeOut" stroke="#1C8DCC" fillOpacity={1} fill="url(#takeOutGrad)" activeDot={{r: 4}}/>
                  <Area type="monotone" name="Dine in" dataKey="countDineIn" stroke="#783E79" fillOpacity={1} fill="url(#dineInGrad)" activeDot={{r: 4}}/>
                </AreaChart>
              </ResponsiveContainer>
            </Row>
          </Col>
        </Row> : null }
        { reportsData.productsByCount && reportsData.productsByCount.length ?
        <Row className='reports-data-row'>
          <Col span={22}>
            <Row style={{marginBottom: '12px'}}>
              <label className="reports-label">Best Sellers</label>
            </Row>
            <Row>
              <ProductsTable
                products={reportsData.productsByCount}
                history={this.props.history}
              />
            </Row>
            <Row style={{marginTop: '12px'}}>
              <Button style={{background: '#1C8DCC', borderColor: '#1C8DCC'}} onClick={this.props.openProductsReport} type="primary">Detailed items report</Button>
            </Row>
          </Col>
        </Row> : null }
      </div>
    )
  }
}

export default withTranslation()(withWindowSizeListener(GeneralReport));
