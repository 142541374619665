import React from 'react';
import { Modal } from 'antd';
import Menu from 'screens/Dashboard/Branches/components/SingleBranch/components/Menu/Menu';

class ShowMenu extends React.Component {
	state = {
		putREQUEST: false
	}
	componentDidMount = () => {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  render() {
  	const {
  		putREQUEST
  	} = this.state;
  	const {
  		menuId,
  		restaurantId
  	} = this.props;
  	console.log('menuId', menuId);

    return (
    	<Modal 
        className="general-modal-btns-center general-card"
        title=""
        centered={true}
        visible={true}
        onCancel={this.props.handleClose}
        destroyOnClose={true}
      	footer={null}
        confirmLoading={putREQUEST}
        maskClosable={true}
        width={1000}
      >
        <Menu 
          role={this.props.role}
          menuId={menuId}
          showAddMenu={false}
          restaurantId={restaurantId}
          history={this.props.history}
          itsModalWindow={true}
          />
      </Modal>
    )
  }
};

export default ShowMenu;