import React, { Component } from 'react';
import {
  Layout,
  Spin,
  Col,
  Row,
  Table,
  Button,
  Tag,
  DatePicker,
  Radio,
  Popover,
  notification
} from 'antd';
import Settings from 'services/config/Settings';
import { withTranslation, Trans } from 'react-i18next';
import fetchJSON from 'services/utils/fetchJSON';
import { getTimeDistance } from 'services/utils/utils';
import { CloseOutlined } from '@ant-design/icons';
import SingleOrder from './components/SingleOrder';
import Socket from 'services/utils/Socket';

import './styles.less';

const { Content } = Layout;
const { RangePicker } = DatePicker;

const paymentMethodsLabels = {
  card_pay_at_counter: 'Card pay at counter',
  cash: 'Cash',
  paid_online: 'Mobile payment',
  discount: 'Discount',
  credit_card: 'Credit Card',
  card: 'Credit Card',
  pay_at_counter: 'Pay at counter'
};


class Manager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      fetchingProducts: false,
      totalNumberTable: 0,
      branch: {},
      orders: [],
      tables: [],
      timezone: 'America/Los_Angeles',
      filteredInfo: {},
      sortedInfo: {},
      orderTypesList: [],
      paymentMethodsList: [],
      rangePickerValue: getTimeDistance('sixmonths'),
      datePickerSize: 'default',
      isDateFilterActive: false,
      filterTypeOrder: 'all',
      visibleSingleOrder: false,
      selectedOrderUuid: '',
      scrollTable: false,
    };
    this.totalNumberTable = 0;
    this.totalNumberInterval = null;
  }
	componentDidMount() {
    this.mounted = true;
    const { t } = this.props;
    document.title = `${t('shareable.orders', { defaultValue: 'Orders' })} | ${Settings.title}`;
    this.getOrders();
    this.setState({
      scrollTable: window.innerWidth < 769 ? true : false,
      pagination: {
        total: 0,
        pageSizeOptions: [10, 30, 50, 100],
        showSizeChanger: true,
        showTotal: (total) => {
          return <p id="totalNumber" style={{display: 'none'}}>{this.renderTotal(total)}</p>
        }
      }
    })
    this.subscribeToSocket();
  }
  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribeFromSocket()
    clearInterval(this.totalNumberInterval);
  }
  subscribeToSocket = () => {
    const { branchUuid } = this.props;
    Socket.on(`notification_${branchUuid}`, this.updateScreen);
  }
  unsubscribeFromSocket = () => {
    const { branchUuid } = this.props;
    Socket.off(`notification_${branchUuid}`, this.updateScreen);
  }

  updateScreen = (data) => {
    console.log('notification', data);

    const { type } = data;
    if (type === 'new_order') {
      notification.info({
        message: 'New order added'
      });
    }

    if (type === 'ready_to_pay') {
      notification.info({
        message: 'User is ready to pay'
      });
    }
    if (type === 'order_updated') {
      notification.info({
        message: 'Order updated'
      });
    }
    this.getOrders();

  }
  renderTotal = (total) => {
    this.totalNumberTable = total;
    const { totalNumberTable } = this.state;
    if (totalNumberTable !== this.totalNumberTable && !this.totalNumberInterval) {
      this.totalNumberInterval = setInterval(this.trackTotalNumberValue, 100);
    }
    return total;
  }
  trackTotalNumberValue = () => {
    clearInterval(this.totalNumberInterval);
    const { totalNumberTable } = this.state;
    if (totalNumberTable !== this.totalNumberTable) {
      this.setState({
        totalNumberTable: this.totalNumberTable
      });
    }
    this.totalNumberInterval = null;
  }
  getOrders = () => {
    this.setState({
      isFetching: true
    });
    const { branchUuid } = this.props;
    const { rangePickerValue, isDateFilterActive } = this.state;
    const dates = [rangePickerValue[0].format(), rangePickerValue[1].format()];

    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/orders/list/', {
      method: 'post',
      body: {
        branchUuid,
        isDateFilterActive,
        dates
      }
    }).then(response => {
      console.log(response);
      if (this.mounted) {
        const { timezone } = response.data.branch;
        this.setState({
          branch: response.data,
          tables: response.data.tables,
          orders: response.data.orders,
          totalNumberTable: response.data.orders.length,
          timezone,
          isFetching: false,
        }, this.setFilter);
      }
    }).catch(error => {
      console.log(error);
      if (this.mounted) {
        this.setState({
          isFetching: false
        });
      }
    });
  }
  setFilter = () => {
    const { tables } = this.state;
    const orderTypesList = [{ text: 'Takeaway', value: 'takeout' }];
    const paymentMethodsList = [];
    tables.forEach((table) => {
      orderTypesList.push({ text: table.display_name, value: table.id })
    });
    const temp = [];
    Object.keys(paymentMethodsLabels).forEach((key) => {
      if (temp.indexOf(paymentMethodsLabels[key]) === -1) {
        temp.push(paymentMethodsLabels[key]);
        paymentMethodsList.push({ text: paymentMethodsLabels[key], value: paymentMethodsLabels[key] });
      }
    });

    this.setState({
      orderTypesList,
      paymentMethodsList
    });
  }
  renderTable = (table_id) => {
  	if (!table_id) {
  		return 'Dine In';
  	}
    const { tables } = this.state;
    const table = tables.filter((item) => {
      return item.id === table_id;
    });
    if (table) {
      return table[0].display_name;
    }
    return null;
  }

  setIsFetching = (param) => {
    this.setState({
      fetchingProducts: param
    });
  }
  columns = () => {
    let {
      sortedInfo,
      filteredInfo,
      orderTypesList,
      paymentMethodsList
    } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    const columns = [{
      title: 'Order #',
      dataIndex: 'index_id',
      key: 'index_id',
      sortOrder: sortedInfo.columnKey === 'index_id' && sortedInfo.order,
      sorter: (a, b) => a.index_id > b.index_id ? 1 : -1,
    },
    {
      title: 'Order Status',
      dataIndex: 'status',
      key: 'status',
      filters: [{
        text: 'Submitted',
        value: 'Submitted',
      }, {
        text: 'Complete',
        value: 'Complete'
      }, {
        text: 'Delivered',
        value: 'Delivered'
      }, {
        text: 'Completed',
        value: 'Completed'
      }, {
        text: 'Cancelled',
        value: 'Cancelled'
      }],
      filteredValue: filteredInfo.status || null,
      onFilter: (value, record) => {
        return record.status.includes(value)
      },
      render: (text, record) => {
        let type = record.orderStatusType;
        return <Tag color={type}>{text}</Tag>
      }
    },
    {
      title: 'Order Type',
      dataIndex: 'order_type_display',
      key: 'order_type_display',
      filters: orderTypesList,
      filteredValue: filteredInfo.order_type_display || null,
      onFilter: (value, record) => {
        if (typeof record.order_type === 'number') {
          return record.order_type === value;
        }
        return record.order_type.includes(value)
      },
      render: (text, record) => {
        let type = 'orange';
        if (record.order_type === 'takeout') {
          type = 'blue';
        }
        if (typeof record.order_type === 'number') {
          type = 'success';
        }
        return <Tag color={type}>{text}</Tag>
      }
    },
    {
      title: 'Completed',
      dataIndex: 'completed',
      key: 'completed',
      sortOrder: sortedInfo.columnKey === 'completed' && sortedInfo.order,
      sorter: (a, b) => a.completed > b.completed ? 1 : -1,
    },
    {
      title: '# / Name',
      dataIndex: 'user_name',
      key: 'user_name',
    },
    {
      title: 'Payment method',
      dataIndex: 'payment_method_display',
      key: 'payment_method_display',
      filters: paymentMethodsList,
      filteredValue: filteredInfo.payment_method_display || null,
      onFilter: (value, record) => {
        return record.payment_method_display.includes(value)
      },
      render: (text, record) => {
        if (record.payment_comment) {
          return <Popover content={record.payment_comment} title="Comments">
          <span style={{ textDecoration: 'underline' }}>{text}</span>
          </Popover>
        }
        return text;
      }
    },
    {
      title: 'Payment status',
      dataIndex: 'payment_status',
      key: 'payment_status',
      filters: [{
        text: 'Paid',
        value: 'Paid'
      }, {
        text: 'Not Paid',
        value: 'Not Paid'
      }, {
        text: 'Refunded',
        value: 'Refunded'
      }],
      filteredValue: filteredInfo.payment_status || null,
      onFilter: (value, record) => {
        return record.payment_status === value;
      },
      render: (text, record) => {
        let type = this.tagPayment(record);

        if (text === 'Refunded' && record.refunded_by_at) {
          return <Popover content={record.refunded_by_at}>
            <Tag color={type}>{text}</Tag>
          </Popover>
        }

        return <Tag color={type}>{text}</Tag>
      }
    },
    {
      title: 'Items',
      dataIndex: 'items',
      key: 'items',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      sortOrder: sortedInfo.columnKey === 'total' && sortedInfo.order,
      sorter: (a, b) => a.total > b.total ? 1 : -1,
      render: (text, record) => {
        return <span>${!!text?text.toFixed(2):0}</span>
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <React.Fragment>
            <Button onClick={() => this.openOrder(record)} style={{ marginRight: '8px'}}>
              Details
            </Button>
            <Button onClick={() => this.print(record)} >
              Reprint
            </Button>
          </React.Fragment>
        )
      }
    }];


    return columns;
  }


  tagPayment = (order) => {
    let type = 'error';
    if (order.is_paid) {
      type = 'success';
    }
    if (order.is_refunded) {
      type = "#6B6F6E";
    }
    return type;
  }
  print = (record) => {
    console.log('print');
    const { orderObj } = record;
    const { branchUuid } = this.props;

    this.setIsFetching(true);
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches-orders/submit/', {
      method: 'POST',
      body: {
        branchUuid,
        orderUuid: orderObj.uuid,
        key: 'print_counter_receipt',
        value: true
      }
    }).then(response => {
      console.log(response);
      this.setIsFetching(false);
      notification.success({
        message: 'Ok',
      });
     }).catch(error => {
        console.log(error);
        this.setIsFetching(false);
     });
  }
  handleTableChange = (pagination, filters, sorter) => {
    //console.log('handleTableChange', pagination, filters, sorter);
    setTimeout(() => {
      if (!document.getElementById("totalNumber")) {
        this.setState({
          totalNumberTable: 0
        })
      }
    }, 100)
    // used for tooltips in cells (because it searchs for html element)
    setTimeout(() => {
      const { pagination } = this.state;
      this.setState({
        pagination: {
          ...pagination,
          update: !pagination.update
        }
      })
    }, 100)
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    })
  }
  getData = () => {
    const data = [];
    const { orders } = this.state;
    const {
      searchOrderId,
      filteredById
    } = this.props;
    if (!orders) return data;

    orders.forEach((order, i) => {
      //this.props.searchOrderId
      // console.log('order', order.customer);
      if (filteredById) {
        const orderId = order.index_id.toString();
        if (orderId.indexOf(searchOrderId) === -1) return;
      }

      const obj = {
        key: i,
        uuid: order.uuid,
        id: order.id,
        index_id: order.index_id,
        status: order.orderStatus,
        order_type: order.order_type,
        order_type_display: order.order_type_display,
        placed: order.is_submitted_at,
        completed: order.is_closed_at,
        user_name: order.server_name,
        payment_method: order.payment_method,
        payment_method_display: order.payment_method_display,
        is_paid: order.is_paid,
        payment_status: order.payment_status,
        items: order.order_items ? order.order_items.length : 0,
        total: order.total + order.processing_fee,
        refundable: !order.is_refunded && order.is_paid,
        payment_comment: order.payment_comment,
        orderObj: order,
        is_refunded: order.is_refunded,
        refunded_by_at: order.refunded_by_at,
        order_due: order.order_due,
        orderStatusType: order.orderStatusType
      };
      data.push(obj);
    });

    return data;
  }
  renderOrders = () => {
    const {
      isFetching,
      pagination,
      totalNumberTable
    } = this.state;

    return <Table
      columns={ this.columns() }
      loading={ isFetching }
      dataSource={ isFetching ? null : this.getData() }
      scroll={this.state.scrollTable ? {x: 'fit-content'} : false}
      pagination={{
        ...pagination,
        showSizeChanger: totalNumberTable > 10 ? true : false}
      }
      size={"middle"}
      onChange={ this.handleTableChange }
    />
  }

  isActive(type) {
    const { rangePickerValue, timezone } = this.state;
    const value = getTimeDistance(type, timezone);
    if (!rangePickerValue[0] || !rangePickerValue[1]) {
      return '';
    }
    if (
      rangePickerValue[0].isSame(value[0], 'day') &&
      rangePickerValue[1].isSame(value[1], 'day')
    ) {
      return 'ant-btn-primary';
    }

    return '';
  }
  selectDate = (type) => {
    const { timezone } = this.state;
    this.setState({
      rangePickerValue: getTimeDistance(type, timezone),
    }, this.getOrders);
  }
  filterByDate = (boolean) => {
    this.setState({
      isDateFilterActive: boolean
    }, () => {
      if (!boolean) {
        this.getOrders();
      }
    });
  }
  openOrder = (record) => {
    const { orderObj } = record;
    this.setState({
      selectedOrderUuid: orderObj.uuid
    }, () => {
      this.updateState('visibleSingleOrder', true);
    })
  }
  updateState = (key, value) => {
    this.setState({
      [key]: value
    });
  }
  filterType = (e) => {
    const { filteredInfo } = this.state;
    const copy = {...filteredInfo};
    const filterTypeOrder = e.target.value;
    const status = [];
    if (filterTypeOrder === 'active') {
      status.push('Submitted', 'Complete', 'Delivered');
    }
    copy.status = status;

    this.setState({
      filterTypeOrder,
      filteredInfo: copy
    });
  }
  render() {
    const {
      isFetching,
      totalNumberTable,
      rangePickerValue,
      isDateFilterActive,
      filterTypeOrder,
      visibleSingleOrder,
      selectedOrderUuid,
    } = this.state;
    const { branchUuid } = this.props;
    return (
      <Content className="bg-transparent table-content StatisticsScreen">
        <Spin spinning={isFetching}>
          <Row gutter={8}>
            <Col span={24}>
              <Row gutter={8}>
                <Col span={24}>
                  <h3 style={{color: '#454545'}}>
                    Orders
                  </h3>
                  <span>Qty: {totalNumberTable}</span>
                  <div className="kitchenScreenHead" style={{marginTop: '10px'}}>
                    <div>
                      <Radio.Group value={filterTypeOrder} buttonStyle="solid" onChange={this.filterType}>
                        <Radio.Button value="all">All</Radio.Button>
                        <Radio.Button value="active">Active</Radio.Button>
                      </Radio.Group>
                    </div>
                    <div className="usageElectronWrap">
                      <div className="usageElectron">
                        {
                          !isDateFilterActive ?
                          <Button onClick={() => this.filterByDate(true)} size="medium" type="primary">
                            Filter by date
                          </Button>
                          :
                          <React.Fragment>
                          <div className="range-button-container">
                            <button className={"ant-btn "+this.isActive('today')} onClick={() => this.selectDate('today')}>
                              <Trans i18nKey="statistics.today">Today</Trans>
                            </button>
                            <button className={"ant-btn "+this.isActive('week')  + ' responsive-hide'} onClick={() => this.selectDate('week')}>
                              <Trans i18nKey="statistics.week">Week</Trans>
                            </button>
                            <button className={"ant-btn "+this.isActive('month')} onClick={() => this.selectDate('month')}>
                              <Trans i18nKey="statistics.month">Month</Trans>
                            </button>
                          </div>
                          <div className="range-button-container">
                            <button className={"ant-btn "+this.isActive('sixmonths')} onClick={() => this.selectDate('sixmonths')}>
                              <Trans i18nKey="statistics.last6month">Last 6 month</Trans>
                            </button>
                            <button className={"ant-btn "+this.isActive('year') + ' responsive-hide'} onClick={() => this.selectDate('year')}>
                              <Trans i18nKey="statistics.year">Year</Trans>
                            </button>
                            <button className={"ant-btn "+this.isActive('total') + ' responsive-hide'} onClick={() => this.selectDate('total')}>
                              <Trans i18nKey="shareable.total">Total</Trans>
                            </button>
                          </div>
                          <div className="range-picker-container-web">
                            <RangePicker
                              value={rangePickerValue}
                              onChange={this.handleRangePickerChange}
                            />
                            <Button onClick={() => this.filterByDate(false)} size="medium" type="primary">
                              <CloseOutlined />
                            </Button>
                          </div>
                          </React.Fragment>
                        }
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24} >
                  {this.renderOrders()}
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
        { visibleSingleOrder ? <SingleOrder
          branchUuid={branchUuid}
          visible={visibleSingleOrder}
          orderUuid={selectedOrderUuid}
          renderList = { this.getOrders }
          handleClose={ () => this.updateState('visibleSingleOrder', false) } />
          : null }
      </Content>
    )
  }
}


export default withTranslation()(Manager);
