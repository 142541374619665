import React from 'react';
import {
  Row,
  Form,
  Input,
  Col,
  Button,
  Radio,
  Modal,
  Checkbox
} from 'antd';
import { v4 as uuidv4 } from 'uuid';
import arrayMove from 'array-move';
import MovableTableList from './MovableTableList';
import fetchJSON from 'services/utils/fetchJSON';

import {
  PlusCircleOutlined,
 } from '@ant-design/icons';

const addModifier = function addModifier() {
  const { modifierList } = this.state;
  const copy = [...modifierList];

  let maxIndex = 0;
  if (copy && copy.length) {
    const max = Math.max.apply(Math, copy.map((o) => { return o.sort_order; }));
    if (typeof max === 'number') {
      maxIndex = max + 1;
    }
  }

  copy.push({
    key: uuidv4(),
    sort_order: maxIndex,
    required: false,
    display_name: '',
    price_add: '',
    modifier_id: null
  });
  this.saveChanges(copy);
}
const deleteModifier = function deleteModifier(deleteIndex) {
  const { modifierList } = this.state;
  const copy = [...modifierList].filter(el => el.sort_order !== deleteIndex);
  this.saveChanges(copy);
}

const saveChanges = function saveChanges(copyArray) {
  const final = copyArray.filter(el => !!el).map((item, index) => { item.sort_order = index; return item; });
  this.setState({
    modifierList: final
  }, () => {
    this.formRef.current.setFieldsValue({
      modifiers: final
    });
  });
}

const changeModifier = function changeModifier(key, value, index) {
  const { modifierList } = this.state;
  const copy = [...modifierList];
  const findIndex = copy.findIndex((el) => el.sort_order === index);
  if (copy[findIndex] && copy[findIndex].hasOwnProperty(key)) {
    copy[findIndex][key] = value;
    this.setState({
      modifierList: copy
    });
  }
}

const onSortEnd = function onSortEnd({ oldIndex, newIndex }) {
  const { modifierList } = this.state;
  if (oldIndex !== newIndex) {
    const newData = arrayMove([].concat(modifierList), oldIndex, newIndex).filter(el => !!el);
    newData.forEach((item, index) => { item.sort_order = index; });

    this.saveChanges(newData);
  }
}

const getProducts = function getProducts() {
  const bodyObj = {};
  if (this.props.restaurantId) {
    bodyObj.restaurantId = this.props.restaurantId;
  }
  fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/products/list', {
    method: 'put',
    body: bodyObj
  }).then(response => {
    if (this.mounted) {
      if (response.data && response.data.length) {
        this.setState({
          productsTotal: response.data,
        });
      }
    }
  }).catch(error => {
    console.log(error);
  });
}
const productToMidifier = function productToMidifier(product_id) {
  const { productConnected } = this.state;
  const copy = [...productConnected];
  const index = copy.indexOf(product_id);

  if (index > -1) {
    delete copy[index];
  } else {
    copy.push(product_id);
  }

  const final = copy.filter(el => !!el);

  this.setState({
    productConnected: final
  });
}
const checkIfChecked = function checkIfChecked(product_id) {
  const { productConnected } = this.state;
  return productConnected.indexOf(product_id) > -1;
}
const openProductsModal = function openProductsModal() {
  const { productsTotal } = this.state;

  Modal.confirm({
    width: 400,
    title: 'Product list',
    icon: false,
    centered: true,
    content: (
      <div className="UserManualsAddFirstLocation__body">
        <ul style={{listStyle: 'none', padding: 0}}>
          {productsTotal.map((product, i) => <li key={i} style={{padding: '5px 0'}}>
            <Checkbox
              onChange={e => this.productToMidifier(product.id)}
              defaultChecked={this.checkIfChecked(product.id)}
              > { product.display_name } </Checkbox>
          </li>)}
        </ul>
      </div>
    ),
    cancelText: 'Cancel',
    cancelButtonProps: {style: {display: 'none'}},
    okText: 'Close',
    onOk: () => {
      console.log('okay openProductsModal');
    },
    maskClosable: true
  });
}
const renderConnectedProducts = function renderConnectedProducts() {
  const { productsTotal, productConnected } = this.state;

  return productsTotal.map((product, i) => {
    if (productConnected.indexOf(product.id) > -1) {
      return <span className="ant-select-selection-item" key={i}>
        <span className="ant-select-selection-item-content">{product.display_name}</span>
      </span>;
    }
    return null;
  })


}
const ModifierForm = function ModifierForm() {
  const { modifierList, productsTotal, productConnected } = this.state;
  const FormItem = Form.Item;
  const { t, hideProductConnection } = this.props;

  return (<React.Fragment>
    <Row>
      <Col span={24}>
        <FormItem rules={[{ required: true, message: t('shareable.required.name', { defaultValue: 'Display Name is required!' }) }]} name="display_name" label="Display Name">
          <Input placeholder="Display Name"/>
        </FormItem>
      </Col>
    </Row>

    <Row>
      <Col span={24}>
        <Form.Item className="addLocation__xs--fw" name="single_select" label="Modifier option choice for a customer"
          rules={[{
            required: false
          }]}>
          <Radio.Group buttonStyle="solid">
            <Radio.Button value={false}>Multiple, many from the list</Radio.Button>
            <Radio.Button value={true}>Single, one from the list</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Col>
    </Row>

    <Row style={{ marginBottom: '24px' }}>
      <Col span={24}>
        <Row>
          <div className="ant-col ant-form-item-label">
            <label className="ant-form-item-required" title="Modifiers">Modifiers</label>
          </div>
        </Row>

        <MovableTableList
          modifierList={modifierList}
          deleteModifier={this.deleteModifier}
          onSortEnd={this.onSortEnd}
          changeModifier={this.changeModifier}
          footer={() => <Button className="contacts__add-btn" onClick={ this.addModifier }>
             <PlusCircleOutlined /> Add modifier
           </Button>}
        />


      </Col>
    </Row>

    <Row>
      <Col span={24}>
        <Form.Item className="addLocation__xs--fw" name="required" label="Required"
                   rules={[{
                     required: false
                   }]}>
          <Radio.Group buttonStyle="solid">
            <Radio.Button value={false}>No</Radio.Button>
            <Radio.Button value={true}>Yes</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Col>
    </Row>

    { productsTotal && productsTotal.length && !hideProductConnection ? <Row>
      <Col span={24}>
        <Row>
          <div className="ant-col ant-form-item-label">
            <label className="ant-form-item-required" title="Link to Products">Link to Products</label>
          </div>
        </Row>
        <div>
          <div className="ant-select ant-select-multiple" style={{ width: '100%' }} onClick={ this.openProductsModal }>
            <div className="ant-select-selector heightNotLimited">
              { !productConnected.length ?
                <span className="ant-select-selection-placeholder">No products linked yet</span>
              :
                this.renderConnectedProducts()
              }
            </div>
             <span className="ant-select-arrow" unselectable="on" aria-hidden="true" style={{userSelect: 'none'}}>
              <span role="img" aria-label="down" className="anticon anticon-down ant-select-suffix">
                <svg viewBox="64 64 896 896" focusable="false" data-icon="down" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                  <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z" />
                </svg>
              </span>
            </span>
          </div>
        </div>
      </Col>
    </Row> : null }
  </React.Fragment>);
}
export {
  addModifier,
  deleteModifier,
  saveChanges,
  getProducts,
  changeModifier,
  onSortEnd,
  ModifierForm,
  openProductsModal,
  productToMidifier,
  checkIfChecked,
  renderConnectedProducts
};
