import React from 'react';
import fetchJSON from 'services/utils/fetchJSON';
import { withTranslation, Trans } from 'react-i18next';
import {Modal, Form, message, Select, Radio, Button, Input, Spin, Image} from 'antd';
import { withWindowSizeListener } from 'react-window-size-listener';
import { LeftOutlined, MinusOutlined, PlusOutlined, EditOutlined, BuildOutlined } from '@ant-design/icons';

import EditOrderAddons from './EditOrderAddons';
import {surchargesToString} from "../../../../../../../helpers/surchargesToString";
import { cloneDeep } from 'lodash';
import formModifierPriceString from "../../../../../../../helpers/formModifierPriceString";
import surchargesMessageString from '../../../../../../../helpers/surchargesMessageString'
import {correctModifierSurcharges} from "../../../../../../../helpers/correctModifierSurcharges";

const FormItem = Form.Item;
const { Option } = Select;

class AddOrder extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      putREQUEST: false,
      order_type: 'dine_in',
      orderUuid: null,
      selectedCategory: null,
      isDeliveredDialog: false,
      cartList: {},
      fetchingProducts: false,
      menuId: null,
      visibleEditOrderAddons: false,
      firstTimeEditOrderAddons: false,
      selectedItem: {},
      its_resumed_order: false,
      surcharges: 1,
      order_surcharges: 1
    };
  }
  componentDidMount = () => {
    this.mounted = true;
    const { orders_type, branch } = this.props;
    const { menu } = branch;

    this.setState({
      order_type: orders_type,
      menuId: menu.menu_id
    });
  }
  componentWillUnmount = () => {
    this.mounted = false;
  }
  handleSubmit = () => {
    const { t, branchUuid } = this.props;
    return this.formRef.current.validateFields()
        .then((values) => {

          this.setState({
            putREQUEST: true
          });
          fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches-orders/create/${branchUuid}`, {
            method: 'put',
            body: values
          }).then(response => {
            console.log(response);
            /// its_resumed_order
            if (response.code === 200) {
              const { its_resumed_order, order_uuid, order_surcharges } = response.data;
              this.setState({
                // putREQUEST: false,
                putSUCCESS: false,
                putFAILURE: false,
                orderUuid: order_uuid,
                its_resumed_order,
                order_surcharges,
                surcharges: order_surcharges
              });
            } else {
              this.setState({
                putREQUEST: false,
                putFAILURE: true,
                putSUCCESS: false,
                //errorMessage: response.message
              });
              message.error(response.message);
            }
          }).catch(error => {
            console.log(error);
            this.setState({
              putSUCCESS: false,
              putREQUEST: false,
              putFAILURE: true,
            });
            message.error(t('shareable.ERR', { defaultValue: 'ERR' }));
          });
        }).catch((err) => console.log('validate err', err))
  }
  validatePhone = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || !value.phone) {
        return Promise.reject('Phone is required!')
      } else {
        if (value.phone.length < 7) {
          return Promise.reject(<p><Trans i18nKey="shareable.reqPhone.minLength">Phone should be 8 symbols and longer</Trans></p>);
        }
        const isnum = /^\d+$/.test(value.phone);
        if (!isnum) {
          return Promise.reject(<p><Trans i18nKey="shareable.reqPhone.onlyNumber">Please, enter correct phone</Trans></p>);
        }
      }
      return Promise.resolve();
    },
    validateTrigger: 'onSubmit'
  })
  orderTypeChanged = (e) => {
    this.setState({
      order_type: e.target.value
    });
  }
  selectCategory = (category_id) => {
    this.setState({
      selectedCategory: category_id
    });
  }
  resetCategory = () => {
    this.setState({
      selectedCategory: null
    });
  }
  setIsFetching = (param) => {
    this.setState({
      fetchingProducts: param
    });
  }
  addToOrder = (activeItem) => {
    const addToCart = {
      item: {...activeItem},
      quantity: 1,
      order_item_id: null,
      comments: '',
      modifiers: [],
      modifiersRaw: []
    };
    const { branchUuid } = this.props;
    const { orderUuid, menuId } = this.state;
    this.setIsFetching(true);
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches-orders/addItem/', {
      method: 'POST',
      body: {
        branchUuid,
        orderUuid,
        menuId,
        productId: addToCart.item.id,
        quantity: 1,
      }
    }).then(response => {
      console.log(response);
      if (response.code === 200) {
        const { cartList } = this.state;
        const newCartList = {...cartList};
        addToCart.order_item_id = response.data.id.toString();
        newCartList[addToCart.order_item_id] = addToCart;
        this.setState({
          cartList: newCartList
        });
        if (addToCart.item.modifierSets.some(set => set.required)) {
          this.editOrderAddons(addToCart);
          this.setState({
            firstTimeEditOrderAddons: true
          });
        }
      }
      this.setIsFetching(false);
    }).catch(error => {
      console.log(error);
      this.setIsFetching(false);
    });
  }
  changeProductCardQuantity = (item, operation) => {
    const {cartList} = this.state;
    const item_id = item.order_item_id;
    let changedItem =  Object.values(cartList).find(listItem =>
    {return listItem.item.id.toString() === item.id.toString() && listItem.modifiersRaw.length === 0})
    if (changedItem) {
      let actualQ = this.getQuantityInOrder(changedItem);
      if (operation === 'add') {
        actualQ += 1;
      }
      if (operation === 'remove') {
        actualQ -= 1;
      }

      if (actualQ > 0) {
        this.editItem(changedItem, actualQ);
      } else {
        this.removeItem(changedItem);
      }
    }
    else {
      changedItem = Object.values(cartList).find(listItem =>
      {return listItem.item.id.toString() === item.id.toString()})

      if (operation === 'add') {
        this.addToOrder(item);
        return
      }
    else {
        let actualQ = this.getQuantityInOrder(changedItem);
         actualQ -= 1;

        if (actualQ > 0) {
          this.editItem(changedItem, actualQ);
        } else {
          this.removeItem(changedItem);
        }
      }
    }
  }
  changeCheckoutQuantity = (item, operation) => {
    let actualQ = this.getQuantityInOrder(item);
    if (operation === 'add') {
      actualQ += 1;
    }
    if (operation === 'remove') {
      actualQ -= 1;
    }

    if (actualQ > 0) {
      this.editItem(item, actualQ);
    } else {
      this.removeItem(item);
    }
  }

  editItem = (item, quantity) => {
    const { cartList } = this.state;
    const newCartList = {...cartList};
    const item_id = item.order_item_id.toString();
    const { branchUuid } = this.props;
    const { orderUuid } = this.state;

    this.setIsFetching(true);
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches-orders/editItem/', {
      method: 'POST',
      body: {
        branchUuid,
        orderUuid,
        order_item_id: item_id,
        quantity,
        comments: newCartList[item_id].comments,
        modifiers: newCartList[item_id].modifiers
      }
    }).then(response => {
      console.log(response);
      this.setIsFetching(false);
      if (response.code === 200) {
        newCartList[item_id].quantity = quantity;
        this.setState({
          cartList: newCartList
        });
      }
    }).catch(error => {
      this.setIsFetching(false);
      console.log(error);
    });
  }
  removeItem = (item) => {
    const { cartList } = this.state;
    const newCartList = {...cartList};
    const item_id = item.order_item_id;
    const { branchUuid } = this.props;
    const { orderUuid } = this.state;

    this.setIsFetching(true);
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches-orders/removeItem/', {
      method: 'POST',
      body: {
        branchUuid,
        orderUuid,
        order_item_id: item_id,
      }
    }).then(response => {
      console.log(response);
      this.setIsFetching(false);
      if (response.code === 200) {
        const newCopy = {};
        Object.keys(newCartList).forEach((key, i) => {
          if (key !== item_id) {
            newCopy[key] = newCartList[key];
          }
        });
        this.setState({
          cartList: newCopy
        });
      }
    }).catch(error => {
      console.log(error);
      this.setIsFetching(false);
    });
  }
  getQuantityInOrder = (item) => {
    const { cartList } = this.state;
    const item_id = item.order_item_id ? item.order_item_id.toString(): null;
    if (Object.keys(cartList).indexOf(item_id) === -1) {
      return 0;
    }
    return cartList[item_id].quantity;
  }
  cartEmpty = () => {
    const { cartList } = this.state;
    let empty = 1;
    if (cartList && typeof cartList === 'object' && Object.keys(cartList).length) {
      empty = 0;
    }
    return empty;
  }
  getQuantityAll = (item) => {
    const { cartList } = this.state;
    let quantity = 0;
    Object.values(cartList).forEach(line => {
      if (line.item.id === item.id) quantity+=line.quantity
    })
    return quantity;
  }

  summModifiersPrice = (allModifierSets, activeModifiers) => {
    const { order_surcharges } = this.state;
    let totalMdsf = 0;

    if (allModifierSets && allModifierSets.length && activeModifiers && activeModifiers.length) {
      allModifierSets.forEach((modifierSet) => {
        modifierSet.modifiers.forEach((modifier) => {
          if (activeModifiers.indexOf(modifier.id) > -1 && modifier.price_add) {
            totalMdsf += correctModifierSurcharges(modifier.price_add, order_surcharges);
          }
        });
      })
    }
    return totalMdsf;
  }
  countTotal = (params = false) => {
    const { cartList, order_surcharges } = this.state;
    let total = 0;
    if (cartList && typeof cartList === 'object' && Object.keys(cartList).length) {
      Object.keys(cartList).forEach((key) => {
        const line = cartList[key];
        const { modifierSets } = line.item;
        const totalModifiers = this.summModifiersPrice(modifierSets, line.modifiers);

        if (!params) {
          total += correctModifierSurcharges(line.quantity * parseFloat(line.item.price), order_surcharges)  + totalModifiers * line.quantity;
        }

        if (params === 'noTaxes') {
          const gross = correctModifierSurcharges(line.quantity * parseFloat(line.item.price), order_surcharges)  + totalModifiers * line.quantity;
          let net = gross;
          if (line.item.vat) {
            net = gross - (gross * line.item.vat / 100);
          }
          total += net;
        }
        if (params === 'onlyTaxes') {
          const gross = correctModifierSurcharges(line.quantity * parseFloat(line.item.price), order_surcharges)  + totalModifiers * line.quantity;
          let taxes = 0;
          if (line.item.vat) {
            taxes = (gross * line.item.vat / 100);
          }
          total += taxes;
        }
      });
    }

    return `$${total.toFixed(2)}`;
  }
 toggleIsDeliveredDialog = () => {
    this.setState({
      isDeliveredDialog: !this.state.isDeliveredDialog
    })
  }
  checkDelivered = (e) => {
    const isDelivered = e.target.value;
    this.checkoutFinal(true, isDelivered);
  }
  checkoutFinal = (payNow = false, deliverNow = false) => {
    this.setIsFetching(true);
    const { branchUuid } = this.props;
    const { orderUuid, order_type } = this.state;
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches-orders/submit/', {
      method: 'POST',
      body: {
        branchUuid,
        orderUuid,
        key: 'is_submitted',
        value: true
      }
    }).then(response => {
      console.log(response);
      this.setIsFetching(false);
      if (response.code === 200) {
        this.props.renderList(() => this.props.handleClose(orderUuid, order_type, payNow, deliverNow));
      }
    }).catch(error => {
      console.log(error);
      this.setIsFetching(false);
    });
  }
  editOrderAddons = (line) => {
    const { cartList } = this.state;
    const item_id = line.order_item_id.toString();

    this.setState({
      selectedItem: {...cartList[item_id]}
    }, () => {
      this.updateState('visibleEditOrderAddons', true)
    });
  }
  renderAddons = (item) => {
    const { cartList } = this.state;
    const item_id = item.order_item_id ? item.order_item_id.toString() : null;
    if (item_id && cartList[item_id]) {
      const { comments, modifiers, item } = cartList[item_id];
      const render = [];
      if (comments) {
        render.push(<div key="note">Note: {comments}</div>);
      }
      const { modifierSets } = item;

      let modifiersRender = [];
      if (modifierSets && modifierSets.length && modifiers && modifiers.length) {
        modifierSets.forEach((modifierSet) => {
          modifierSet.modifiers.forEach((modifier) => {
            if (modifiers.indexOf(modifier.id) > -1) {
              modifiersRender.push(modifier.display_name);
            }
          });
        })
      }

      if (render && render.length) {
        return <div className="cartScreenItem__comments">{render}</div>
      }
    }

    return null;
  }
  submitOrderAddons = (itemId, values) => {
    const { cartList } = this.state;
    const { comments, modifiers, modifiersRaw } = values;

    const item_id = itemId.toString();
    const newCartList = {...cartList};

    newCartList[item_id].comments = comments;
    newCartList[item_id].modifiers = modifiers;
    newCartList[item_id].modifiersRaw = modifiersRaw;

    this.setState({
      cartList: {...newCartList}
    }, () => {
      const { branchUuid } = this.props;
      const { orderUuid } = this.state;
      this.setIsFetching(true);
      fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches-orders/editItem/', {
        method: 'POST',
        body: {
          branchUuid,
          orderUuid,
          order_item_id: newCartList[item_id].order_item_id,
          quantity: newCartList[item_id].quantity,
          comments: newCartList[item_id].comments,
          modifiers: newCartList[item_id].modifiers
        }
      }).then(response => {
        console.log(response);
        this.setIsFetching(false);
      }).catch(error => {
        this.setIsFetching(false);
        console.log(error);
      });
    });
  }
  updateState = (key, value) => {
    this.setState({
      [key]: value
    });
  }

  checkAlcohol = (categories, order_type, is_alcohol_sales_for_takeaway) => {
    return categories.map(category => {
      if (order_type === 'takeout' && is_alcohol_sales_for_takeaway === false) {
        category.items = category.items.filter(item => item.is_alcohol === false)
      }
      return category
    })
  }


  checkIsSubmitDisabled = () => {
    const {cartList} = this.state;
    return Object.values(cartList).some(cartListItem => {
      const {item, modifiers: chosenModifiers} = cartListItem;
      const {modifierSets} = item;

      return modifierSets.some(set => {  // if some modifierSets are required...
        if (set.required) {
          const {modifiers} = set;

          return modifiers.every(modifier => {
            return chosenModifiers.indexOf(modifier.id) === -1 // ... and no checkboxes are checked, disable submit
          })
        }
        return false;
      })
    })
  }

  includeSurchargesIntoItemPrice = (price, surcharges) => {
    return (parseFloat(price) * surcharges).toFixed(2)
  }

  formCategoryButton = (categoryName) => {
    if (categoryName.length > 40) {
      const wordsArray = categoryName.split(" ");
      let letterCount =  0;
      let breakIndex = 0;
      for (let i = 0; i <wordsArray.length; i++) {
        letterCount+=wordsArray[i].length;
        if (letterCount >= Math.floor(categoryName.length / 2)) {
          breakIndex = i;
          break;
        }
      };
      const firstLine = wordsArray.filter((_,i) => i < breakIndex).join(" ");
      const secondLine = wordsArray.filter((_,i) => i >= breakIndex).join(" ");

      return <Button>{firstLine}
        <br/>
        {secondLine}
      </Button>
    }
    else return <Button>{categoryName}</Button>
  }


  render() {
    const { branch, orders_type, branchUuid } = this.props;
    const { tables, menu } = branch;

    const {
      putREQUEST,
      order_type,
      orderUuid,
      selectedCategory,
      fetchingProducts,
      isDeliveredDialog,
      cartList,
      visibleEditOrderAddons,
      firstTimeEditOrderAddons,
      selectedItem,
      its_resumed_order,
      order_surcharges
    } = this.state;

    const categories = this.checkAlcohol(cloneDeep(menu?.categories || []), order_type, branch?.branch?.is_alcohol_sales_for_takeaway)

    const { windowWidth, windowHeight } = this.props.windowSize;
    const containerStyle = {
      maxWidth: (windowWidth-60),
    }
    const containerStyleMobile = {
      width: "100%", //windowWidth,
      height: windowHeight,
      minHeight: windowHeight
    };
    const cartScreenCss = {
      marginRight: windowWidth > 768 ? "60px" : 0
    };

    const title = `New Order ${surchargesMessageString(order_surcharges)}`
    return (
        <Modal
            className="general-modal-btns-center general-card createModalOrder"
            title={title}
            centered={true}
            visible={this.props.visible}
            footer={null}
            onCancel={this.props.handleClose}
            destroyOnClose={true}
            confirmLoading={putREQUEST}
            width={(orderUuid && !isDeliveredDialog) ? "100%" : "520px"}
            height={(orderUuid && !isDeliveredDialog) ? "100vh" : "auto"}
        >
          { !orderUuid ? <Form
              requiredMark={false}
              className="general-form"
              layout="vertical"
              ref={this.formRef}
              onFinish={this.handleSubmit}
              initialValues={{
                orderType: orders_type,
                branchUuid,
                menuId: menu.menu_id
              }}
          >
            <FormItem rules={[{
              required: true,
            }]} name="branchUuid" label="branchUuid" style={{display: 'none'}}>
              <Input placeholder="branchUuid"/>
            </FormItem>
            <FormItem rules={[{
              required: true,
            }]} name="menuId" label="menuId" style={{display: 'none'}}>
              <Input placeholder="menuId"/>
            </FormItem>
            <FormItem rules={[{
              required: true,
              message: 'Table number is required!'
            }]} name="orderType" label="Order type">
              <Radio.Group
                  className="orderTypeRadio"
                  size="large"
                  buttonStyle="solid"
                  onChange={this.orderTypeChanged}
              >
                <Radio.Button value="dine_in">Dine In</Radio.Button>
                <Radio.Button value="takeout">Takeaway</Radio.Button>
              </Radio.Group>
            </FormItem>
            <FormItem rules={[{
              required: order_type === 'takeout',
              message: 'A customer name is required!'
            }]} name="customer_name" type="text" label="Customer name" style={{display: order_type !== 'takeout' ? 'none' : ''}}>
              <Input placeholder="Customer name"/>
            </FormItem>
            <FormItem rules={[{
              required: order_type === 'dine_in',
              message: 'Table number is required!'
            }]} name="tableId" style={{
              display: order_type !== 'dine_in' ? 'none' : ''
            }} label="Table number">
              <Select placeholder="Table number" size='large'>
                {tables.map((item, i) => (
                    <Option key={i} value={item.id}>{item.display_name}</Option>
                ))}
              </Select>
            </FormItem>
            <Button className="createModalOrder__submit" type="primary" size="large" htmlType="submit" style={{ width: '100%' }}>Submit</Button>
          </Form> : null }

          {(orderUuid && !isDeliveredDialog) ?
              <React.Fragment>
                <Spin spinning={fetchingProducts}>
                  <React.Fragment>
                      <div className="cartScreen" style={cartScreenCss}>
                        <div className="cartScreenBody">
                          { this.cartEmpty() ?
                              <div className="cartScreenBody__empty">Cart is empty</div>
                              :
                              <div style={{ width: '100%'}}>
                                <div className="cartScreenList">
                                  {Object.keys(cartList).map((key, index) => {
                                    const line = cartList[key];
                                    const { modifiersRaw } = line;

                                    return (<React.Fragment key={index}><div className="cartScreenItem" >
                                      <div className="cartScreenItem__middle">
                                        <div className="cartScreenItem__title">{line.item.displayName}</div>
                                        { this.renderAddons(line.item) }
                                      </div>
                                      <div className="cartScreenItem__itemMiddle">
                                        <div className="cartScreenItem__quantity">
                                          <button
                                              className="cartScreenItem__quantityBtn cartScreenItem__quantityBtn--remove"
                                              onClick={() => this.changeCheckoutQuantity(line, 'remove')}
                                              alt="reduce product quantity"
                                              disabled={!this.getQuantityInOrder(line)}
                                          ><MinusOutlined /></button>
                                          <div className="cartScreenItem__quantityNumber">{this.getQuantityInOrder(line)}</div>
                                          <button
                                              className="cartScreenItem__quantityBtn cartScreenItem__quantityBtn--add"
                                              onClick={() => this.changeCheckoutQuantity(line, 'add')}
                                              alt="increase product quantity"
                                          ><PlusOutlined /></button>
                                          {this.getQuantityInOrder(line) ? <button
                                              className="cartScreenItem__quantityBtn"
                                              style={{ marginLeft: '15px' }}
                                              onClick={() => this.editOrderAddons(line)}
                                              alt="edit product comment"
                                          >{line.item.modifierSets.length === 0 ? <EditOutlined /> : <BuildOutlined style={{color: "#E01491"}}/>}</button> : null }
                                        </div>
                                      </div>
                                      <div className="cartScreenItem__price">
                                        ${(line.quantity * line.item.price * order_surcharges).toFixed(2)}
                                      </div>
                                    </div>
                                      {Array.isArray(modifiersRaw) && modifiersRaw.length ?
                                          modifiersRaw.map((modifier) => <div className="cartScreenItem" key={modifier.id} style={{ paddingLeft: '35px' }}>
                                            <div className="cartScreenItem__middle">
                                              <div className="cartScreenItem__title">{modifier.display_name}</div>
                                            </div>
                                            <div className="cartScreenItem__itemMiddle"></div>
                                            <div className="cartScreenItem__price">
                                              {modifier.price_add ? `${formModifierPriceString(correctModifierSurcharges(modifier.price_add, order_surcharges))}` : ''}
                                            </div>
                                          </div>)
                                          : null}
                                    </React.Fragment>)
                                  }) }
                                </div>
                              </div>
                          }
                        </div>

                        <div className="cartScreenFooter">
                          <div className="cartScreenTotal">
                            <div className="cartScreenTotal__item">
                              GST
                              <span className="cartScreenTotal__price">{this.countTotal('onlyTaxes')}</span>
                            </div>
                            { order_surcharges != 1 ?
                                <div className="cartScreenTotal__item">
                                  {order_surcharges > 1 ? `Surcharges` : `Discount` }
                                  <span className="cartScreenTotal__price">{surchargesToString(order_surcharges)}</span>
                                </div>
                                : null }
                            <div className="cartScreenTotal__item">
                              Total Amount Payable
                              <span className="cartScreenTotal__price cartScreenTotal__price--red">{this.countTotal()}</span>
                            </div>
                          </div>
                          <div className="cartScreenFooter__box cartScreenFooter__box--columns">
                            {
                              its_resumed_order ?
                                  <button className="cartScreenFooter__checkout" onClick={this.checkoutFinal} disabled={this.cartEmpty() || this.checkIsSubmitDisabled()}>Submit order</button>
                                  :
                                  <React.Fragment>
                                    <button className="cartScreenFooter__checkout cartScreenFooter__checkout--reverse" onClick={this.checkoutFinal} disabled={this.cartEmpty() || this.checkIsSubmitDisabled()}>Submit - pay later</button>
                                    <button className="cartScreenFooter__checkout cartScreenFooter__checkout--reverse" onClick={() => this.toggleIsDeliveredDialog()} disabled={this.cartEmpty() || this.checkIsSubmitDisabled()}>Submit - pay now</button>
                                  </React.Fragment>
                            }
                          </div>
                        </div>
                      </div>
                  </React.Fragment>
                  <div style={{width: "100%"}}>
                      <React.Fragment>
                        { categories && Array.isArray(categories) && categories.length ?
                            <ul className="categoryList">
                              {categories.filter(category => category?.items?.length > 0).map((category, index) => (
                                <li className={"categoryList__item"+(category.id === selectedCategory ? "__selected" : "")} key={index} onClick={() => this.selectCategory(category.id)}>
                                  {this.formCategoryButton(category.displayName)}
                                </li>
                              ))}
                            </ul>
                            : null }
                      </React.Fragment>
                  { selectedCategory ?
                      <React.Fragment>
                        <div className="menuList__wrapper">
                          {
                            categories.filter(category => category.id === selectedCategory).map((category, index) => (
                                <div id={`section_category_${category.id}`} data-category-id={category.id} key={index}>
                                  <div id={`menu_category_${category.id}`} data-category-id={category.id} className="menuList__catTitle">
                                    {category.displayName}
                                  </div>
                                  <div className="menuList__listWrapper">
                                  <div className="menuList__list">
                                    {category.items.map((item, index) => (
                                        <div key={index} className="menuItem">
                                          <div className="menuItem__columnTwo">
                                            {item.thumbnail ? <img src={item.thumbnail} className="menuItem__image"></img> : <div className="menuItem__image"><p>No image</p></div>}
                                            <div title={item.displayName} className={item.modifierSets.some(set => set.required) ? "menuItem__title__withRequiredModifiers" : "menuItem__title"}>{item.displayName}</div>
                                            <div className="menuItem__price">${this.includeSurchargesIntoItemPrice(item.price, order_surcharges)}</div>
                                          </div>
                                          <div>
                                            <div className="cartScreenItem__quantity">
                                              <button
                                                  className="cartScreenItem__quantityBtn cartScreenItem__quantityBtn--remove"
                                                  onClick={() => this.changeProductCardQuantity(item, 'remove')}
                                                  alt="reduce product quantity"
                                                  disabled={!this.getQuantityAll(item)}
                                              ><MinusOutlined /></button>
                                              <div className="cartScreenItem__quantityNumber">{this.getQuantityAll(item)}</div>
                                              <button
                                                  className="cartScreenItem__quantityBtn cartScreenItem__quantityBtn--add"
                                                  onClick={() => this.changeProductCardQuantity(item, 'add')}
                                                  alt="increase product quantity"
                                              ><PlusOutlined /></button>
                                            </div>
                                          </div>

                                        </div>
                                    ))}
                                  </div>
                                  </div>
                                </div>
                            ))
                          }
                        </div>
                      </React.Fragment>
                      : null }
                  </div>
                </Spin>
              </React.Fragment>
              : null }
          {(orderUuid && isDeliveredDialog) &&
          <React.Fragment>
            <div className="deliveredDialog">
            <h2 className="deliveredDialogTitle">Is order delivered?</h2>
            <Form
                className="general-form"
                layout="vertical"
                ref={this.formRef}>
            <FormItem name="orderType">
              <Radio.Group
                  className="orderTypeRadio"
                  size="large"
                  buttonStyle="solid"
                  onChange={this.checkDelivered}
              >
                <Radio.Button value={true}>Order delivered</Radio.Button>
                <Radio.Button value={false}>Not delivered</Radio.Button>
              </Radio.Group>
            </FormItem>
            </Form>
            </div>
          </React.Fragment>}

          { visibleEditOrderAddons ? <EditOrderAddons
                  branchUuid={branchUuid}
                  visible={visibleEditOrderAddons}
                  selectedItem={selectedItem}
                  surcharges={order_surcharges}
                  submitOrderAddons = { this.submitOrderAddons }
                  handleClose={ (shouldDeleteItem) => {
                    if (firstTimeEditOrderAddons && shouldDeleteItem) {
                      this.removeItem(selectedItem);
                    };
                    this.setState({
                      firstTimeEditOrderAddons: false,
                      visibleEditOrderAddons: false,
                    });
                  } } />
              : null
          }

        </Modal>
    )
  }
};

export default withTranslation()(withWindowSizeListener(AddOrder));
