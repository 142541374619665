import React from 'react';
import { Layout, Tabs, Col, Select, Row } from 'antd';
import { withTranslation } from 'react-i18next';
import { withWindowSizeListener } from 'react-window-size-listener';
import qs from 'qs';
import _ from 'lodash';
import * as _sharedModules from '../modules/_modules';
import store from 'store';

import ProductsTable from './components/ProductsTable/ProductsTable';
import ProductsCategories from './components/ProductsCategories/ProductsCategories';
import Menus from './components/Menus/Menus';
import ModifierSets from './components/ModifierSets/ModifierSets';

import './styles.less';

const { Content } = Layout;
const { TabPane } = Tabs;

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldAddProduct: false,
      targetRestaurantId: null,
      restaurantChoiceDisabled: false,
      branchesListLoaded: false,
      branchesList: []
    };
    Object.keys(_sharedModules).forEach((_moduleName) => {
      this[_moduleName] = _sharedModules[_moduleName].bind(this);
    });
    this.userRole = this.props.role;
  }
  componentDidMount() {  
    this.mounted = true;
    let paramFromUrl = {};
    let activeTab = 'products'; 
    if (window.location.search) {
      paramFromUrl = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      const tabList = ['categories', 'menues', 'modifiers'];
      if (Object.keys(paramFromUrl).length) {
        Object.keys(paramFromUrl).forEach((param) => {
          if (paramFromUrl[param] && tabList.indexOf(param) > -1) {
            activeTab = param; 
          }
          if (param === 'addProduct' && paramFromUrl[param]) {
            this.setState({ shouldAddProduct: true })
            let newUrl = _.get(this.props, 'history.location.pathname');
            if (newUrl) {
              const paramList = [];
              Object.keys(paramFromUrl).forEach((param) => {
                if (param !== 'addProduct') {
                  paramList.push(`${param}=1`);
                }
              })
              const search = '?' + paramList.join('&');
              this.props.history.push(newUrl + search);
            }
          }
        });
      }
    }
    if (this.userRole === 'admin') {
      this.getRestaurantsListSimple();
    } else {
      const targetRestaurantId = this.props.related_restaurant_id;
      this.setState({
        targetRestaurantId,
        targetRestaurantUuid: this.props.related_restaurant_uuid
      }, this.getBranchesList)
    }
    this.setState({ 
      activeTab 
    })

    this.restoreScreen();
  }
  restoreScreen = () => {
    const targetRestaurantId = store.get('products_targetRestaurantId');
    const targetRestaurantUuid = store.get('products_targetRestaurantUuid');

    const state = {};

    if (targetRestaurantId) state.targetRestaurantId = targetRestaurantId;
    if (targetRestaurantUuid) state.targetRestaurantUuid = targetRestaurantUuid;

    this.setState(state, () => {
      if (targetRestaurantId) this.getBranchesList(false);
    });
  }
  componentWillUnmount() {
    this.mounted = false;
    store.remove('products_targetRestaurantId')
    store.remove('products_targetRestaurantUuid')
  }
  onRestaurantSelectChange = (targetRestaurantId) => {
    const { restaurantsList } = this.state;
    if (restaurantsList && restaurantsList.length) {
      restaurantsList.forEach((restaurant) => {
        if (restaurant.id === targetRestaurantId) {
          store.set('products_targetRestaurantId', targetRestaurantId)
          store.set('products_targetRestaurantUuid', restaurant.uuid)

          this.setState({
            targetRestaurantId,
            targetRestaurantUuid: restaurant.uuid
          }, this.getBranchesList)
        }
      })
    }
  }
  onTabChange = (key) => {
    if (key !== 'products') {
      this.props.history.push(`/products/?${key}=1`);
    } else {
      this.props.history.push(`/products`);
    }
    this.setState({
      activeTab: key,
      restaurantChoiceDisabled: false
    });
  }
  turnOffShouldAddProduct = () => {
    this.setState({
      shouldAddProduct: false
    })
  }
  render() {
    const { t } = this.props;
    const { activeTab, shouldAddProduct, targetRestaurantId, branchesList, targetRestaurantUuid, restaurantChoiceDisabled, branchesListLoaded } = this.state;
    const tabsPosition = this.props.windowSize.windowWidth < 800 ? 'top' : 'left';
    const type = this.props.windowSize.windowWidth < 800 ? 'card' : 'line';
    const restautantSelect =             
      <Col sm={4} xs={12}>
        <Select disabled={restaurantChoiceDisabled} value={targetRestaurantId} style={{width: '100%'}} placeholder="Restaurant" onChange={(value) => this.onRestaurantSelectChange(value)}>
          {this.renderRestaurantsOptions()}
        </Select>
      </Col>;

    return ( 
      <Content className="bg-transparent table-content" style={{paddingLeft: '0px'}}>
        <Tabs defaultActiveKey="products" activeKey={activeTab} tabPosition={tabsPosition} type={type} 
          onChange={(key) => {
            this.onTabChange(key)         
          }}>
          <TabPane forceRender={true} tab={t('shareable.productsList', { defaultValue: 'Products list' })} key="products">
            { activeTab === 'products' ? 
              <div>
                { this.userRole === 'admin' && 
                  <div className="header-margin-same-as-table" style={{marginTop: '12px'}}>
                    <Row style={{marginBottom: '4px', fontSize: '15px'}}>
                      <label>Restaurant:</label>
                    </Row>
                    <Row>
                      {restautantSelect}
                    </Row>
                  </div> }
                { targetRestaurantId ? 
                <ProductsTable
                   history={this.props.history}
                   role={this.props.role}
                   restaurantId={targetRestaurantId}
                   shouldAddProduct={shouldAddProduct}
                   turnOffShouldAddProduct={this.turnOffShouldAddProduct}
                  /> : null }
                </div> : null }
          </TabPane>
          <TabPane tab={t('shareable.modifiers', { defaultValue: 'Modifiers' })} key="modifiers">
            { activeTab === 'modifiers' ? 
              <div>
                { this.userRole === 'admin' && 
                  <div style={{marginTop: '12px'}}>
                    <Row style={{marginBottom: '4px', fontSize: '15px'}}>
                      <label>Restaurant:</label>
                    </Row>
                    <Row>
                      {restautantSelect}
                    </Row>
                  </div> }
                { targetRestaurantId ? 
                <ModifierSets 
                  history={this.props.history}
                  role={this.props.role}
                  restaurantId={targetRestaurantId}
                /> : null }
              </div> : null }
          </TabPane>
          
          <TabPane tab={t('shareable.categories', { defaultValue: 'Categories' })} key="categories">
            { activeTab === 'categories' ? 
              <div>
                { this.userRole === 'admin' && 
                  <div style={{marginTop: '12px'}}>
                    <Row style={{marginBottom: '4px', fontSize: '15px'}}>
                      <label>Restaurant:</label>
                    </Row>
                    <Row>
                      {restautantSelect}
                    </Row>
                  </div> }
                { targetRestaurantId ? 
                <ProductsCategories 
                  history={this.props.history}
                  role={this.props.role}
                  restaurantId={targetRestaurantId}
                /> : null }
              </div> : null }
          </TabPane>

          <TabPane tab={t('shareable.menus', { defaultValue: 'Menus' })} key="menues">
            { activeTab === 'menues' ? 
              <div style={{padding: type === 'card' ? '0px 12px' : ''}}>
                { this.userRole === 'admin' && 
                  <div style={{marginTop: '12px'}}>
                    <Row style={{marginBottom: '4px', fontSize: '15px'}}>
                      <label>Restaurant:</label>
                    </Row>
                    <Row>
                      {restautantSelect}
                    </Row>
                  </div> }
                { targetRestaurantId && branchesListLoaded ? 
                <Menus 
                  history={this.props.history}
                  role={this.props.role}
                  restaurantId={targetRestaurantId}
                  restaurantUuid={targetRestaurantUuid}
                  branchesList={branchesList}
                  setRestaurantChoiceDisabled={(value) => this.setState({ restaurantChoiceDisabled: value })}
                /> : null }
              </div> : null }
          </TabPane>
        </Tabs>
      </Content>
    )
  }
};

export default withTranslation()(withWindowSizeListener(Products));