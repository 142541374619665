const Settings = {
	// branding name for our app
	title: 'Dezy dashboard',
	
	// Web analytics settings
  analytics: {
    google: process.env.REACT_APP_GA,
		heap: process.env.REACT_APP_HEAP,
		gtm: process.env.REACT_APP_GTM
  },
	
	endpoindApi: '',
	
	// endpoint for socket
	api: {
		socketUrl: '/'
	}
};

export default Settings;
