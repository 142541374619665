import React from 'react';
import fetchJSON from 'services/utils/fetchJSON';
import { message, Select, notification } from 'antd';

const getListPrintersModels = function getListPrintersModels() {
  const { t } = this.props;
	fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/printers/getModelsList`, {
    method: 'get',
  }).then(response => {
    console.log(response);
    if (response.success) {
      this.setState({ 
        isFetching: false,
        printersModelsList: response.data
      }, () => {
        const keys = Object.keys(response.data);
        if (keys.length === 1 && this.formRef && this.formRef.current) {
          this.formRef.current.setFieldsValue({
            printerType: keys[0]
          });
          this.sortPrintersModelsList(keys[0]);
        }
      })
    } else {
      this.setState({
        isFetching: false,
        printersModelsList: {}
      });
      message.error(t('shareable.error', { defaultValue: 'Error' }));
    }
   }).catch(error => {
      console.log(error);
      this.setState({
        isFetching: false,
        printersModelsList: {}
      });
      message.error(t('shareable.error', { defaultValue: 'Error' }));
   });
}

const createNewPrinter = function createNewPrinter(values) {
  const { t } = this.props;
  this.setState({
    isFetching: true
  });
  console.log('createNewPrinter', values);

  fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/printers/create', {
    method: 'post',
    body: values
  }).then(response => {
    console.log(response);
    if (response.success) {
      // this.setState({ 
      //   isFetching: false,
      //   putSUCCESS: true,
      //   putFAILURE: false
      // });
      window.location.href = `${this.props.history.location.pathname}?printing=1`;
    } else {
      this.setState({
        isFetching: false,
        putFAILURE: true,
        putSUCCESS: false,
      });
      notification.error({
        message: response.message
      });
    }
   }).catch(error => {
      console.log(error);
      this.setState({
        putSUCCESS: false,
        isFetching: false,
        putFAILURE: true,
      });
      message.error(t('shareable.error', { defaultValue: 'Error' }));
   });
}

const Option = Select.Option;
const renderPrintersTypeList = function renderPrintersTypeList() {
  const { printersModelsList } = this.state;
  let options = [];
  const keys = Object.keys(printersModelsList);
  if (keys && Array.isArray(keys) && keys.length) {
    keys.forEach((type, i) => {
      options.push(<Option key={i} value={type}>{type}</Option>)
    });
  }
  return options;
}
const renderPrintersModels = function renderPrintersModels() {
  const { printersSelectedTypeList } = this.state;
  let options = [];
  if (printersSelectedTypeList && Array.isArray(printersSelectedTypeList) && printersSelectedTypeList.length) {
    printersSelectedTypeList.forEach(type => {
      options.push(<Option key={type.id} value={type.id}>{type.model}</Option>)
    })
  }
  return options;
}

const sortPrintersModelsList = function sortPrintersModelsList(printerType) {
  const { printersModelsList } = this.state;

  this.formRef.current.resetFields(['printerModel']);

  let printersSelectedTypeList = [];

  if (printersModelsList.hasOwnProperty(printerType)) {
    printersSelectedTypeList = printersModelsList[printerType];
  }
 
  if (printersSelectedTypeList.length === 1) {
    this.formRef.current.setFieldsValue({
      printerModel: printersSelectedTypeList[0].id
    })
  }

  this.setState({
    printersSelectedTypeList,
    noPrinterType: false
  })
}


export {
  getListPrintersModels,
  renderPrintersTypeList,
  renderPrintersModels,
  sortPrintersModelsList,
  createNewPrinter
};