import React from 'react';
import fetchJSON from 'services/utils/fetchJSON';
import { withTranslation } from 'react-i18next';
import { Row, Button, Modal, Spin, Table, Select, Switch, message } from 'antd';
import { withWindowSizeListener } from 'react-window-size-listener';
import * as _sharedModules from '../../../../../../../modules/_modules';
import arrayMove from 'array-move';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';

import {
  PlusCircleOutlined,
  MenuOutlined
} from '@ant-design/icons';

import './styles.less';
const { Option } = Select;
const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));
const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

class ProductsDraggableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      dataSource: [],
      initialDataLoading: true,
      showAddProducts: false,
      productsToAdd: [],
      addingProductsProccess: false
    };
    Object.keys(_sharedModules).forEach((_moduleName) => {
      this[_moduleName] = _sharedModules[_moduleName].bind(this);
    });
  }
  componentDidMount() {
    this.mounted = true;
    this.init();
  }
  init = () => {
    const { categoryId, menuId, productsCategory, productsInMenu } = this.props;
    this.setState({
      categoryId,
      menuId,
      products: productsCategory || [],
      productsInMenu
    }, this.getInitialData);
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  getInitialData = () => {
    const { categoryId, productsInMenu } = this.state;
    const data = [];
    if (productsInMenu && productsInMenu.length) {
      productsInMenu.forEach((productMenu) => {
        if (productMenu.category_id === categoryId) {
          const product = productMenu.product;
          let show_online = true;

          if (product.branch_settings && Array.isArray(product.branch_settings) && product.branch_settings.length) {
            show_online = product.branch_settings[0].show_online ? true : false;
          }
          if (product && product.categories_list && product.categories_list.length) {
            const order_index = productMenu.order_index;
            product.categories_list.forEach((category) => {
              if (category.id === categoryId && order_index) {


                data.push({
                  key: order_index,
                  index: order_index,
                  id: product.id,
                  name: product.display_name,
                  price: product.product_price || 0,
                  vat: product.vat === 10 ? '10% GST' : 'Tax free',
                  show_online
                })
              }
            })
          }
        }
      })
    }
    setTimeout(() => this.setState({ dataSource: data, initialDataLoading: false }), 500);
  }
  columns = () => {
    let columns = [];
    if (this.props.role !== 'manager') {
      columns = [
      {
        title: 'Sort',
        dataIndex: 'sort',
        width: 60,
        className: 'drag-visible',
        render: () => <DragHandle />,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        className: 'drag-visible',
      },
      {
        title: 'Price',
        dataIndex: 'price',
        render: (text, record) => {
          if (record.price) return (<span>{'$' + record.price}</span>);
        }
      },
      {
        title: 'Vat',
        dataIndex: 'vat',
      },
      {
        dataIndex: 'action',
        render: (text, record) => {
          return (
            <Button type="link" onClick={() => this.removeProductConfirm(record.id)}>Remove</Button>
          )
        }
      }];
    } else {
      columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        className: 'drag-visible',
      },
      {
        title: 'Price',
        dataIndex: 'price',
        render: (text, record) => {
          if (record.price) return (<span>{'$' + record.price}</span>);
        }
      },
      {
        title: 'Vat',
        dataIndex: 'vat',
      }, {
        dataIndex: 'action',
        render: (text, record) => {
          return (
            <div>Show online <Switch checked={ record.show_online } onChange={() => this.productVisibility(record.id, record.show_online)} /></div>
          )
        }
      }];
    }

    return columns;
  }
  productVisibility = (product_id, show_online) => {
    const {
      branchUuid,
      t,
      menuId
    } = this.props;
    this.setState({
      initialDataLoading: true
    })
    console.log('show_online', show_online, 'branchUuid', branchUuid);
    fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/menus/productVisibility`, {
      method: 'post',
      body: {
        product_id,
        show_online: show_online ? 0 : 1,
        branchUuid,
        menuId
      }
    }).then(response => {
      console.log(response);
      if (response.success) {
        this.props.updateMenu();
        setTimeout(() => {
          this.init()
        }, 1000)
        message.success(t('shareable.saved', { defaultValue: 'Saved' }));
      } else {
        message.error(t('shareable.error', { defaultValue: 'Error' }));
      }
     }).catch(error => {
        console.log(error);
        message.error(t('shareable.error', { defaultValue: 'Error' }));
     });
  }
  removeProductConfirm = (productId) => {
    const { categoryId, menuId } = this.state;
    Modal.confirm({
      title: 'Are you sure you want to remove product from menu?',
      okText: 'Yes',
      onOk: () => {
        this.removeProductFromMenu(menuId, categoryId, productId);
      },
      className: "confirm-modal-btns-center"
    })
  }
  onSortEnd = ({ oldIndex, newIndex }) => {
    const { dataSource, categoryId, menuId } = this.state;
    if (oldIndex !== newIndex) {
      const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
      //console.log('Sorted items: ', newData);
      this.setState({ dataSource: newData });
      const dataToUpload = [];
      newData.forEach((product, i) => {
        dataToUpload.push({
          order_index: i + 1,
          product_id: product.id
        });
      });
      this.updateProductsOrder(menuId, categoryId, dataToUpload);
    }
  };
  DraggableBodyRow = ({ className, style, ...restProps }) => {
    const { dataSource } = this.state;
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(x => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };
  renderAddProductOptions = () => {
    const { dataSource, products } = this.state;
    const render = [];
    const existedProductsIds = [];
    if (products && products.length) {
      dataSource.forEach((product) => {
        if (existedProductsIds.indexOf(product.id) === -1) {
          existedProductsIds.push(product.id);
        }
      })
      products.forEach((product, i) => {
        if (existedProductsIds.indexOf(product.id) === -1) {
          render.push(<Option key={i} value={product.id}>{product.display_name}</Option>)
        }
      })
    }
    return render
  }
  addProducts = () => {
    const { productsToAdd, categoryId, menuId } = this.state;
    this.setState({
      addingProductsProccess: true
    }, () => this.addProductsBackend(productsToAdd, categoryId, menuId));
  }
  onSelect = (values) => {
    console.log('values', values);
    this.setState({
      productsToAdd: values
    })
  }
  render() {
    const {
      dataSource,
      initialDataLoading,
      showAddProducts,
      addingProductsProccess
    } = this.state;
    const DraggableContainer = props => (
      <SortableContainer
        useDragHandle
        helperClass="row-dragging"
        onSortEnd={this.onSortEnd}
        {...props}
      />
    );
  	return (
      <div>
      <Spin spinning={initialDataLoading}>
        <Row style={{display: this.props.role === 'manager' ? 'none' : ''}}>
          <Button onClick={() => this.setState({ showAddProducts: true })} className="add-products-btn"><PlusCircleOutlined style={{color: '#E01491', fontSize: '16px'}}/>Add products to the list</Button>
        </Row>
        <Row style={{marginTop: '16px'}}>
        { dataSource.length ?
          <Table
            pagination={false}
            style={{width: '90%'}}
            size="small"
            dataSource={dataSource}
            columns={this.columns()}
            rowKey="index"
            components={{
              body: {
                wrapper: DraggableContainer,
                row: this.DraggableBodyRow,
              },
            }}
          /> : null}
        </Row>
        <Modal
          title="Add products"
          className="general-modal-btns-center"
          visible={showAddProducts}
          okText="Add"
          onCancel={() => this.setState({ showAddProducts: false }) }
          onOk={this.addProducts}
          confirmLoading={addingProductsProccess}
          destroyOnClose={true}
          maskClosable={false}

        >
          <div style={{ minHeight: '150px' }}>
            <Row style={{marginBottom: '4px', fontSize: '15px'}}>
              Only products connected to this category are in the list.
            </Row>
            <Row style={{marginTop: '8px'}}>
              <Select
                style={{width: '100%'}}
                onChange={(values) => this.setState({ productsToAdd: values})}
                placeholder="Select products"
                allowClear={true}
                showArrow={true}
                mode="multiple"
                listHeight={100}
                >
                {this.renderAddProductOptions()}
              </Select>
            </Row>
          </div>
        </Modal>
      </Spin>
      </div>
  	)
  }
}
export default  withTranslation()(withWindowSizeListener(ProductsDraggableTable));
