import React from 'react';
import Settings from 'services/config/Settings';
import { Layout } from 'antd';

class Main extends React.Component {
	componentDidMount = () => {
    document.title = `Main | ${Settings.title}`;
  }

  render() {
    return (
    	<Layout className="bg-transparent">
      	Something will be here soon!
      </Layout>
    )
  }
};

export default Main;