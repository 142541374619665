import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import Routes from 'services/config/Routes';

// styles
import 'normalize.css';
import './App.less';
import './i18n';

ReactDOM.render(
    <Suspense fallback={null}>
    	<Routes />
    </Suspense>,
    document.getElementById('app')
);