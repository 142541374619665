import { message, Modal } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';

const confirm = Modal.confirm;

const editEmployee = function editEmployee() {
  const { t, user } = this.props;
  return this.formRef.current.validateFields()
  .then((values) => {
    values.branchUuid = values.branchUuid ? values.branchUuid : user.branchUuid;
    this.setState({
      putREQUEST: true
    });
    fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/employees/edit`, {
      method: 'post',
      body: values
    }).then(response => {
      console.log(response);
      if (response.code === 200) {
        this.setState({
          // putREQUEST: false,
          putSUCCESS: false,
          putFAILURE: false
        }, () => {
          setTimeout(() => {
            message.success('Success!');
            this.props.renderList();
            this.props.handleClose();
          }, 500);
        });          
      } else {
        this.setState({
          putREQUEST: false,
          putFAILURE: true,
          putSUCCESS: false,
          //errorMessage: response.message
        });
        message.error(response.message);
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          putSUCCESS: false,
          putREQUEST: false,
          putFAILURE: true,
        });
        message.error(t('shareable.ERR', { defaultValue: 'ERR' }));
     });
  }).catch((err) => console.log('validate err', err))
}

const addEmployee = function addEmployee() {
  const { t, restaurantId } = this.props;
  return this.formRef.current.validateFields()
  .then((values) => {
    values.restaurantId = restaurantId;
    if (this.props.managerBranchUuid && this.props.role === 'manager') {
      values.branchUuid = this.props.managerBranchUuid;
    }
    console.log('values add', values);
    console.log(this.props, 'props')
    this.setState({
      putREQUEST: true
    });
    fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/employees/create`, {
      method: 'put',
      body: values
    }).then(response => {
      console.log(response);
      if (response.success) {
        this.setState({
          // putREQUEST: false,
          putSUCCESS: false,
          putFAILURE: false
        }, () => {
          setTimeout(() => {
            message.success(t('employees.feedback.createSuccess', { defaultValue: 'Added successfully' }));
            this.props.renderList();
            this.props.handleClose();
          }, 500);
        });          
      } else {
        this.setState({
          putREQUEST: false,
          putFAILURE: true,
          putSUCCESS: false,
          //errorMessage: response.message
        });
        message.error(response.message);
      }
     }).catch(error => {
        console.log(error);
        this.setState({
          putSUCCESS: false,
          putREQUEST: false,
          putFAILURE: true,
        });
        message.error(t('shareable.ERR', { defaultValue: 'ERR' }));
     });
  }).catch((err) => console.log('validate err', err))
}

const changeUserStatus = function changeUserStatus(userUuid, status, is_deleted = false) {
  console.log('changeUserStatus', userUuid, status);
  //const { branchUuid } = this.props;
  confirm({
    title: `Are you sure you want to ${ status === 1 && !is_deleted ? 'activate' : !is_deleted ? 'suspend' : '' }
    ${ status === 2 && is_deleted ? 'delete' : '' } the employee account?`,
    onOk: () => {
      return new Promise((resolve, reject) => {
        this.setState({
          isFetching: true
        })
        fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/employees/status`, {
          method: 'post',
          body: {
            userUuid,
            status,
            is_deleted
          }
        }).then(response => {
          console.log(response);
          if (response.code === 200) {
            message.success('Success!');
            this.setState({
              isFetching: false
            });
            this.getEmployees()
            resolve()
          } else {
            this.setState({
              isFetching: false
            });
            reject();
            message.error('Something went wrong, please try again.');
          }
         }).catch(error => {
            console.log(error);
            reject();
            this.setState({
              isFetching: false
            });
            message.error('ERR');
         });
      }).catch(() => console.log('Oops errors!'));
    },
    onCancel() {},
  });
}
const getEmployees = function getEmployees() {
  const { role } = this.props;
  this.setState({
    isFetching: true
  });
  const body = {};
  if (role === 'admin') {
    body.restaurantId = this.props.restaurantId;
  }
  fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/employees/list', {
    method: 'post',
    body
  }).then(response => {
    const branchFilters = [];
    const branchStorage = [];
    if (response && ['admin', 'owner'].indexOf(role) > -1 && response.fullEmployeesObj && typeof response.fullEmployeesObj === 'object') {
      if (response.fullEmployeesObj.branchesRelated && Array.isArray(response.fullEmployeesObj.branchesRelated)) {
        response.fullEmployeesObj.branchesRelated.forEach((branch) => {
          if (branchStorage.indexOf(branch.branchId) === -1) {
            branchStorage.push(branch.branchId);
            branchFilters.push({ text: branch.branchTitle, value: branch.branchId });
          }
        })
      }
    }
    if (this.mounted) {
      this.setState({
        fullEmployeesObj: response.fullEmployeesObj,
        branchFilters,
        isFetching: false
      });
    }
  }).catch(error => {
    console.log(error);
    if (this.mounted) {
      this.setState({
        isFetching: false,
        fullEmployeesObj: {}
      });
    }
  });
}

export {
	getEmployees,
  addEmployee,
  changeUserStatus,
  editEmployee
};