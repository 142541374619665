import React, { Component } from 'react';
import Settings from 'services/config/Settings';
import { withTranslation } from 'react-i18next';
import SingleBranch from 'screens/Dashboard/Branches/components/SingleBranch/SingleBranch';

class Location extends Component {
	componentDidMount() {  
    const { t } = this.props;
    document.title = `${t('shareable.location', { defaultValue: 'Location' })} | ${Settings.title}`;
  }
  render() {
    return <SingleBranch {...this.props} />
  }
}


export default withTranslation()(Location);