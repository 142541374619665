import React from 'react';
import Settings from 'services/config/Settings';
import Auth from 'services/utils/Auth';
import fetchJSON from 'services/utils/fetchJSON';
import CountryPhoneInput from 'components/AntdCountryPhoneInput';

import { withWindowSizeListener } from 'react-window-size-listener';
import { getFingerprint } from 'services/utils/Analytics';
import { withTranslation, Trans } from 'react-i18next';

import { Link } from 'react-router-dom';
import { Layout, Row, Input, Spin, Button, Form, Alert } from 'antd';
import Logo from 'components/images/dezy-header-logo-v2.svg';

const FormItem = Form.Item;

class LoginEmployee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      stage: 1,
      timeLeft: 0
    };
    this.timer = 0;
    this.FormRef = React.createRef();
  }
  componentDidMount = () => {
    const { t } = this.props;
    this.mounted = true;
    document.title = `${t('login.documentTitle', { defaultValue: 'Login' })} | ${Settings.title}`;
    document.body.addEventListener('touchmove', this.preventDefault, { passive: false });
  }
  preventDefault = (e) => {
    e.preventDefault();
  }
  componentWillUnmount = () => {
    this.mounted = false;
    document.body.removeEventListener('touchmove', this.preventDefault);
    clearInterval(this.timer);
  }
  handleSubmit = async (values) => {
    if (!this.mounted) return false;    
    this.setState({
      isFetching: true
    });
    const { stage } = this.state;

    let fingerprint = '';
    try {
      fingerprint = await getFingerprint();
    } catch (error) {
      console.log('error', error);
    }
    console.log('values', values);
    const { phoneObj } = values;

    values.stage = stage;
    values.phone = `${phoneObj.code}${phoneObj.phone}`;
    values.fingerprint = fingerprint;

    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/auth/login-employee', {
      method: 'POST',
      body: values
    }).then(response => {
      if (!this.mounted) return false;
      this.setState({
        isFetching: false,
        serverMessage: response.message,
        serverMessageType: response.code > 300 ? 'error' : 'success',
      });

      if (response.data && response.data.stage) {
        this.setState({
          stage: response.data.stage
        });
       if (response.data.stage === 2) {
          this.setTimeLeft(60);
        }
      }
      if (response.code === 200) {
        // If login was successful, set the token in cookie
        Auth.loginUser(response.token);
        this.props.history.push('/');
      } else {
        setTimeout(() => {
          if (this.mounted) {
            this.setState({
              serverMessage: ''
            });
          }
        }, 3000);
      }
     }).catch(error => {
        console.log(error);
        if (this.mounted) {
          this.setState({
            isFetching: false
          });
        }
     });
  }
  validatePhone = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || !value.phone) {
        return Promise.reject('Phone is required!')
      } else {
        if (value.phone.length < 7) {
          return Promise.reject(<p><Trans i18nKey="shareable.reqPhone.minLength">Phone should be 8 symbols and longer</Trans></p>);
        }
        const isnum = /^\d+$/.test(value.phone);
        if (!isnum) {
          return Promise.reject(<p><Trans i18nKey="shareable.reqPhone.onlyNumber">Please, enter correct phone</Trans></p>);
        }
      }
      return Promise.resolve();
    },
    validateTrigger: 'onSubmit'
  })
  setTimeLeft = (sec) => {
    this.setState({
      timeLeft: sec
    }, () => {
      if (this.state.timeLeft > 0) {
        this.timer = setInterval(this.countDown, 1000);
      }
    });
  }
  countDown = () => {
    // Remove one second, set state so a re-render happens.
    const { timeLeft } = this.state;
    const newNum = timeLeft - 1;

    this.setState({
      timeLeft: newNum
    });
    
    // Check if we're at zero.
    if (newNum === 0) { 
      clearInterval(this.timer);
    }
  }

  sendCodeAgain = async () => {
    console.log('sendCodeAgain');
    console.log('this.FormRef', this.FormRef);
    if (this.FormRef && this.FormRef.current) {
      const values = this.FormRef.current.getFieldsValue();
      let fingerprint = '';
      try {
        fingerprint = await getFingerprint();
      } catch (error) {
        console.log('error', error);
      }
      console.log('values', values);
      const { phoneObj } = values;
      values.stage = 1;
      values.phone = `${phoneObj.code}${phoneObj.phone}`;
      values.fingerprint = fingerprint;

      fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/auth/login-employee', {
        method: 'POST',
        body: values
      }).then(response => {
        if (!this.mounted) return false;
        this.setTimeLeft(60);
       }).catch(error => {
          console.log(error);
       });
    }
  }
  render() {
    const { t } = this.props;
    const { 
      isFetching,
      serverMessage,
      serverMessageType,
      stage,
      timeLeft
    } = this.state;

    const containerStyle = {
      width: this.props.windowSize.windowWidth,
      height: this.props.windowSize.windowHeight,
      minHeight: this.props.windowSize.windowHeight
    };

    return (
      <Layout className="layoutApp layoutLogin" style={containerStyle}>
      <Spin spinning={ isFetching }>
        <div style={{marginRight: '30px'}}>
          <img className="layoutLogin__logo-img" src={Logo} alt="Logo" />
        </div>
        <Form onFinish={this.handleSubmit} ref={this.FormRef}>
        { serverMessage ?
        <Row>
          <FormItem><Alert message={serverMessage} type={ serverMessageType } /></FormItem></Row>
        : null }
        <Row className="layoutLogin__input-container" style={{
          display: stage !== 1 && 'none' 
        }}>
          <FormItem
            rules={[this.validatePhone]}
            name="phoneObj" 
           >
              <CountryPhoneInput className="phoneCodeInput layoutLogin__input" placeholder="Phone" />
          </FormItem>
        </Row>
        <Row className="layoutLogin__input-container" style={{
          display: stage !== 2 && 'none' 
        }}>
          <FormItem
            name="code"
            rules={[{
              required: stage === 2,
              message: 'Please enter code from the sms!',
            }]}>
              <Input className="layoutLogin__input" placeholder={t('shareable.code', { defaultValue: 'Code' })}/>
          </FormItem>
        </Row>
        <Button style={{width: '120px'}} htmlType="submit">Log in</Button>
        </Form>
        <div style={{marginTop: '30px'}}>
          { stage === 1 && <Link className="layoutLogin__link" to='/login'>
            Back to login
          </Link> }
          { stage === 2 &&
            <button
              className="layoutLogin__link"
              type="button"
              onClick={this.sendCodeAgain}
              disabled={timeLeft !== 0}
            >Send the code again {timeLeft ? `in ${timeLeft} sec` : ''}</button>
          }

        </div>
      </Spin>
      </Layout>
    );
  }
}

export default withTranslation()(withWindowSizeListener(LoginEmployee));

