import React from 'react';
import fetchJSON from 'services/utils/fetchJSON';
import { withTranslation } from 'react-i18next';
import {  Modal, Form, message } from 'antd';
import * as _sharedModules from './modifierModules';


class AddModifierSets extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      putREQUEST: false,
      modifierList: [{
        key: 0,
        display_name: '',
        price_add: '',
        sort_order: 0,
        modifier_id: null
      }],
      productsTotal: [],
      productConnected: []
    };
    Object.keys(_sharedModules).forEach((_moduleName) => {
      this[_moduleName] = _sharedModules[_moduleName].bind(this);
    });
  }
	componentDidMount = () => {
    this.mounted = true;
    this.getProducts();
  }
  componentWillUnmount = () => {
    this.mounted = false;
  }
  handleSubmit = () => {
    const { t } = this.props;
    return this.formRef.current.validateFields()
    .then((values) => {
      const { productConnected } = this.state;
      if (this.props.restaurantId) {
        values.restaurantId = this.props.restaurantId;
      }

      console.log('values submit', values);
      values.productConnected = productConnected;
      this.setState({
        putREQUEST: true
      });
      fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/products/modifiers/create`, {
        method: 'put',
        body: values
      }).then(response => {
        console.log(response);
        if (response.success) {
          this.setState({
            // putREQUEST: false,
            putSUCCESS: true,
            putFAILURE: false
          }, () => {
            setTimeout(() => {
              message.success(t('singleLocation.feedback.saveSuccess', { defaultValue: 'Saved successfully' }));
              this.props.handleClose();
              this.props.updateModifiersList(response.modifier_id);
            }, 500);
          });          
        } else {
          this.setState({
            putREQUEST: false,
            putFAILURE: true,
            putSUCCESS: false,
            //errorMessage: response.message
          });
          message.error(response.message);
        }
       }).catch(error => {
          console.log(error);
          this.setState({
            putSUCCESS: false,
            putREQUEST: false,
            putFAILURE: true,
          });
          message.error(t('shareable.ERR', { defaultValue: 'ERR' }));
       });
    }).catch((err) => console.log('validate err', err))
  }

  render() {
    const { 
      putREQUEST,
      modifierList
    } = this.state;
    return (
      <Modal 
        className="general-modal-btns-center general-card"
        title="New Modifier Sets"
        centered={true}
        visible={this.props.visible}
        okButtonProps={{htmlType: "submit"}}
        okText="Add"
        onOk={() => this.handleSubmit()}
        onCancel={this.props.handleClose}
        destroyOnClose={true}
        confirmLoading={putREQUEST}
        maskClosable={true}
        width={650}
      >
        <Form 
          requiredMark={false} 
          className="general-form" 
          layout="vertical" 
          ref={this.formRef} 
          initialValues={{
            display_name: '',
            single_select: false,
            required: false,
            modifiers: modifierList
          }} 
        >
          {this.ModifierForm()}
        </Form> 
      </Modal>
    )
  }
};

export default withTranslation()(AddModifierSets);

