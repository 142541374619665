import React from 'react';
import Settings from 'services/config/Settings';
import { Layout, Card, Row, Col, Select, Empty, DatePicker, Spin } from 'antd';
import { withTranslation, Trans } from 'react-i18next';
import { getTimeDistance } from 'services/utils/utils';
import { withWindowSizeListener } from 'react-window-size-listener';
import qs from 'qs';
import * as _sharedModules from '../modules/_modules';

import GeneralReport from './components/GeneralReport/GeneralReport';
import ProductsReport from './components/ProductsReport/ProductsReport'; 
import TransactionsReport from './components/TransactionsReport/TransactionsReport';

import './styles.less';

const { Content } = Layout;
const { RangePicker } = DatePicker;

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      initialDataLoaded: false,
      branchesList: [],
      targetRestaurantId: null,
      targetBranchId: null,
      rangePickerValue: getTimeDistance('today'),
      datePickerSize: 'default',
      timezone: 'Australia/Brisbane',
      filterTypeOrder: 'all',
      analiticsLoading: false
    };
    Object.keys(_sharedModules).forEach((_moduleName) => {
      this[_moduleName] = _sharedModules[_moduleName].bind(this);
    });
    this.userRole = this.props.role;
  }
  componentDidMount() {
    this.mounted = true;
    if (this.userRole === 'admin') {
      this.getRestaurantsListSimple();
    } else {
      const targetRestaurantId = this.props.related_restaurant_id;
      this.setState({
        targetRestaurantId
      }, () => {
        if (this.userRole === 'owner') {
          this.getBranchesList();
        }
        if (this.userRole === 'manager' || this.props.managerReports) {
          this.setState({
            targetBranchUuid: this.props.branchUuid
          })
        }
      })
    }
    let activeTab = 'general';
    let paramFromUrl = {};
    if (window.location.search) {
      paramFromUrl = qs.parse(window.location.search, { ignoreQueryPrefix: true });

      const tabList = [
      'general',
      'transactions',
      'products'
      ];
      if (Object.keys(paramFromUrl).length) {
        Object.keys(paramFromUrl).forEach((param) => {
          if (paramFromUrl[param] && tabList.indexOf(param) > -1) {
            activeTab = param;
          }
        });
      }
    }
    this.setState({
      activeTab
    });
    if (this.props.branchUuid && this.props.managerReports) {
      this.setState({
        targetBranchId: this.props.branchUuid
      }, () => {
        setTimeout(() => this.getReportData(activeTab), 500);
      })
    }
    this.setDocumentTitle(activeTab);
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  setDocumentTitle = (activeTab) => {
    const { t } = this.props;
    const tabList = this.getTabList();
    let tabAdditive = '';
    tabList.forEach((tab) => {
      if (tab.key === activeTab) {
        tabAdditive = `${tab.tab} | `;
      }
    });
    document.title = `${t('shareable.reports', { defaultValue: 'Reports' })} | ${tabAdditive} ${Settings.title}`;
  }
  onRestaurantSelectChange = (targetRestaurantId) => {
    this.setState({
      targetRestaurantId,
      targetBranchId: null
    }, this.getBranchesList)
  }
  onBranchSelectChange = (targetBranchId) => {
    const { targetRestaurantId, activeTab } = this.state;
    const { branchesList } = this.state;
    branchesList.forEach((branch) => {
      if (branch.id === targetBranchId) {
        this.setState({
          timezone: branch.timezone,
          rangePickerValue: getTimeDistance('today', branch.timezone)
        })
      }
    })
    this.setState({
      targetBranchId
    }, () => {
      if (targetRestaurantId && targetBranchId) {
        this.getReportData(activeTab);
      }
    })
  }
  selectDate = (type) => {
    const { timezone, targetBranchId, targetRestaurantId, activeTab } = this.state;
    this.setState({
      rangePickerValue: getTimeDistance(type, timezone),
    }, () => {
      if (targetRestaurantId && targetBranchId) {
        this.getReportData(activeTab);
      }
    });
  }
  isActive(type) {
    const { rangePickerValue, timezone } = this.state;
    const value = getTimeDistance(type, timezone);
    if (!rangePickerValue[0] || !rangePickerValue[1]) {
      return '';
    }
    if (
      rangePickerValue[0].isSame(value[0], 'day') &&
      rangePickerValue[1].isSame(value[1], 'day')
    ) {
      return 'ant-btn-primary';
    }

    return '';
  }
  isActiveOrderType = (type) => {
    const { filterTypeOrder } = this.state;
    if (type === filterTypeOrder) return 'ant-btn-primary';
    return '';
  }
  changeFilterOrderType = (filterTypeOrder) => {
    const { targetBranchId, targetRestaurantId, activeTab } = this.state;
    this.setState({
      filterTypeOrder
    }, () => {
      if (targetRestaurantId && targetBranchId) {
        this.getReportData(activeTab);
      }
    })
  }
  handleRangePickerChange = (rangePickerValue) => {
    const { timezone, targetBranchId, targetRestaurantId, activeTab } = this.state;
    rangePickerValue[0].tz(timezone).startOf('day');
    rangePickerValue[1].tz(timezone).endOf('day');
    this.setState({
      rangePickerValue,
    }, () => {
      if (targetRestaurantId && targetBranchId) {
        this.getReportData(activeTab);
      }      
    });
  }
  onTabChange = (key, type) => {
    const { targetBranchId, targetRestaurantId } = this.state;
    this.setDocumentTitle(key);
    let urlTabChange = this.props.managerReports ? `/manager/reports?${key}=1` : `/reports?${key}=1`;
    this.props.history.push(urlTabChange);
    this.setState({
      [type]: key,
      reportsData: this.getEmptyReportObj(key)
    }, () => {
      if (targetRestaurantId && targetBranchId) {
        this.getReportData(key);
      }
    })
  }
  getTabList = () => {
    const { t } = this.props;
    return [{
      key: 'general',
      tab: t('shareable.info', { defaultValue: 'General' }),
    }, {
      key: 'products',
      tab: t('shareable.products', { defaultValue: 'Products' })
    }, 
    {
      key: 'transactions',
      tab: t('shareable.transactions', { defaultValue: 'Transactions' })
    }
    ];
  }
  render() {
    const { 
      initialDataLoaded,
      branchesListLoaded,
      branchesList,
      targetRestaurantId,
      targetBranchId,
      rangePickerValue,
      filterTypeOrder,
      analiticsLoading, 
      reportsData,
      activeTab
    } = this.state;
    const orderTypeFilter = (
      <Row className="order-filter-container-btns">
        <button onClick={() => this.changeFilterOrderType('all')} className={"ant-btn "+this.isActiveOrderType('all')}>All</button>
        <button onClick={() => this.changeFilterOrderType('dine_in')} className={"ant-btn "+this.isActiveOrderType('dine_in')}>Dine in</button>
        <button onClick={() => this.changeFilterOrderType('takeout')} className={"ant-btn "+this.isActiveOrderType('takeout')}>Takeaway</button>
      </Row>
    );
    let spanDate = 15;
    if (this.props.role === 'owner') spanDate = 20;
    if (this.props.managerReports) spanDate = 23;
    const hideSelectBranches = (this.userRole !== 'admin' && branchesListLoaded && branchesList.length === 1) || (this.props.managerReports);
    return ( 
      <Content className="bg-transparent table-content" style={{paddingLeft: '0px'}}>
        <meta name="viewport" content="target-densitydpi=device-dpi, initial-scale=1.0, user-scalable=no" />
        <Card 
          className="general-card"
          loading={!initialDataLoaded && !this.props.managerReports}
          tabList={this.getTabList()}
          activeTabKey={activeTab}
          onTabChange={(key) => { this.onTabChange(key, 'activeTab'); }}
          title={
            <Row>
              <span className="general-card__title-text">Reports</span>
            </Row>
          }
          >
          <Row gutter={16}>
            { this.userRole === 'admin' ?
            <Col sm={4} xs={12}>
              <Select value={targetRestaurantId} style={{width: '100%'}} placeholder="Restaurant" onChange={(value) => this.onRestaurantSelectChange(value)}>
                {this.renderRestaurantsOptions()}
              </Select>
            </Col> : null }
            { !hideSelectBranches ?
            <Col sm={4} xs={12}>
              <Select 
                onChange={(value) => this.onBranchSelectChange(value)} 
                value={targetBranchId} 
                disabled={!targetRestaurantId || (targetRestaurantId && branchesListLoaded && !branchesList.length)} 
                loading={targetRestaurantId && !branchesListLoaded} style={{width: '100%'}} placeholder="Venue"
              >
                {this.renderBranchesOptions()}
              </Select>
            </Col> : null }
            <Col sm={spanDate} xs={24} className="mobileTopMargin">
              <Row>
                <div className="mobile-hide" style={{display: activeTab === 'transactions' ? 'none' : ''}}>
                  {orderTypeFilter}
                </div>  
                <div className="div-btns-fix">
                  <div>
                    <React.Fragment>
                      <div>
                        <RangePicker
                          value={rangePickerValue}
                          onChange={this.handleRangePickerChange}
                        />
                      </div>
                      <div className="date-container-btns">
                        <button className={"ant-btn "+this.isActive('today')} onClick={() => this.selectDate('today')}>
                          <div><Trans i18nKey="statistics.today">Today</Trans></div>
                        </button>
                        <button className={"ant-btn "+this.isActive('week')} onClick={() => this.selectDate('week')}>
                          <div><Trans i18nKey="statistics.week">Week</Trans></div>
                        </button>
                        <button className={"ant-btn "+this.isActive('month')} onClick={() => this.selectDate('month')}>
                          <div><Trans i18nKey="statistics.month">Month</Trans></div>
                        </button>
                        <button className={"ant-btn "+this.isActive('year')} onClick={() => this.selectDate('year')}>
                          <div><Trans i18nKey="statistics.year">Year</Trans></div>
                        </button>
                      </div>
                    </React.Fragment>
                  </div>
                </div>    
              </Row>
            </Col>
            <Col sm={0} xs={24} className="mobileTopMargin"  style={{display: activeTab === 'transactions' ? 'none' : ''}}>
              {orderTypeFilter}
            </Col>
          </Row>
          <Row>
            { targetRestaurantId && branchesListLoaded && !branchesList.length ?
              <div style={{width: '100%', height: '200px', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <Empty/>
              </div> : null }
            { targetBranchId && targetRestaurantId && reportsData && typeof reportsData === 'object' ? 
            <div style={{width: '100%'}}>
              <Spin spinning={analiticsLoading}>
                { activeTab === 'general' && 
                  <div style={{marginTop: '36px'}}>
                    <GeneralReport
                      reportsData={reportsData} 
                      filterTypeOrder={filterTypeOrder}
                      openProductsReport={() => { this.onTabChange('products', 'activeTab')}}
                    />
                  </div> }
                { activeTab === 'products' && 
                  <ProductsReport
                    reportsData={reportsData}
                    /> }
                { activeTab === 'transactions' && 
                  <div style={{marginTop: this.props.managerReports ? '-30px' : ''}}>
                  <TransactionsReport
                    reportsData={reportsData.transactions}
                    updateList={() => this.getReportData('transactions')}
                  /></div>}
              </Spin>
            </div> : null }
          </Row>
        </Card>
      </Content>
    )
  }
};

export default withTranslation()(withWindowSizeListener(Reports));