import React from 'react';
import axios from 'axios'
import Auth from 'services/utils/Auth';
import { Upload, Modal, message } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';
import { PlusOutlined } from '@ant-design/icons';
import { withTranslation, Trans } from 'react-i18next';

const confirm = Modal.confirm;

class ImageProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
			previewImage: '',
			previewVisible: false,
			getWidth: true,
			containerSryle: {},
			fileList: []
		}
    this.imageStyle = { display: 'none', height: 0 };
    this.intervalHeight = null;
  }
	componentDidMount() {
		this.mounted = true;
		this.intervalHeight = setInterval(this.getUploadWidth, 500);
		if (this.props.file && typeof this.props.file === 'object' && Object.keys(this.props.file).length) {
			let fileList = [{
				uid: this.props.file.id,
				status: 'done',
				url: `https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/files/origin/${this.props.file.file}`
			}];
			this.setState({
				fileList
			})
		} else if (this.props.images && this.props.images.length) {
			let fileList = this.props.images.map((obj, key) => {
				return {
					uid: obj.image.id,
					status: 'done',
					url: `https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/files/origin/${obj.image.file}`
				}
			})
			this.setState({
				fileList
			})
		}
	}
  componentWillUnmount() {
    this.mounted = false;
    clearInterval(this.intervalHeight);
  }
 	getUploadWidth = () => {
 		const imageUpload = document.getElementById("demo");
 		if (imageUpload && imageUpload.offsetWidth) {
 			this.imageStyle = { height: `${imageUpload.offsetWidth}px`};
 			clearInterval(this.intervalHeight);
 			this.setState({
 				containerSryle: this.imageStyle
 			})
 		}
 	}
 	imageOrientation = (file) => new Promise((resolve, reject) => {
    try {
      const image = new Image();
      image.onload = function () {
      	let orientation;

      	if (image.naturalWidth > image.naturalHeight) {
		      orientation = 'landscape';
		    } else if (image.naturalWidth < image.naturalHeight) {
		      orientation = 'portrait';
		    } else {
		      orientation = 'even';
		    }
        resolve(orientation);
      };

      image.onerror = function () {
        reject(null);
      }
      image.src = window.URL.createObjectURL(file);
    } catch (e) {
    	console.error(e);
      reject(null)
    }
	});
  handleDrop = async (data) => {
  	// console.log(data);
  	const file = data.file;
  	const { t } = this.props;
		if ( file ) {
			if ( file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {
				if ( file.size > 5242880 ) {
					let errorLoadMessage =  t('venueImage.error.tooBig', { defaultValue: 'This image is too big! Max image file size is 5 mb.' });
					message.error(errorLoadMessage);
					data.onError();
					return false;
				}
			} else {
				let errorLoadMessage = t('venueImage.error.fileType', { defaultValue: 'Wrong file type! file type must be png, jpg or jpeg!' });
				message.error(errorLoadMessage);
				data.onError();
				return false;
			}
		} else {
			let errorLoadMessage =  t('venueImage.error.fileNull', { defaultValue: 'File was null!' });
			message.error(errorLoadMessage);
			data.onError();
			return false;
		}

		// Initial FormData
		const formData = new FormData();
		formData.append("file", file);

		const orientation = await this.imageOrientation(file);

		const config = {
			headers: {
				"X-Requested-With" : "XMLHttpRequest",
				"Authorization" : `bearer ${Auth.getToken()}`
			},
			onUploadProgress: data.onProgress,
			params: {
				productId: this.props.productId,
				type: 'image',
				role: 'product_image',
				orientation
			}
		};
		return axios.put(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/files/upload`, formData, config)
			.then(response => {
				// console.log(response);
				data.onSuccess(response);
				const { fileList } = this.state;
				fileList.map(file => {
					if (file.uid === data.file.uid) {
						file.uid = response.data.uid;
					}
					return file;
				});
				this.setState({ fileList })
			})
			.catch(error => {
				console.log(error);
				let errorLoadMessage = t('venueImage.error.couldntUpload', { defaultValue: 'Could not upload file. Please try again!' });
				message.error(errorLoadMessage);
				data.onError();
		})
	}

	handleCancel = () => this.setState({ previewVisible: false })
	handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  }
  handleChange = ({ fileList }) => this.setState({ fileList })
  onRemove = params => {
  	const { t } = this.props;
  	return new Promise((resolve, reject) => {
  		confirm({
		    title: t('venueImage.deleteQuestion', { defaultValue: 'Do you want to delete this image?' }),
		    onOk() {
		      fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/files/${params.uid}`, {
					  method: 'DELETE'
					}).then(response => {
					  if (response.success) {
					    message.success(t('venueImage.fileDeleted', { defaultValue: 'File deleted' }));
					    resolve()
					  } else {
					  	reject()
					  }
					 }).catch(error => {
					    console.log(error);
					    reject()
					 })
		    },
		    onCancel() {

		    },
		  });

  	})
		
  }
  render() {
  	const { previewVisible, previewImage, fileList, containerSryle } = this.state;
  	const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text"><Trans i18nKey="venueImage.upload">Upload</Trans></div>
      </div>
    );
    return (
      <div>
        <label className="formDefault__label">
        	<Trans i18nKey="venueImage.imageForApp">Image</Trans>
        </label>
        <p><Trans i18nKey="venueImage.imageRequirements">Maximum size - 5mb</Trans>. Recommended aspect ratio: 1 x 1 (Square).</p>
				<div id="demo" className="clearfix image-fix" style={containerSryle}>
	        <Upload
	          action="https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/files/upload"
	          multiple={false}
	          customRequest={this.handleDrop}
	          listType="picture-card"
	          //showUploadList={false}
	          fileList={fileList}
	          onPreview={this.handlePreview}
	          onChange={this.handleChange}
	          onRemove={this.onRemove}
	        >
          	{ fileList.length > 0 ? null : uploadButton }
	        </Upload>
	        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
	          <img alt="example" style={{ width: '100%' }} src={previewImage} />
	        </Modal>
	      </div>
      </div>
    )
  }
};

export default withTranslation()(ImageProduct);