import React from 'react';
import Settings from 'services/config/Settings';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import {
	Button,
	Input,
	Row,
	Col,
	Spin,
	Select,
	Form,
  Alert,
  Card,
  Steps,
  Checkbox,
  message,
  Layout,
  Radio
} from 'antd';
import AddressField from '../../../components/AddressField/AddressField';
import WorkingHours, { slotTemplate } from '../../../components/WorkingHours/WorkingHours';
import { withWindowSizeListener } from 'react-window-size-listener';
import { withTranslation, Trans } from 'react-i18next';
import fetchJSON from 'services/utils/fetchJSON';
import * as _sharedModules from '../../../modules/_modules';
import isValidABN from 'is-valid-abn';
import qs from 'qs';
import ImageUpload from '../../../components/ImageUpload/ImageUpload';
import PdfBranch from '../SingleBranch/components/PdfBranch';

import {
  GoogleOutlined,
  LeftOutlined,
  MobileOutlined,
  SnippetsOutlined,
  InfoCircleTwoTone
 } from '@ant-design/icons';

import './styles.less';
const { Content } = Layout;
const FormItem = Form.Item;
const Option = Select.Option;
const { Step } = Steps;
const { TextArea } = Input;

class AddBranch extends React.Component {
  constructor() {
    super();
    this.formRef = React.createRef();
    const emptySlot = JSON.parse(JSON.stringify(slotTemplate));
    this.state = {
      addressGroup: {},
      putREQUEST: false,
      putFAILURE: false,
      putSUCCESS: false,
      isFetching: false,
      opening_hours: {},
      businessHours: [emptySlot],
      addressError: '',
      createNewRest: 0,
      types: [],
      business_categories: [],
      locationName: '',
      addressInput: '',
      allFieldsToForm: 0,
      everVisitedStep: 0,
      currentStep: 0,
      formatted_address: '',
      restaurantsList: [],
      shouldAskAmountBranches: false,
      shouldAskPayment: false,
      menuTypeConfirmed: false,
      bannerUploadInProccess: false,
      logoUploadInProccess: false,
      menuChangingProccess: false,
      menuAdded: false
    }
    Object.keys(_sharedModules).forEach((_moduleName) => {
      this[_moduleName] = _sharedModules[_moduleName].bind(this);
    });
  }
  componentDidMount() {
    this.mounted = true;
    const { t } = this.props;
    document.title = `${t('addBranch.documentTitle', { defaultValue: 'Add Branch' })} | ${Settings.title}`;
    this.getBusinessCategories();
    let paramFromUrl = {};
    if (window.location.search) {
      paramFromUrl = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      if (paramFromUrl.uuid) {
        this.chechRestaurantAccess(paramFromUrl.uuid);
        this.props.history.push('/branches/add');
      }
    }
    if (this.props.role === 'admin' && !paramFromUrl.uuid) {
      this.setState({ shouldAskAmountBranches: true })
      this.getRestaurantsList();
    }
    if (this.props.role !== 'admin') {
      this.getBranches();
    }
  }
  componentWillUnmount(){
    this.mounted = false;
  }
  getBranches = () => {
    this.setState({
      isFetching: true
    });
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches/list', {
      method: 'put'
    }).then(response => {
      if (this.mounted) {
        if (response.data) {
          this.setState({
            shouldAskAmountBranches: !response.data.length,
            isFetching: false
          });
        } else {
          this.setState({ isFetching: false });
        }
      }
    }).catch(error => {
      if (this.mounted) {
        this.setState({
          isFetching: false
        });
      }
    });
  }
  chechRestaurantAccess = (restaurantUuid) => {
    this.setState({
      isFetching: true
    });
    fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/restaurants/${restaurantUuid}`, {
      method: 'get'
    }).then(response => {
      if (this.mounted) {
        if (response.data) {
          const restaurant = response.data;
          const shouldAskAmountBranches = (restaurant.branch && Array.isArray(restaurant.branch) && !restaurant.branch.length) || !restaurant.expected_branches_amount;
          this.setState({
            isFetching: false,
            redirectedRestaurantUuid: restaurant.uuid,
            redirectedRestauranId: restaurant.id,
            redirectAccess: true,
            shouldAskAmountBranches
          })
        } else {
          this.setState({ isFetching: false });
        }
      }
    }).catch(error => {
      console.log(error);
      if (this.mounted) {
        this.setState({
          isFetching: false
        });
      }
    });
  }
  getRestaurantsList = () => {
    const restaurantsList = [];
    const restStorage = [];
    this.setState({
      isFetching: true
    });
    fetchJSON('https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/restaurants/list', {
      method: 'get'
    }).then(response => {
      if (this.mounted) {
        if (response.data && response.data.length) {
          response.data.forEach((restaurant) => {
            console.log(restaurant, 'restaurant')
            if (restStorage.indexOf(restaurant.id) === -1) {
              restaurantsList.push({ id: restaurant.id, title: restaurant.name});
              restStorage.push(restaurant.id);
            }
          })
          this.setState({
            isFetching: false,
            restaurants: response.data,
            restaurantsList
          })
        } else {
          this.setState({ isFetching: false });
        }
      }
    }).catch(error => {
      console.log(error);
      if (this.mounted) {
        this.setState({
          isFetching: false
        });
      }
    });
  }
  onStepChange = currentStep => {
    this.setState({ currentStep });
    this.proccessStepChanging(currentStep);
  }
  changeFieldStateCheckbox = (e, key) => {
    if (key === 'createNewRest') {
      if (e.target.checked) {
        this.formRef.current.setFieldsValue({
          restaurantId: null
        });
      }
      else if (!e.target.checked) {
        const { restaurantsList } = this.state;
        if (restaurantsList && Array.isArray(restaurantsList) && restaurantsList.length === 1) {
          this.formRef.current.setFieldsValue({
            restaurantId: restaurantsList[0].id
          })
        }
      }
    }
    this.setState({
      [key]: e.target.checked
    });
  }
  modalVisible = (key, value) => {
    this.setState({ [key]: value });
  }
  changeFieldStateValue = (e, key) => {
    this.setState({
      [key]: e.target.value
    });
  }
  setFieldsValue = (name, value) => {
    this.formRef.current.setFieldsValue({
      [name]: value
    });
  }
  proccessStepChanging = (nextStep) => {
    console.log('test values', this.formRef.current.getFieldsValue())
    const {
      everVisitedStep
    } = this.state;
    let everVisited = everVisitedStep;
    let allFieldsToForm = 0;
    if (nextStep > everVisited) {
      everVisited = nextStep;
    }
    if (nextStep === 4) {
      allFieldsToForm = 1;
    }
    this.setState({
      allFieldsToForm,
      everVisitedStep: everVisited
    });
  }
  goNextStep = () => {
    const {
      currentStep,
    } = this.state;
    let nextStep = currentStep + 1;
    this.setState({
      currentStep: nextStep
    });
    this.proccessStepChanging(nextStep);
  }
  nextStep = () => {
    const { t } = this.props;
    const { validateFields } = this.formRef.current;
    validateFields()
    .then(values => {
      const { currentStep, addressGroup } = this.state;
      if (currentStep === 0) {
        if (!this.state.createNewRest && !values.restaurantId && this.props.role === 'admin') {
          return false;
        }
        console.log('values.addressGroup', addressGroup);
        const lat = _.get(addressGroup, 'coordinates.lat');
        if (!lat && currentStep === 1) {
          this.setState({
            addressError: t('shareable.enterSelectAddressPlease', { defaultValue: 'Please, enter correct address and select right option from a dropdown list' })
          });
          return false;
        }
      }
      this.goNextStep();
    })
    .catch((error) => {
      this.scrollToError(error);
    });

  }
  scrollToError = (error) => {
    const fieldName = _.get(error, 'errorFields[0].name[0]');
    if (fieldName) {
      const element = document.getElementById(`formitem_${fieldName}`);
      if (element) {
        const reactElement = element.getBoundingClientRect();
        const windowPosition = window.scrollY;
        const windowHeight = this.props.windowSize.windowHeight;
        if (reactElement.top > 0 && reactElement.top < windowHeight) {
          return;
        } else {
          window.scrollTo({
              top: windowPosition + reactElement.top - windowHeight*0.4,
              behavior: "smooth"
          });
        }
      }
    }
  }
  changeFieldStateSwitch = (value, key) => {
    this.setState({
      [key]: value
    });
  }
  handleSubmit = (values) => {
    const { t } = this.props;
    values.working_hours = this.state.businessHours;
    values.addressGroup = this.state.addressGroup;
    values.formatted_address = this.state.formatted_address;
    let abn_txt = values.abn.match(/\d/g);
    abn_txt = abn_txt.join("");
    values.abn = parseInt(abn_txt, 10);
    if (values.online_order === 0) {
      values.payment_in_app = 0;
      values.take_out = 0;
    }
    //console.log(values, 'values');
    const lat = _.get(values.addressGroup, 'coordinates.lat');
    if (!lat) {
      message.error(t('shareable.enterSelectAddressPlease', { defaultValue: 'Please, enter correct address and select right option from a dropdown list' }));
      return false;
    }
    //console.log(values, 'values')
    this.setState({
      putREQUEST: true
    });

    fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/branches/create`, {
      method: 'PUT',
      body: values
    }).then(response => {
      console.log(response);
      if (response.success) {
        this.setState({
          putREQUEST: false,
          putSUCCESS: true,
          putFAILURE: false
        }, () => {
          this.setState({
            venueData: response.data
          }, () => {
            const { redirectedRestaurantUuid, redirectAccess } = this.state;
            if (redirectAccess && redirectedRestaurantUuid) {
              this.props.history.push(`/restaurants/${redirectedRestaurantUuid}?branches=1`);
            } else {
              this.props.history.push(`/branches/${response.data.uuid}`);
            }

          })
        });
      } else {
        this.setState({
          putREQUEST: false,
          putFAILURE: true,
          putSUCCESS: false,
        });
        message.error(response.message);
      }
      setTimeout(() => {
        this.setState({
          errorMessage: ''
        });
      }, 3000)
     }).catch(error => {
        console.log(error);
        this.setState({
          putSUCCESS: false,
          putREQUEST: false,
          putFAILURE: true,
        });
     });
  }
  validateAmountOfTables = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value) return Promise.resolve();
      if (parseInt(value, 10) && typeof parseInt(value, 10) === 'number') {
        if (Number.isInteger(parseFloat(value, 10))) {
          return Promise.resolve();
        } else {
          return Promise.reject(<span><Trans i18nKey="addBranch.amountOfTablesMustBeNumber">Amount must be a real number</Trans></span>);
        }
      } else {
        return Promise.reject(<span><Trans i18nKey="addBranch.amountOfTablesMustBeNumber">Amount must be a number</Trans></span>);
      }
    },
    validateTrigger: 'onSubmit'
  })
  validateABN = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value) return Promise.resolve();
      if (isValidABN(value)) {
        return Promise.resolve();
      } else {
        return Promise.reject(<span><Trans i18nKey="addBranch.abn_not_valid">ABN is not valid</Trans></span>);
      }
    },
    validateTrigger: 'onSubmit'
  })
  checkAmountOfBranchesNeed = (value) => {
    const { restaurants } = this.state;
    let shouldAskAmountBranches = false;
    if (restaurants && Array.isArray(restaurants)) {
      restaurants.forEach((restaurant) => {
        if (restaurant.id === value) {
          if ((restaurant.branch && Array.isArray(restaurant.branch) && !restaurant.branch.length) || !restaurant.expected_branches_amount) {
            shouldAskAmountBranches = true;
          }
        }
      })
    }
    this.setState({ shouldAskAmountBranches })
  }
  onlineOrderChange = (e) => {
    this.setState({
      shouldAskPayment: parseInt(e.target.value, 10)
    })
  }
  setFieldsValue = (name, value) => {
    this.formRef.current.setFieldsValue({
      [name]: value
    });
  }
  goBackToMenuType = () => {
    this.formRef.current.setFieldsValue({
      menu_type: undefined,
      file_id_menu: undefined,
      take_out: 0,
      online_order: 0,
      payment_in_app: 0,
      covid_checkin: true,
      shouldAskPayment: false
    });
    this.setState({
      menuTypeConfirmed: false
    })
  }

  render() {
    const { t } = this.props;
    const {
    	putREQUEST,
    	opening_hours,
    	businessHours,
      addressError,
      currentStep,
      createNewRest,
      allFieldsToForm,
      everVisitedStep,
      addressGroup,
      restaurantsList,
      redirectedRestauranId,
      redirectedRestaurantUuid,
      redirectAccess,
      isFetching,
      shouldAskAmountBranches,
      shouldAskPayment,
      menuTypeConfirmed,
      bannerUploadInProccess,
      logoUploadInProccess,
      menuAdded
    } = this.state;
    const { restaurantId } = this.props;

    const initialValues = {
      addressInput: "",
      online_order: 0,
      payment_in_app: 0,
      take_out: 0,
      covid_checkin: true
    };
    if (restaurantId) {
    	initialValues.restaurantId = restaurantId;
    }
    if (redirectAccess && redirectedRestauranId) {
      initialValues.restaurantId = redirectedRestauranId;
    }
    const userRole = this.props.role;
    const restIdRequired = createNewRest && userRole === 'admin' ? false : true;

    const canUseGoogleOpenHours = _.get(opening_hours, 'can_use_google');
    const goBackLink = redirectAccess && redirectedRestaurantUuid ? `/restaurants/${redirectedRestaurantUuid}?branches=1` : '/branches';
    return (
      <Content className="bg-transparent table-content">
      <Card className="general-card" style={{padding: '20px'}}
      title={
        <Row>
          <Link to={goBackLink} className="general-card__back-btn"><LeftOutlined /></Link>
          <span className="general-card__title-text">{t('shareable.addLocation', { defaultValue: 'Add Location' })}</span>
        </Row>
        }
      loading={isFetching}>
        <Steps
          current={currentStep}
          onChange={this.onStepChange}
          style={{ marginBottom: '30px'}}
        >
          <Step title={t('shareable.location', { defaultValue: 'Location'})} disabled={currentStep === 4}/>
          <Step title={t('shareable.addressAndContact', { defaultValue: 'Address & Contact'})} disabled={everVisitedStep < 1 || currentStep === 4} />
          <Step title={t('shareable.workingHours', { defaultValue: 'Working hours'})} disabled={everVisitedStep < 2 || currentStep === 4} />
          <Step title={t('shareable.menu', { defaultValue: 'Menu'})} disabled={everVisitedStep < 3 || currentStep === 4} />
          <Step title={t('shareable.customization', { defaultValue: 'Customization'})} disabled={everVisitedStep < 4} />
        </Steps>
        <Form className="general-form--big mobile-margin-fix-form" style={{ minHeight: '300px'}} ref={this.formRef} onFinish={this.handleSubmit} initialValues={initialValues} scrollToFirstError={true}>
        <Spin spinning={putREQUEST} >
         { currentStep === 0 || allFieldsToForm ?
          <div style={{ display: allFieldsToForm && currentStep !== 0 ? 'none' : '' }}>
            <Row gutter={8}>
              { createNewRest && userRole === 'admin' ? <div className="addLocation__xs--fw error-fix" >
                <Row>
                  <label className="formDefault__label">
                    <Trans i18nKey="shareable.restaurant_name">Restaurant name</Trans>
                  </label>
                </Row>
                <Row id='formitem_restaurant_name'>
                  <Form.Item className="addLocation__xs--fw" name="restaurant_name" rules={[{ required: true, message: t('shareable.required.restName', { defaultValue: 'Please enter a restaurant name' }) }]} >
                    <Input className="addLocation__input--short" placeholder={t('shareable.restaurant_name', { defaultValue: 'Restaurant name' })} />
                  </Form.Item>
                </Row>
              </div> : null }
                { !createNewRest && userRole === 'admin' ?
                  <div className="addLocation__xs--fw" style={{display: redirectAccess ? 'none' : ''}}>
                    <Row>
                      <label className="formDefault__label">
                        <Trans i18nKey="shareable.restaurant">Restaurant</Trans>
                      </label>
                    </Row>
                    <Row id='formitem_restaurantId'>
                      <Form.Item className="addLocation__xs--fw" name="restaurantId" rules={[{ required: {restIdRequired}, message: t('addLocation.restaurant.requiredChoice', { defaultValue: 'Please select restaurant!' }) }]} >
                        <Select
                          className="addLocation__select"
                          placeholder={t('shareable.selectFromList', { defaultValue: 'Select from the list'})}
                          onChange={(value) => this.checkAmountOfBranchesNeed(value)}
                          >
                          { restaurantsList.map((rest, key) => <Option key={key} value={rest.id}>{rest.title}</Option>) }
                        </Select>
                      </Form.Item>
                    </Row>
                  </div>
                : null }
                <div style={{display: userRole !== 'admin' || redirectAccess ? 'none' : ''}} className="addLocation__checkboxWrapper">
                  <Checkbox defaultChecked={createNewRest ? true : false} onChange={(e) => this.changeFieldStateCheckbox(e, 'createNewRest')}>
                    <Trans i18nKey="addLocation.addNewRest">Add new restaurant</Trans>
                    </Checkbox>
                </div>
              </Row>
              <Row>
                <Col span={24} style={{padding: 0}}>
                  <div>
                    <Row>
                      <label className="formDefault__label">
                        <Trans i18nKey="shareable.location_name">Location name</Trans>
                      </label>
                    </Row>
                    <Row id='formitem_title' className="error-fix">
                      <Form.Item className="addLocation__xs--fw" name="title" rules={[{ required: true, message: t('shareable.enterTitlePlease', { defaultValue: 'Please enter a location name' }) }]} >
                        <Input
                          className="addLocation__input--short"
                          placeholder={t('shareable.location_name', { defaultValue: 'Location name' })}
                          onChange={(e) => this.changeFieldStateValue(e, 'locationName')}
                          />
                      </Form.Item>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row className="error-fix">
                <FormItem className="addLocation__pd15 addLocation__xs--fw">
                  <Row id='formitem_businessType'>
                    <label className="formDefault__label">
                      <Trans i18nKey="shareable.location_type.label">Please type your ABN number</Trans>
                    </label>
                  </Row>
                  <FormItem
                    name="abn"
                    rules={[{required: true, message: t('shareable.tables.required', { defaultValue: 'Please enter ABN'}) }, this.validateABN ]}
                    style={{marginBottom: '0px'}}
                    >
                    <Input placeholder="ABN"/>
                  </FormItem>
                </FormItem>
              </Row>
              <Row>
                <FormItem className="addLocation__pd15 addLocation__xs--fw">
                  <Row id='formitem_businessType'>
                    <label className="formDefault__label">
                      <Trans i18nKey="shareable.business_type.label">Type of business</Trans>
                    </label>
                  </Row>
                  <FormItem
                    name="business_category_id"
                    rules={[{required: true, message: t('shareable.business_type.required', { defaultValue: 'Please choose your type of business'}) }]}
                    style={{marginBottom: '0px'}}
                    >
                    <Select
                      className="addLocation__selectType"
                      placeholder={t('shareable.business_type.placeholder', { defaultValue: 'Select type'})}
                    >
                      {this.renderBusinessCategoriesOptions()}
                    </Select>
                  </FormItem>
                </FormItem>
              </Row>
              { shouldAskAmountBranches || createNewRest ?
              <Row>
                <FormItem className="addLocation__pd15 addLocation__xs--fw">
                  <Row id='formitem_expected_branches_amount'>
                    <label className="formDefault__label">
                      <Trans i18nKey="shareable.howManyLocations.label">How many locations will you have?</Trans>
                    </label>
                  </Row>
                  <FormItem
                    name="expected_branches_amount"
                    rules={[{required: shouldAskAmountBranches || createNewRest, message: t('shareable.howManyLocations.required', { defaultValue: 'Please choose amount of locations'}) }]}
                    style={{marginBottom: '0px'}}
                    >
                    <Select
                      className="addLocation__selectType"
                      placeholder={t('shareable.business_type.placeholder', { defaultValue: 'Select amount'})}
                    >
                      <Option key={1} value={'1'}>1</Option>
                      <Option key={2} value={'2-4'}>2-4</Option>
                      <Option key={3} value={'5+'}>5+</Option>
                    </Select>
                  </FormItem>
                </FormItem>
              </Row> : null }
              <Row className="error-fix">
                <FormItem className="addLocation__pd15 addLocation__xs--fw">
                  <Row id='formitem_businessType'>
                    <label className="formDefault__label">
                      <Trans i18nKey="shareable.location_type.label">How many tables?</Trans>
                    </label>
                  </Row>
                  <FormItem
                    name="tablesAmount"
                    rules={[{required: true, message: t('shareable.tables.required', { defaultValue: 'Please enter amount of tables'}) }, this.validateAmountOfTables ]}
                    style={{marginBottom: '0px'}}
                    >
                    <Input placeholder="Amount of tables"/>
                  </FormItem>
                </FormItem>
              </Row>
              <Row className="error-fix">
                <FormItem className="addLocation__pd15 addLocation description-fix">
                  <Row id='formitem_description'>
                    <label className="formDefault__label">
                      <Trans i18nKey="shareable.location_type.label">Description</Trans>
                    </label>
                  </Row>
                  <FormItem
                    name="description"
                    rules={[{required: false }]}
                    style={{marginBottom: '0px'}}
                    >
                    <TextArea placeholder="E.g.: Quiet place with exquisite cuisine..." showCount maxLength={255}/>
                  </FormItem>
                </FormItem>
              </Row>
              <FormItem>
                <Row>
                  <Button className="general-card__submit-btn" style={{marginTop: '18px'}} type="primary" htmlType="button" onClick={this.nextStep}>
                    <Trans i18nKey="shareable.next">Next</Trans>
                  </Button>
                </Row>
              </FormItem>
            </div>

            : null }
            { currentStep === 1 || allFieldsToForm ?
            <div style={{ display: allFieldsToForm && currentStep !== 1 ? 'none' : '' }}>
              <FormItem name="address">
                <Row gutter={8}>
                  <Col xs={24} sm={12}>
                    <Row>
                      <label className="formDefault__label label-fix" style={{marginBottom: '12px', fontSize: '20px !important'}}>
                      <Trans i18nKey="shareable.address">Address</Trans></label>
                    </Row>
                    <Row>
                      <p style={{marginBottom: '6px', fontWeight: 400}}>
                        <Trans i18nKey="addressField.description">If your organization is present in Google Maps please search it by its name. Otherwise search by address.
                        </Trans>
                      </p>
                    </Row>
                    <Row id='formitem_addressInput'>
                       <AddressField
                          t={this.props.t}
                          value={addressGroup}
                          triggerChanges={this.triggerAddressChanges}
                          wrapIntoFormItem={true}
                        />
                    </Row>
                    { addressError ?
                      <div style={{marginTop: '20px'}}><Alert message={addressError} type="error" /></div>
                    : null }
                  </Col>
                </Row>
              </FormItem>
              <Row>
                <Col sm={15} xs={24}>
                  <Row>
                    <label className="formDefault__label label-fix" style={{color: '#454545', marginBottom: '1px'}}><Trans i18nKey="shareable.contact">Contact</Trans></label>
                  </Row>
                  <Row>
                    <p>This phone number will be shown for customers to call you if they need any help or have questions.</p>
                  </Row>
                  <Row className="error-fix">
                    <Col sm={8} xs={24}>
                      <FormItem className="addLocation__pd15 addLocation__xs--fw">
                        <Row id='formitem_contactName'>
                          <label className="formDefault__label">
                            <Trans i18nKey="shareable.name">Name</Trans>
                          </label>
                        </Row>
                        <FormItem
                          name="contact_name"
                          rules={[{ required: true, message: t('shareable.required.name', { defaultValue: 'Name is required!' }) }]}
                          style={{marginBottom: '0px'}}
                          >
                          <Input placeholder={ t('shareable.name', { defaultValue: 'Name' }) } />
                        </FormItem>
                      </FormItem>
                    </Col>
                    <Col sm={8} xs={24}>
                      <FormItem className="addLocation__pd15 addLocation__xs--fw">
                        <Row id='formitem_contactName'>
                          <label className="formDefault__label">
                            <Trans i18nKey="shareable.phone">Phone</Trans>
                          </label>
                        </Row>
                        <FormItem
                          name="contact_phone"
                          rules={[{ required: true, message: t('shareable.required.phone', { defaultValue: 'Phone is required!' }) }]}
                          style={{marginBottom: '0px'}}
                          >
                          <Input placeholder={t('shareable.phone', { defaultValue: 'Phone' })}/>
                        </FormItem>
                      </FormItem>
                    </Col>
                    <Col sm={8} xs={24}>
                      <FormItem className="addLocation__pd15 addLocation__xs--fw">
                        <Row id='formitem_contactName'>
                          <label className="formDefault__label">
                            <Trans i18nKey="shareable.email">Email</Trans>
                          </label>
                        </Row>
                        <FormItem
                          name="contact_email"
                          rules={[{
                            type: 'email',
                            message: t('login.email.wrongFormat', { defaultValue: 'Wrong format of email' }),
                            validateTrigger: 'onSubmit'
                          }, { required: true, message: t('shareable.required.email', { defaultValue: 'Email is required!' }) }]}
                          style={{marginBottom: '0px'}}
                          >
                          <Input placeholder={ t('shareable.email', { defaultValue: 'Email' }) } />
                        </FormItem>
                      </FormItem>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <FormItem>
                  <Row>
                    <Button className="general-card__submit-btn" type="primary" htmlType="button" onClick={this.nextStep}>
                      <Trans i18nKey="shareable.next">Next</Trans>
                    </Button>
                  </Row>
                </FormItem>
            </div>
            : null }
            { currentStep === 2 || allFieldsToForm ?
            <div style={{ display: allFieldsToForm && currentStep !== 2 ? 'none' : '' }}>
              <Row gutter={8} >
                <Col>
                  <Row>
                    <label style={{fontSize: '20px !important'}} className="formDefault__label label-fix"><Trans i18nKey="shareable.businessHours">Business hours</Trans>
                        {canUseGoogleOpenHours ? <span style={{fontSize: '14px', color:'green','paddingLeft': '10px'}}>
                      <GoogleOutlined /><Trans i18nKey="shareable.from">From</Trans> Google Places
                    </span> : null}</label>
                  </Row>
                  <Row style={{marginTop: '12px'}}>
                    <WorkingHours
                      businessHours={businessHours}
                      setBusinessHours={this.setBusinessHours}
                    />
                  </Row>
                </Col>
              </Row>
                <FormItem>
                  <Row>
                    <Button className="general-card__submit-btn" type="primary" onClick={this.nextStep}>
                      <Trans i18nKey="shareable.next">Next</Trans>
                    </Button>
                  </Row>
                </FormItem>
              </div>
            : null }
            { currentStep === 3 || allFieldsToForm ?
              <div style={{ display: allFieldsToForm && currentStep !== 3 ? 'none' : '' }}>
                <div style={{display: menuTypeConfirmed ? 'none' : ''}}>
                  <Row align="center">
                    <h2>What type of menu would you like to use?</h2>
                  </Row>
                  <Row align="center">
                    <Col xs={24} sm={16}>
                      <FormItem
                        name="menu_type"
                        rules={[{ required: true, message: ' ' }]}
                        style={{margin: '20px 0px 40px 0px'}}
                        >
                        <Radio.Group className="locationRadioButton" style={{marginLeft: 'auto', marginRight: 'auto'}}>
                          <Radio value="online" className="locationRadioButton__item locationRadioButton__item--menuType locationRadioButton__item--online">
                            <span className="locationRadioButton__title"><Trans i18nKey="shareable.directWall">Online ordering</Trans></span>
                            <span className="locationRadioButton__descr" style={{visibility: 'hidden'}}>Comission 1.75/2.9%+$0.3 per transaction</span>
                            <span className="locationRadioButton__icon locationRadioButton__icon--online"><MobileOutlined  className="locationRadioButton--iconOnline"/></span>
                          </Radio>
                          <Radio value="scan" className="locationRadioButton__item locationRadioButton__item--menuType locationRadioButton__item--scan">
                            <span className="locationRadioButton__title"><Trans i18nKey="shareable.wallPanel">Scan copy of your menu</Trans></span>
                            <span className="locationRadioButton__descr">Online ordering is not available for customers</span>
                            <span className="locationRadioButton__icon locationRadioButton__icon--scan"><SnippetsOutlined className="locationRadioButton--iconScan"/></span>
                          </Radio>
                        </Radio.Group>
                      </FormItem>
                    </Col>
                  </Row>
                    <FormItem>
                      <Row align="center">
                        <Button onClick={() => this.changeFieldStateSwitch(true, 'menuTypeConfirmed')} className="general-card__submit-btn" type="primary">
                          <Trans i18nKey="shareable.confirm">Confirm</Trans>
                        </Button>
                      </Row>
                    </FormItem>
                  </div>
                <div style={{ display: !menuTypeConfirmed ? 'none' : '' }}>
                { this.formRef.current && this.formRef.current.getFieldValue('menu_type') === 'online' ?
                  <div>
                  <Row>
                    <label className="formDefault__label">
                      <Trans i18nKey="shareable.restaurant_name">Covid-19 checkin</Trans>
                    </label>
                  </Row>
                  <Row id='formitem_covid_checkin'>
                    <Form.Item className="addLocation__xs--fw"
                      name="covid_checkin" rules={[{ required: true }]} >
                      <Radio.Group buttonStyle="solid">
                        <Radio.Button value={false}>Off</Radio.Button>
                        <Radio.Button value={true}>On</Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Row>
                  <Row>
                    <label className="formDefault__label">
                      <Trans i18nKey="shareable.restaurant_name">Online ordering via app</Trans>
                    </label>
                  </Row>
                  <Row id='formitem_online_order'>
                    <Form.Item className="addLocation__xs--fw" onChange={this.onlineOrderChange} name="online_order" rules={[{ required: true }]} >
                      <Radio.Group buttonStyle="solid">
                        <Radio.Button value={0}>Off</Radio.Button>
                        <Radio.Button value={1}>On</Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Row>
                  { shouldAskPayment ?
                  <div>
                    <Row>
                      <label className="orgering-settings-label">
                        <Trans i18nKey="shareable.restaurant_name">Payment via app</Trans>
                      </label>
                    </Row>
                    <Row>
                      <p style={{marginBottom: '8px'}}>Comission 1.75/2.9%+$0.3 per transaction</p>
                    </Row>
                    <Row id='formitem_payment_in_app'>
                      <Form.Item className="addLocation__xs--fw" name="payment_in_app" rules={[{ required: false }]} >
                        <Radio.Group buttonStyle="solid">
                          <Radio.Button value={0}>Off</Radio.Button>
                          <Radio.Button value={1}>On</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    </Row>
                      <Row>
                        <label className="formDefault__label">
                          <Trans i18nKey="shareable.takeaway">Takeaway</Trans>
                        </label>
                      </Row>
                      <Row id='formitem_take_out'>
                        <Form.Item className="addLocation__xs--fw" name="take_out" rules={[{ required: true }]} >
                          <Radio.Group buttonStyle="solid">
                            <Radio.Button value={0}>Off</Radio.Button>
                            <Radio.Button value={1}>On</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Row>
                    </div> : null }
                  </div> : null }
                  { this.formRef.current && this.formRef.current.getFieldValue('menu_type') === 'scan' ?
                    <div>
                      <Col xs={24} sm={8}>
                        <PdfBranch
                          placement="branch_menu_new"
                          type="menu"
                          extra="on_first_add"
                          changeUploadStatus={(value) => this.setState({ menuChangingProccess: value, menuAdded: !value })}
                          setFieldsValue={this.setFieldsValue}
                        />
                      </Col>
                      <Form.Item name="file_id_menu" style={{ display: 'none' }}>
                        <Input placeholder="file_id_menu"/>
                      </Form.Item>
                    </div>
                    : null }
                    <FormItem>
                      <Row>
                        <Button
                          disabled={this.formRef.current && this.formRef.current.getFieldValue('menu_type') === 'scan' && !menuAdded}
                          onClick={this.nextStep} className="general-card__submit-btn" type="primary">
                          <Trans i18nKey="shareable.next">Next</Trans>
                        </Button>
                        <Button onClick={this.goBackToMenuType} className="general-card__submit-btn" style={{marginLeft: '8px'}}>
                          Change type
                        </Button>
                      </Row>
                    </FormItem>
                  </div>
                </div>
              : null }
            { currentStep === 4 || allFieldsToForm ?
              <div style={{ display: allFieldsToForm && currentStep !== 4 ? 'none' : '' }}>
                <Spin size="large" spinning={bannerUploadInProccess || logoUploadInProccess} tip="Loading...">
                  <Row style={{marginTop: '8px'}}>
                    <h2>Images</h2>
                  </Row>
                  <Row>
                    <h3 style={{color: '#1C8DCC'}}>
                      <InfoCircleTwoTone twoToneColor="#1C8DCC" style={{marginRight: '6px'}} />
                      Images will be saved automatically after loading.
                    </h3>
                  </Row>
                  <Col xs={24} sm={12}>
                    <ImageUpload
                      type="branch_logo_new"
                      label="Logo"
                      changeUploadStatus={(value) => this.changeFieldStateSwitch(value, 'logoUploadInProccess')}
                      setFieldsValue={this.setFieldsValue}
                      setImageCoverUrl={(value) => this.changeFieldStateSwitch(value, 'imageCoverUrl')}
                      />
                    <Form.Item name="file_id_logo" style={{ display: 'none' }}>
                      <Input placeholder="file_id_logo"/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <ImageUpload
                      type="branch_banner_new"
                      label="Banner"
                      changeUploadStatus={(value) => this.changeFieldStateSwitch(value, 'bannerUploadInProccess')}
                      setFieldsValue={this.setFieldsValue}
                      setImageCoverUrl={(value) => this.changeFieldStateSwitch(value, 'imageCoverUrl')}
                      />
                    <Form.Item name="file_id_banner" style={{ display: 'none' }}>
                      <Input placeholder="file_id_banner" />
                    </Form.Item>
                  </Col>
                  <FormItem>
                    <Row className="loading-btn">
                      <Button disabled={bannerUploadInProccess || logoUploadInProccess} loading={logoUploadInProccess || bannerUploadInProccess} className="general-card__submit-btn" type="primary" htmlType="submit">
                        <Trans i18nKey="shareable.create">Create</Trans>
                      </Button>
                    </Row>
                  </FormItem>
                  </Spin>
              </div> : null }
            </Spin>
          </Form>
      </Card>
      </Content>
    )
  }
}

export default withTranslation()(withWindowSizeListener(AddBranch));
