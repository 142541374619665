import React from 'react';
import fetchJSON from 'services/utils/fetchJSON';
import { withTranslation } from 'react-i18next';
import { Row, Modal, Form, Input, Col, message } from 'antd';

import '../styles.less';

const FormItem = Form.Item;
const { TextArea } = Input;

class AddCategory extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      putREQUEST: false
    };
  }
	componentDidMount = () => {
    this.mounted = true;
  }
  componentWillUnmount = () => {
    this.mounted = false;
  }
  handleSubmit = () => {
    const { t } = this.props;
    return this.formRef.current.validateFields()
    .then((values) => {
      if (this.props.restaurantId) {
        values.restaurantId = this.props.restaurantId;
      }
      console.log('values', values);
      this.setState({
        putREQUEST: true
      });
      fetchJSON(`https://server.dezy.com.auhttps://server.dezy.com.au/api/v2/products/categories/create`, {
        method: 'put',
        body: values
      }).then(response => {
        console.log(response);
        if (response.success) {
          this.setState({
            // putREQUEST: false,
            putSUCCESS: true,
            putFAILURE: false
          }, () => {
            setTimeout(() => {
              message.success(t('singleLocation.feedback.saveSuccess', { defaultValue: 'Saved successfully' }));
              this.props.handleClose();
              this.props.updateCategoriesList();
            }, 500);
          });          
        } else {
          this.setState({
            putREQUEST: false,
            putFAILURE: true,
            putSUCCESS: false,
            //errorMessage: response.message
          });
          message.error(response.message);
        }
       }).catch(error => {
          console.log(error);
          this.setState({
            putSUCCESS: false,
            putREQUEST: false,
            putFAILURE: true,
          });
          message.error(t('shareable.ERR', { defaultValue: 'ERR' }));
       });
    }).catch((err) => console.log('validate err', err))
  }

  render() {
    const { t } = this.props;
    const { 
      putREQUEST
    } = this.state;
    return (
      <Modal 
        className="general-modal-btns-center general-card"
        title="New category"
        centered={true}
        visible={this.props.visible}
        okButtonProps={{htmlType: "submit"}}
        okText="Add"
        onOk={() => this.handleSubmit()}
        onCancel={this.props.handleClose}
        destroyOnClose={true}
        confirmLoading={putREQUEST}
      >
        <Form 
          requiredMark={false} 
          className="general-form" 
          layout="vertical" 
          ref={this.formRef} 
        >
          <Row>
            <Col span={24}>
              <FormItem rules={[{ required: true, message: t('shareable.required.name', { defaultValue: 'Name is required!' }) }]} name="display_name" label="Name">
                <Input placeholder="Name"/>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormItem name="description" label="Description" style={{marginBottom: '0px'}}>
                <TextArea placeholder="E.g.: Italian food is always a matter of regional pride..." showCount maxLength={255}/>
              </FormItem>
            </Col>
          </Row>
        </Form> 
      </Modal>
    )
  }
};

export default withTranslation()(AddCategory);